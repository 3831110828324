// GLOBAL VARIABLES
// ===========================================================

// ==========[ GLOBALE KLEUR VOOR URLS ]==========
$global-link-color: #61A6C7;
$global-link-hover-color: #61A6C7;

// ==========[ GLOBALE KLEUREN ]==========
$global-background: #ffffff;
$global-primary-background: #61A6C7;
$global-success-background: #1eaf3a;
$global-warning-background: #ff6601;
$global-danger-background: #D60727;

// ==========[ DEFAULT FONT FAMILY ]==========
$global-font-family: myriad-pro, sans-serif;

// ==========[ GLOBAL MARGINS ]==========
$global-large-margin: 100px;
$global-medium-margin: 60px;
$global-small-margin: 10px;

// ==========[ GLOBAL BORDERS ]==========
$global-border-width: 1px;
$global-border: #e8e8e8;
$grid-divider-border: $global-border;

// ==========[ GRID ]==========

//GUTTER
$global-gutter: 60px;
$grid-gutter-horizontal: 60px;
$grid-gutter-vertical: 60px;
$global-large-gutter:60;
$grid-large-gutter-horizontal: 60px;
$grid-large-gutter-vertical: 60px;
$global-medium-gutter:60;
$grid-medium-gutter-horizontal: 20px;
$grid-medium-gutter-vertical: 40px;
$global-small-gutter: 60px;
$grid-small-gutter-horizontal: 24px;
$grid-small-gutter-vertical: 24px;


// OFF CANVAS NAV VARIABLES
// ===========================================================

// ==========[ OFFCANVAS NAV ACHTERGROND KLEUR ]==========
$offcanvas-bar-background: #fff;

// ==========[ KLEUR INHOUD VAN DE OFFCANVAS NAV (UK-LIGHT, UK-DARK, NONE) ]==========
$offcanvas-bar-color-mode: none;

// CONTAINERS
// ===========================================================

// ==========[ CONTAINER BREEDTES ]==========
$container-max-width: 1600px;
$container-xsmall-max-width: 750px;
$container-small-max-width: 900px;
$container-large-max-width: 1600px;
