@charset "UTF-8";
/*!
 * Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
:root, :host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-brands-400.woff2") format("woff2"), url("../webfonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa-42-group:before {
  content: "\e080";
}

.fa-innosoft:before {
  content: "\e080";
}

.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-audible:before {
  content: "\f373";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-aws:before {
  content: "\f375";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bilibili:before {
  content: "\e3d9";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-bots:before {
  content: "\e340";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-buy-n-large:before {
  content: "\f8a6";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-cloudflare:before {
  content: "\e07d";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-cmplid:before {
  content: "\e360";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-cotton-bureau:before {
  content: "\f89e";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dailymotion:before {
  content: "\e052";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-deezer:before {
  content: "\e077";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edge-legacy:before {
  content: "\e078";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envira:before {
  content: "\f299";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-figma:before {
  content: "\f799";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-firefox-browser:before {
  content: "\e007";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-fly:before {
  content: "\f417";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-flag:before {
  content: "\f2b4";
}

.fa-font-awesome-logo-full:before {
  content: "\f2b4";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golang:before {
  content: "\e40f";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-pay:before {
  content: "\e079";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guilded:before {
  content: "\e07e";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hashnode:before {
  content: "\e499";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-hive:before {
  content: "\e07f";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-ideal:before {
  content: "\e013";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-instagram-square:before {
  content: "\e055";
}

.fa-instalod:before {
  content: "\e081";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-less:before {
  content: "\f41d";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-mdb:before {
  content: "\f8ca";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f23a";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-meta:before {
  content: "\e49b";
}

.fa-microblog:before {
  content: "\e01a";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mixer:before {
  content: "\e056";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neos:before {
  content: "\f612";
}

.fa-nfc-directional:before {
  content: "\e530";
}

.fa-nfc-symbol:before {
  content: "\e531";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-octopus-deploy:before {
  content: "\e082";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-orcid:before {
  content: "\f8d2";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-padlet:before {
  content: "\e4a0";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-perbyte:before {
  content: "\e083";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pied-piper-square:before {
  content: "\e01e";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-pix:before {
  content: "\e43a";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-rust:before {
  content: "\e07a";
}

.fa-safari:before {
  content: "\f267";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-screenpal:before {
  content: "\e570";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shopify:before {
  content: "\e057";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sith:before {
  content: "\f512";
}

.fa-sitrox:before {
  content: "\e44a";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f198";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ab";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-square-font-awesome:before {
  content: "\f425";
}

.fa-square-font-awesome-stroke:before {
  content: "\f35c";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swift:before {
  content: "\f8e1";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f2c6";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-tiktok:before {
  content: "\e07b";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trello:before {
  content: "\f181";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbraco:before {
  content: "\f8e8";
}

.fa-uncharted:before {
  content: "\e084";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-unity:before {
  content: "\e049";
}

.fa-unsplash:before {
  content: "\e07c";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-viber:before {
  content: "\f409";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-watchman-monitoring:before {
  content: "\e087";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wirsindhandwerk:before {
  content: "\e2d0";
}

.fa-wsh:before {
  content: "\e2d0";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wodu:before {
  content: "\e088";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

/*!
 * Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
:root, :host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../webfonts/fa-light-300.woff2") format("woff2"), url("../webfonts/fa-light-300.ttf") format("truetype");
}
.fal,
.fa-light {
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/*!
 * Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
:root, :host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

/*!
 * Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
:root, :host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}

/*!
 * Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
.fa-bounce,
.fa-fade,
.fa-beat-fade,
.fa-flip,
.fa-pulse,
.fa-shake,
.fa-spin,
.fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  8%, 24% {
    transform: rotate(-18deg);
  }
  12%, 28% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%, 100% {
    transform: rotate(0deg);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "\30 ";
}

.fa-1::before {
  content: "\31 ";
}

.fa-2::before {
  content: "\32 ";
}

.fa-3::before {
  content: "\33 ";
}

.fa-4::before {
  content: "\34 ";
}

.fa-5::before {
  content: "\35 ";
}

.fa-6::before {
  content: "\36 ";
}

.fa-7::before {
  content: "\37 ";
}

.fa-8::before {
  content: "\38 ";
}

.fa-9::before {
  content: "\39 ";
}

.fa-00::before {
  content: "\e467";
}

.fa-360-degrees::before {
  content: "\e2dc";
}

.fa-a::before {
  content: "A";
}

.fa-abacus::before {
  content: "\f640";
}

.fa-accent-grave::before {
  content: "\`";
}

.fa-acorn::before {
  content: "\f6ae";
}

.fa-address-book::before {
  content: "\f2b9";
}

.fa-contact-book::before {
  content: "\f2b9";
}

.fa-address-card::before {
  content: "\f2bb";
}

.fa-contact-card::before {
  content: "\f2bb";
}

.fa-vcard::before {
  content: "\f2bb";
}

.fa-air-conditioner::before {
  content: "\f8f4";
}

.fa-airplay::before {
  content: "\e089";
}

.fa-alarm-clock::before {
  content: "\f34e";
}

.fa-alarm-exclamation::before {
  content: "\f843";
}

.fa-alarm-plus::before {
  content: "\f844";
}

.fa-alarm-snooze::before {
  content: "\f845";
}

.fa-album::before {
  content: "\f89f";
}

.fa-album-circle-plus::before {
  content: "\e48c";
}

.fa-album-circle-user::before {
  content: "\e48d";
}

.fa-album-collection::before {
  content: "\f8a0";
}

.fa-album-collection-circle-plus::before {
  content: "\e48e";
}

.fa-album-collection-circle-user::before {
  content: "\e48f";
}

.fa-alicorn::before {
  content: "\f6b0";
}

.fa-alien::before {
  content: "\f8f5";
}

.fa-alien-8bit::before {
  content: "\f8f6";
}

.fa-alien-monster::before {
  content: "\f8f6";
}

.fa-align-center::before {
  content: "\f037";
}

.fa-align-justify::before {
  content: "\f039";
}

.fa-align-left::before {
  content: "\f036";
}

.fa-align-right::before {
  content: "\f038";
}

.fa-align-slash::before {
  content: "\f846";
}

.fa-alt::before {
  content: "\e08a";
}

.fa-amp-guitar::before {
  content: "\f8a1";
}

.fa-ampersand::before {
  content: "\&";
}

.fa-anchor::before {
  content: "\f13d";
}

.fa-anchor-circle-check::before {
  content: "\e4aa";
}

.fa-anchor-circle-exclamation::before {
  content: "\e4ab";
}

.fa-anchor-circle-xmark::before {
  content: "\e4ac";
}

.fa-anchor-lock::before {
  content: "\e4ad";
}

.fa-angel::before {
  content: "\f779";
}

.fa-angle::before {
  content: "\e08c";
}

.fa-angle-90::before {
  content: "\e08d";
}

.fa-angle-down::before {
  content: "\f107";
}

.fa-angle-left::before {
  content: "\f104";
}

.fa-angle-right::before {
  content: "\f105";
}

.fa-angle-up::before {
  content: "\f106";
}

.fa-angles-down::before {
  content: "\f103";
}

.fa-angle-double-down::before {
  content: "\f103";
}

.fa-angles-left::before {
  content: "\f100";
}

.fa-angle-double-left::before {
  content: "\f100";
}

.fa-angles-right::before {
  content: "\f101";
}

.fa-angle-double-right::before {
  content: "\f101";
}

.fa-angles-up::before {
  content: "\f102";
}

.fa-angle-double-up::before {
  content: "\f102";
}

.fa-ankh::before {
  content: "\f644";
}

.fa-apartment::before {
  content: "\e468";
}

.fa-aperture::before {
  content: "\e2df";
}

.fa-apostrophe::before {
  content: "\'";
}

.fa-apple-core::before {
  content: "\e08f";
}

.fa-apple-whole::before {
  content: "\f5d1";
}

.fa-apple-alt::before {
  content: "\f5d1";
}

.fa-archway::before {
  content: "\f557";
}

.fa-arrow-down::before {
  content: "\f063";
}

.fa-arrow-down-1-9::before {
  content: "\f162";
}

.fa-sort-numeric-asc::before {
  content: "\f162";
}

.fa-sort-numeric-down::before {
  content: "\f162";
}

.fa-arrow-down-9-1::before {
  content: "\f886";
}

.fa-sort-numeric-desc::before {
  content: "\f886";
}

.fa-sort-numeric-down-alt::before {
  content: "\f886";
}

.fa-arrow-down-a-z::before {
  content: "\f15d";
}

.fa-sort-alpha-asc::before {
  content: "\f15d";
}

.fa-sort-alpha-down::before {
  content: "\f15d";
}

.fa-arrow-down-arrow-up::before {
  content: "\f883";
}

.fa-sort-alt::before {
  content: "\f883";
}

.fa-arrow-down-big-small::before {
  content: "\f88c";
}

.fa-sort-size-down::before {
  content: "\f88c";
}

.fa-arrow-down-from-dotted-line::before {
  content: "\e090";
}

.fa-arrow-down-from-line::before {
  content: "\f345";
}

.fa-arrow-from-top::before {
  content: "\f345";
}

.fa-arrow-down-left::before {
  content: "\e091";
}

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092";
}

.fa-arrow-down-long::before {
  content: "\f175";
}

.fa-long-arrow-down::before {
  content: "\f175";
}

.fa-arrow-down-right::before {
  content: "\e093";
}

.fa-arrow-down-short-wide::before {
  content: "\f884";
}

.fa-sort-amount-desc::before {
  content: "\f884";
}

.fa-sort-amount-down-alt::before {
  content: "\f884";
}

.fa-arrow-down-small-big::before {
  content: "\f88d";
}

.fa-sort-size-down-alt::before {
  content: "\f88d";
}

.fa-arrow-down-square-triangle::before {
  content: "\f889";
}

.fa-sort-shapes-down-alt::before {
  content: "\f889";
}

.fa-arrow-down-to-arc::before {
  content: "\e4ae";
}

.fa-arrow-down-to-bracket::before {
  content: "\e094";
}

.fa-arrow-down-to-dotted-line::before {
  content: "\e095";
}

.fa-arrow-down-to-line::before {
  content: "\f33d";
}

.fa-arrow-to-bottom::before {
  content: "\f33d";
}

.fa-arrow-down-to-square::before {
  content: "\e096";
}

.fa-arrow-down-triangle-square::before {
  content: "\f888";
}

.fa-sort-shapes-down::before {
  content: "\f888";
}

.fa-arrow-down-up-across-line::before {
  content: "\e4af";
}

.fa-arrow-down-up-lock::before {
  content: "\e4b0";
}

.fa-arrow-down-wide-short::before {
  content: "\f160";
}

.fa-sort-amount-asc::before {
  content: "\f160";
}

.fa-sort-amount-down::before {
  content: "\f160";
}

.fa-arrow-down-z-a::before {
  content: "\f881";
}

.fa-sort-alpha-desc::before {
  content: "\f881";
}

.fa-sort-alpha-down-alt::before {
  content: "\f881";
}

.fa-arrow-left::before {
  content: "\f060";
}

.fa-arrow-left-from-line::before {
  content: "\f344";
}

.fa-arrow-from-right::before {
  content: "\f344";
}

.fa-arrow-left-long::before {
  content: "\f177";
}

.fa-long-arrow-left::before {
  content: "\f177";
}

.fa-arrow-left-long-to-line::before {
  content: "\e3d4";
}

.fa-arrow-left-to-line::before {
  content: "\f33e";
}

.fa-arrow-to-left::before {
  content: "\f33e";
}

.fa-arrow-pointer::before {
  content: "\f245";
}

.fa-mouse-pointer::before {
  content: "\f245";
}

.fa-arrow-right::before {
  content: "\f061";
}

.fa-arrow-right-arrow-left::before {
  content: "\f0ec";
}

.fa-exchange::before {
  content: "\f0ec";
}

.fa-arrow-right-from-arc::before {
  content: "\e4b1";
}

.fa-arrow-right-from-bracket::before {
  content: "\f08b";
}

.fa-sign-out::before {
  content: "\f08b";
}

.fa-arrow-right-from-line::before {
  content: "\f343";
}

.fa-arrow-from-left::before {
  content: "\f343";
}

.fa-arrow-right-long::before {
  content: "\f178";
}

.fa-long-arrow-right::before {
  content: "\f178";
}

.fa-arrow-right-long-to-line::before {
  content: "\e3d5";
}

.fa-arrow-right-to-arc::before {
  content: "\e4b2";
}

.fa-arrow-right-to-bracket::before {
  content: "\f090";
}

.fa-sign-in::before {
  content: "\f090";
}

.fa-arrow-right-to-city::before {
  content: "\e4b3";
}

.fa-arrow-right-to-line::before {
  content: "\f340";
}

.fa-arrow-to-right::before {
  content: "\f340";
}

.fa-arrow-rotate-left::before {
  content: "\f0e2";
}

.fa-arrow-left-rotate::before {
  content: "\f0e2";
}

.fa-arrow-rotate-back::before {
  content: "\f0e2";
}

.fa-arrow-rotate-backward::before {
  content: "\f0e2";
}

.fa-undo::before {
  content: "\f0e2";
}

.fa-arrow-rotate-right::before {
  content: "\f01e";
}

.fa-arrow-right-rotate::before {
  content: "\f01e";
}

.fa-arrow-rotate-forward::before {
  content: "\f01e";
}

.fa-redo::before {
  content: "\f01e";
}

.fa-arrow-trend-down::before {
  content: "\e097";
}

.fa-arrow-trend-up::before {
  content: "\e098";
}

.fa-arrow-turn-down::before {
  content: "\f149";
}

.fa-level-down::before {
  content: "\f149";
}

.fa-arrow-turn-down-left::before {
  content: "\e2e1";
}

.fa-arrow-turn-down-right::before {
  content: "\e3d6";
}

.fa-arrow-turn-up::before {
  content: "\f148";
}

.fa-level-up::before {
  content: "\f148";
}

.fa-arrow-up::before {
  content: "\f062";
}

.fa-arrow-up-1-9::before {
  content: "\f163";
}

.fa-sort-numeric-up::before {
  content: "\f163";
}

.fa-arrow-up-9-1::before {
  content: "\f887";
}

.fa-sort-numeric-up-alt::before {
  content: "\f887";
}

.fa-arrow-up-a-z::before {
  content: "\f15e";
}

.fa-sort-alpha-up::before {
  content: "\f15e";
}

.fa-arrow-up-arrow-down::before {
  content: "\e099";
}

.fa-sort-up-down::before {
  content: "\e099";
}

.fa-arrow-up-big-small::before {
  content: "\f88e";
}

.fa-sort-size-up::before {
  content: "\f88e";
}

.fa-arrow-up-from-arc::before {
  content: "\e4b4";
}

.fa-arrow-up-from-bracket::before {
  content: "\e09a";
}

.fa-arrow-up-from-dotted-line::before {
  content: "\e09b";
}

.fa-arrow-up-from-ground-water::before {
  content: "\e4b5";
}

.fa-arrow-up-from-line::before {
  content: "\f342";
}

.fa-arrow-from-bottom::before {
  content: "\f342";
}

.fa-arrow-up-from-square::before {
  content: "\e09c";
}

.fa-arrow-up-from-water-pump::before {
  content: "\e4b6";
}

.fa-arrow-up-left::before {
  content: "\e09d";
}

.fa-arrow-up-left-from-circle::before {
  content: "\e09e";
}

.fa-arrow-up-long::before {
  content: "\f176";
}

.fa-long-arrow-up::before {
  content: "\f176";
}

.fa-arrow-up-right::before {
  content: "\e09f";
}

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0";
}

.fa-arrow-up-right-dots::before {
  content: "\e4b7";
}

.fa-arrow-up-right-from-square::before {
  content: "\f08e";
}

.fa-external-link::before {
  content: "\f08e";
}

.fa-arrow-up-short-wide::before {
  content: "\f885";
}

.fa-sort-amount-up-alt::before {
  content: "\f885";
}

.fa-arrow-up-small-big::before {
  content: "\f88f";
}

.fa-sort-size-up-alt::before {
  content: "\f88f";
}

.fa-arrow-up-square-triangle::before {
  content: "\f88b";
}

.fa-sort-shapes-up-alt::before {
  content: "\f88b";
}

.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1";
}

.fa-arrow-up-to-line::before {
  content: "\f341";
}

.fa-arrow-to-top::before {
  content: "\f341";
}

.fa-arrow-up-triangle-square::before {
  content: "\f88a";
}

.fa-sort-shapes-up::before {
  content: "\f88a";
}

.fa-arrow-up-wide-short::before {
  content: "\f161";
}

.fa-sort-amount-up::before {
  content: "\f161";
}

.fa-arrow-up-z-a::before {
  content: "\f882";
}

.fa-sort-alpha-up-alt::before {
  content: "\f882";
}

.fa-arrows-cross::before {
  content: "\e0a2";
}

.fa-arrows-down-to-line::before {
  content: "\e4b8";
}

.fa-arrows-down-to-people::before {
  content: "\e4b9";
}

.fa-arrows-from-dotted-line::before {
  content: "\e0a3";
}

.fa-arrows-from-line::before {
  content: "\e0a4";
}

.fa-arrows-left-right::before {
  content: "\f07e";
}

.fa-arrows-h::before {
  content: "\f07e";
}

.fa-arrows-left-right-to-line::before {
  content: "\e4ba";
}

.fa-arrows-maximize::before {
  content: "\f31d";
}

.fa-expand-arrows::before {
  content: "\f31d";
}

.fa-arrows-minimize::before {
  content: "\e0a5";
}

.fa-compress-arrows::before {
  content: "\e0a5";
}

.fa-arrows-repeat::before {
  content: "\f364";
}

.fa-repeat-alt::before {
  content: "\f364";
}

.fa-arrows-repeat-1::before {
  content: "\f366";
}

.fa-repeat-1-alt::before {
  content: "\f366";
}

.fa-arrows-retweet::before {
  content: "\f361";
}

.fa-retweet-alt::before {
  content: "\f361";
}

.fa-arrows-rotate::before {
  content: "\f021";
}

.fa-refresh::before {
  content: "\f021";
}

.fa-sync::before {
  content: "\f021";
}

.fa-arrows-spin::before {
  content: "\e4bb";
}

.fa-arrows-split-up-and-left::before {
  content: "\e4bc";
}

.fa-arrows-to-circle::before {
  content: "\e4bd";
}

.fa-arrows-to-dot::before {
  content: "\e4be";
}

.fa-arrows-to-dotted-line::before {
  content: "\e0a6";
}

.fa-arrows-to-eye::before {
  content: "\e4bf";
}

.fa-arrows-to-line::before {
  content: "\e0a7";
}

.fa-arrows-turn-right::before {
  content: "\e4c0";
}

.fa-arrows-turn-to-dots::before {
  content: "\e4c1";
}

.fa-arrows-up-down::before {
  content: "\f07d";
}

.fa-arrows-v::before {
  content: "\f07d";
}

.fa-arrows-up-down-left-right::before {
  content: "\f047";
}

.fa-arrows::before {
  content: "\f047";
}

.fa-arrows-up-to-line::before {
  content: "\e4c2";
}

.fa-asterisk::before {
  content: "\*";
}

.fa-at::before {
  content: "\@";
}

.fa-atom::before {
  content: "\f5d2";
}

.fa-atom-simple::before {
  content: "\f5d3";
}

.fa-atom-alt::before {
  content: "\f5d3";
}

.fa-audio-description::before {
  content: "\f29e";
}

.fa-audio-description-slash::before {
  content: "\e0a8";
}

.fa-austral-sign::before {
  content: "\e0a9";
}

.fa-avocado::before {
  content: "\e0aa";
}

.fa-award::before {
  content: "\f559";
}

.fa-award-simple::before {
  content: "\e0ab";
}

.fa-axe::before {
  content: "\f6b2";
}

.fa-axe-battle::before {
  content: "\f6b3";
}

.fa-b::before {
  content: "B";
}

.fa-baby::before {
  content: "\f77c";
}

.fa-baby-carriage::before {
  content: "\f77d";
}

.fa-carriage-baby::before {
  content: "\f77d";
}

.fa-backpack::before {
  content: "\f5d4";
}

.fa-backward::before {
  content: "\f04a";
}

.fa-backward-fast::before {
  content: "\f049";
}

.fa-fast-backward::before {
  content: "\f049";
}

.fa-backward-step::before {
  content: "\f048";
}

.fa-step-backward::before {
  content: "\f048";
}

.fa-bacon::before {
  content: "\f7e5";
}

.fa-bacteria::before {
  content: "\e059";
}

.fa-bacterium::before {
  content: "\e05a";
}

.fa-badge::before {
  content: "\f335";
}

.fa-badge-check::before {
  content: "\f336";
}

.fa-badge-dollar::before {
  content: "\f645";
}

.fa-badge-percent::before {
  content: "\f646";
}

.fa-badge-sheriff::before {
  content: "\f8a2";
}

.fa-badger-honey::before {
  content: "\f6b4";
}

.fa-badminton::before {
  content: "\e33a";
}

.fa-bag-shopping::before {
  content: "\f290";
}

.fa-shopping-bag::before {
  content: "\f290";
}

.fa-bagel::before {
  content: "\e3d7";
}

.fa-bags-shopping::before {
  content: "\f847";
}

.fa-baguette::before {
  content: "\e3d8";
}

.fa-bahai::before {
  content: "\f666";
}

.fa-baht-sign::before {
  content: "\e0ac";
}

.fa-ball-pile::before {
  content: "\f77e";
}

.fa-balloon::before {
  content: "\e2e3";
}

.fa-balloons::before {
  content: "\e2e4";
}

.fa-ballot::before {
  content: "\f732";
}

.fa-ballot-check::before {
  content: "\f733";
}

.fa-ban::before {
  content: "\f05e";
}

.fa-cancel::before {
  content: "\f05e";
}

.fa-ban-bug::before {
  content: "\f7f9";
}

.fa-debug::before {
  content: "\f7f9";
}

.fa-ban-parking::before {
  content: "\f616";
}

.fa-parking-circle-slash::before {
  content: "\f616";
}

.fa-ban-smoking::before {
  content: "\f54d";
}

.fa-smoking-ban::before {
  content: "\f54d";
}

.fa-banana::before {
  content: "\e2e5";
}

.fa-bandage::before {
  content: "\f462";
}

.fa-band-aid::before {
  content: "\f462";
}

.fa-bangladeshi-taka-sign::before {
  content: "\e2e6";
}

.fa-banjo::before {
  content: "\f8a3";
}

.fa-barcode::before {
  content: "\f02a";
}

.fa-barcode-read::before {
  content: "\f464";
}

.fa-barcode-scan::before {
  content: "\f465";
}

.fa-bars::before {
  content: "\f0c9";
}

.fa-navicon::before {
  content: "\f0c9";
}

.fa-bars-filter::before {
  content: "\e0ad";
}

.fa-bars-progress::before {
  content: "\f828";
}

.fa-tasks-alt::before {
  content: "\f828";
}

.fa-bars-sort::before {
  content: "\e0ae";
}

.fa-bars-staggered::before {
  content: "\f550";
}

.fa-reorder::before {
  content: "\f550";
}

.fa-stream::before {
  content: "\f550";
}

.fa-baseball::before {
  content: "\f433";
}

.fa-baseball-ball::before {
  content: "\f433";
}

.fa-baseball-bat-ball::before {
  content: "\f432";
}

.fa-basket-shopping::before {
  content: "\f291";
}

.fa-shopping-basket::before {
  content: "\f291";
}

.fa-basket-shopping-simple::before {
  content: "\e0af";
}

.fa-shopping-basket-alt::before {
  content: "\e0af";
}

.fa-basketball::before {
  content: "\f434";
}

.fa-basketball-ball::before {
  content: "\f434";
}

.fa-basketball-hoop::before {
  content: "\f435";
}

.fa-bat::before {
  content: "\f6b5";
}

.fa-bath::before {
  content: "\f2cd";
}

.fa-bathtub::before {
  content: "\f2cd";
}

.fa-battery-bolt::before {
  content: "\f376";
}

.fa-battery-empty::before {
  content: "\f244";
}

.fa-battery-0::before {
  content: "\f244";
}

.fa-battery-exclamation::before {
  content: "\e0b0";
}

.fa-battery-full::before {
  content: "\f240";
}

.fa-battery::before {
  content: "\f240";
}

.fa-battery-5::before {
  content: "\f240";
}

.fa-battery-half::before {
  content: "\f242";
}

.fa-battery-3::before {
  content: "\f242";
}

.fa-battery-low::before {
  content: "\e0b1";
}

.fa-battery-1::before {
  content: "\e0b1";
}

.fa-battery-quarter::before {
  content: "\f243";
}

.fa-battery-2::before {
  content: "\f243";
}

.fa-battery-slash::before {
  content: "\f377";
}

.fa-battery-three-quarters::before {
  content: "\f241";
}

.fa-battery-4::before {
  content: "\f241";
}

.fa-bed::before {
  content: "\f236";
}

.fa-bed-bunk::before {
  content: "\f8f8";
}

.fa-bed-empty::before {
  content: "\f8f9";
}

.fa-bed-front::before {
  content: "\f8f7";
}

.fa-bed-alt::before {
  content: "\f8f7";
}

.fa-bed-pulse::before {
  content: "\f487";
}

.fa-procedures::before {
  content: "\f487";
}

.fa-bee::before {
  content: "\e0b2";
}

.fa-beer-mug::before {
  content: "\e0b3";
}

.fa-beer-foam::before {
  content: "\e0b3";
}

.fa-beer-mug-empty::before {
  content: "\f0fc";
}

.fa-beer::before {
  content: "\f0fc";
}

.fa-bell::before {
  content: "\f0f3";
}

.fa-bell-concierge::before {
  content: "\f562";
}

.fa-concierge-bell::before {
  content: "\f562";
}

.fa-bell-exclamation::before {
  content: "\f848";
}

.fa-bell-on::before {
  content: "\f8fa";
}

.fa-bell-plus::before {
  content: "\f849";
}

.fa-bell-school::before {
  content: "\f5d5";
}

.fa-bell-school-slash::before {
  content: "\f5d6";
}

.fa-bell-slash::before {
  content: "\f1f6";
}

.fa-bells::before {
  content: "\f77f";
}

.fa-bench-tree::before {
  content: "\e2e7";
}

.fa-bezier-curve::before {
  content: "\f55b";
}

.fa-bicycle::before {
  content: "\f206";
}

.fa-binary::before {
  content: "\e33b";
}

.fa-binary-circle-check::before {
  content: "\e33c";
}

.fa-binary-lock::before {
  content: "\e33d";
}

.fa-binary-slash::before {
  content: "\e33e";
}

.fa-binoculars::before {
  content: "\f1e5";
}

.fa-biohazard::before {
  content: "\f780";
}

.fa-bird::before {
  content: "\e469";
}

.fa-bitcoin-sign::before {
  content: "\e0b4";
}

.fa-blanket::before {
  content: "\f498";
}

.fa-blanket-fire::before {
  content: "\e3da";
}

.fa-blender::before {
  content: "\f517";
}

.fa-blender-phone::before {
  content: "\f6b6";
}

.fa-blinds::before {
  content: "\f8fb";
}

.fa-blinds-open::before {
  content: "\f8fc";
}

.fa-blinds-raised::before {
  content: "\f8fd";
}

.fa-block::before {
  content: "\e46a";
}

.fa-block-brick::before {
  content: "\e3db";
}

.fa-wall-brick::before {
  content: "\e3db";
}

.fa-block-brick-fire::before {
  content: "\e3dc";
}

.fa-firewall::before {
  content: "\e3dc";
}

.fa-block-question::before {
  content: "\e3dd";
}

.fa-block-quote::before {
  content: "\e0b5";
}

.fa-blog::before {
  content: "\f781";
}

.fa-blueberries::before {
  content: "\e2e8";
}

.fa-bold::before {
  content: "\f032";
}

.fa-bolt::before {
  content: "\f0e7";
}

.fa-zap::before {
  content: "\f0e7";
}

.fa-bolt-auto::before {
  content: "\e0b6";
}

.fa-bolt-lightning::before {
  content: "\e0b7";
}

.fa-bolt-slash::before {
  content: "\e0b8";
}

.fa-bomb::before {
  content: "\f1e2";
}

.fa-bone::before {
  content: "\f5d7";
}

.fa-bone-break::before {
  content: "\f5d8";
}

.fa-bong::before {
  content: "\f55c";
}

.fa-book::before {
  content: "\f02d";
}

.fa-book-arrow-right::before {
  content: "\e0b9";
}

.fa-book-arrow-up::before {
  content: "\e0ba";
}

.fa-book-atlas::before {
  content: "\f558";
}

.fa-atlas::before {
  content: "\f558";
}

.fa-book-bible::before {
  content: "\f647";
}

.fa-bible::before {
  content: "\f647";
}

.fa-book-blank::before {
  content: "\f5d9";
}

.fa-book-alt::before {
  content: "\f5d9";
}

.fa-book-bookmark::before {
  content: "\e0bb";
}

.fa-book-circle-arrow-right::before {
  content: "\e0bc";
}

.fa-book-circle-arrow-up::before {
  content: "\e0bd";
}

.fa-book-copy::before {
  content: "\e0be";
}

.fa-book-font::before {
  content: "\e0bf";
}

.fa-book-heart::before {
  content: "\f499";
}

.fa-book-journal-whills::before {
  content: "\f66a";
}

.fa-journal-whills::before {
  content: "\f66a";
}

.fa-book-medical::before {
  content: "\f7e6";
}

.fa-book-open::before {
  content: "\f518";
}

.fa-book-open-cover::before {
  content: "\e0c0";
}

.fa-book-open-alt::before {
  content: "\e0c0";
}

.fa-book-open-reader::before {
  content: "\f5da";
}

.fa-book-reader::before {
  content: "\f5da";
}

.fa-book-quran::before {
  content: "\f687";
}

.fa-quran::before {
  content: "\f687";
}

.fa-book-section::before {
  content: "\e0c1";
}

.fa-book-law::before {
  content: "\e0c1";
}

.fa-book-skull::before {
  content: "\f6b7";
}

.fa-book-dead::before {
  content: "\f6b7";
}

.fa-book-sparkles::before {
  content: "\f6b8";
}

.fa-book-spells::before {
  content: "\f6b8";
}

.fa-book-tanakh::before {
  content: "\f827";
}

.fa-tanakh::before {
  content: "\f827";
}

.fa-book-user::before {
  content: "\f7e7";
}

.fa-bookmark::before {
  content: "\f02e";
}

.fa-bookmark-slash::before {
  content: "\e0c2";
}

.fa-books::before {
  content: "\f5db";
}

.fa-books-medical::before {
  content: "\f7e8";
}

.fa-boombox::before {
  content: "\f8a5";
}

.fa-boot::before {
  content: "\f782";
}

.fa-boot-heeled::before {
  content: "\e33f";
}

.fa-booth-curtain::before {
  content: "\f734";
}

.fa-border-all::before {
  content: "\f84c";
}

.fa-border-bottom::before {
  content: "\f84d";
}

.fa-border-bottom-right::before {
  content: "\f854";
}

.fa-border-style-alt::before {
  content: "\f854";
}

.fa-border-center-h::before {
  content: "\f89c";
}

.fa-border-center-v::before {
  content: "\f89d";
}

.fa-border-inner::before {
  content: "\f84e";
}

.fa-border-left::before {
  content: "\f84f";
}

.fa-border-none::before {
  content: "\f850";
}

.fa-border-outer::before {
  content: "\f851";
}

.fa-border-right::before {
  content: "\f852";
}

.fa-border-top::before {
  content: "\f855";
}

.fa-border-top-left::before {
  content: "\f853";
}

.fa-border-style::before {
  content: "\f853";
}

.fa-bore-hole::before {
  content: "\e4c3";
}

.fa-bottle-droplet::before {
  content: "\e4c4";
}

.fa-bottle-water::before {
  content: "\e4c5";
}

.fa-bow-arrow::before {
  content: "\f6b9";
}

.fa-bowl-chopsticks::before {
  content: "\e2e9";
}

.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea";
}

.fa-bowl-food::before {
  content: "\e4c6";
}

.fa-bowl-hot::before {
  content: "\f823";
}

.fa-soup::before {
  content: "\f823";
}

.fa-bowl-rice::before {
  content: "\e2eb";
}

.fa-bowl-scoop::before {
  content: "\e3de";
}

.fa-bowl-shaved-ice::before {
  content: "\e3de";
}

.fa-bowl-scoops::before {
  content: "\e3df";
}

.fa-bowl-soft-serve::before {
  content: "\e46b";
}

.fa-bowl-spoon::before {
  content: "\e3e0";
}

.fa-bowling-ball::before {
  content: "\f436";
}

.fa-bowling-ball-pin::before {
  content: "\e0c3";
}

.fa-bowling-pins::before {
  content: "\f437";
}

.fa-box::before {
  content: "\f466";
}

.fa-box-archive::before {
  content: "\f187";
}

.fa-archive::before {
  content: "\f187";
}

.fa-box-ballot::before {
  content: "\f735";
}

.fa-box-check::before {
  content: "\f467";
}

.fa-box-circle-check::before {
  content: "\e0c4";
}

.fa-box-dollar::before {
  content: "\f4a0";
}

.fa-box-usd::before {
  content: "\f4a0";
}

.fa-box-heart::before {
  content: "\f49d";
}

.fa-box-open::before {
  content: "\f49e";
}

.fa-box-open-full::before {
  content: "\f49c";
}

.fa-box-full::before {
  content: "\f49c";
}

.fa-box-taped::before {
  content: "\f49a";
}

.fa-box-alt::before {
  content: "\f49a";
}

.fa-box-tissue::before {
  content: "\e05b";
}

.fa-boxes-packing::before {
  content: "\e4c7";
}

.fa-boxes-stacked::before {
  content: "\f468";
}

.fa-boxes::before {
  content: "\f468";
}

.fa-boxes-alt::before {
  content: "\f468";
}

.fa-boxing-glove::before {
  content: "\f438";
}

.fa-glove-boxing::before {
  content: "\f438";
}

.fa-bracket-curly::before {
  content: "\{";
}

.fa-bracket-curly-left::before {
  content: "\{";
}

.fa-bracket-curly-right::before {
  content: "\}";
}

.fa-bracket-round::before {
  content: "\(";
}

.fa-parenthesis::before {
  content: "\(";
}

.fa-bracket-round-right::before {
  content: "\)";
}

.fa-bracket-square::before {
  content: "\[";
}

.fa-bracket::before {
  content: "\[";
}

.fa-bracket-left::before {
  content: "\[";
}

.fa-bracket-square-right::before {
  content: "\]";
}

.fa-brackets-curly::before {
  content: "\f7ea";
}

.fa-brackets-round::before {
  content: "\e0c5";
}

.fa-parentheses::before {
  content: "\e0c5";
}

.fa-brackets-square::before {
  content: "\f7e9";
}

.fa-brackets::before {
  content: "\f7e9";
}

.fa-braille::before {
  content: "\f2a1";
}

.fa-brain::before {
  content: "\f5dc";
}

.fa-brain-arrow-curved-right::before {
  content: "\f677";
}

.fa-mind-share::before {
  content: "\f677";
}

.fa-brain-circuit::before {
  content: "\e0c6";
}

.fa-brake-warning::before {
  content: "\e0c7";
}

.fa-brazilian-real-sign::before {
  content: "\e46c";
}

.fa-bread-loaf::before {
  content: "\f7eb";
}

.fa-bread-slice::before {
  content: "\f7ec";
}

.fa-bread-slice-butter::before {
  content: "\e3e1";
}

.fa-bridge::before {
  content: "\e4c8";
}

.fa-bridge-circle-check::before {
  content: "\e4c9";
}

.fa-bridge-circle-exclamation::before {
  content: "\e4ca";
}

.fa-bridge-circle-xmark::before {
  content: "\e4cb";
}

.fa-bridge-lock::before {
  content: "\e4cc";
}

.fa-bridge-suspension::before {
  content: "\e4cd";
}

.fa-bridge-water::before {
  content: "\e4ce";
}

.fa-briefcase::before {
  content: "\f0b1";
}

.fa-briefcase-arrow-right::before {
  content: "\e2f2";
}

.fa-briefcase-blank::before {
  content: "\e0c8";
}

.fa-briefcase-medical::before {
  content: "\f469";
}

.fa-brightness::before {
  content: "\e0c9";
}

.fa-brightness-low::before {
  content: "\e0ca";
}

.fa-bring-forward::before {
  content: "\f856";
}

.fa-bring-front::before {
  content: "\f857";
}

.fa-broccoli::before {
  content: "\e3e2";
}

.fa-broom::before {
  content: "\f51a";
}

.fa-broom-ball::before {
  content: "\f458";
}

.fa-quidditch::before {
  content: "\f458";
}

.fa-quidditch-broom-ball::before {
  content: "\f458";
}

.fa-browser::before {
  content: "\f37e";
}

.fa-browsers::before {
  content: "\e0cb";
}

.fa-brush::before {
  content: "\f55d";
}

.fa-bucket::before {
  content: "\e4cf";
}

.fa-bug::before {
  content: "\f188";
}

.fa-bug-slash::before {
  content: "\e490";
}

.fa-bugs::before {
  content: "\e4d0";
}

.fa-building::before {
  content: "\f1ad";
}

.fa-building-circle-arrow-right::before {
  content: "\e4d1";
}

.fa-building-circle-check::before {
  content: "\e4d2";
}

.fa-building-circle-exclamation::before {
  content: "\e4d3";
}

.fa-building-circle-xmark::before {
  content: "\e4d4";
}

.fa-building-columns::before {
  content: "\f19c";
}

.fa-bank::before {
  content: "\f19c";
}

.fa-institution::before {
  content: "\f19c";
}

.fa-museum::before {
  content: "\f19c";
}

.fa-university::before {
  content: "\f19c";
}

.fa-building-flag::before {
  content: "\e4d5";
}

.fa-building-lock::before {
  content: "\e4d6";
}

.fa-building-ngo::before {
  content: "\e4d7";
}

.fa-building-shield::before {
  content: "\e4d8";
}

.fa-building-un::before {
  content: "\e4d9";
}

.fa-building-user::before {
  content: "\e4da";
}

.fa-building-wheat::before {
  content: "\e4db";
}

.fa-buildings::before {
  content: "\e0cc";
}

.fa-bullhorn::before {
  content: "\f0a1";
}

.fa-bullseye::before {
  content: "\f140";
}

.fa-bullseye-arrow::before {
  content: "\f648";
}

.fa-bullseye-pointer::before {
  content: "\f649";
}

.fa-burger::before {
  content: "\f805";
}

.fa-hamburger::before {
  content: "\f805";
}

.fa-burger-cheese::before {
  content: "\f7f1";
}

.fa-cheeseburger::before {
  content: "\f7f1";
}

.fa-burger-fries::before {
  content: "\e0cd";
}

.fa-burger-glass::before {
  content: "\e0ce";
}

.fa-burger-lettuce::before {
  content: "\e3e3";
}

.fa-burger-soda::before {
  content: "\f858";
}

.fa-burrito::before {
  content: "\f7ed";
}

.fa-burst::before {
  content: "\e4dc";
}

.fa-bus::before {
  content: "\f207";
}

.fa-bus-school::before {
  content: "\f5dd";
}

.fa-bus-simple::before {
  content: "\f55e";
}

.fa-bus-alt::before {
  content: "\f55e";
}

.fa-business-time::before {
  content: "\f64a";
}

.fa-briefcase-clock::before {
  content: "\f64a";
}

.fa-butter::before {
  content: "\e3e4";
}

.fa-c::before {
  content: "C";
}

.fa-cabin::before {
  content: "\e46d";
}

.fa-cabinet-filing::before {
  content: "\f64b";
}

.fa-cable-car::before {
  content: "\e0cf";
}

.fa-cactus::before {
  content: "\f8a7";
}

.fa-cake-candles::before {
  content: "\f1fd";
}

.fa-birthday-cake::before {
  content: "\f1fd";
}

.fa-cake::before {
  content: "\f1fd";
}

.fa-cake-slice::before {
  content: "\e3e5";
}

.fa-shortcake::before {
  content: "\e3e5";
}

.fa-calculator::before {
  content: "\f1ec";
}

.fa-calculator-simple::before {
  content: "\f64c";
}

.fa-calculator-alt::before {
  content: "\f64c";
}

.fa-calendar::before {
  content: "\f133";
}

.fa-calendar-arrow-down::before {
  content: "\e0d0";
}

.fa-calendar-download::before {
  content: "\e0d0";
}

.fa-calendar-arrow-up::before {
  content: "\e0d1";
}

.fa-calendar-upload::before {
  content: "\e0d1";
}

.fa-calendar-check::before {
  content: "\f274";
}

.fa-calendar-circle-exclamation::before {
  content: "\e46e";
}

.fa-calendar-circle-minus::before {
  content: "\e46f";
}

.fa-calendar-circle-plus::before {
  content: "\e470";
}

.fa-calendar-circle-user::before {
  content: "\e471";
}

.fa-calendar-clock::before {
  content: "\e0d2";
}

.fa-calendar-time::before {
  content: "\e0d2";
}

.fa-calendar-day::before {
  content: "\f783";
}

.fa-calendar-days::before {
  content: "\f073";
}

.fa-calendar-alt::before {
  content: "\f073";
}

.fa-calendar-exclamation::before {
  content: "\f334";
}

.fa-calendar-heart::before {
  content: "\e0d3";
}

.fa-calendar-image::before {
  content: "\e0d4";
}

.fa-calendar-lines::before {
  content: "\e0d5";
}

.fa-calendar-note::before {
  content: "\e0d5";
}

.fa-calendar-lines-pen::before {
  content: "\e472";
}

.fa-calendar-minus::before {
  content: "\f272";
}

.fa-calendar-pen::before {
  content: "\f333";
}

.fa-calendar-edit::before {
  content: "\f333";
}

.fa-calendar-plus::before {
  content: "\f271";
}

.fa-calendar-range::before {
  content: "\e0d6";
}

.fa-calendar-star::before {
  content: "\f736";
}

.fa-calendar-week::before {
  content: "\f784";
}

.fa-calendar-xmark::before {
  content: "\f273";
}

.fa-calendar-times::before {
  content: "\f273";
}

.fa-calendars::before {
  content: "\e0d7";
}

.fa-camcorder::before {
  content: "\f8a8";
}

.fa-video-handheld::before {
  content: "\f8a8";
}

.fa-camera::before {
  content: "\f030";
}

.fa-camera-alt::before {
  content: "\f030";
}

.fa-camera-cctv::before {
  content: "\f8ac";
}

.fa-cctv::before {
  content: "\f8ac";
}

.fa-camera-movie::before {
  content: "\f8a9";
}

.fa-camera-polaroid::before {
  content: "\f8aa";
}

.fa-camera-retro::before {
  content: "\f083";
}

.fa-camera-rotate::before {
  content: "\e0d8";
}

.fa-camera-security::before {
  content: "\f8fe";
}

.fa-camera-home::before {
  content: "\f8fe";
}

.fa-camera-slash::before {
  content: "\e0d9";
}

.fa-camera-viewfinder::before {
  content: "\e0da";
}

.fa-screenshot::before {
  content: "\e0da";
}

.fa-camera-web::before {
  content: "\f832";
}

.fa-webcam::before {
  content: "\f832";
}

.fa-camera-web-slash::before {
  content: "\f833";
}

.fa-webcam-slash::before {
  content: "\f833";
}

.fa-campfire::before {
  content: "\f6ba";
}

.fa-campground::before {
  content: "\f6bb";
}

.fa-can-food::before {
  content: "\e3e6";
}

.fa-candle-holder::before {
  content: "\f6bc";
}

.fa-candy::before {
  content: "\e3e7";
}

.fa-candy-bar::before {
  content: "\e3e8";
}

.fa-chocolate-bar::before {
  content: "\e3e8";
}

.fa-candy-cane::before {
  content: "\f786";
}

.fa-candy-corn::before {
  content: "\f6bd";
}

.fa-cannabis::before {
  content: "\f55f";
}

.fa-capsules::before {
  content: "\f46b";
}

.fa-car::before {
  content: "\f1b9";
}

.fa-automobile::before {
  content: "\f1b9";
}

.fa-car-battery::before {
  content: "\f5df";
}

.fa-battery-car::before {
  content: "\f5df";
}

.fa-car-bolt::before {
  content: "\e341";
}

.fa-car-building::before {
  content: "\f859";
}

.fa-car-bump::before {
  content: "\f5e0";
}

.fa-car-burst::before {
  content: "\f5e1";
}

.fa-car-crash::before {
  content: "\f5e1";
}

.fa-car-bus::before {
  content: "\f85a";
}

.fa-car-circle-bolt::before {
  content: "\e342";
}

.fa-car-garage::before {
  content: "\f5e2";
}

.fa-car-mirrors::before {
  content: "\e343";
}

.fa-car-on::before {
  content: "\e4dd";
}

.fa-car-rear::before {
  content: "\f5de";
}

.fa-car-alt::before {
  content: "\f5de";
}

.fa-car-side::before {
  content: "\f5e4";
}

.fa-car-side-bolt::before {
  content: "\e344";
}

.fa-car-tilt::before {
  content: "\f5e5";
}

.fa-car-tunnel::before {
  content: "\e4de";
}

.fa-car-wash::before {
  content: "\f5e6";
}

.fa-car-wrench::before {
  content: "\f5e3";
}

.fa-car-mechanic::before {
  content: "\f5e3";
}

.fa-caravan::before {
  content: "\f8ff";
}

.fa-caravan-simple::before {
  content: "\e000";
}

.fa-caravan-alt::before {
  content: "\e000";
}

.fa-card-club::before {
  content: "\e3e9";
}

.fa-card-diamond::before {
  content: "\e3ea";
}

.fa-card-heart::before {
  content: "\e3eb";
}

.fa-card-spade::before {
  content: "\e3ec";
}

.fa-cards::before {
  content: "\e3ed";
}

.fa-cards-blank::before {
  content: "\e4df";
}

.fa-caret-down::before {
  content: "\f0d7";
}

.fa-caret-left::before {
  content: "\f0d9";
}

.fa-caret-right::before {
  content: "\f0da";
}

.fa-caret-up::before {
  content: "\f0d8";
}

.fa-carrot::before {
  content: "\f787";
}

.fa-cars::before {
  content: "\f85b";
}

.fa-cart-arrow-down::before {
  content: "\f218";
}

.fa-cart-arrow-up::before {
  content: "\e3ee";
}

.fa-cart-circle-arrow-down::before {
  content: "\e3ef";
}

.fa-cart-circle-arrow-up::before {
  content: "\e3f0";
}

.fa-cart-circle-check::before {
  content: "\e3f1";
}

.fa-cart-circle-exclamation::before {
  content: "\e3f2";
}

.fa-cart-circle-plus::before {
  content: "\e3f3";
}

.fa-cart-circle-xmark::before {
  content: "\e3f4";
}

.fa-cart-flatbed::before {
  content: "\f474";
}

.fa-dolly-flatbed::before {
  content: "\f474";
}

.fa-cart-flatbed-boxes::before {
  content: "\f475";
}

.fa-dolly-flatbed-alt::before {
  content: "\f475";
}

.fa-cart-flatbed-empty::before {
  content: "\f476";
}

.fa-dolly-flatbed-empty::before {
  content: "\f476";
}

.fa-cart-flatbed-suitcase::before {
  content: "\f59d";
}

.fa-luggage-cart::before {
  content: "\f59d";
}

.fa-cart-minus::before {
  content: "\e0db";
}

.fa-cart-plus::before {
  content: "\f217";
}

.fa-cart-shopping::before {
  content: "\f07a";
}

.fa-shopping-cart::before {
  content: "\f07a";
}

.fa-cart-shopping-fast::before {
  content: "\e0dc";
}

.fa-cart-xmark::before {
  content: "\e0dd";
}

.fa-cash-register::before {
  content: "\f788";
}

.fa-cassette-betamax::before {
  content: "\f8a4";
}

.fa-betamax::before {
  content: "\f8a4";
}

.fa-cassette-tape::before {
  content: "\f8ab";
}

.fa-cassette-vhs::before {
  content: "\f8ec";
}

.fa-vhs::before {
  content: "\f8ec";
}

.fa-castle::before {
  content: "\e0de";
}

.fa-cat::before {
  content: "\f6be";
}

.fa-cat-space::before {
  content: "\e001";
}

.fa-cauldron::before {
  content: "\f6bf";
}

.fa-cedi-sign::before {
  content: "\e0df";
}

.fa-cent-sign::before {
  content: "\e3f5";
}

.fa-certificate::before {
  content: "\f0a3";
}

.fa-chair::before {
  content: "\f6c0";
}

.fa-chair-office::before {
  content: "\f6c1";
}

.fa-chalkboard::before {
  content: "\f51b";
}

.fa-blackboard::before {
  content: "\f51b";
}

.fa-chalkboard-user::before {
  content: "\f51c";
}

.fa-chalkboard-teacher::before {
  content: "\f51c";
}

.fa-champagne-glass::before {
  content: "\f79e";
}

.fa-glass-champagne::before {
  content: "\f79e";
}

.fa-champagne-glasses::before {
  content: "\f79f";
}

.fa-glass-cheers::before {
  content: "\f79f";
}

.fa-charging-station::before {
  content: "\f5e7";
}

.fa-chart-area::before {
  content: "\f1fe";
}

.fa-area-chart::before {
  content: "\f1fe";
}

.fa-chart-bar::before {
  content: "\f080";
}

.fa-bar-chart::before {
  content: "\f080";
}

.fa-chart-bullet::before {
  content: "\e0e1";
}

.fa-chart-candlestick::before {
  content: "\e0e2";
}

.fa-chart-column::before {
  content: "\e0e3";
}

.fa-chart-gantt::before {
  content: "\e0e4";
}

.fa-chart-line::before {
  content: "\f201";
}

.fa-line-chart::before {
  content: "\f201";
}

.fa-chart-line-down::before {
  content: "\f64d";
}

.fa-chart-line-up::before {
  content: "\e0e5";
}

.fa-chart-mixed::before {
  content: "\f643";
}

.fa-analytics::before {
  content: "\f643";
}

.fa-chart-network::before {
  content: "\f78a";
}

.fa-chart-pie::before {
  content: "\f200";
}

.fa-pie-chart::before {
  content: "\f200";
}

.fa-chart-pie-simple::before {
  content: "\f64e";
}

.fa-chart-pie-alt::before {
  content: "\f64e";
}

.fa-chart-pyramid::before {
  content: "\e0e6";
}

.fa-chart-radar::before {
  content: "\e0e7";
}

.fa-chart-scatter::before {
  content: "\f7ee";
}

.fa-chart-scatter-3d::before {
  content: "\e0e8";
}

.fa-chart-scatter-bubble::before {
  content: "\e0e9";
}

.fa-chart-simple::before {
  content: "\e473";
}

.fa-chart-simple-horizontal::before {
  content: "\e474";
}

.fa-chart-tree-map::before {
  content: "\e0ea";
}

.fa-chart-user::before {
  content: "\f6a3";
}

.fa-user-chart::before {
  content: "\f6a3";
}

.fa-chart-waterfall::before {
  content: "\e0eb";
}

.fa-check::before {
  content: "\f00c";
}

.fa-check-double::before {
  content: "\f560";
}

.fa-check-to-slot::before {
  content: "\f772";
}

.fa-vote-yea::before {
  content: "\f772";
}

.fa-cheese::before {
  content: "\f7ef";
}

.fa-cheese-swiss::before {
  content: "\f7f0";
}

.fa-cherries::before {
  content: "\e0ec";
}

.fa-chess::before {
  content: "\f439";
}

.fa-chess-bishop::before {
  content: "\f43a";
}

.fa-chess-bishop-piece::before {
  content: "\f43b";
}

.fa-chess-bishop-alt::before {
  content: "\f43b";
}

.fa-chess-board::before {
  content: "\f43c";
}

.fa-chess-clock::before {
  content: "\f43d";
}

.fa-chess-clock-flip::before {
  content: "\f43e";
}

.fa-chess-clock-alt::before {
  content: "\f43e";
}

.fa-chess-king::before {
  content: "\f43f";
}

.fa-chess-king-piece::before {
  content: "\f440";
}

.fa-chess-king-alt::before {
  content: "\f440";
}

.fa-chess-knight::before {
  content: "\f441";
}

.fa-chess-knight-piece::before {
  content: "\f442";
}

.fa-chess-knight-alt::before {
  content: "\f442";
}

.fa-chess-pawn::before {
  content: "\f443";
}

.fa-chess-pawn-piece::before {
  content: "\f444";
}

.fa-chess-pawn-alt::before {
  content: "\f444";
}

.fa-chess-queen::before {
  content: "\f445";
}

.fa-chess-queen-piece::before {
  content: "\f446";
}

.fa-chess-queen-alt::before {
  content: "\f446";
}

.fa-chess-rook::before {
  content: "\f447";
}

.fa-chess-rook-piece::before {
  content: "\f448";
}

.fa-chess-rook-alt::before {
  content: "\f448";
}

.fa-chestnut::before {
  content: "\e3f6";
}

.fa-chevron-down::before {
  content: "\f078";
}

.fa-chevron-left::before {
  content: "\f053";
}

.fa-chevron-right::before {
  content: "\f054";
}

.fa-chevron-up::before {
  content: "\f077";
}

.fa-chevrons-down::before {
  content: "\f322";
}

.fa-chevron-double-down::before {
  content: "\f322";
}

.fa-chevrons-left::before {
  content: "\f323";
}

.fa-chevron-double-left::before {
  content: "\f323";
}

.fa-chevrons-right::before {
  content: "\f324";
}

.fa-chevron-double-right::before {
  content: "\f324";
}

.fa-chevrons-up::before {
  content: "\f325";
}

.fa-chevron-double-up::before {
  content: "\f325";
}

.fa-child::before {
  content: "\f1ae";
}

.fa-child-dress::before {
  content: "\e59c";
}

.fa-child-reaching::before {
  content: "\e59d";
}

.fa-child-rifle::before {
  content: "\e4e0";
}

.fa-children::before {
  content: "\e4e1";
}

.fa-chimney::before {
  content: "\f78b";
}

.fa-chopsticks::before {
  content: "\e3f7";
}

.fa-church::before {
  content: "\f51d";
}

.fa-circle::before {
  content: "\f111";
}

.fa-circle-0::before {
  content: "\e0ed";
}

.fa-circle-1::before {
  content: "\e0ee";
}

.fa-circle-2::before {
  content: "\e0ef";
}

.fa-circle-3::before {
  content: "\e0f0";
}

.fa-circle-4::before {
  content: "\e0f1";
}

.fa-circle-5::before {
  content: "\e0f2";
}

.fa-circle-6::before {
  content: "\e0f3";
}

.fa-circle-7::before {
  content: "\e0f4";
}

.fa-circle-8::before {
  content: "\e0f5";
}

.fa-circle-9::before {
  content: "\e0f6";
}

.fa-circle-a::before {
  content: "\e0f7";
}

.fa-circle-ampersand::before {
  content: "\e0f8";
}

.fa-circle-arrow-down::before {
  content: "\f0ab";
}

.fa-arrow-circle-down::before {
  content: "\f0ab";
}

.fa-circle-arrow-down-left::before {
  content: "\e0f9";
}

.fa-circle-arrow-down-right::before {
  content: "\e0fa";
}

.fa-circle-arrow-left::before {
  content: "\f0a8";
}

.fa-arrow-circle-left::before {
  content: "\f0a8";
}

.fa-circle-arrow-right::before {
  content: "\f0a9";
}

.fa-arrow-circle-right::before {
  content: "\f0a9";
}

.fa-circle-arrow-up::before {
  content: "\f0aa";
}

.fa-arrow-circle-up::before {
  content: "\f0aa";
}

.fa-circle-arrow-up-left::before {
  content: "\e0fb";
}

.fa-circle-arrow-up-right::before {
  content: "\e0fc";
}

.fa-circle-b::before {
  content: "\e0fd";
}

.fa-circle-bolt::before {
  content: "\e0fe";
}

.fa-circle-book-open::before {
  content: "\e0ff";
}

.fa-book-circle::before {
  content: "\e0ff";
}

.fa-circle-bookmark::before {
  content: "\e100";
}

.fa-bookmark-circle::before {
  content: "\e100";
}

.fa-circle-c::before {
  content: "\e101";
}

.fa-circle-calendar::before {
  content: "\e102";
}

.fa-calendar-circle::before {
  content: "\e102";
}

.fa-circle-camera::before {
  content: "\e103";
}

.fa-camera-circle::before {
  content: "\e103";
}

.fa-circle-caret-down::before {
  content: "\f32d";
}

.fa-caret-circle-down::before {
  content: "\f32d";
}

.fa-circle-caret-left::before {
  content: "\f32e";
}

.fa-caret-circle-left::before {
  content: "\f32e";
}

.fa-circle-caret-right::before {
  content: "\f330";
}

.fa-caret-circle-right::before {
  content: "\f330";
}

.fa-circle-caret-up::before {
  content: "\f331";
}

.fa-caret-circle-up::before {
  content: "\f331";
}

.fa-circle-check::before {
  content: "\f058";
}

.fa-check-circle::before {
  content: "\f058";
}

.fa-circle-chevron-down::before {
  content: "\f13a";
}

.fa-chevron-circle-down::before {
  content: "\f13a";
}

.fa-circle-chevron-left::before {
  content: "\f137";
}

.fa-chevron-circle-left::before {
  content: "\f137";
}

.fa-circle-chevron-right::before {
  content: "\f138";
}

.fa-chevron-circle-right::before {
  content: "\f138";
}

.fa-circle-chevron-up::before {
  content: "\f139";
}

.fa-chevron-circle-up::before {
  content: "\f139";
}

.fa-circle-d::before {
  content: "\e104";
}

.fa-circle-dashed::before {
  content: "\e105";
}

.fa-circle-divide::before {
  content: "\e106";
}

.fa-circle-dollar::before {
  content: "\f2e8";
}

.fa-dollar-circle::before {
  content: "\f2e8";
}

.fa-usd-circle::before {
  content: "\f2e8";
}

.fa-circle-dollar-to-slot::before {
  content: "\f4b9";
}

.fa-donate::before {
  content: "\f4b9";
}

.fa-circle-dot::before {
  content: "\f192";
}

.fa-dot-circle::before {
  content: "\f192";
}

.fa-circle-down::before {
  content: "\f358";
}

.fa-arrow-alt-circle-down::before {
  content: "\f358";
}

.fa-circle-down-left::before {
  content: "\e107";
}

.fa-circle-down-right::before {
  content: "\e108";
}

.fa-circle-e::before {
  content: "\e109";
}

.fa-circle-ellipsis::before {
  content: "\e10a";
}

.fa-circle-ellipsis-vertical::before {
  content: "\e10b";
}

.fa-circle-envelope::before {
  content: "\e10c";
}

.fa-envelope-circle::before {
  content: "\e10c";
}

.fa-circle-exclamation::before {
  content: "\f06a";
}

.fa-exclamation-circle::before {
  content: "\f06a";
}

.fa-circle-exclamation-check::before {
  content: "\e10d";
}

.fa-circle-f::before {
  content: "\e10e";
}

.fa-circle-g::before {
  content: "\e10f";
}

.fa-circle-h::before {
  content: "\f47e";
}

.fa-hospital-symbol::before {
  content: "\f47e";
}

.fa-circle-half::before {
  content: "\e110";
}

.fa-circle-half-stroke::before {
  content: "\f042";
}

.fa-adjust::before {
  content: "\f042";
}

.fa-circle-heart::before {
  content: "\f4c7";
}

.fa-heart-circle::before {
  content: "\f4c7";
}

.fa-circle-i::before {
  content: "\e111";
}

.fa-circle-info::before {
  content: "\f05a";
}

.fa-info-circle::before {
  content: "\f05a";
}

.fa-circle-j::before {
  content: "\e112";
}

.fa-circle-k::before {
  content: "\e113";
}

.fa-circle-l::before {
  content: "\e114";
}

.fa-circle-left::before {
  content: "\f359";
}

.fa-arrow-alt-circle-left::before {
  content: "\f359";
}

.fa-circle-location-arrow::before {
  content: "\f602";
}

.fa-location-circle::before {
  content: "\f602";
}

.fa-circle-m::before {
  content: "\e115";
}

.fa-circle-microphone::before {
  content: "\e116";
}

.fa-microphone-circle::before {
  content: "\e116";
}

.fa-circle-microphone-lines::before {
  content: "\e117";
}

.fa-microphone-circle-alt::before {
  content: "\e117";
}

.fa-circle-minus::before {
  content: "\f056";
}

.fa-minus-circle::before {
  content: "\f056";
}

.fa-circle-n::before {
  content: "\e118";
}

.fa-circle-nodes::before {
  content: "\e4e2";
}

.fa-circle-notch::before {
  content: "\f1ce";
}

.fa-circle-o::before {
  content: "\e119";
}

.fa-circle-p::before {
  content: "\e11a";
}

.fa-circle-parking::before {
  content: "\f615";
}

.fa-parking-circle::before {
  content: "\f615";
}

.fa-circle-pause::before {
  content: "\f28b";
}

.fa-pause-circle::before {
  content: "\f28b";
}

.fa-circle-phone::before {
  content: "\e11b";
}

.fa-phone-circle::before {
  content: "\e11b";
}

.fa-circle-phone-flip::before {
  content: "\e11c";
}

.fa-phone-circle-alt::before {
  content: "\e11c";
}

.fa-circle-phone-hangup::before {
  content: "\e11d";
}

.fa-phone-circle-down::before {
  content: "\e11d";
}

.fa-circle-play::before {
  content: "\f144";
}

.fa-play-circle::before {
  content: "\f144";
}

.fa-circle-plus::before {
  content: "\f055";
}

.fa-plus-circle::before {
  content: "\f055";
}

.fa-circle-q::before {
  content: "\e11e";
}

.fa-circle-quarter::before {
  content: "\e11f";
}

.fa-circle-quarters::before {
  content: "\e3f8";
}

.fa-circle-question::before {
  content: "\f059";
}

.fa-question-circle::before {
  content: "\f059";
}

.fa-circle-r::before {
  content: "\e120";
}

.fa-circle-radiation::before {
  content: "\f7ba";
}

.fa-radiation-alt::before {
  content: "\f7ba";
}

.fa-circle-right::before {
  content: "\f35a";
}

.fa-arrow-alt-circle-right::before {
  content: "\f35a";
}

.fa-circle-s::before {
  content: "\e121";
}

.fa-circle-small::before {
  content: "\e122";
}

.fa-circle-sort::before {
  content: "\e030";
}

.fa-sort-circle::before {
  content: "\e030";
}

.fa-circle-sort-down::before {
  content: "\e031";
}

.fa-sort-circle-down::before {
  content: "\e031";
}

.fa-circle-sort-up::before {
  content: "\e032";
}

.fa-sort-circle-up::before {
  content: "\e032";
}

.fa-circle-star::before {
  content: "\e123";
}

.fa-star-circle::before {
  content: "\e123";
}

.fa-circle-stop::before {
  content: "\f28d";
}

.fa-stop-circle::before {
  content: "\f28d";
}

.fa-circle-t::before {
  content: "\e124";
}

.fa-circle-three-quarters::before {
  content: "\e125";
}

.fa-circle-trash::before {
  content: "\e126";
}

.fa-trash-circle::before {
  content: "\e126";
}

.fa-circle-u::before {
  content: "\e127";
}

.fa-circle-up::before {
  content: "\f35b";
}

.fa-arrow-alt-circle-up::before {
  content: "\f35b";
}

.fa-circle-up-left::before {
  content: "\e128";
}

.fa-circle-up-right::before {
  content: "\e129";
}

.fa-circle-user::before {
  content: "\f2bd";
}

.fa-user-circle::before {
  content: "\f2bd";
}

.fa-circle-v::before {
  content: "\e12a";
}

.fa-circle-video::before {
  content: "\e12b";
}

.fa-video-circle::before {
  content: "\e12b";
}

.fa-circle-w::before {
  content: "\e12c";
}

.fa-circle-waveform-lines::before {
  content: "\e12d";
}

.fa-waveform-circle::before {
  content: "\e12d";
}

.fa-circle-x::before {
  content: "\e12e";
}

.fa-circle-xmark::before {
  content: "\f057";
}

.fa-times-circle::before {
  content: "\f057";
}

.fa-xmark-circle::before {
  content: "\f057";
}

.fa-circle-y::before {
  content: "\e12f";
}

.fa-circle-z::before {
  content: "\e130";
}

.fa-citrus::before {
  content: "\e2f4";
}

.fa-citrus-slice::before {
  content: "\e2f5";
}

.fa-city::before {
  content: "\f64f";
}

.fa-clapperboard::before {
  content: "\e131";
}

.fa-clapperboard-play::before {
  content: "\e132";
}

.fa-clarinet::before {
  content: "\f8ad";
}

.fa-claw-marks::before {
  content: "\f6c2";
}

.fa-clipboard::before {
  content: "\f328";
}

.fa-clipboard-check::before {
  content: "\f46c";
}

.fa-clipboard-list::before {
  content: "\f46d";
}

.fa-clipboard-list-check::before {
  content: "\f737";
}

.fa-clipboard-medical::before {
  content: "\e133";
}

.fa-clipboard-prescription::before {
  content: "\f5e8";
}

.fa-clipboard-question::before {
  content: "\e4e3";
}

.fa-clipboard-user::before {
  content: "\f7f3";
}

.fa-clock::before {
  content: "\f017";
}

.fa-clock-four::before {
  content: "\f017";
}

.fa-clock-desk::before {
  content: "\e134";
}

.fa-clock-eight::before {
  content: "\e345";
}

.fa-clock-eight-thirty::before {
  content: "\e346";
}

.fa-clock-eleven::before {
  content: "\e347";
}

.fa-clock-eleven-thirty::before {
  content: "\e348";
}

.fa-clock-five::before {
  content: "\e349";
}

.fa-clock-five-thirty::before {
  content: "\e34a";
}

.fa-clock-four-thirty::before {
  content: "\e34b";
}

.fa-clock-nine::before {
  content: "\e34c";
}

.fa-clock-nine-thirty::before {
  content: "\e34d";
}

.fa-clock-one::before {
  content: "\e34e";
}

.fa-clock-one-thirty::before {
  content: "\e34f";
}

.fa-clock-rotate-left::before {
  content: "\f1da";
}

.fa-history::before {
  content: "\f1da";
}

.fa-clock-seven::before {
  content: "\e350";
}

.fa-clock-seven-thirty::before {
  content: "\e351";
}

.fa-clock-six::before {
  content: "\e352";
}

.fa-clock-six-thirty::before {
  content: "\e353";
}

.fa-clock-ten::before {
  content: "\e354";
}

.fa-clock-ten-thirty::before {
  content: "\e355";
}

.fa-clock-three::before {
  content: "\e356";
}

.fa-clock-three-thirty::before {
  content: "\e357";
}

.fa-clock-twelve::before {
  content: "\e358";
}

.fa-clock-twelve-thirty::before {
  content: "\e359";
}

.fa-clock-two::before {
  content: "\e35a";
}

.fa-clock-two-thirty::before {
  content: "\e35b";
}

.fa-clone::before {
  content: "\f24d";
}

.fa-closed-captioning::before {
  content: "\f20a";
}

.fa-closed-captioning-slash::before {
  content: "\e135";
}

.fa-clothes-hanger::before {
  content: "\e136";
}

.fa-cloud::before {
  content: "\f0c2";
}

.fa-cloud-arrow-down::before {
  content: "\f0ed";
}

.fa-cloud-download::before {
  content: "\f0ed";
}

.fa-cloud-download-alt::before {
  content: "\f0ed";
}

.fa-cloud-arrow-up::before {
  content: "\f0ee";
}

.fa-cloud-upload::before {
  content: "\f0ee";
}

.fa-cloud-upload-alt::before {
  content: "\f0ee";
}

.fa-cloud-bolt::before {
  content: "\f76c";
}

.fa-thunderstorm::before {
  content: "\f76c";
}

.fa-cloud-bolt-moon::before {
  content: "\f76d";
}

.fa-thunderstorm-moon::before {
  content: "\f76d";
}

.fa-cloud-bolt-sun::before {
  content: "\f76e";
}

.fa-thunderstorm-sun::before {
  content: "\f76e";
}

.fa-cloud-check::before {
  content: "\e35c";
}

.fa-cloud-drizzle::before {
  content: "\f738";
}

.fa-cloud-exclamation::before {
  content: "\e491";
}

.fa-cloud-fog::before {
  content: "\f74e";
}

.fa-fog::before {
  content: "\f74e";
}

.fa-cloud-hail::before {
  content: "\f739";
}

.fa-cloud-hail-mixed::before {
  content: "\f73a";
}

.fa-cloud-meatball::before {
  content: "\f73b";
}

.fa-cloud-minus::before {
  content: "\e35d";
}

.fa-cloud-moon::before {
  content: "\f6c3";
}

.fa-cloud-moon-rain::before {
  content: "\f73c";
}

.fa-cloud-music::before {
  content: "\f8ae";
}

.fa-cloud-plus::before {
  content: "\e35e";
}

.fa-cloud-question::before {
  content: "\e492";
}

.fa-cloud-rain::before {
  content: "\f73d";
}

.fa-cloud-rainbow::before {
  content: "\f73e";
}

.fa-cloud-showers::before {
  content: "\f73f";
}

.fa-cloud-showers-heavy::before {
  content: "\f740";
}

.fa-cloud-showers-water::before {
  content: "\e4e4";
}

.fa-cloud-slash::before {
  content: "\e137";
}

.fa-cloud-sleet::before {
  content: "\f741";
}

.fa-cloud-snow::before {
  content: "\f742";
}

.fa-cloud-sun::before {
  content: "\f6c4";
}

.fa-cloud-sun-rain::before {
  content: "\f743";
}

.fa-cloud-word::before {
  content: "\e138";
}

.fa-cloud-xmark::before {
  content: "\e35f";
}

.fa-clouds::before {
  content: "\f744";
}

.fa-clouds-moon::before {
  content: "\f745";
}

.fa-clouds-sun::before {
  content: "\f746";
}

.fa-clover::before {
  content: "\e139";
}

.fa-club::before {
  content: "\f327";
}

.fa-coconut::before {
  content: "\e2f6";
}

.fa-code::before {
  content: "\f121";
}

.fa-code-branch::before {
  content: "\f126";
}

.fa-code-commit::before {
  content: "\f386";
}

.fa-code-compare::before {
  content: "\e13a";
}

.fa-code-fork::before {
  content: "\e13b";
}

.fa-code-merge::before {
  content: "\f387";
}

.fa-code-pull-request::before {
  content: "\e13c";
}

.fa-code-pull-request-closed::before {
  content: "\e3f9";
}

.fa-code-pull-request-draft::before {
  content: "\e3fa";
}

.fa-code-simple::before {
  content: "\e13d";
}

.fa-coffee-bean::before {
  content: "\e13e";
}

.fa-coffee-beans::before {
  content: "\e13f";
}

.fa-coffee-pot::before {
  content: "\e002";
}

.fa-coffin::before {
  content: "\f6c6";
}

.fa-coffin-cross::before {
  content: "\e051";
}

.fa-coin::before {
  content: "\f85c";
}

.fa-coin-blank::before {
  content: "\e3fb";
}

.fa-coin-front::before {
  content: "\e3fc";
}

.fa-coin-vertical::before {
  content: "\e3fd";
}

.fa-coins::before {
  content: "\f51e";
}

.fa-colon::before {
  content: "\:";
}

.fa-colon-sign::before {
  content: "\e140";
}

.fa-columns-3::before {
  content: "\e361";
}

.fa-comet::before {
  content: "\e003";
}

.fa-comma::before {
  content: "\,";
}

.fa-command::before {
  content: "\e142";
}

.fa-comment::before {
  content: "\f075";
}

.fa-comment-arrow-down::before {
  content: "\e143";
}

.fa-comment-arrow-up::before {
  content: "\e144";
}

.fa-comment-arrow-up-right::before {
  content: "\e145";
}

.fa-comment-captions::before {
  content: "\e146";
}

.fa-comment-check::before {
  content: "\f4ac";
}

.fa-comment-code::before {
  content: "\e147";
}

.fa-comment-dollar::before {
  content: "\f651";
}

.fa-comment-dots::before {
  content: "\f4ad";
}

.fa-commenting::before {
  content: "\f4ad";
}

.fa-comment-exclamation::before {
  content: "\f4af";
}

.fa-comment-image::before {
  content: "\e148";
}

.fa-comment-lines::before {
  content: "\f4b0";
}

.fa-comment-medical::before {
  content: "\f7f5";
}

.fa-comment-middle::before {
  content: "\e149";
}

.fa-comment-middle-top::before {
  content: "\e14a";
}

.fa-comment-minus::before {
  content: "\f4b1";
}

.fa-comment-music::before {
  content: "\f8b0";
}

.fa-comment-pen::before {
  content: "\f4ae";
}

.fa-comment-edit::before {
  content: "\f4ae";
}

.fa-comment-plus::before {
  content: "\f4b2";
}

.fa-comment-question::before {
  content: "\e14b";
}

.fa-comment-quote::before {
  content: "\e14c";
}

.fa-comment-slash::before {
  content: "\f4b3";
}

.fa-comment-smile::before {
  content: "\f4b4";
}

.fa-comment-sms::before {
  content: "\f7cd";
}

.fa-sms::before {
  content: "\f7cd";
}

.fa-comment-text::before {
  content: "\e14d";
}

.fa-comment-xmark::before {
  content: "\f4b5";
}

.fa-comment-times::before {
  content: "\f4b5";
}

.fa-comments::before {
  content: "\f086";
}

.fa-comments-dollar::before {
  content: "\f653";
}

.fa-comments-question::before {
  content: "\e14e";
}

.fa-comments-question-check::before {
  content: "\e14f";
}

.fa-compact-disc::before {
  content: "\f51f";
}

.fa-compass::before {
  content: "\f14e";
}

.fa-compass-drafting::before {
  content: "\f568";
}

.fa-drafting-compass::before {
  content: "\f568";
}

.fa-compass-slash::before {
  content: "\f5e9";
}

.fa-compress::before {
  content: "\f066";
}

.fa-compress-wide::before {
  content: "\f326";
}

.fa-computer::before {
  content: "\e4e5";
}

.fa-computer-classic::before {
  content: "\f8b1";
}

.fa-computer-mouse::before {
  content: "\f8cc";
}

.fa-mouse::before {
  content: "\f8cc";
}

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd";
}

.fa-mouse-alt::before {
  content: "\f8cd";
}

.fa-computer-speaker::before {
  content: "\f8b2";
}

.fa-container-storage::before {
  content: "\f4b7";
}

.fa-conveyor-belt::before {
  content: "\f46e";
}

.fa-conveyor-belt-boxes::before {
  content: "\f46f";
}

.fa-conveyor-belt-alt::before {
  content: "\f46f";
}

.fa-conveyor-belt-empty::before {
  content: "\e150";
}

.fa-cookie::before {
  content: "\f563";
}

.fa-cookie-bite::before {
  content: "\f564";
}

.fa-copy::before {
  content: "\f0c5";
}

.fa-copyright::before {
  content: "\f1f9";
}

.fa-corn::before {
  content: "\f6c7";
}

.fa-corner::before {
  content: "\e3fe";
}

.fa-couch::before {
  content: "\f4b8";
}

.fa-cow::before {
  content: "\f6c8";
}

.fa-cowbell::before {
  content: "\f8b3";
}

.fa-cowbell-circle-plus::before {
  content: "\f8b4";
}

.fa-cowbell-more::before {
  content: "\f8b4";
}

.fa-crab::before {
  content: "\e3ff";
}

.fa-crate-apple::before {
  content: "\f6b1";
}

.fa-apple-crate::before {
  content: "\f6b1";
}

.fa-crate-empty::before {
  content: "\e151";
}

.fa-credit-card::before {
  content: "\f09d";
}

.fa-credit-card-alt::before {
  content: "\f09d";
}

.fa-credit-card-blank::before {
  content: "\f389";
}

.fa-credit-card-front::before {
  content: "\f38a";
}

.fa-cricket-bat-ball::before {
  content: "\f449";
}

.fa-cricket::before {
  content: "\f449";
}

.fa-croissant::before {
  content: "\f7f6";
}

.fa-crop::before {
  content: "\f125";
}

.fa-crop-simple::before {
  content: "\f565";
}

.fa-crop-alt::before {
  content: "\f565";
}

.fa-cross::before {
  content: "\f654";
}

.fa-crosshairs::before {
  content: "\f05b";
}

.fa-crow::before {
  content: "\f520";
}

.fa-crown::before {
  content: "\f521";
}

.fa-crutch::before {
  content: "\f7f7";
}

.fa-crutches::before {
  content: "\f7f8";
}

.fa-cruzeiro-sign::before {
  content: "\e152";
}

.fa-crystal-ball::before {
  content: "\e362";
}

.fa-cube::before {
  content: "\f1b2";
}

.fa-cubes::before {
  content: "\f1b3";
}

.fa-cubes-stacked::before {
  content: "\e4e6";
}

.fa-cucumber::before {
  content: "\e401";
}

.fa-cup-straw::before {
  content: "\e363";
}

.fa-cup-straw-swoosh::before {
  content: "\e364";
}

.fa-cup-togo::before {
  content: "\f6c5";
}

.fa-coffee-togo::before {
  content: "\f6c5";
}

.fa-cupcake::before {
  content: "\e402";
}

.fa-curling-stone::before {
  content: "\f44a";
}

.fa-curling::before {
  content: "\f44a";
}

.fa-custard::before {
  content: "\e403";
}

.fa-d::before {
  content: "D";
}

.fa-dagger::before {
  content: "\f6cb";
}

.fa-dash::before {
  content: "\e404";
}

.fa-minus-large::before {
  content: "\e404";
}

.fa-database::before {
  content: "\f1c0";
}

.fa-deer::before {
  content: "\f78e";
}

.fa-deer-rudolph::before {
  content: "\f78f";
}

.fa-delete-left::before {
  content: "\f55a";
}

.fa-backspace::before {
  content: "\f55a";
}

.fa-delete-right::before {
  content: "\e154";
}

.fa-democrat::before {
  content: "\f747";
}

.fa-desktop::before {
  content: "\f390";
}

.fa-desktop-alt::before {
  content: "\f390";
}

.fa-desktop-arrow-down::before {
  content: "\e155";
}

.fa-dharmachakra::before {
  content: "\f655";
}

.fa-diagram-cells::before {
  content: "\e475";
}

.fa-diagram-lean-canvas::before {
  content: "\e156";
}

.fa-diagram-nested::before {
  content: "\e157";
}

.fa-diagram-next::before {
  content: "\e476";
}

.fa-diagram-predecessor::before {
  content: "\e477";
}

.fa-diagram-previous::before {
  content: "\e478";
}

.fa-diagram-project::before {
  content: "\f542";
}

.fa-project-diagram::before {
  content: "\f542";
}

.fa-diagram-sankey::before {
  content: "\e158";
}

.fa-diagram-subtask::before {
  content: "\e479";
}

.fa-diagram-successor::before {
  content: "\e47a";
}

.fa-diagram-venn::before {
  content: "\e15a";
}

.fa-dial::before {
  content: "\e15b";
}

.fa-dial-med-high::before {
  content: "\e15b";
}

.fa-dial-high::before {
  content: "\e15c";
}

.fa-dial-low::before {
  content: "\e15d";
}

.fa-dial-max::before {
  content: "\e15e";
}

.fa-dial-med::before {
  content: "\e15f";
}

.fa-dial-med-low::before {
  content: "\e160";
}

.fa-dial-min::before {
  content: "\e161";
}

.fa-dial-off::before {
  content: "\e162";
}

.fa-diamond::before {
  content: "\f219";
}

.fa-diamond-exclamation::before {
  content: "\e405";
}

.fa-diamond-turn-right::before {
  content: "\f5eb";
}

.fa-directions::before {
  content: "\f5eb";
}

.fa-dice::before {
  content: "\f522";
}

.fa-dice-d10::before {
  content: "\f6cd";
}

.fa-dice-d12::before {
  content: "\f6ce";
}

.fa-dice-d20::before {
  content: "\f6cf";
}

.fa-dice-d4::before {
  content: "\f6d0";
}

.fa-dice-d6::before {
  content: "\f6d1";
}

.fa-dice-d8::before {
  content: "\f6d2";
}

.fa-dice-five::before {
  content: "\f523";
}

.fa-dice-four::before {
  content: "\f524";
}

.fa-dice-one::before {
  content: "\f525";
}

.fa-dice-six::before {
  content: "\f526";
}

.fa-dice-three::before {
  content: "\f527";
}

.fa-dice-two::before {
  content: "\f528";
}

.fa-diploma::before {
  content: "\f5ea";
}

.fa-scroll-ribbon::before {
  content: "\f5ea";
}

.fa-disc-drive::before {
  content: "\f8b5";
}

.fa-disease::before {
  content: "\f7fa";
}

.fa-display::before {
  content: "\e163";
}

.fa-display-arrow-down::before {
  content: "\e164";
}

.fa-display-code::before {
  content: "\e165";
}

.fa-desktop-code::before {
  content: "\e165";
}

.fa-display-medical::before {
  content: "\e166";
}

.fa-desktop-medical::before {
  content: "\e166";
}

.fa-display-slash::before {
  content: "\e2fa";
}

.fa-desktop-slash::before {
  content: "\e2fa";
}

.fa-distribute-spacing-horizontal::before {
  content: "\e365";
}

.fa-distribute-spacing-vertical::before {
  content: "\e366";
}

.fa-ditto::before {
  content: "\"";
}

.fa-divide::before {
  content: "\f529";
}

.fa-dna::before {
  content: "\f471";
}

.fa-do-not-enter::before {
  content: "\f5ec";
}

.fa-dog::before {
  content: "\f6d3";
}

.fa-dog-leashed::before {
  content: "\f6d4";
}

.fa-dollar-sign::before {
  content: "\$";
}

.fa-dollar::before {
  content: "\$";
}

.fa-usd::before {
  content: "\$";
}

.fa-dolly::before {
  content: "\f472";
}

.fa-dolly-box::before {
  content: "\f472";
}

.fa-dolly-empty::before {
  content: "\f473";
}

.fa-dolphin::before {
  content: "\e168";
}

.fa-dong-sign::before {
  content: "\e169";
}

.fa-donut::before {
  content: "\e406";
}

.fa-doughnut::before {
  content: "\e406";
}

.fa-door-closed::before {
  content: "\f52a";
}

.fa-door-open::before {
  content: "\f52b";
}

.fa-dove::before {
  content: "\f4ba";
}

.fa-down::before {
  content: "\f354";
}

.fa-arrow-alt-down::before {
  content: "\f354";
}

.fa-down-from-dotted-line::before {
  content: "\e407";
}

.fa-down-from-line::before {
  content: "\f349";
}

.fa-arrow-alt-from-top::before {
  content: "\f349";
}

.fa-down-left::before {
  content: "\e16a";
}

.fa-down-left-and-up-right-to-center::before {
  content: "\f422";
}

.fa-compress-alt::before {
  content: "\f422";
}

.fa-down-long::before {
  content: "\f309";
}

.fa-long-arrow-alt-down::before {
  content: "\f309";
}

.fa-down-right::before {
  content: "\e16b";
}

.fa-down-to-bracket::before {
  content: "\e4e7";
}

.fa-down-to-dotted-line::before {
  content: "\e408";
}

.fa-down-to-line::before {
  content: "\f34a";
}

.fa-arrow-alt-to-bottom::before {
  content: "\f34a";
}

.fa-download::before {
  content: "\f019";
}

.fa-dragon::before {
  content: "\f6d5";
}

.fa-draw-circle::before {
  content: "\f5ed";
}

.fa-draw-polygon::before {
  content: "\f5ee";
}

.fa-draw-square::before {
  content: "\f5ef";
}

.fa-dreidel::before {
  content: "\f792";
}

.fa-drone::before {
  content: "\f85f";
}

.fa-drone-front::before {
  content: "\f860";
}

.fa-drone-alt::before {
  content: "\f860";
}

.fa-droplet::before {
  content: "\f043";
}

.fa-tint::before {
  content: "\f043";
}

.fa-droplet-degree::before {
  content: "\f748";
}

.fa-dewpoint::before {
  content: "\f748";
}

.fa-droplet-percent::before {
  content: "\f750";
}

.fa-humidity::before {
  content: "\f750";
}

.fa-droplet-slash::before {
  content: "\f5c7";
}

.fa-tint-slash::before {
  content: "\f5c7";
}

.fa-drum::before {
  content: "\f569";
}

.fa-drum-steelpan::before {
  content: "\f56a";
}

.fa-drumstick::before {
  content: "\f6d6";
}

.fa-drumstick-bite::before {
  content: "\f6d7";
}

.fa-dryer::before {
  content: "\f861";
}

.fa-dryer-heat::before {
  content: "\f862";
}

.fa-dryer-alt::before {
  content: "\f862";
}

.fa-duck::before {
  content: "\f6d8";
}

.fa-dumbbell::before {
  content: "\f44b";
}

.fa-dumpster::before {
  content: "\f793";
}

.fa-dumpster-fire::before {
  content: "\f794";
}

.fa-dungeon::before {
  content: "\f6d9";
}

.fa-e::before {
  content: "E";
}

.fa-ear::before {
  content: "\f5f0";
}

.fa-ear-deaf::before {
  content: "\f2a4";
}

.fa-deaf::before {
  content: "\f2a4";
}

.fa-deafness::before {
  content: "\f2a4";
}

.fa-hard-of-hearing::before {
  content: "\f2a4";
}

.fa-ear-listen::before {
  content: "\f2a2";
}

.fa-assistive-listening-systems::before {
  content: "\f2a2";
}

.fa-ear-muffs::before {
  content: "\f795";
}

.fa-earth-africa::before {
  content: "\f57c";
}

.fa-globe-africa::before {
  content: "\f57c";
}

.fa-earth-americas::before {
  content: "\f57d";
}

.fa-earth::before {
  content: "\f57d";
}

.fa-earth-america::before {
  content: "\f57d";
}

.fa-globe-americas::before {
  content: "\f57d";
}

.fa-earth-asia::before {
  content: "\f57e";
}

.fa-globe-asia::before {
  content: "\f57e";
}

.fa-earth-europe::before {
  content: "\f7a2";
}

.fa-globe-europe::before {
  content: "\f7a2";
}

.fa-earth-oceania::before {
  content: "\e47b";
}

.fa-globe-oceania::before {
  content: "\e47b";
}

.fa-eclipse::before {
  content: "\f749";
}

.fa-egg::before {
  content: "\f7fb";
}

.fa-egg-fried::before {
  content: "\f7fc";
}

.fa-eggplant::before {
  content: "\e16c";
}

.fa-eject::before {
  content: "\f052";
}

.fa-elephant::before {
  content: "\f6da";
}

.fa-elevator::before {
  content: "\e16d";
}

.fa-ellipsis::before {
  content: "\f141";
}

.fa-ellipsis-h::before {
  content: "\f141";
}

.fa-ellipsis-stroke::before {
  content: "\f39b";
}

.fa-ellipsis-h-alt::before {
  content: "\f39b";
}

.fa-ellipsis-stroke-vertical::before {
  content: "\f39c";
}

.fa-ellipsis-v-alt::before {
  content: "\f39c";
}

.fa-ellipsis-vertical::before {
  content: "\f142";
}

.fa-ellipsis-v::before {
  content: "\f142";
}

.fa-empty-set::before {
  content: "\f656";
}

.fa-engine::before {
  content: "\e16e";
}

.fa-engine-warning::before {
  content: "\f5f2";
}

.fa-engine-exclamation::before {
  content: "\f5f2";
}

.fa-envelope::before {
  content: "\f0e0";
}

.fa-envelope-circle-check::before {
  content: "\e4e8";
}

.fa-envelope-dot::before {
  content: "\e16f";
}

.fa-envelope-badge::before {
  content: "\e16f";
}

.fa-envelope-open::before {
  content: "\f2b6";
}

.fa-envelope-open-dollar::before {
  content: "\f657";
}

.fa-envelope-open-text::before {
  content: "\f658";
}

.fa-envelopes::before {
  content: "\e170";
}

.fa-envelopes-bulk::before {
  content: "\f674";
}

.fa-mail-bulk::before {
  content: "\f674";
}

.fa-equals::before {
  content: "\=";
}

.fa-eraser::before {
  content: "\f12d";
}

.fa-escalator::before {
  content: "\e171";
}

.fa-ethernet::before {
  content: "\f796";
}

.fa-euro-sign::before {
  content: "\f153";
}

.fa-eur::before {
  content: "\f153";
}

.fa-euro::before {
  content: "\f153";
}

.fa-exclamation::before {
  content: "\!";
}

.fa-expand::before {
  content: "\f065";
}

.fa-expand-wide::before {
  content: "\f320";
}

.fa-explosion::before {
  content: "\e4e9";
}

.fa-eye::before {
  content: "\f06e";
}

.fa-eye-dropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-empty::before {
  content: "\f1fb";
}

.fa-eyedropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-full::before {
  content: "\e172";
}

.fa-eye-dropper-half::before {
  content: "\e173";
}

.fa-eye-evil::before {
  content: "\f6db";
}

.fa-eye-low-vision::before {
  content: "\f2a8";
}

.fa-low-vision::before {
  content: "\f2a8";
}

.fa-eye-slash::before {
  content: "\f070";
}

.fa-eyes::before {
  content: "\e367";
}

.fa-f::before {
  content: "F";
}

.fa-face-angry::before {
  content: "\f556";
}

.fa-angry::before {
  content: "\f556";
}

.fa-face-angry-horns::before {
  content: "\e368";
}

.fa-face-anguished::before {
  content: "\e369";
}

.fa-face-anxious-sweat::before {
  content: "\e36a";
}

.fa-face-astonished::before {
  content: "\e36b";
}

.fa-face-awesome::before {
  content: "\e409";
}

.fa-gave-dandy::before {
  content: "\e409";
}

.fa-face-beam-hand-over-mouth::before {
  content: "\e47c";
}

.fa-face-clouds::before {
  content: "\e47d";
}

.fa-face-confounded::before {
  content: "\e36c";
}

.fa-face-confused::before {
  content: "\e36d";
}

.fa-face-cowboy-hat::before {
  content: "\e36e";
}

.fa-face-diagonal-mouth::before {
  content: "\e47e";
}

.fa-face-disappointed::before {
  content: "\e36f";
}

.fa-face-disguise::before {
  content: "\e370";
}

.fa-face-dizzy::before {
  content: "\f567";
}

.fa-dizzy::before {
  content: "\f567";
}

.fa-face-dotted::before {
  content: "\e47f";
}

.fa-face-downcast-sweat::before {
  content: "\e371";
}

.fa-face-drooling::before {
  content: "\e372";
}

.fa-face-exhaling::before {
  content: "\e480";
}

.fa-face-explode::before {
  content: "\e2fe";
}

.fa-exploding-head::before {
  content: "\e2fe";
}

.fa-face-expressionless::before {
  content: "\e373";
}

.fa-face-eyes-xmarks::before {
  content: "\e374";
}

.fa-face-fearful::before {
  content: "\e375";
}

.fa-face-flushed::before {
  content: "\f579";
}

.fa-flushed::before {
  content: "\f579";
}

.fa-face-frown::before {
  content: "\f119";
}

.fa-frown::before {
  content: "\f119";
}

.fa-face-frown-open::before {
  content: "\f57a";
}

.fa-frown-open::before {
  content: "\f57a";
}

.fa-face-frown-slight::before {
  content: "\e376";
}

.fa-face-glasses::before {
  content: "\e377";
}

.fa-face-grimace::before {
  content: "\f57f";
}

.fa-grimace::before {
  content: "\f57f";
}

.fa-face-grin::before {
  content: "\f580";
}

.fa-grin::before {
  content: "\f580";
}

.fa-face-grin-beam::before {
  content: "\f582";
}

.fa-grin-beam::before {
  content: "\f582";
}

.fa-face-grin-beam-sweat::before {
  content: "\f583";
}

.fa-grin-beam-sweat::before {
  content: "\f583";
}

.fa-face-grin-hearts::before {
  content: "\f584";
}

.fa-grin-hearts::before {
  content: "\f584";
}

.fa-face-grin-squint::before {
  content: "\f585";
}

.fa-grin-squint::before {
  content: "\f585";
}

.fa-face-grin-squint-tears::before {
  content: "\f586";
}

.fa-grin-squint-tears::before {
  content: "\f586";
}

.fa-face-grin-stars::before {
  content: "\f587";
}

.fa-grin-stars::before {
  content: "\f587";
}

.fa-face-grin-tears::before {
  content: "\f588";
}

.fa-grin-tears::before {
  content: "\f588";
}

.fa-face-grin-tongue::before {
  content: "\f589";
}

.fa-grin-tongue::before {
  content: "\f589";
}

.fa-face-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-face-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-face-grin-wide::before {
  content: "\f581";
}

.fa-grin-alt::before {
  content: "\f581";
}

.fa-face-grin-wink::before {
  content: "\f58c";
}

.fa-grin-wink::before {
  content: "\f58c";
}

.fa-face-hand-over-mouth::before {
  content: "\e378";
}

.fa-face-hand-peeking::before {
  content: "\e481";
}

.fa-face-hand-yawn::before {
  content: "\e379";
}

.fa-face-head-bandage::before {
  content: "\e37a";
}

.fa-face-holding-back-tears::before {
  content: "\e482";
}

.fa-face-hushed::before {
  content: "\e37b";
}

.fa-face-icicles::before {
  content: "\e37c";
}

.fa-face-kiss::before {
  content: "\f596";
}

.fa-kiss::before {
  content: "\f596";
}

.fa-face-kiss-beam::before {
  content: "\f597";
}

.fa-kiss-beam::before {
  content: "\f597";
}

.fa-face-kiss-closed-eyes::before {
  content: "\e37d";
}

.fa-face-kiss-wink-heart::before {
  content: "\f598";
}

.fa-kiss-wink-heart::before {
  content: "\f598";
}

.fa-face-laugh::before {
  content: "\f599";
}

.fa-laugh::before {
  content: "\f599";
}

.fa-face-laugh-beam::before {
  content: "\f59a";
}

.fa-laugh-beam::before {
  content: "\f59a";
}

.fa-face-laugh-squint::before {
  content: "\f59b";
}

.fa-laugh-squint::before {
  content: "\f59b";
}

.fa-face-laugh-wink::before {
  content: "\f59c";
}

.fa-laugh-wink::before {
  content: "\f59c";
}

.fa-face-lying::before {
  content: "\e37e";
}

.fa-face-mask::before {
  content: "\e37f";
}

.fa-face-meh::before {
  content: "\f11a";
}

.fa-meh::before {
  content: "\f11a";
}

.fa-face-meh-blank::before {
  content: "\f5a4";
}

.fa-meh-blank::before {
  content: "\f5a4";
}

.fa-face-melting::before {
  content: "\e483";
}

.fa-face-monocle::before {
  content: "\e380";
}

.fa-face-nauseated::before {
  content: "\e381";
}

.fa-face-nose-steam::before {
  content: "\e382";
}

.fa-face-party::before {
  content: "\e383";
}

.fa-face-pensive::before {
  content: "\e384";
}

.fa-face-persevering::before {
  content: "\e385";
}

.fa-face-pleading::before {
  content: "\e386";
}

.fa-face-pouting::before {
  content: "\e387";
}

.fa-face-raised-eyebrow::before {
  content: "\e388";
}

.fa-face-relieved::before {
  content: "\e389";
}

.fa-face-rolling-eyes::before {
  content: "\f5a5";
}

.fa-meh-rolling-eyes::before {
  content: "\f5a5";
}

.fa-face-sad-cry::before {
  content: "\f5b3";
}

.fa-sad-cry::before {
  content: "\f5b3";
}

.fa-face-sad-sweat::before {
  content: "\e38a";
}

.fa-face-sad-tear::before {
  content: "\f5b4";
}

.fa-sad-tear::before {
  content: "\f5b4";
}

.fa-face-saluting::before {
  content: "\e484";
}

.fa-face-scream::before {
  content: "\e38b";
}

.fa-face-shush::before {
  content: "\e38c";
}

.fa-face-sleeping::before {
  content: "\e38d";
}

.fa-face-sleepy::before {
  content: "\e38e";
}

.fa-face-smile::before {
  content: "\f118";
}

.fa-smile::before {
  content: "\f118";
}

.fa-face-smile-beam::before {
  content: "\f5b8";
}

.fa-smile-beam::before {
  content: "\f5b8";
}

.fa-face-smile-halo::before {
  content: "\e38f";
}

.fa-face-smile-hearts::before {
  content: "\e390";
}

.fa-face-smile-horns::before {
  content: "\e391";
}

.fa-face-smile-plus::before {
  content: "\f5b9";
}

.fa-smile-plus::before {
  content: "\f5b9";
}

.fa-face-smile-relaxed::before {
  content: "\e392";
}

.fa-face-smile-tear::before {
  content: "\e393";
}

.fa-face-smile-tongue::before {
  content: "\e394";
}

.fa-face-smile-upside-down::before {
  content: "\e395";
}

.fa-face-smile-wink::before {
  content: "\f4da";
}

.fa-smile-wink::before {
  content: "\f4da";
}

.fa-face-smiling-hands::before {
  content: "\e396";
}

.fa-face-smirking::before {
  content: "\e397";
}

.fa-face-spiral-eyes::before {
  content: "\e485";
}

.fa-face-sunglasses::before {
  content: "\e398";
}

.fa-face-surprise::before {
  content: "\f5c2";
}

.fa-surprise::before {
  content: "\f5c2";
}

.fa-face-swear::before {
  content: "\e399";
}

.fa-face-thermometer::before {
  content: "\e39a";
}

.fa-face-thinking::before {
  content: "\e39b";
}

.fa-face-tired::before {
  content: "\f5c8";
}

.fa-tired::before {
  content: "\f5c8";
}

.fa-face-tissue::before {
  content: "\e39c";
}

.fa-face-tongue-money::before {
  content: "\e39d";
}

.fa-face-tongue-sweat::before {
  content: "\e39e";
}

.fa-face-unamused::before {
  content: "\e39f";
}

.fa-face-viewfinder::before {
  content: "\e2ff";
}

.fa-face-vomit::before {
  content: "\e3a0";
}

.fa-face-weary::before {
  content: "\e3a1";
}

.fa-face-woozy::before {
  content: "\e3a2";
}

.fa-face-worried::before {
  content: "\e3a3";
}

.fa-face-zany::before {
  content: "\e3a4";
}

.fa-face-zipper::before {
  content: "\e3a5";
}

.fa-falafel::before {
  content: "\e40a";
}

.fa-family::before {
  content: "\e300";
}

.fa-family-dress::before {
  content: "\e301";
}

.fa-family-pants::before {
  content: "\e302";
}

.fa-fan::before {
  content: "\f863";
}

.fa-fan-table::before {
  content: "\e004";
}

.fa-farm::before {
  content: "\f864";
}

.fa-barn-silo::before {
  content: "\f864";
}

.fa-faucet::before {
  content: "\e005";
}

.fa-faucet-drip::before {
  content: "\e006";
}

.fa-fax::before {
  content: "\f1ac";
}

.fa-feather::before {
  content: "\f52d";
}

.fa-feather-pointed::before {
  content: "\f56b";
}

.fa-feather-alt::before {
  content: "\f56b";
}

.fa-fence::before {
  content: "\e303";
}

.fa-ferris-wheel::before {
  content: "\e174";
}

.fa-ferry::before {
  content: "\e4ea";
}

.fa-field-hockey-stick-ball::before {
  content: "\f44c";
}

.fa-field-hockey::before {
  content: "\f44c";
}

.fa-file::before {
  content: "\f15b";
}

.fa-file-arrow-down::before {
  content: "\f56d";
}

.fa-file-download::before {
  content: "\f56d";
}

.fa-file-arrow-up::before {
  content: "\f574";
}

.fa-file-upload::before {
  content: "\f574";
}

.fa-file-audio::before {
  content: "\f1c7";
}

.fa-file-binary::before {
  content: "\e175";
}

.fa-file-certificate::before {
  content: "\f5f3";
}

.fa-file-award::before {
  content: "\f5f3";
}

.fa-file-chart-column::before {
  content: "\f659";
}

.fa-file-chart-line::before {
  content: "\f659";
}

.fa-file-chart-pie::before {
  content: "\f65a";
}

.fa-file-check::before {
  content: "\f316";
}

.fa-file-circle-check::before {
  content: "\e493";
}

.fa-file-circle-exclamation::before {
  content: "\e4eb";
}

.fa-file-circle-info::before {
  content: "\e4ec";
}

.fa-file-circle-minus::before {
  content: "\e4ed";
}

.fa-file-circle-plus::before {
  content: "\e4ee";
}

.fa-file-circle-question::before {
  content: "\e4ef";
}

.fa-file-circle-xmark::before {
  content: "\e494";
}

.fa-file-code::before {
  content: "\f1c9";
}

.fa-file-contract::before {
  content: "\f56c";
}

.fa-file-csv::before {
  content: "\f6dd";
}

.fa-file-dashed-line::before {
  content: "\f877";
}

.fa-page-break::before {
  content: "\f877";
}

.fa-file-excel::before {
  content: "\f1c3";
}

.fa-file-exclamation::before {
  content: "\f31a";
}

.fa-file-export::before {
  content: "\f56e";
}

.fa-arrow-right-from-file::before {
  content: "\f56e";
}

.fa-file-heart::before {
  content: "\e176";
}

.fa-file-image::before {
  content: "\f1c5";
}

.fa-file-import::before {
  content: "\f56f";
}

.fa-arrow-right-to-file::before {
  content: "\f56f";
}

.fa-file-invoice::before {
  content: "\f570";
}

.fa-file-invoice-dollar::before {
  content: "\f571";
}

.fa-file-lines::before {
  content: "\f15c";
}

.fa-file-alt::before {
  content: "\f15c";
}

.fa-file-text::before {
  content: "\f15c";
}

.fa-file-lock::before {
  content: "\e3a6";
}

.fa-file-magnifying-glass::before {
  content: "\f865";
}

.fa-file-search::before {
  content: "\f865";
}

.fa-file-medical::before {
  content: "\f477";
}

.fa-file-minus::before {
  content: "\f318";
}

.fa-file-music::before {
  content: "\f8b6";
}

.fa-file-pdf::before {
  content: "\f1c1";
}

.fa-file-pen::before {
  content: "\f31c";
}

.fa-file-edit::before {
  content: "\f31c";
}

.fa-file-plus::before {
  content: "\f319";
}

.fa-file-plus-minus::before {
  content: "\e177";
}

.fa-file-powerpoint::before {
  content: "\f1c4";
}

.fa-file-prescription::before {
  content: "\f572";
}

.fa-file-shield::before {
  content: "\e4f0";
}

.fa-file-signature::before {
  content: "\f573";
}

.fa-file-slash::before {
  content: "\e3a7";
}

.fa-file-spreadsheet::before {
  content: "\f65b";
}

.fa-file-user::before {
  content: "\f65c";
}

.fa-file-video::before {
  content: "\f1c8";
}

.fa-file-waveform::before {
  content: "\f478";
}

.fa-file-medical-alt::before {
  content: "\f478";
}

.fa-file-word::before {
  content: "\f1c2";
}

.fa-file-xmark::before {
  content: "\f317";
}

.fa-file-times::before {
  content: "\f317";
}

.fa-file-zipper::before {
  content: "\f1c6";
}

.fa-file-archive::before {
  content: "\f1c6";
}

.fa-files::before {
  content: "\e178";
}

.fa-files-medical::before {
  content: "\f7fd";
}

.fa-fill::before {
  content: "\f575";
}

.fa-fill-drip::before {
  content: "\f576";
}

.fa-film::before {
  content: "\f008";
}

.fa-film-canister::before {
  content: "\f8b7";
}

.fa-film-simple::before {
  content: "\f3a0";
}

.fa-film-alt::before {
  content: "\f3a0";
}

.fa-film-slash::before {
  content: "\e179";
}

.fa-films::before {
  content: "\e17a";
}

.fa-filter::before {
  content: "\f0b0";
}

.fa-filter-circle-dollar::before {
  content: "\f662";
}

.fa-funnel-dollar::before {
  content: "\f662";
}

.fa-filter-circle-xmark::before {
  content: "\e17b";
}

.fa-filter-list::before {
  content: "\e17c";
}

.fa-filter-slash::before {
  content: "\e17d";
}

.fa-filters::before {
  content: "\e17e";
}

.fa-fingerprint::before {
  content: "\f577";
}

.fa-fire::before {
  content: "\f06d";
}

.fa-fire-burner::before {
  content: "\e4f1";
}

.fa-fire-extinguisher::before {
  content: "\f134";
}

.fa-fire-flame::before {
  content: "\f6df";
}

.fa-flame::before {
  content: "\f6df";
}

.fa-fire-flame-curved::before {
  content: "\f7e4";
}

.fa-fire-alt::before {
  content: "\f7e4";
}

.fa-fire-flame-simple::before {
  content: "\f46a";
}

.fa-burn::before {
  content: "\f46a";
}

.fa-fire-hydrant::before {
  content: "\e17f";
}

.fa-fire-smoke::before {
  content: "\f74b";
}

.fa-fireplace::before {
  content: "\f79a";
}

.fa-fish::before {
  content: "\f578";
}

.fa-fish-bones::before {
  content: "\e304";
}

.fa-fish-cooked::before {
  content: "\f7fe";
}

.fa-fish-fins::before {
  content: "\e4f2";
}

.fa-fishing-rod::before {
  content: "\e3a8";
}

.fa-flag::before {
  content: "\f024";
}

.fa-flag-checkered::before {
  content: "\f11e";
}

.fa-flag-pennant::before {
  content: "\f456";
}

.fa-pennant::before {
  content: "\f456";
}

.fa-flag-swallowtail::before {
  content: "\f74c";
}

.fa-flag-alt::before {
  content: "\f74c";
}

.fa-flag-usa::before {
  content: "\f74d";
}

.fa-flashlight::before {
  content: "\f8b8";
}

.fa-flask::before {
  content: "\f0c3";
}

.fa-flask-round-poison::before {
  content: "\f6e0";
}

.fa-flask-poison::before {
  content: "\f6e0";
}

.fa-flask-round-potion::before {
  content: "\f6e1";
}

.fa-flask-potion::before {
  content: "\f6e1";
}

.fa-flask-vial::before {
  content: "\e4f3";
}

.fa-flatbread::before {
  content: "\e40b";
}

.fa-flatbread-stuffed::before {
  content: "\e40c";
}

.fa-floppy-disk::before {
  content: "\f0c7";
}

.fa-save::before {
  content: "\f0c7";
}

.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180";
}

.fa-save-circle-arrow-right::before {
  content: "\e180";
}

.fa-floppy-disk-circle-xmark::before {
  content: "\e181";
}

.fa-floppy-disk-times::before {
  content: "\e181";
}

.fa-save-circle-xmark::before {
  content: "\e181";
}

.fa-save-times::before {
  content: "\e181";
}

.fa-floppy-disk-pen::before {
  content: "\e182";
}

.fa-floppy-disks::before {
  content: "\e183";
}

.fa-florin-sign::before {
  content: "\e184";
}

.fa-flower::before {
  content: "\f7ff";
}

.fa-flower-daffodil::before {
  content: "\f800";
}

.fa-flower-tulip::before {
  content: "\f801";
}

.fa-flute::before {
  content: "\f8b9";
}

.fa-flux-capacitor::before {
  content: "\f8ba";
}

.fa-flying-disc::before {
  content: "\e3a9";
}

.fa-folder::before {
  content: "\f07b";
}

.fa-folder-blank::before {
  content: "\f07b";
}

.fa-folder-arrow-down::before {
  content: "\e053";
}

.fa-folder-download::before {
  content: "\e053";
}

.fa-folder-arrow-up::before {
  content: "\e054";
}

.fa-folder-upload::before {
  content: "\e054";
}

.fa-folder-bookmark::before {
  content: "\e186";
}

.fa-folder-closed::before {
  content: "\e185";
}

.fa-folder-gear::before {
  content: "\e187";
}

.fa-folder-cog::before {
  content: "\e187";
}

.fa-folder-grid::before {
  content: "\e188";
}

.fa-folder-heart::before {
  content: "\e189";
}

.fa-folder-image::before {
  content: "\e18a";
}

.fa-folder-magnifying-glass::before {
  content: "\e18b";
}

.fa-folder-search::before {
  content: "\e18b";
}

.fa-folder-medical::before {
  content: "\e18c";
}

.fa-folder-minus::before {
  content: "\f65d";
}

.fa-folder-music::before {
  content: "\e18d";
}

.fa-folder-open::before {
  content: "\f07c";
}

.fa-folder-plus::before {
  content: "\f65e";
}

.fa-folder-tree::before {
  content: "\f802";
}

.fa-folder-user::before {
  content: "\e18e";
}

.fa-folder-xmark::before {
  content: "\f65f";
}

.fa-folder-times::before {
  content: "\f65f";
}

.fa-folders::before {
  content: "\f660";
}

.fa-fondue-pot::before {
  content: "\e40d";
}

.fa-font::before {
  content: "\f031";
}

.fa-font-case::before {
  content: "\f866";
}

.fa-football::before {
  content: "\f44e";
}

.fa-football-ball::before {
  content: "\f44e";
}

.fa-football-helmet::before {
  content: "\f44f";
}

.fa-fork::before {
  content: "\f2e3";
}

.fa-utensil-fork::before {
  content: "\f2e3";
}

.fa-fork-knife::before {
  content: "\f2e6";
}

.fa-utensils-alt::before {
  content: "\f2e6";
}

.fa-forklift::before {
  content: "\f47a";
}

.fa-fort::before {
  content: "\e486";
}

.fa-forward::before {
  content: "\f04e";
}

.fa-forward-fast::before {
  content: "\f050";
}

.fa-fast-forward::before {
  content: "\f050";
}

.fa-forward-step::before {
  content: "\f051";
}

.fa-step-forward::before {
  content: "\f051";
}

.fa-frame::before {
  content: "\e495";
}

.fa-franc-sign::before {
  content: "\e18f";
}

.fa-french-fries::before {
  content: "\f803";
}

.fa-frog::before {
  content: "\f52e";
}

.fa-function::before {
  content: "\f661";
}

.fa-futbol::before {
  content: "\f1e3";
}

.fa-futbol-ball::before {
  content: "\f1e3";
}

.fa-soccer-ball::before {
  content: "\f1e3";
}

.fa-g::before {
  content: "G";
}

.fa-galaxy::before {
  content: "\e008";
}

.fa-gallery-thumbnails::before {
  content: "\e3aa";
}

.fa-game-board::before {
  content: "\f867";
}

.fa-game-board-simple::before {
  content: "\f868";
}

.fa-game-board-alt::before {
  content: "\f868";
}

.fa-game-console-handheld::before {
  content: "\f8bb";
}

.fa-gamepad::before {
  content: "\f11b";
}

.fa-gamepad-modern::before {
  content: "\f8bc";
}

.fa-gamepad-alt::before {
  content: "\f8bc";
}

.fa-garage::before {
  content: "\e009";
}

.fa-garage-car::before {
  content: "\e00a";
}

.fa-garage-open::before {
  content: "\e00b";
}

.fa-garlic::before {
  content: "\e40e";
}

.fa-gas-pump::before {
  content: "\f52f";
}

.fa-gas-pump-slash::before {
  content: "\f5f4";
}

.fa-gauge::before {
  content: "\f624";
}

.fa-dashboard::before {
  content: "\f624";
}

.fa-gauge-med::before {
  content: "\f624";
}

.fa-tachometer-alt-average::before {
  content: "\f624";
}

.fa-gauge-circle-bolt::before {
  content: "\e496";
}

.fa-gauge-circle-minus::before {
  content: "\e497";
}

.fa-gauge-circle-plus::before {
  content: "\e498";
}

.fa-gauge-high::before {
  content: "\f625";
}

.fa-tachometer-alt::before {
  content: "\f625";
}

.fa-tachometer-alt-fast::before {
  content: "\f625";
}

.fa-gauge-low::before {
  content: "\f627";
}

.fa-tachometer-alt-slow::before {
  content: "\f627";
}

.fa-gauge-max::before {
  content: "\f626";
}

.fa-tachometer-alt-fastest::before {
  content: "\f626";
}

.fa-gauge-min::before {
  content: "\f628";
}

.fa-tachometer-alt-slowest::before {
  content: "\f628";
}

.fa-gauge-simple::before {
  content: "\f629";
}

.fa-gauge-simple-med::before {
  content: "\f629";
}

.fa-tachometer-average::before {
  content: "\f629";
}

.fa-gauge-simple-high::before {
  content: "\f62a";
}

.fa-tachometer::before {
  content: "\f62a";
}

.fa-tachometer-fast::before {
  content: "\f62a";
}

.fa-gauge-simple-low::before {
  content: "\f62c";
}

.fa-tachometer-slow::before {
  content: "\f62c";
}

.fa-gauge-simple-max::before {
  content: "\f62b";
}

.fa-tachometer-fastest::before {
  content: "\f62b";
}

.fa-gauge-simple-min::before {
  content: "\f62d";
}

.fa-tachometer-slowest::before {
  content: "\f62d";
}

.fa-gavel::before {
  content: "\f0e3";
}

.fa-legal::before {
  content: "\f0e3";
}

.fa-gear::before {
  content: "\f013";
}

.fa-cog::before {
  content: "\f013";
}

.fa-gears::before {
  content: "\f085";
}

.fa-cogs::before {
  content: "\f085";
}

.fa-gem::before {
  content: "\f3a5";
}

.fa-genderless::before {
  content: "\f22d";
}

.fa-ghost::before {
  content: "\f6e2";
}

.fa-gif::before {
  content: "\e190";
}

.fa-gift::before {
  content: "\f06b";
}

.fa-gift-card::before {
  content: "\f663";
}

.fa-gifts::before {
  content: "\f79c";
}

.fa-gingerbread-man::before {
  content: "\f79d";
}

.fa-glass::before {
  content: "\f804";
}

.fa-glass-citrus::before {
  content: "\f869";
}

.fa-glass-empty::before {
  content: "\e191";
}

.fa-glass-half::before {
  content: "\e192";
}

.fa-glass-half-empty::before {
  content: "\e192";
}

.fa-glass-half-full::before {
  content: "\e192";
}

.fa-glass-water::before {
  content: "\e4f4";
}

.fa-glass-water-droplet::before {
  content: "\e4f5";
}

.fa-glasses::before {
  content: "\f530";
}

.fa-glasses-round::before {
  content: "\f5f5";
}

.fa-glasses-alt::before {
  content: "\f5f5";
}

.fa-globe::before {
  content: "\f0ac";
}

.fa-globe-snow::before {
  content: "\f7a3";
}

.fa-globe-stand::before {
  content: "\f5f6";
}

.fa-goal-net::before {
  content: "\e3ab";
}

.fa-golf-ball-tee::before {
  content: "\f450";
}

.fa-golf-ball::before {
  content: "\f450";
}

.fa-golf-club::before {
  content: "\f451";
}

.fa-golf-flag-hole::before {
  content: "\e3ac";
}

.fa-gopuram::before {
  content: "\f664";
}

.fa-graduation-cap::before {
  content: "\f19d";
}

.fa-mortar-board::before {
  content: "\f19d";
}

.fa-gramophone::before {
  content: "\f8bd";
}

.fa-grapes::before {
  content: "\e306";
}

.fa-grate::before {
  content: "\e193";
}

.fa-grate-droplet::before {
  content: "\e194";
}

.fa-greater-than::before {
  content: "\>";
}

.fa-greater-than-equal::before {
  content: "\f532";
}

.fa-grid::before {
  content: "\e195";
}

.fa-grid-3::before {
  content: "\e195";
}

.fa-grid-2::before {
  content: "\e196";
}

.fa-grid-2-plus::before {
  content: "\e197";
}

.fa-grid-4::before {
  content: "\e198";
}

.fa-grid-5::before {
  content: "\e199";
}

.fa-grid-dividers::before {
  content: "\e3ad";
}

.fa-grid-horizontal::before {
  content: "\e307";
}

.fa-grip::before {
  content: "\f58d";
}

.fa-grip-horizontal::before {
  content: "\f58d";
}

.fa-grip-dots::before {
  content: "\e410";
}

.fa-grip-dots-vertical::before {
  content: "\e411";
}

.fa-grip-lines::before {
  content: "\f7a4";
}

.fa-grip-lines-vertical::before {
  content: "\f7a5";
}

.fa-grip-vertical::before {
  content: "\f58e";
}

.fa-group-arrows-rotate::before {
  content: "\e4f6";
}

.fa-guarani-sign::before {
  content: "\e19a";
}

.fa-guitar::before {
  content: "\f7a6";
}

.fa-guitar-electric::before {
  content: "\f8be";
}

.fa-guitars::before {
  content: "\f8bf";
}

.fa-gun::before {
  content: "\e19b";
}

.fa-gun-slash::before {
  content: "\e19c";
}

.fa-gun-squirt::before {
  content: "\e19d";
}

.fa-h::before {
  content: "H";
}

.fa-h1::before {
  content: "\f313";
}

.fa-h2::before {
  content: "\f314";
}

.fa-h3::before {
  content: "\f315";
}

.fa-h4::before {
  content: "\f86a";
}

.fa-h5::before {
  content: "\e412";
}

.fa-h6::before {
  content: "\e413";
}

.fa-hammer::before {
  content: "\f6e3";
}

.fa-hammer-crash::before {
  content: "\e414";
}

.fa-hammer-war::before {
  content: "\f6e4";
}

.fa-hamsa::before {
  content: "\f665";
}

.fa-hand::before {
  content: "\f256";
}

.fa-hand-paper::before {
  content: "\f256";
}

.fa-hand-back-fist::before {
  content: "\f255";
}

.fa-hand-rock::before {
  content: "\f255";
}

.fa-hand-back-point-down::before {
  content: "\e19e";
}

.fa-hand-back-point-left::before {
  content: "\e19f";
}

.fa-hand-back-point-ribbon::before {
  content: "\e1a0";
}

.fa-hand-back-point-right::before {
  content: "\e1a1";
}

.fa-hand-back-point-up::before {
  content: "\e1a2";
}

.fa-hand-dots::before {
  content: "\f461";
}

.fa-allergies::before {
  content: "\f461";
}

.fa-hand-fingers-crossed::before {
  content: "\e1a3";
}

.fa-hand-fist::before {
  content: "\f6de";
}

.fa-fist-raised::before {
  content: "\f6de";
}

.fa-hand-heart::before {
  content: "\f4bc";
}

.fa-hand-holding::before {
  content: "\f4bd";
}

.fa-hand-holding-box::before {
  content: "\f47b";
}

.fa-hand-holding-dollar::before {
  content: "\f4c0";
}

.fa-hand-holding-usd::before {
  content: "\f4c0";
}

.fa-hand-holding-droplet::before {
  content: "\f4c1";
}

.fa-hand-holding-water::before {
  content: "\f4c1";
}

.fa-hand-holding-hand::before {
  content: "\e4f7";
}

.fa-hand-holding-heart::before {
  content: "\f4be";
}

.fa-hand-holding-magic::before {
  content: "\f6e5";
}

.fa-hand-holding-medical::before {
  content: "\e05c";
}

.fa-hand-holding-seedling::before {
  content: "\f4bf";
}

.fa-hand-holding-skull::before {
  content: "\e1a4";
}

.fa-hand-horns::before {
  content: "\e1a9";
}

.fa-hand-lizard::before {
  content: "\f258";
}

.fa-hand-love::before {
  content: "\e1a5";
}

.fa-hand-middle-finger::before {
  content: "\f806";
}

.fa-hand-peace::before {
  content: "\f25b";
}

.fa-hand-point-down::before {
  content: "\f0a7";
}

.fa-hand-point-left::before {
  content: "\f0a5";
}

.fa-hand-point-ribbon::before {
  content: "\e1a6";
}

.fa-hand-point-right::before {
  content: "\f0a4";
}

.fa-hand-point-up::before {
  content: "\f0a6";
}

.fa-hand-pointer::before {
  content: "\f25a";
}

.fa-hand-scissors::before {
  content: "\f257";
}

.fa-hand-sparkles::before {
  content: "\e05d";
}

.fa-hand-spock::before {
  content: "\f259";
}

.fa-hand-wave::before {
  content: "\e1a7";
}

.fa-handcuffs::before {
  content: "\e4f8";
}

.fa-hands::before {
  content: "\f2a7";
}

.fa-sign-language::before {
  content: "\f2a7";
}

.fa-signing::before {
  content: "\f2a7";
}

.fa-hands-asl-interpreting::before {
  content: "\f2a3";
}

.fa-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-asl-interpreting::before {
  content: "\f2a3";
}

.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-hands-bound::before {
  content: "\e4f9";
}

.fa-hands-bubbles::before {
  content: "\e05e";
}

.fa-hands-wash::before {
  content: "\e05e";
}

.fa-hands-clapping::before {
  content: "\e1a8";
}

.fa-hands-holding::before {
  content: "\f4c2";
}

.fa-hands-holding-child::before {
  content: "\e4fa";
}

.fa-hands-holding-circle::before {
  content: "\e4fb";
}

.fa-hands-holding-diamond::before {
  content: "\f47c";
}

.fa-hand-receiving::before {
  content: "\f47c";
}

.fa-hands-holding-dollar::before {
  content: "\f4c5";
}

.fa-hands-usd::before {
  content: "\f4c5";
}

.fa-hands-holding-heart::before {
  content: "\f4c3";
}

.fa-hands-heart::before {
  content: "\f4c3";
}

.fa-hands-praying::before {
  content: "\f684";
}

.fa-praying-hands::before {
  content: "\f684";
}

.fa-handshake::before {
  content: "\f2b5";
}

.fa-handshake-angle::before {
  content: "\f4c4";
}

.fa-hands-helping::before {
  content: "\f4c4";
}

.fa-handshake-simple::before {
  content: "\f4c6";
}

.fa-handshake-alt::before {
  content: "\f4c6";
}

.fa-handshake-simple-slash::before {
  content: "\e05f";
}

.fa-handshake-alt-slash::before {
  content: "\e05f";
}

.fa-handshake-slash::before {
  content: "\e060";
}

.fa-hanukiah::before {
  content: "\f6e6";
}

.fa-hard-drive::before {
  content: "\f0a0";
}

.fa-hdd::before {
  content: "\f0a0";
}

.fa-hashtag::before {
  content: "\#";
}

.fa-hashtag-lock::before {
  content: "\e415";
}

.fa-hat-chef::before {
  content: "\f86b";
}

.fa-hat-cowboy::before {
  content: "\f8c0";
}

.fa-hat-cowboy-side::before {
  content: "\f8c1";
}

.fa-hat-santa::before {
  content: "\f7a7";
}

.fa-hat-winter::before {
  content: "\f7a8";
}

.fa-hat-witch::before {
  content: "\f6e7";
}

.fa-hat-wizard::before {
  content: "\f6e8";
}

.fa-head-side::before {
  content: "\f6e9";
}

.fa-head-side-brain::before {
  content: "\f808";
}

.fa-head-side-cough::before {
  content: "\e061";
}

.fa-head-side-cough-slash::before {
  content: "\e062";
}

.fa-head-side-goggles::before {
  content: "\f6ea";
}

.fa-head-vr::before {
  content: "\f6ea";
}

.fa-head-side-headphones::before {
  content: "\f8c2";
}

.fa-head-side-heart::before {
  content: "\e1aa";
}

.fa-head-side-mask::before {
  content: "\e063";
}

.fa-head-side-medical::before {
  content: "\f809";
}

.fa-head-side-virus::before {
  content: "\e064";
}

.fa-heading::before {
  content: "\f1dc";
}

.fa-header::before {
  content: "\f1dc";
}

.fa-headphones::before {
  content: "\f025";
}

.fa-headphones-simple::before {
  content: "\f58f";
}

.fa-headphones-alt::before {
  content: "\f58f";
}

.fa-headset::before {
  content: "\f590";
}

.fa-heart::before {
  content: "\f004";
}

.fa-heart-circle-bolt::before {
  content: "\e4fc";
}

.fa-heart-circle-check::before {
  content: "\e4fd";
}

.fa-heart-circle-exclamation::before {
  content: "\e4fe";
}

.fa-heart-circle-minus::before {
  content: "\e4ff";
}

.fa-heart-circle-plus::before {
  content: "\e500";
}

.fa-heart-circle-xmark::before {
  content: "\e501";
}

.fa-heart-crack::before {
  content: "\f7a9";
}

.fa-heart-broken::before {
  content: "\f7a9";
}

.fa-heart-half::before {
  content: "\e1ab";
}

.fa-heart-half-stroke::before {
  content: "\e1ac";
}

.fa-heart-half-alt::before {
  content: "\e1ac";
}

.fa-heart-pulse::before {
  content: "\f21e";
}

.fa-heartbeat::before {
  content: "\f21e";
}

.fa-heat::before {
  content: "\e00c";
}

.fa-helicopter::before {
  content: "\f533";
}

.fa-helicopter-symbol::before {
  content: "\e502";
}

.fa-helmet-battle::before {
  content: "\f6eb";
}

.fa-helmet-safety::before {
  content: "\f807";
}

.fa-hard-hat::before {
  content: "\f807";
}

.fa-hat-hard::before {
  content: "\f807";
}

.fa-helmet-un::before {
  content: "\e503";
}

.fa-hexagon::before {
  content: "\f312";
}

.fa-hexagon-check::before {
  content: "\e416";
}

.fa-hexagon-divide::before {
  content: "\e1ad";
}

.fa-hexagon-exclamation::before {
  content: "\e417";
}

.fa-hexagon-image::before {
  content: "\e504";
}

.fa-hexagon-minus::before {
  content: "\f307";
}

.fa-minus-hexagon::before {
  content: "\f307";
}

.fa-hexagon-plus::before {
  content: "\f300";
}

.fa-plus-hexagon::before {
  content: "\f300";
}

.fa-hexagon-vertical-nft::before {
  content: "\e505";
}

.fa-hexagon-vertical-nft-slanted::before {
  content: "\e506";
}

.fa-hexagon-xmark::before {
  content: "\f2ee";
}

.fa-times-hexagon::before {
  content: "\f2ee";
}

.fa-xmark-hexagon::before {
  content: "\f2ee";
}

.fa-high-definition::before {
  content: "\e1ae";
}

.fa-rectangle-hd::before {
  content: "\e1ae";
}

.fa-highlighter::before {
  content: "\f591";
}

.fa-highlighter-line::before {
  content: "\e1af";
}

.fa-hill-avalanche::before {
  content: "\e507";
}

.fa-hill-rockslide::before {
  content: "\e508";
}

.fa-hippo::before {
  content: "\f6ed";
}

.fa-hockey-mask::before {
  content: "\f6ee";
}

.fa-hockey-puck::before {
  content: "\f453";
}

.fa-hockey-stick-puck::before {
  content: "\e3ae";
}

.fa-hockey-sticks::before {
  content: "\f454";
}

.fa-holly-berry::before {
  content: "\f7aa";
}

.fa-honey-pot::before {
  content: "\e418";
}

.fa-hood-cloak::before {
  content: "\f6ef";
}

.fa-horizontal-rule::before {
  content: "\f86c";
}

.fa-horse::before {
  content: "\f6f0";
}

.fa-horse-head::before {
  content: "\f7ab";
}

.fa-horse-saddle::before {
  content: "\f8c3";
}

.fa-hose::before {
  content: "\e419";
}

.fa-hose-reel::before {
  content: "\e41a";
}

.fa-hospital::before {
  content: "\f0f8";
}

.fa-hospital-alt::before {
  content: "\f0f8";
}

.fa-hospital-wide::before {
  content: "\f0f8";
}

.fa-hospital-user::before {
  content: "\f80d";
}

.fa-hospitals::before {
  content: "\f80e";
}

.fa-hot-tub-person::before {
  content: "\f593";
}

.fa-hot-tub::before {
  content: "\f593";
}

.fa-hotdog::before {
  content: "\f80f";
}

.fa-hotel::before {
  content: "\f594";
}

.fa-hourglass::before {
  content: "\f254";
}

.fa-hourglass-2::before {
  content: "\f254";
}

.fa-hourglass-half::before {
  content: "\f254";
}

.fa-hourglass-clock::before {
  content: "\e41b";
}

.fa-hourglass-empty::before {
  content: "\f252";
}

.fa-hourglass-end::before {
  content: "\f253";
}

.fa-hourglass-3::before {
  content: "\f253";
}

.fa-hourglass-start::before {
  content: "\f251";
}

.fa-hourglass-1::before {
  content: "\f251";
}

.fa-house::before {
  content: "\f015";
}

.fa-home::before {
  content: "\f015";
}

.fa-home-alt::before {
  content: "\f015";
}

.fa-home-lg-alt::before {
  content: "\f015";
}

.fa-house-blank::before {
  content: "\e487";
}

.fa-home-blank::before {
  content: "\e487";
}

.fa-house-building::before {
  content: "\e1b1";
}

.fa-house-chimney::before {
  content: "\e3af";
}

.fa-home-lg::before {
  content: "\e3af";
}

.fa-house-chimney-blank::before {
  content: "\e3b0";
}

.fa-house-chimney-crack::before {
  content: "\f6f1";
}

.fa-house-damage::before {
  content: "\f6f1";
}

.fa-house-chimney-heart::before {
  content: "\e1b2";
}

.fa-house-chimney-medical::before {
  content: "\f7f2";
}

.fa-clinic-medical::before {
  content: "\f7f2";
}

.fa-house-chimney-user::before {
  content: "\e065";
}

.fa-house-chimney-window::before {
  content: "\e00d";
}

.fa-house-circle-check::before {
  content: "\e509";
}

.fa-house-circle-exclamation::before {
  content: "\e50a";
}

.fa-house-circle-xmark::before {
  content: "\e50b";
}

.fa-house-crack::before {
  content: "\e3b1";
}

.fa-house-day::before {
  content: "\e00e";
}

.fa-house-fire::before {
  content: "\e50c";
}

.fa-house-flag::before {
  content: "\e50d";
}

.fa-house-flood-water::before {
  content: "\e50e";
}

.fa-house-flood-water-circle-arrow-right::before {
  content: "\e50f";
}

.fa-house-heart::before {
  content: "\f4c9";
}

.fa-home-heart::before {
  content: "\f4c9";
}

.fa-house-laptop::before {
  content: "\e066";
}

.fa-laptop-house::before {
  content: "\e066";
}

.fa-house-lock::before {
  content: "\e510";
}

.fa-house-medical::before {
  content: "\e3b2";
}

.fa-house-medical-circle-check::before {
  content: "\e511";
}

.fa-house-medical-circle-exclamation::before {
  content: "\e512";
}

.fa-house-medical-circle-xmark::before {
  content: "\e513";
}

.fa-house-medical-flag::before {
  content: "\e514";
}

.fa-house-night::before {
  content: "\e010";
}

.fa-house-person-leave::before {
  content: "\e00f";
}

.fa-house-leave::before {
  content: "\e00f";
}

.fa-house-person-depart::before {
  content: "\e00f";
}

.fa-house-person-return::before {
  content: "\e011";
}

.fa-house-person-arrive::before {
  content: "\e011";
}

.fa-house-return::before {
  content: "\e011";
}

.fa-house-signal::before {
  content: "\e012";
}

.fa-house-tree::before {
  content: "\e1b3";
}

.fa-house-tsunami::before {
  content: "\e515";
}

.fa-house-turret::before {
  content: "\e1b4";
}

.fa-house-user::before {
  content: "\e1b0";
}

.fa-home-user::before {
  content: "\e1b0";
}

.fa-house-water::before {
  content: "\f74f";
}

.fa-house-flood::before {
  content: "\f74f";
}

.fa-house-window::before {
  content: "\e3b3";
}

.fa-hryvnia-sign::before {
  content: "\f6f2";
}

.fa-hryvnia::before {
  content: "\f6f2";
}

.fa-hundred-points::before {
  content: "\e41c";
}

.fa-100::before {
  content: "\e41c";
}

.fa-hurricane::before {
  content: "\f751";
}

.fa-hyphen::before {
  content: "\-";
}

.fa-i::before {
  content: "I";
}

.fa-i-cursor::before {
  content: "\f246";
}

.fa-ice-cream::before {
  content: "\f810";
}

.fa-ice-skate::before {
  content: "\f7ac";
}

.fa-icicles::before {
  content: "\f7ad";
}

.fa-icons::before {
  content: "\f86d";
}

.fa-heart-music-camera-bolt::before {
  content: "\f86d";
}

.fa-id-badge::before {
  content: "\f2c1";
}

.fa-id-card::before {
  content: "\f2c2";
}

.fa-drivers-license::before {
  content: "\f2c2";
}

.fa-id-card-clip::before {
  content: "\f47f";
}

.fa-id-card-alt::before {
  content: "\f47f";
}

.fa-igloo::before {
  content: "\f7ae";
}

.fa-image::before {
  content: "\f03e";
}

.fa-image-landscape::before {
  content: "\e1b5";
}

.fa-landscape::before {
  content: "\e1b5";
}

.fa-image-polaroid::before {
  content: "\f8c4";
}

.fa-image-polaroid-user::before {
  content: "\e1b6";
}

.fa-image-portrait::before {
  content: "\f3e0";
}

.fa-portrait::before {
  content: "\f3e0";
}

.fa-image-slash::before {
  content: "\e1b7";
}

.fa-image-user::before {
  content: "\e1b8";
}

.fa-images::before {
  content: "\f302";
}

.fa-images-user::before {
  content: "\e1b9";
}

.fa-inbox::before {
  content: "\f01c";
}

.fa-inbox-full::before {
  content: "\e1ba";
}

.fa-inbox-in::before {
  content: "\f310";
}

.fa-inbox-arrow-down::before {
  content: "\f310";
}

.fa-inbox-out::before {
  content: "\f311";
}

.fa-inbox-arrow-up::before {
  content: "\f311";
}

.fa-inboxes::before {
  content: "\e1bb";
}

.fa-indent::before {
  content: "\f03c";
}

.fa-indian-rupee-sign::before {
  content: "\e1bc";
}

.fa-indian-rupee::before {
  content: "\e1bc";
}

.fa-inr::before {
  content: "\e1bc";
}

.fa-industry::before {
  content: "\f275";
}

.fa-industry-windows::before {
  content: "\f3b3";
}

.fa-industry-alt::before {
  content: "\f3b3";
}

.fa-infinity::before {
  content: "\f534";
}

.fa-info::before {
  content: "\f129";
}

.fa-inhaler::before {
  content: "\f5f9";
}

.fa-input-numeric::before {
  content: "\e1bd";
}

.fa-input-pipe::before {
  content: "\e1be";
}

.fa-input-text::before {
  content: "\e1bf";
}

.fa-integral::before {
  content: "\f667";
}

.fa-intersection::before {
  content: "\f668";
}

.fa-island-tropical::before {
  content: "\f811";
}

.fa-island-tree-palm::before {
  content: "\f811";
}

.fa-italic::before {
  content: "\f033";
}

.fa-j::before {
  content: "J";
}

.fa-jack-o-lantern::before {
  content: "\f30e";
}

.fa-jar::before {
  content: "\e516";
}

.fa-jar-wheat::before {
  content: "\e517";
}

.fa-jedi::before {
  content: "\f669";
}

.fa-jet-fighter::before {
  content: "\f0fb";
}

.fa-fighter-jet::before {
  content: "\f0fb";
}

.fa-jet-fighter-up::before {
  content: "\e518";
}

.fa-joint::before {
  content: "\f595";
}

.fa-joystick::before {
  content: "\f8c5";
}

.fa-jug::before {
  content: "\f8c6";
}

.fa-jug-detergent::before {
  content: "\e519";
}

.fa-k::before {
  content: "K";
}

.fa-kaaba::before {
  content: "\f66b";
}

.fa-kazoo::before {
  content: "\f8c7";
}

.fa-kerning::before {
  content: "\f86f";
}

.fa-key::before {
  content: "\f084";
}

.fa-key-skeleton::before {
  content: "\f6f3";
}

.fa-key-skeleton-left-right::before {
  content: "\e3b4";
}

.fa-keyboard::before {
  content: "\f11c";
}

.fa-keyboard-brightness::before {
  content: "\e1c0";
}

.fa-keyboard-brightness-low::before {
  content: "\e1c1";
}

.fa-keyboard-down::before {
  content: "\e1c2";
}

.fa-keyboard-left::before {
  content: "\e1c3";
}

.fa-keynote::before {
  content: "\f66c";
}

.fa-khanda::before {
  content: "\f66d";
}

.fa-kidneys::before {
  content: "\f5fb";
}

.fa-kip-sign::before {
  content: "\e1c4";
}

.fa-kit-medical::before {
  content: "\f479";
}

.fa-first-aid::before {
  content: "\f479";
}

.fa-kitchen-set::before {
  content: "\e51a";
}

.fa-kite::before {
  content: "\f6f4";
}

.fa-kiwi-bird::before {
  content: "\f535";
}

.fa-kiwi-fruit::before {
  content: "\e30c";
}

.fa-knife::before {
  content: "\f2e4";
}

.fa-utensil-knife::before {
  content: "\f2e4";
}

.fa-knife-kitchen::before {
  content: "\f6f5";
}

.fa-l::before {
  content: "L";
}

.fa-lacrosse-stick::before {
  content: "\e3b5";
}

.fa-lacrosse-stick-ball::before {
  content: "\e3b6";
}

.fa-lambda::before {
  content: "\f66e";
}

.fa-lamp::before {
  content: "\f4ca";
}

.fa-lamp-desk::before {
  content: "\e014";
}

.fa-lamp-floor::before {
  content: "\e015";
}

.fa-lamp-street::before {
  content: "\e1c5";
}

.fa-land-mine-on::before {
  content: "\e51b";
}

.fa-landmark::before {
  content: "\f66f";
}

.fa-landmark-dome::before {
  content: "\f752";
}

.fa-landmark-alt::before {
  content: "\f752";
}

.fa-landmark-flag::before {
  content: "\e51c";
}

.fa-language::before {
  content: "\f1ab";
}

.fa-laptop::before {
  content: "\f109";
}

.fa-laptop-arrow-down::before {
  content: "\e1c6";
}

.fa-laptop-code::before {
  content: "\f5fc";
}

.fa-laptop-file::before {
  content: "\e51d";
}

.fa-laptop-medical::before {
  content: "\f812";
}

.fa-laptop-mobile::before {
  content: "\f87a";
}

.fa-phone-laptop::before {
  content: "\f87a";
}

.fa-laptop-slash::before {
  content: "\e1c7";
}

.fa-lari-sign::before {
  content: "\e1c8";
}

.fa-lasso::before {
  content: "\f8c8";
}

.fa-lasso-sparkles::before {
  content: "\e1c9";
}

.fa-layer-group::before {
  content: "\f5fd";
}

.fa-layer-minus::before {
  content: "\f5fe";
}

.fa-layer-group-minus::before {
  content: "\f5fe";
}

.fa-layer-plus::before {
  content: "\f5ff";
}

.fa-layer-group-plus::before {
  content: "\f5ff";
}

.fa-leaf::before {
  content: "\f06c";
}

.fa-leaf-heart::before {
  content: "\f4cb";
}

.fa-leaf-maple::before {
  content: "\f6f6";
}

.fa-leaf-oak::before {
  content: "\f6f7";
}

.fa-leafy-green::before {
  content: "\e41d";
}

.fa-left::before {
  content: "\f355";
}

.fa-arrow-alt-left::before {
  content: "\f355";
}

.fa-left-from-line::before {
  content: "\f348";
}

.fa-arrow-alt-from-right::before {
  content: "\f348";
}

.fa-left-long::before {
  content: "\f30a";
}

.fa-long-arrow-alt-left::before {
  content: "\f30a";
}

.fa-left-long-to-line::before {
  content: "\e41e";
}

.fa-left-right::before {
  content: "\f337";
}

.fa-arrows-alt-h::before {
  content: "\f337";
}

.fa-left-to-line::before {
  content: "\f34b";
}

.fa-arrow-alt-to-left::before {
  content: "\f34b";
}

.fa-lemon::before {
  content: "\f094";
}

.fa-less-than::before {
  content: "\<";
}

.fa-less-than-equal::before {
  content: "\f537";
}

.fa-life-ring::before {
  content: "\f1cd";
}

.fa-light-ceiling::before {
  content: "\e016";
}

.fa-light-emergency::before {
  content: "\e41f";
}

.fa-light-emergency-on::before {
  content: "\e420";
}

.fa-light-switch::before {
  content: "\e017";
}

.fa-light-switch-off::before {
  content: "\e018";
}

.fa-light-switch-on::before {
  content: "\e019";
}

.fa-lightbulb::before {
  content: "\f0eb";
}

.fa-lightbulb-dollar::before {
  content: "\f670";
}

.fa-lightbulb-exclamation::before {
  content: "\f671";
}

.fa-lightbulb-exclamation-on::before {
  content: "\e1ca";
}

.fa-lightbulb-on::before {
  content: "\f672";
}

.fa-lightbulb-slash::before {
  content: "\f673";
}

.fa-lights-holiday::before {
  content: "\f7b2";
}

.fa-line-columns::before {
  content: "\f870";
}

.fa-line-height::before {
  content: "\f871";
}

.fa-lines-leaning::before {
  content: "\e51e";
}

.fa-link::before {
  content: "\f0c1";
}

.fa-chain::before {
  content: "\f0c1";
}

.fa-link-horizontal::before {
  content: "\e1cb";
}

.fa-chain-horizontal::before {
  content: "\e1cb";
}

.fa-link-horizontal-slash::before {
  content: "\e1cc";
}

.fa-chain-horizontal-slash::before {
  content: "\e1cc";
}

.fa-link-simple::before {
  content: "\e1cd";
}

.fa-link-simple-slash::before {
  content: "\e1ce";
}

.fa-link-slash::before {
  content: "\f127";
}

.fa-chain-broken::before {
  content: "\f127";
}

.fa-chain-slash::before {
  content: "\f127";
}

.fa-unlink::before {
  content: "\f127";
}

.fa-lips::before {
  content: "\f600";
}

.fa-lira-sign::before {
  content: "\f195";
}

.fa-list::before {
  content: "\f03a";
}

.fa-list-squares::before {
  content: "\f03a";
}

.fa-list-check::before {
  content: "\f0ae";
}

.fa-tasks::before {
  content: "\f0ae";
}

.fa-list-dropdown::before {
  content: "\e1cf";
}

.fa-list-music::before {
  content: "\f8c9";
}

.fa-list-ol::before {
  content: "\f0cb";
}

.fa-list-1-2::before {
  content: "\f0cb";
}

.fa-list-numeric::before {
  content: "\f0cb";
}

.fa-list-radio::before {
  content: "\e1d0";
}

.fa-list-timeline::before {
  content: "\e1d1";
}

.fa-list-tree::before {
  content: "\e1d2";
}

.fa-list-ul::before {
  content: "\f0ca";
}

.fa-list-dots::before {
  content: "\f0ca";
}

.fa-litecoin-sign::before {
  content: "\e1d3";
}

.fa-loader::before {
  content: "\e1d4";
}

.fa-lobster::before {
  content: "\e421";
}

.fa-location-arrow::before {
  content: "\f124";
}

.fa-location-check::before {
  content: "\f606";
}

.fa-map-marker-check::before {
  content: "\f606";
}

.fa-location-crosshairs::before {
  content: "\f601";
}

.fa-location::before {
  content: "\f601";
}

.fa-location-crosshairs-slash::before {
  content: "\f603";
}

.fa-location-slash::before {
  content: "\f603";
}

.fa-location-dot::before {
  content: "\f3c5";
}

.fa-map-marker-alt::before {
  content: "\f3c5";
}

.fa-location-dot-slash::before {
  content: "\f605";
}

.fa-map-marker-alt-slash::before {
  content: "\f605";
}

.fa-location-exclamation::before {
  content: "\f608";
}

.fa-map-marker-exclamation::before {
  content: "\f608";
}

.fa-location-minus::before {
  content: "\f609";
}

.fa-map-marker-minus::before {
  content: "\f609";
}

.fa-location-pen::before {
  content: "\f607";
}

.fa-map-marker-edit::before {
  content: "\f607";
}

.fa-location-pin::before {
  content: "\f041";
}

.fa-map-marker::before {
  content: "\f041";
}

.fa-location-pin-lock::before {
  content: "\e51f";
}

.fa-location-pin-slash::before {
  content: "\f60c";
}

.fa-map-marker-slash::before {
  content: "\f60c";
}

.fa-location-plus::before {
  content: "\f60a";
}

.fa-map-marker-plus::before {
  content: "\f60a";
}

.fa-location-question::before {
  content: "\f60b";
}

.fa-map-marker-question::before {
  content: "\f60b";
}

.fa-location-smile::before {
  content: "\f60d";
}

.fa-map-marker-smile::before {
  content: "\f60d";
}

.fa-location-xmark::before {
  content: "\f60e";
}

.fa-map-marker-times::before {
  content: "\f60e";
}

.fa-map-marker-xmark::before {
  content: "\f60e";
}

.fa-lock::before {
  content: "\f023";
}

.fa-lock-a::before {
  content: "\e422";
}

.fa-lock-hashtag::before {
  content: "\e423";
}

.fa-lock-keyhole::before {
  content: "\f30d";
}

.fa-lock-alt::before {
  content: "\f30d";
}

.fa-lock-keyhole-open::before {
  content: "\f3c2";
}

.fa-lock-open-alt::before {
  content: "\f3c2";
}

.fa-lock-open::before {
  content: "\f3c1";
}

.fa-locust::before {
  content: "\e520";
}

.fa-lollipop::before {
  content: "\e424";
}

.fa-lollypop::before {
  content: "\e424";
}

.fa-loveseat::before {
  content: "\f4cc";
}

.fa-couch-small::before {
  content: "\f4cc";
}

.fa-luchador-mask::before {
  content: "\f455";
}

.fa-luchador::before {
  content: "\f455";
}

.fa-mask-luchador::before {
  content: "\f455";
}

.fa-lungs::before {
  content: "\f604";
}

.fa-lungs-virus::before {
  content: "\e067";
}

.fa-m::before {
  content: "M";
}

.fa-mace::before {
  content: "\f6f8";
}

.fa-magnet::before {
  content: "\f076";
}

.fa-magnifying-glass::before {
  content: "\f002";
}

.fa-search::before {
  content: "\f002";
}

.fa-magnifying-glass-arrow-right::before {
  content: "\e521";
}

.fa-magnifying-glass-chart::before {
  content: "\e522";
}

.fa-magnifying-glass-dollar::before {
  content: "\f688";
}

.fa-search-dollar::before {
  content: "\f688";
}

.fa-magnifying-glass-location::before {
  content: "\f689";
}

.fa-search-location::before {
  content: "\f689";
}

.fa-magnifying-glass-minus::before {
  content: "\f010";
}

.fa-search-minus::before {
  content: "\f010";
}

.fa-magnifying-glass-plus::before {
  content: "\f00e";
}

.fa-search-plus::before {
  content: "\f00e";
}

.fa-mailbox::before {
  content: "\f813";
}

.fa-manat-sign::before {
  content: "\e1d5";
}

.fa-mandolin::before {
  content: "\f6f9";
}

.fa-mango::before {
  content: "\e30f";
}

.fa-manhole::before {
  content: "\e1d6";
}

.fa-map::before {
  content: "\f279";
}

.fa-map-location::before {
  content: "\f59f";
}

.fa-map-marked::before {
  content: "\f59f";
}

.fa-map-location-dot::before {
  content: "\f5a0";
}

.fa-map-marked-alt::before {
  content: "\f5a0";
}

.fa-map-pin::before {
  content: "\f276";
}

.fa-marker::before {
  content: "\f5a1";
}

.fa-mars::before {
  content: "\f222";
}

.fa-mars-and-venus::before {
  content: "\f224";
}

.fa-mars-and-venus-burst::before {
  content: "\e523";
}

.fa-mars-double::before {
  content: "\f227";
}

.fa-mars-stroke::before {
  content: "\f229";
}

.fa-mars-stroke-right::before {
  content: "\f22b";
}

.fa-mars-stroke-h::before {
  content: "\f22b";
}

.fa-mars-stroke-up::before {
  content: "\f22a";
}

.fa-mars-stroke-v::before {
  content: "\f22a";
}

.fa-martini-glass::before {
  content: "\f57b";
}

.fa-glass-martini-alt::before {
  content: "\f57b";
}

.fa-martini-glass-citrus::before {
  content: "\f561";
}

.fa-cocktail::before {
  content: "\f561";
}

.fa-martini-glass-empty::before {
  content: "\f000";
}

.fa-glass-martini::before {
  content: "\f000";
}

.fa-mask::before {
  content: "\f6fa";
}

.fa-mask-face::before {
  content: "\e1d7";
}

.fa-mask-snorkel::before {
  content: "\e3b7";
}

.fa-mask-ventilator::before {
  content: "\e524";
}

.fa-masks-theater::before {
  content: "\f630";
}

.fa-theater-masks::before {
  content: "\f630";
}

.fa-mattress-pillow::before {
  content: "\e525";
}

.fa-maximize::before {
  content: "\f31e";
}

.fa-expand-arrows-alt::before {
  content: "\f31e";
}

.fa-meat::before {
  content: "\f814";
}

.fa-medal::before {
  content: "\f5a2";
}

.fa-megaphone::before {
  content: "\f675";
}

.fa-melon::before {
  content: "\e310";
}

.fa-melon-slice::before {
  content: "\e311";
}

.fa-memo::before {
  content: "\e1d8";
}

.fa-memo-circle-check::before {
  content: "\e1d9";
}

.fa-memo-circle-info::before {
  content: "\e49a";
}

.fa-memo-pad::before {
  content: "\e1da";
}

.fa-memory::before {
  content: "\f538";
}

.fa-menorah::before {
  content: "\f676";
}

.fa-mercury::before {
  content: "\f223";
}

.fa-merge::before {
  content: "\e526";
}

.fa-message::before {
  content: "\f27a";
}

.fa-comment-alt::before {
  content: "\f27a";
}

.fa-message-arrow-down::before {
  content: "\e1db";
}

.fa-comment-alt-arrow-down::before {
  content: "\e1db";
}

.fa-message-arrow-up::before {
  content: "\e1dc";
}

.fa-comment-alt-arrow-up::before {
  content: "\e1dc";
}

.fa-message-arrow-up-right::before {
  content: "\e1dd";
}

.fa-message-bot::before {
  content: "\e3b8";
}

.fa-message-captions::before {
  content: "\e1de";
}

.fa-comment-alt-captions::before {
  content: "\e1de";
}

.fa-message-check::before {
  content: "\f4a2";
}

.fa-comment-alt-check::before {
  content: "\f4a2";
}

.fa-message-code::before {
  content: "\e1df";
}

.fa-message-dollar::before {
  content: "\f650";
}

.fa-comment-alt-dollar::before {
  content: "\f650";
}

.fa-message-dots::before {
  content: "\f4a3";
}

.fa-comment-alt-dots::before {
  content: "\f4a3";
}

.fa-messaging::before {
  content: "\f4a3";
}

.fa-message-exclamation::before {
  content: "\f4a5";
}

.fa-comment-alt-exclamation::before {
  content: "\f4a5";
}

.fa-message-image::before {
  content: "\e1e0";
}

.fa-comment-alt-image::before {
  content: "\e1e0";
}

.fa-message-lines::before {
  content: "\f4a6";
}

.fa-comment-alt-lines::before {
  content: "\f4a6";
}

.fa-message-medical::before {
  content: "\f7f4";
}

.fa-comment-alt-medical::before {
  content: "\f7f4";
}

.fa-message-middle::before {
  content: "\e1e1";
}

.fa-comment-middle-alt::before {
  content: "\e1e1";
}

.fa-message-middle-top::before {
  content: "\e1e2";
}

.fa-comment-middle-top-alt::before {
  content: "\e1e2";
}

.fa-message-minus::before {
  content: "\f4a7";
}

.fa-comment-alt-minus::before {
  content: "\f4a7";
}

.fa-message-music::before {
  content: "\f8af";
}

.fa-comment-alt-music::before {
  content: "\f8af";
}

.fa-message-pen::before {
  content: "\f4a4";
}

.fa-comment-alt-edit::before {
  content: "\f4a4";
}

.fa-message-edit::before {
  content: "\f4a4";
}

.fa-message-plus::before {
  content: "\f4a8";
}

.fa-comment-alt-plus::before {
  content: "\f4a8";
}

.fa-message-question::before {
  content: "\e1e3";
}

.fa-message-quote::before {
  content: "\e1e4";
}

.fa-comment-alt-quote::before {
  content: "\e1e4";
}

.fa-message-slash::before {
  content: "\f4a9";
}

.fa-comment-alt-slash::before {
  content: "\f4a9";
}

.fa-message-smile::before {
  content: "\f4aa";
}

.fa-comment-alt-smile::before {
  content: "\f4aa";
}

.fa-message-sms::before {
  content: "\e1e5";
}

.fa-message-text::before {
  content: "\e1e6";
}

.fa-comment-alt-text::before {
  content: "\e1e6";
}

.fa-message-xmark::before {
  content: "\f4ab";
}

.fa-comment-alt-times::before {
  content: "\f4ab";
}

.fa-message-times::before {
  content: "\f4ab";
}

.fa-messages::before {
  content: "\f4b6";
}

.fa-comments-alt::before {
  content: "\f4b6";
}

.fa-messages-dollar::before {
  content: "\f652";
}

.fa-comments-alt-dollar::before {
  content: "\f652";
}

.fa-messages-question::before {
  content: "\e1e7";
}

.fa-meteor::before {
  content: "\f753";
}

.fa-meter::before {
  content: "\e1e8";
}

.fa-meter-bolt::before {
  content: "\e1e9";
}

.fa-meter-droplet::before {
  content: "\e1ea";
}

.fa-meter-fire::before {
  content: "\e1eb";
}

.fa-microchip::before {
  content: "\f2db";
}

.fa-microchip-ai::before {
  content: "\e1ec";
}

.fa-microphone::before {
  content: "\f130";
}

.fa-microphone-lines::before {
  content: "\f3c9";
}

.fa-microphone-alt::before {
  content: "\f3c9";
}

.fa-microphone-lines-slash::before {
  content: "\f539";
}

.fa-microphone-alt-slash::before {
  content: "\f539";
}

.fa-microphone-slash::before {
  content: "\f131";
}

.fa-microphone-stand::before {
  content: "\f8cb";
}

.fa-microscope::before {
  content: "\f610";
}

.fa-microwave::before {
  content: "\e01b";
}

.fa-mill-sign::before {
  content: "\e1ed";
}

.fa-minimize::before {
  content: "\f78c";
}

.fa-compress-arrows-alt::before {
  content: "\f78c";
}

.fa-minus::before {
  content: "\f068";
}

.fa-subtract::before {
  content: "\f068";
}

.fa-mistletoe::before {
  content: "\f7b4";
}

.fa-mitten::before {
  content: "\f7b5";
}

.fa-mobile::before {
  content: "\f3ce";
}

.fa-mobile-android::before {
  content: "\f3ce";
}

.fa-mobile-phone::before {
  content: "\f3ce";
}

.fa-mobile-button::before {
  content: "\f10b";
}

.fa-mobile-notch::before {
  content: "\e1ee";
}

.fa-mobile-iphone::before {
  content: "\e1ee";
}

.fa-mobile-retro::before {
  content: "\e527";
}

.fa-mobile-screen::before {
  content: "\f3cf";
}

.fa-mobile-android-alt::before {
  content: "\f3cf";
}

.fa-mobile-screen-button::before {
  content: "\f3cd";
}

.fa-mobile-alt::before {
  content: "\f3cd";
}

.fa-mobile-signal::before {
  content: "\e1ef";
}

.fa-mobile-signal-out::before {
  content: "\e1f0";
}

.fa-money-bill::before {
  content: "\f0d6";
}

.fa-money-bill-1::before {
  content: "\f3d1";
}

.fa-money-bill-alt::before {
  content: "\f3d1";
}

.fa-money-bill-1-wave::before {
  content: "\f53b";
}

.fa-money-bill-wave-alt::before {
  content: "\f53b";
}

.fa-money-bill-simple::before {
  content: "\e1f1";
}

.fa-money-bill-simple-wave::before {
  content: "\e1f2";
}

.fa-money-bill-transfer::before {
  content: "\e528";
}

.fa-money-bill-trend-up::before {
  content: "\e529";
}

.fa-money-bill-wave::before {
  content: "\f53a";
}

.fa-money-bill-wheat::before {
  content: "\e52a";
}

.fa-money-bills::before {
  content: "\e1f3";
}

.fa-money-bills-simple::before {
  content: "\e1f4";
}

.fa-money-bills-alt::before {
  content: "\e1f4";
}

.fa-money-check::before {
  content: "\f53c";
}

.fa-money-check-dollar::before {
  content: "\f53d";
}

.fa-money-check-alt::before {
  content: "\f53d";
}

.fa-money-check-dollar-pen::before {
  content: "\f873";
}

.fa-money-check-edit-alt::before {
  content: "\f873";
}

.fa-money-check-pen::before {
  content: "\f872";
}

.fa-money-check-edit::before {
  content: "\f872";
}

.fa-money-from-bracket::before {
  content: "\e312";
}

.fa-money-simple-from-bracket::before {
  content: "\e313";
}

.fa-monitor-waveform::before {
  content: "\f611";
}

.fa-monitor-heart-rate::before {
  content: "\f611";
}

.fa-monkey::before {
  content: "\f6fb";
}

.fa-monument::before {
  content: "\f5a6";
}

.fa-moon::before {
  content: "\f186";
}

.fa-moon-cloud::before {
  content: "\f754";
}

.fa-moon-over-sun::before {
  content: "\f74a";
}

.fa-eclipse-alt::before {
  content: "\f74a";
}

.fa-moon-stars::before {
  content: "\f755";
}

.fa-moped::before {
  content: "\e3b9";
}

.fa-mortar-pestle::before {
  content: "\f5a7";
}

.fa-mosque::before {
  content: "\f678";
}

.fa-mosquito::before {
  content: "\e52b";
}

.fa-mosquito-net::before {
  content: "\e52c";
}

.fa-motorcycle::before {
  content: "\f21c";
}

.fa-mound::before {
  content: "\e52d";
}

.fa-mountain::before {
  content: "\f6fc";
}

.fa-mountain-city::before {
  content: "\e52e";
}

.fa-mountain-sun::before {
  content: "\e52f";
}

.fa-mountains::before {
  content: "\f6fd";
}

.fa-mp3-player::before {
  content: "\f8ce";
}

.fa-mug::before {
  content: "\f874";
}

.fa-mug-hot::before {
  content: "\f7b6";
}

.fa-mug-marshmallows::before {
  content: "\f7b7";
}

.fa-mug-saucer::before {
  content: "\f0f4";
}

.fa-coffee::before {
  content: "\f0f4";
}

.fa-mug-tea::before {
  content: "\f875";
}

.fa-mug-tea-saucer::before {
  content: "\e1f5";
}

.fa-mushroom::before {
  content: "\e425";
}

.fa-music::before {
  content: "\f001";
}

.fa-music-note::before {
  content: "\f8cf";
}

.fa-music-alt::before {
  content: "\f8cf";
}

.fa-music-note-slash::before {
  content: "\f8d0";
}

.fa-music-alt-slash::before {
  content: "\f8d0";
}

.fa-music-slash::before {
  content: "\f8d1";
}

.fa-n::before {
  content: "N";
}

.fa-naira-sign::before {
  content: "\e1f6";
}

.fa-narwhal::before {
  content: "\f6fe";
}

.fa-nesting-dolls::before {
  content: "\e3ba";
}

.fa-network-wired::before {
  content: "\f6ff";
}

.fa-neuter::before {
  content: "\f22c";
}

.fa-newspaper::before {
  content: "\f1ea";
}

.fa-nfc::before {
  content: "\e1f7";
}

.fa-nfc-lock::before {
  content: "\e1f8";
}

.fa-nfc-magnifying-glass::before {
  content: "\e1f9";
}

.fa-nfc-pen::before {
  content: "\e1fa";
}

.fa-nfc-signal::before {
  content: "\e1fb";
}

.fa-nfc-slash::before {
  content: "\e1fc";
}

.fa-nfc-trash::before {
  content: "\e1fd";
}

.fa-not-equal::before {
  content: "\f53e";
}

.fa-notdef::before {
  content: "\e1fe";
}

.fa-note::before {
  content: "\e1ff";
}

.fa-note-medical::before {
  content: "\e200";
}

.fa-note-sticky::before {
  content: "\f249";
}

.fa-sticky-note::before {
  content: "\f249";
}

.fa-notebook::before {
  content: "\e201";
}

.fa-notes::before {
  content: "\e202";
}

.fa-notes-medical::before {
  content: "\f481";
}

.fa-o::before {
  content: "O";
}

.fa-object-exclude::before {
  content: "\e49c";
}

.fa-object-group::before {
  content: "\f247";
}

.fa-object-intersect::before {
  content: "\e49d";
}

.fa-object-subtract::before {
  content: "\e49e";
}

.fa-object-ungroup::before {
  content: "\f248";
}

.fa-object-union::before {
  content: "\e49f";
}

.fa-objects-align-bottom::before {
  content: "\e3bb";
}

.fa-objects-align-center-horizontal::before {
  content: "\e3bc";
}

.fa-objects-align-center-vertical::before {
  content: "\e3bd";
}

.fa-objects-align-left::before {
  content: "\e3be";
}

.fa-objects-align-right::before {
  content: "\e3bf";
}

.fa-objects-align-top::before {
  content: "\e3c0";
}

.fa-objects-column::before {
  content: "\e3c1";
}

.fa-octagon::before {
  content: "\f306";
}

.fa-octagon-check::before {
  content: "\e426";
}

.fa-octagon-divide::before {
  content: "\e203";
}

.fa-octagon-exclamation::before {
  content: "\e204";
}

.fa-octagon-minus::before {
  content: "\f308";
}

.fa-minus-octagon::before {
  content: "\f308";
}

.fa-octagon-plus::before {
  content: "\f301";
}

.fa-plus-octagon::before {
  content: "\f301";
}

.fa-octagon-xmark::before {
  content: "\f2f0";
}

.fa-times-octagon::before {
  content: "\f2f0";
}

.fa-xmark-octagon::before {
  content: "\f2f0";
}

.fa-oil-can::before {
  content: "\f613";
}

.fa-oil-can-drip::before {
  content: "\e205";
}

.fa-oil-temperature::before {
  content: "\f614";
}

.fa-oil-temp::before {
  content: "\f614";
}

.fa-oil-well::before {
  content: "\e532";
}

.fa-olive::before {
  content: "\e316";
}

.fa-olive-branch::before {
  content: "\e317";
}

.fa-om::before {
  content: "\f679";
}

.fa-omega::before {
  content: "\f67a";
}

.fa-onion::before {
  content: "\e427";
}

.fa-option::before {
  content: "\e318";
}

.fa-ornament::before {
  content: "\f7b8";
}

.fa-otter::before {
  content: "\f700";
}

.fa-outdent::before {
  content: "\f03b";
}

.fa-dedent::before {
  content: "\f03b";
}

.fa-outlet::before {
  content: "\e01c";
}

.fa-oven::before {
  content: "\e01d";
}

.fa-overline::before {
  content: "\f876";
}

.fa-p::before {
  content: "P";
}

.fa-page::before {
  content: "\e428";
}

.fa-page-caret-down::before {
  content: "\e429";
}

.fa-file-caret-down::before {
  content: "\e429";
}

.fa-page-caret-up::before {
  content: "\e42a";
}

.fa-file-caret-up::before {
  content: "\e42a";
}

.fa-pager::before {
  content: "\f815";
}

.fa-paint-roller::before {
  content: "\f5aa";
}

.fa-paintbrush::before {
  content: "\f1fc";
}

.fa-paint-brush::before {
  content: "\f1fc";
}

.fa-paintbrush-fine::before {
  content: "\f5a9";
}

.fa-paint-brush-alt::before {
  content: "\f5a9";
}

.fa-paint-brush-fine::before {
  content: "\f5a9";
}

.fa-paintbrush-alt::before {
  content: "\f5a9";
}

.fa-paintbrush-pencil::before {
  content: "\e206";
}

.fa-palette::before {
  content: "\f53f";
}

.fa-pallet::before {
  content: "\f482";
}

.fa-pallet-box::before {
  content: "\e208";
}

.fa-pallet-boxes::before {
  content: "\f483";
}

.fa-palette-boxes::before {
  content: "\f483";
}

.fa-pallet-alt::before {
  content: "\f483";
}

.fa-pan-food::before {
  content: "\e42b";
}

.fa-pan-frying::before {
  content: "\e42c";
}

.fa-pancakes::before {
  content: "\e42d";
}

.fa-panel-ews::before {
  content: "\e42e";
}

.fa-panel-fire::before {
  content: "\e42f";
}

.fa-panorama::before {
  content: "\e209";
}

.fa-paper-plane::before {
  content: "\f1d8";
}

.fa-paper-plane-top::before {
  content: "\e20a";
}

.fa-paper-plane-alt::before {
  content: "\e20a";
}

.fa-send::before {
  content: "\e20a";
}

.fa-paperclip::before {
  content: "\f0c6";
}

.fa-paperclip-vertical::before {
  content: "\e3c2";
}

.fa-parachute-box::before {
  content: "\f4cd";
}

.fa-paragraph::before {
  content: "\f1dd";
}

.fa-paragraph-left::before {
  content: "\f878";
}

.fa-paragraph-rtl::before {
  content: "\f878";
}

.fa-party-bell::before {
  content: "\e31a";
}

.fa-party-horn::before {
  content: "\e31b";
}

.fa-passport::before {
  content: "\f5ab";
}

.fa-paste::before {
  content: "\f0ea";
}

.fa-file-clipboard::before {
  content: "\f0ea";
}

.fa-pause::before {
  content: "\f04c";
}

.fa-paw::before {
  content: "\f1b0";
}

.fa-paw-claws::before {
  content: "\f702";
}

.fa-paw-simple::before {
  content: "\f701";
}

.fa-paw-alt::before {
  content: "\f701";
}

.fa-peace::before {
  content: "\f67c";
}

.fa-peach::before {
  content: "\e20b";
}

.fa-peanut::before {
  content: "\e430";
}

.fa-peanuts::before {
  content: "\e431";
}

.fa-peapod::before {
  content: "\e31c";
}

.fa-pear::before {
  content: "\e20c";
}

.fa-pedestal::before {
  content: "\e20d";
}

.fa-pegasus::before {
  content: "\f703";
}

.fa-pen::before {
  content: "\f304";
}

.fa-pen-circle::before {
  content: "\e20e";
}

.fa-pen-clip::before {
  content: "\f305";
}

.fa-pen-alt::before {
  content: "\f305";
}

.fa-pen-clip-slash::before {
  content: "\e20f";
}

.fa-pen-alt-slash::before {
  content: "\e20f";
}

.fa-pen-fancy::before {
  content: "\f5ac";
}

.fa-pen-fancy-slash::before {
  content: "\e210";
}

.fa-pen-field::before {
  content: "\e211";
}

.fa-pen-line::before {
  content: "\e212";
}

.fa-pen-nib::before {
  content: "\f5ad";
}

.fa-pen-nib-slash::before {
  content: "\e4a1";
}

.fa-pen-paintbrush::before {
  content: "\f618";
}

.fa-pencil-paintbrush::before {
  content: "\f618";
}

.fa-pen-ruler::before {
  content: "\f5ae";
}

.fa-pencil-ruler::before {
  content: "\f5ae";
}

.fa-pen-slash::before {
  content: "\e213";
}

.fa-pen-swirl::before {
  content: "\e214";
}

.fa-pen-to-square::before {
  content: "\f044";
}

.fa-edit::before {
  content: "\f044";
}

.fa-pencil::before {
  content: "\f303";
}

.fa-pencil-alt::before {
  content: "\f303";
}

.fa-pencil-slash::before {
  content: "\e215";
}

.fa-people::before {
  content: "\e216";
}

.fa-people-arrows-left-right::before {
  content: "\e068";
}

.fa-people-arrows::before {
  content: "\e068";
}

.fa-people-carry-box::before {
  content: "\f4ce";
}

.fa-people-carry::before {
  content: "\f4ce";
}

.fa-people-dress::before {
  content: "\e217";
}

.fa-people-dress-simple::before {
  content: "\e218";
}

.fa-people-group::before {
  content: "\e533";
}

.fa-people-line::before {
  content: "\e534";
}

.fa-people-pants::before {
  content: "\e219";
}

.fa-people-pants-simple::before {
  content: "\e21a";
}

.fa-people-pulling::before {
  content: "\e535";
}

.fa-people-robbery::before {
  content: "\e536";
}

.fa-people-roof::before {
  content: "\e537";
}

.fa-people-simple::before {
  content: "\e21b";
}

.fa-pepper::before {
  content: "\e432";
}

.fa-pepper-hot::before {
  content: "\f816";
}

.fa-percent::before {
  content: "\%";
}

.fa-percentage::before {
  content: "\%";
}

.fa-period::before {
  content: "\.";
}

.fa-person::before {
  content: "\f183";
}

.fa-male::before {
  content: "\f183";
}

.fa-person-arrow-down-to-line::before {
  content: "\e538";
}

.fa-person-arrow-up-from-line::before {
  content: "\e539";
}

.fa-person-biking::before {
  content: "\f84a";
}

.fa-biking::before {
  content: "\f84a";
}

.fa-person-biking-mountain::before {
  content: "\f84b";
}

.fa-biking-mountain::before {
  content: "\f84b";
}

.fa-person-booth::before {
  content: "\f756";
}

.fa-person-breastfeeding::before {
  content: "\e53a";
}

.fa-person-burst::before {
  content: "\e53b";
}

.fa-person-cane::before {
  content: "\e53c";
}

.fa-person-carry-box::before {
  content: "\f4cf";
}

.fa-person-carry::before {
  content: "\f4cf";
}

.fa-person-chalkboard::before {
  content: "\e53d";
}

.fa-person-circle-check::before {
  content: "\e53e";
}

.fa-person-circle-exclamation::before {
  content: "\e53f";
}

.fa-person-circle-minus::before {
  content: "\e540";
}

.fa-person-circle-plus::before {
  content: "\e541";
}

.fa-person-circle-question::before {
  content: "\e542";
}

.fa-person-circle-xmark::before {
  content: "\e543";
}

.fa-person-digging::before {
  content: "\f85e";
}

.fa-digging::before {
  content: "\f85e";
}

.fa-person-dolly::before {
  content: "\f4d0";
}

.fa-person-dolly-empty::before {
  content: "\f4d1";
}

.fa-person-dots-from-line::before {
  content: "\f470";
}

.fa-diagnoses::before {
  content: "\f470";
}

.fa-person-dress::before {
  content: "\f182";
}

.fa-female::before {
  content: "\f182";
}

.fa-person-dress-burst::before {
  content: "\e544";
}

.fa-person-dress-simple::before {
  content: "\e21c";
}

.fa-person-drowning::before {
  content: "\e545";
}

.fa-person-falling::before {
  content: "\e546";
}

.fa-person-falling-burst::before {
  content: "\e547";
}

.fa-person-from-portal::before {
  content: "\e023";
}

.fa-portal-exit::before {
  content: "\e023";
}

.fa-person-half-dress::before {
  content: "\e548";
}

.fa-person-harassing::before {
  content: "\e549";
}

.fa-person-hiking::before {
  content: "\f6ec";
}

.fa-hiking::before {
  content: "\f6ec";
}

.fa-person-military-pointing::before {
  content: "\e54a";
}

.fa-person-military-rifle::before {
  content: "\e54b";
}

.fa-person-military-to-person::before {
  content: "\e54c";
}

.fa-person-pinball::before {
  content: "\e21d";
}

.fa-person-praying::before {
  content: "\f683";
}

.fa-pray::before {
  content: "\f683";
}

.fa-person-pregnant::before {
  content: "\e31e";
}

.fa-person-rays::before {
  content: "\e54d";
}

.fa-person-rifle::before {
  content: "\e54e";
}

.fa-person-running::before {
  content: "\f70c";
}

.fa-running::before {
  content: "\f70c";
}

.fa-person-seat::before {
  content: "\e21e";
}

.fa-person-seat-reclined::before {
  content: "\e21f";
}

.fa-person-shelter::before {
  content: "\e54f";
}

.fa-person-sign::before {
  content: "\f757";
}

.fa-person-simple::before {
  content: "\e220";
}

.fa-person-skating::before {
  content: "\f7c5";
}

.fa-skating::before {
  content: "\f7c5";
}

.fa-person-ski-jumping::before {
  content: "\f7c7";
}

.fa-ski-jump::before {
  content: "\f7c7";
}

.fa-person-ski-lift::before {
  content: "\f7c8";
}

.fa-ski-lift::before {
  content: "\f7c8";
}

.fa-person-skiing::before {
  content: "\f7c9";
}

.fa-skiing::before {
  content: "\f7c9";
}

.fa-person-skiing-nordic::before {
  content: "\f7ca";
}

.fa-skiing-nordic::before {
  content: "\f7ca";
}

.fa-person-sledding::before {
  content: "\f7cb";
}

.fa-sledding::before {
  content: "\f7cb";
}

.fa-person-snowboarding::before {
  content: "\f7ce";
}

.fa-snowboarding::before {
  content: "\f7ce";
}

.fa-person-snowmobiling::before {
  content: "\f7d1";
}

.fa-snowmobile::before {
  content: "\f7d1";
}

.fa-person-swimming::before {
  content: "\f5c4";
}

.fa-swimmer::before {
  content: "\f5c4";
}

.fa-person-through-window::before {
  content: "\e433";
}

.fa-person-to-door::before {
  content: "\e550";
}

.fa-person-to-portal::before {
  content: "\e022";
}

.fa-portal-enter::before {
  content: "\e022";
}

.fa-person-walking::before {
  content: "\f554";
}

.fa-walking::before {
  content: "\f554";
}

.fa-person-walking-arrow-loop-left::before {
  content: "\e551";
}

.fa-person-walking-arrow-right::before {
  content: "\e552";
}

.fa-person-walking-dashed-line-arrow-right::before {
  content: "\e553";
}

.fa-person-walking-luggage::before {
  content: "\e554";
}

.fa-person-walking-with-cane::before {
  content: "\f29d";
}

.fa-blind::before {
  content: "\f29d";
}

.fa-peseta-sign::before {
  content: "\e221";
}

.fa-peso-sign::before {
  content: "\e222";
}

.fa-phone::before {
  content: "\f095";
}

.fa-phone-arrow-down-left::before {
  content: "\e223";
}

.fa-phone-arrow-down::before {
  content: "\e223";
}

.fa-phone-incoming::before {
  content: "\e223";
}

.fa-phone-arrow-up-right::before {
  content: "\e224";
}

.fa-phone-arrow-up::before {
  content: "\e224";
}

.fa-phone-outgoing::before {
  content: "\e224";
}

.fa-phone-flip::before {
  content: "\f879";
}

.fa-phone-alt::before {
  content: "\f879";
}

.fa-phone-hangup::before {
  content: "\e225";
}

.fa-phone-intercom::before {
  content: "\e434";
}

.fa-phone-missed::before {
  content: "\e226";
}

.fa-phone-office::before {
  content: "\f67d";
}

.fa-phone-plus::before {
  content: "\f4d2";
}

.fa-phone-rotary::before {
  content: "\f8d3";
}

.fa-phone-slash::before {
  content: "\f3dd";
}

.fa-phone-volume::before {
  content: "\f2a0";
}

.fa-volume-control-phone::before {
  content: "\f2a0";
}

.fa-phone-xmark::before {
  content: "\e227";
}

.fa-photo-film::before {
  content: "\f87c";
}

.fa-photo-video::before {
  content: "\f87c";
}

.fa-photo-film-music::before {
  content: "\e228";
}

.fa-pi::before {
  content: "\f67e";
}

.fa-piano::before {
  content: "\f8d4";
}

.fa-piano-keyboard::before {
  content: "\f8d5";
}

.fa-pickleball::before {
  content: "\e435";
}

.fa-pie::before {
  content: "\f705";
}

.fa-pig::before {
  content: "\f706";
}

.fa-piggy-bank::before {
  content: "\f4d3";
}

.fa-pills::before {
  content: "\f484";
}

.fa-pinata::before {
  content: "\e3c3";
}

.fa-pinball::before {
  content: "\e229";
}

.fa-pineapple::before {
  content: "\e31f";
}

.fa-pipe::before {
  content: "\|";
}

.fa-pipe-circle-check::before {
  content: "\e436";
}

.fa-pipe-collar::before {
  content: "\e437";
}

.fa-pipe-section::before {
  content: "\e438";
}

.fa-pipe-smoking::before {
  content: "\e3c4";
}

.fa-pipe-valve::before {
  content: "\e439";
}

.fa-pizza::before {
  content: "\f817";
}

.fa-pizza-slice::before {
  content: "\f818";
}

.fa-place-of-worship::before {
  content: "\f67f";
}

.fa-plane::before {
  content: "\f072";
}

.fa-plane-arrival::before {
  content: "\f5af";
}

.fa-plane-circle-check::before {
  content: "\e555";
}

.fa-plane-circle-exclamation::before {
  content: "\e556";
}

.fa-plane-circle-xmark::before {
  content: "\e557";
}

.fa-plane-departure::before {
  content: "\f5b0";
}

.fa-plane-engines::before {
  content: "\f3de";
}

.fa-plane-alt::before {
  content: "\f3de";
}

.fa-plane-lock::before {
  content: "\e558";
}

.fa-plane-prop::before {
  content: "\e22b";
}

.fa-plane-slash::before {
  content: "\e069";
}

.fa-plane-tail::before {
  content: "\e22c";
}

.fa-plane-up::before {
  content: "\e22d";
}

.fa-plane-up-slash::before {
  content: "\e22e";
}

.fa-planet-moon::before {
  content: "\e01f";
}

.fa-planet-ringed::before {
  content: "\e020";
}

.fa-plant-wilt::before {
  content: "\e43b";
}

.fa-plate-utensils::before {
  content: "\e559";
}

.fa-plate-wheat::before {
  content: "\e55a";
}

.fa-play::before {
  content: "\f04b";
}

.fa-play-pause::before {
  content: "\e22f";
}

.fa-plug::before {
  content: "\f1e6";
}

.fa-plug-circle-bolt::before {
  content: "\e55b";
}

.fa-plug-circle-check::before {
  content: "\e55c";
}

.fa-plug-circle-exclamation::before {
  content: "\e55d";
}

.fa-plug-circle-minus::before {
  content: "\e55e";
}

.fa-plug-circle-plus::before {
  content: "\e55f";
}

.fa-plug-circle-xmark::before {
  content: "\e560";
}

.fa-plus::before {
  content: "\+";
}

.fa-add::before {
  content: "\+";
}

.fa-plus-large::before {
  content: "\e59e";
}

.fa-plus-minus::before {
  content: "\e43c";
}

.fa-podcast::before {
  content: "\f2ce";
}

.fa-podium::before {
  content: "\f680";
}

.fa-podium-star::before {
  content: "\f758";
}

.fa-police-box::before {
  content: "\e021";
}

.fa-poll-people::before {
  content: "\f759";
}

.fa-pompebled::before {
  content: "\e43d";
}

.fa-poo::before {
  content: "\f2fe";
}

.fa-poo-storm::before {
  content: "\f75a";
}

.fa-poo-bolt::before {
  content: "\f75a";
}

.fa-pool-8-ball::before {
  content: "\e3c5";
}

.fa-poop::before {
  content: "\f619";
}

.fa-popcorn::before {
  content: "\f819";
}

.fa-popsicle::before {
  content: "\e43e";
}

.fa-pot-food::before {
  content: "\e43f";
}

.fa-potato::before {
  content: "\e440";
}

.fa-power-off::before {
  content: "\f011";
}

.fa-prescription::before {
  content: "\f5b1";
}

.fa-prescription-bottle::before {
  content: "\f485";
}

.fa-prescription-bottle-medical::before {
  content: "\f486";
}

.fa-prescription-bottle-alt::before {
  content: "\f486";
}

.fa-presentation-screen::before {
  content: "\f685";
}

.fa-presentation::before {
  content: "\f685";
}

.fa-pretzel::before {
  content: "\e441";
}

.fa-print::before {
  content: "\f02f";
}

.fa-print-magnifying-glass::before {
  content: "\f81a";
}

.fa-print-search::before {
  content: "\f81a";
}

.fa-print-slash::before {
  content: "\f686";
}

.fa-projector::before {
  content: "\f8d6";
}

.fa-pump::before {
  content: "\e442";
}

.fa-pump-medical::before {
  content: "\e06a";
}

.fa-pump-soap::before {
  content: "\e06b";
}

.fa-pumpkin::before {
  content: "\f707";
}

.fa-puzzle::before {
  content: "\e443";
}

.fa-puzzle-piece::before {
  content: "\f12e";
}

.fa-puzzle-piece-simple::before {
  content: "\e231";
}

.fa-puzzle-piece-alt::before {
  content: "\e231";
}

.fa-q::before {
  content: "Q";
}

.fa-qrcode::before {
  content: "\f029";
}

.fa-question::before {
  content: "\?";
}

.fa-quote-left::before {
  content: "\f10d";
}

.fa-quote-left-alt::before {
  content: "\f10d";
}

.fa-quote-right::before {
  content: "\f10e";
}

.fa-quote-right-alt::before {
  content: "\f10e";
}

.fa-quotes::before {
  content: "\e234";
}

.fa-r::before {
  content: "R";
}

.fa-rabbit::before {
  content: "\f708";
}

.fa-rabbit-running::before {
  content: "\f709";
}

.fa-rabbit-fast::before {
  content: "\f709";
}

.fa-racquet::before {
  content: "\f45a";
}

.fa-radar::before {
  content: "\e024";
}

.fa-radiation::before {
  content: "\f7b9";
}

.fa-radio::before {
  content: "\f8d7";
}

.fa-radio-tuner::before {
  content: "\f8d8";
}

.fa-radio-alt::before {
  content: "\f8d8";
}

.fa-rainbow::before {
  content: "\f75b";
}

.fa-raindrops::before {
  content: "\f75c";
}

.fa-ram::before {
  content: "\f70a";
}

.fa-ramp-loading::before {
  content: "\f4d4";
}

.fa-ranking-star::before {
  content: "\e561";
}

.fa-raygun::before {
  content: "\e025";
}

.fa-receipt::before {
  content: "\f543";
}

.fa-record-vinyl::before {
  content: "\f8d9";
}

.fa-rectangle::before {
  content: "\f2fa";
}

.fa-rectangle-landscape::before {
  content: "\f2fa";
}

.fa-rectangle-ad::before {
  content: "\f641";
}

.fa-ad::before {
  content: "\f641";
}

.fa-rectangle-barcode::before {
  content: "\f463";
}

.fa-barcode-alt::before {
  content: "\f463";
}

.fa-rectangle-code::before {
  content: "\e322";
}

.fa-rectangle-history::before {
  content: "\e4a2";
}

.fa-rectangle-history-circle-plus::before {
  content: "\e4a3";
}

.fa-rectangle-history-circle-user::before {
  content: "\e4a4";
}

.fa-rectangle-list::before {
  content: "\f022";
}

.fa-list-alt::before {
  content: "\f022";
}

.fa-rectangle-pro::before {
  content: "\e235";
}

.fa-pro::before {
  content: "\e235";
}

.fa-rectangle-terminal::before {
  content: "\e236";
}

.fa-rectangle-vertical::before {
  content: "\f2fb";
}

.fa-rectangle-portrait::before {
  content: "\f2fb";
}

.fa-rectangle-vertical-history::before {
  content: "\e237";
}

.fa-rectangle-wide::before {
  content: "\f2fc";
}

.fa-rectangle-xmark::before {
  content: "\f410";
}

.fa-rectangle-times::before {
  content: "\f410";
}

.fa-times-rectangle::before {
  content: "\f410";
}

.fa-window-close::before {
  content: "\f410";
}

.fa-rectangles-mixed::before {
  content: "\e323";
}

.fa-recycle::before {
  content: "\f1b8";
}

.fa-reel::before {
  content: "\e238";
}

.fa-refrigerator::before {
  content: "\e026";
}

.fa-registered::before {
  content: "\f25d";
}

.fa-repeat::before {
  content: "\f363";
}

.fa-repeat-1::before {
  content: "\f365";
}

.fa-reply::before {
  content: "\f3e5";
}

.fa-mail-reply::before {
  content: "\f3e5";
}

.fa-reply-all::before {
  content: "\f122";
}

.fa-mail-reply-all::before {
  content: "\f122";
}

.fa-reply-clock::before {
  content: "\e239";
}

.fa-reply-time::before {
  content: "\e239";
}

.fa-republican::before {
  content: "\f75e";
}

.fa-restroom::before {
  content: "\f7bd";
}

.fa-restroom-simple::before {
  content: "\e23a";
}

.fa-retweet::before {
  content: "\f079";
}

.fa-rhombus::before {
  content: "\e23b";
}

.fa-ribbon::before {
  content: "\f4d6";
}

.fa-right::before {
  content: "\f356";
}

.fa-arrow-alt-right::before {
  content: "\f356";
}

.fa-right-from-bracket::before {
  content: "\f2f5";
}

.fa-sign-out-alt::before {
  content: "\f2f5";
}

.fa-right-from-line::before {
  content: "\f347";
}

.fa-arrow-alt-from-left::before {
  content: "\f347";
}

.fa-right-left::before {
  content: "\f362";
}

.fa-exchange-alt::before {
  content: "\f362";
}

.fa-right-long::before {
  content: "\f30b";
}

.fa-long-arrow-alt-right::before {
  content: "\f30b";
}

.fa-right-long-to-line::before {
  content: "\e444";
}

.fa-right-to-bracket::before {
  content: "\f2f6";
}

.fa-sign-in-alt::before {
  content: "\f2f6";
}

.fa-right-to-line::before {
  content: "\f34c";
}

.fa-arrow-alt-to-right::before {
  content: "\f34c";
}

.fa-ring::before {
  content: "\f70b";
}

.fa-rings-wedding::before {
  content: "\f81b";
}

.fa-road::before {
  content: "\f018";
}

.fa-road-barrier::before {
  content: "\e562";
}

.fa-road-bridge::before {
  content: "\e563";
}

.fa-road-circle-check::before {
  content: "\e564";
}

.fa-road-circle-exclamation::before {
  content: "\e565";
}

.fa-road-circle-xmark::before {
  content: "\e566";
}

.fa-road-lock::before {
  content: "\e567";
}

.fa-road-spikes::before {
  content: "\e568";
}

.fa-robot::before {
  content: "\f544";
}

.fa-robot-astromech::before {
  content: "\e2d2";
}

.fa-rocket::before {
  content: "\f135";
}

.fa-rocket-launch::before {
  content: "\e027";
}

.fa-roller-coaster::before {
  content: "\e324";
}

.fa-rotate::before {
  content: "\f2f1";
}

.fa-sync-alt::before {
  content: "\f2f1";
}

.fa-rotate-exclamation::before {
  content: "\e23c";
}

.fa-rotate-left::before {
  content: "\f2ea";
}

.fa-rotate-back::before {
  content: "\f2ea";
}

.fa-rotate-backward::before {
  content: "\f2ea";
}

.fa-undo-alt::before {
  content: "\f2ea";
}

.fa-rotate-right::before {
  content: "\f2f9";
}

.fa-redo-alt::before {
  content: "\f2f9";
}

.fa-rotate-forward::before {
  content: "\f2f9";
}

.fa-route::before {
  content: "\f4d7";
}

.fa-route-highway::before {
  content: "\f61a";
}

.fa-route-interstate::before {
  content: "\f61b";
}

.fa-router::before {
  content: "\f8da";
}

.fa-rss::before {
  content: "\f09e";
}

.fa-feed::before {
  content: "\f09e";
}

.fa-ruble-sign::before {
  content: "\f158";
}

.fa-rouble::before {
  content: "\f158";
}

.fa-rub::before {
  content: "\f158";
}

.fa-ruble::before {
  content: "\f158";
}

.fa-rug::before {
  content: "\e569";
}

.fa-rugby-ball::before {
  content: "\e3c6";
}

.fa-ruler::before {
  content: "\f545";
}

.fa-ruler-combined::before {
  content: "\f546";
}

.fa-ruler-horizontal::before {
  content: "\f547";
}

.fa-ruler-triangle::before {
  content: "\f61c";
}

.fa-ruler-vertical::before {
  content: "\f548";
}

.fa-rupee-sign::before {
  content: "\f156";
}

.fa-rupee::before {
  content: "\f156";
}

.fa-rupiah-sign::before {
  content: "\e23d";
}

.fa-rv::before {
  content: "\f7be";
}

.fa-s::before {
  content: "S";
}

.fa-sack::before {
  content: "\f81c";
}

.fa-sack-dollar::before {
  content: "\f81d";
}

.fa-sack-xmark::before {
  content: "\e56a";
}

.fa-sailboat::before {
  content: "\e445";
}

.fa-salad::before {
  content: "\f81e";
}

.fa-bowl-salad::before {
  content: "\f81e";
}

.fa-salt-shaker::before {
  content: "\e446";
}

.fa-sandwich::before {
  content: "\f81f";
}

.fa-satellite::before {
  content: "\f7bf";
}

.fa-satellite-dish::before {
  content: "\f7c0";
}

.fa-sausage::before {
  content: "\f820";
}

.fa-saxophone::before {
  content: "\f8dc";
}

.fa-saxophone-fire::before {
  content: "\f8db";
}

.fa-sax-hot::before {
  content: "\f8db";
}

.fa-scale-balanced::before {
  content: "\f24e";
}

.fa-balance-scale::before {
  content: "\f24e";
}

.fa-scale-unbalanced::before {
  content: "\f515";
}

.fa-balance-scale-left::before {
  content: "\f515";
}

.fa-scale-unbalanced-flip::before {
  content: "\f516";
}

.fa-balance-scale-right::before {
  content: "\f516";
}

.fa-scalpel::before {
  content: "\f61d";
}

.fa-scalpel-line-dashed::before {
  content: "\f61e";
}

.fa-scalpel-path::before {
  content: "\f61e";
}

.fa-scanner::before {
  content: "\f8f3";
}

.fa-scanner-image::before {
  content: "\f8f3";
}

.fa-scanner-gun::before {
  content: "\f488";
}

.fa-scanner-keyboard::before {
  content: "\f489";
}

.fa-scanner-touchscreen::before {
  content: "\f48a";
}

.fa-scarecrow::before {
  content: "\f70d";
}

.fa-scarf::before {
  content: "\f7c1";
}

.fa-school::before {
  content: "\f549";
}

.fa-school-circle-check::before {
  content: "\e56b";
}

.fa-school-circle-exclamation::before {
  content: "\e56c";
}

.fa-school-circle-xmark::before {
  content: "\e56d";
}

.fa-school-flag::before {
  content: "\e56e";
}

.fa-school-lock::before {
  content: "\e56f";
}

.fa-scissors::before {
  content: "\f0c4";
}

.fa-cut::before {
  content: "\f0c4";
}

.fa-screen-users::before {
  content: "\f63d";
}

.fa-users-class::before {
  content: "\f63d";
}

.fa-screencast::before {
  content: "\e23e";
}

.fa-screwdriver::before {
  content: "\f54a";
}

.fa-screwdriver-wrench::before {
  content: "\f7d9";
}

.fa-tools::before {
  content: "\f7d9";
}

.fa-scribble::before {
  content: "\e23f";
}

.fa-scroll::before {
  content: "\f70e";
}

.fa-scroll-old::before {
  content: "\f70f";
}

.fa-scroll-torah::before {
  content: "\f6a0";
}

.fa-torah::before {
  content: "\f6a0";
}

.fa-scrubber::before {
  content: "\f2f8";
}

.fa-scythe::before {
  content: "\f710";
}

.fa-sd-card::before {
  content: "\f7c2";
}

.fa-sd-cards::before {
  content: "\e240";
}

.fa-seal::before {
  content: "\e241";
}

.fa-seal-exclamation::before {
  content: "\e242";
}

.fa-seal-question::before {
  content: "\e243";
}

.fa-seat-airline::before {
  content: "\e244";
}

.fa-section::before {
  content: "\e447";
}

.fa-seedling::before {
  content: "\f4d8";
}

.fa-sprout::before {
  content: "\f4d8";
}

.fa-semicolon::before {
  content: "\;";
}

.fa-send-back::before {
  content: "\f87e";
}

.fa-send-backward::before {
  content: "\f87f";
}

.fa-sensor::before {
  content: "\e028";
}

.fa-sensor-cloud::before {
  content: "\e02c";
}

.fa-sensor-smoke::before {
  content: "\e02c";
}

.fa-sensor-fire::before {
  content: "\e02a";
}

.fa-sensor-on::before {
  content: "\e02b";
}

.fa-sensor-triangle-exclamation::before {
  content: "\e029";
}

.fa-sensor-alert::before {
  content: "\e029";
}

.fa-server::before {
  content: "\f233";
}

.fa-shapes::before {
  content: "\f61f";
}

.fa-triangle-circle-square::before {
  content: "\f61f";
}

.fa-share::before {
  content: "\f064";
}

.fa-arrow-turn-right::before {
  content: "\f064";
}

.fa-mail-forward::before {
  content: "\f064";
}

.fa-share-all::before {
  content: "\f367";
}

.fa-share-from-square::before {
  content: "\f14d";
}

.fa-share-square::before {
  content: "\f14d";
}

.fa-share-nodes::before {
  content: "\f1e0";
}

.fa-share-alt::before {
  content: "\f1e0";
}

.fa-sheep::before {
  content: "\f711";
}

.fa-sheet-plastic::before {
  content: "\e571";
}

.fa-shekel-sign::before {
  content: "\f20b";
}

.fa-ils::before {
  content: "\f20b";
}

.fa-shekel::before {
  content: "\f20b";
}

.fa-sheqel::before {
  content: "\f20b";
}

.fa-sheqel-sign::before {
  content: "\f20b";
}

.fa-shelves::before {
  content: "\f480";
}

.fa-inventory::before {
  content: "\f480";
}

.fa-shelves-empty::before {
  content: "\e246";
}

.fa-shield::before {
  content: "\f132";
}

.fa-shield-blank::before {
  content: "\f132";
}

.fa-shield-cat::before {
  content: "\e572";
}

.fa-shield-check::before {
  content: "\f2f7";
}

.fa-shield-cross::before {
  content: "\f712";
}

.fa-shield-dog::before {
  content: "\e573";
}

.fa-shield-exclamation::before {
  content: "\e247";
}

.fa-shield-halved::before {
  content: "\f3ed";
}

.fa-shield-alt::before {
  content: "\f3ed";
}

.fa-shield-heart::before {
  content: "\e574";
}

.fa-shield-keyhole::before {
  content: "\e248";
}

.fa-shield-minus::before {
  content: "\e249";
}

.fa-shield-plus::before {
  content: "\e24a";
}

.fa-shield-quartered::before {
  content: "\e575";
}

.fa-shield-slash::before {
  content: "\e24b";
}

.fa-shield-virus::before {
  content: "\e06c";
}

.fa-shield-xmark::before {
  content: "\e24c";
}

.fa-shield-times::before {
  content: "\e24c";
}

.fa-ship::before {
  content: "\f21a";
}

.fa-shirt::before {
  content: "\f553";
}

.fa-t-shirt::before {
  content: "\f553";
}

.fa-tshirt::before {
  content: "\f553";
}

.fa-shirt-long-sleeve::before {
  content: "\e3c7";
}

.fa-shirt-running::before {
  content: "\e3c8";
}

.fa-shirt-tank-top::before {
  content: "\e3c9";
}

.fa-shish-kebab::before {
  content: "\f821";
}

.fa-shoe-prints::before {
  content: "\f54b";
}

.fa-shop::before {
  content: "\f54f";
}

.fa-store-alt::before {
  content: "\f54f";
}

.fa-shop-lock::before {
  content: "\e4a5";
}

.fa-shop-slash::before {
  content: "\e070";
}

.fa-store-alt-slash::before {
  content: "\e070";
}

.fa-shovel::before {
  content: "\f713";
}

.fa-shovel-snow::before {
  content: "\f7c3";
}

.fa-shower::before {
  content: "\f2cc";
}

.fa-shower-down::before {
  content: "\e24d";
}

.fa-shower-alt::before {
  content: "\e24d";
}

.fa-shredder::before {
  content: "\f68a";
}

.fa-shrimp::before {
  content: "\e448";
}

.fa-shuffle::before {
  content: "\f074";
}

.fa-random::before {
  content: "\f074";
}

.fa-shutters::before {
  content: "\e449";
}

.fa-shuttle-space::before {
  content: "\f197";
}

.fa-space-shuttle::before {
  content: "\f197";
}

.fa-shuttlecock::before {
  content: "\f45b";
}

.fa-sickle::before {
  content: "\f822";
}

.fa-sidebar::before {
  content: "\e24e";
}

.fa-sidebar-flip::before {
  content: "\e24f";
}

.fa-sigma::before {
  content: "\f68b";
}

.fa-sign-hanging::before {
  content: "\f4d9";
}

.fa-sign::before {
  content: "\f4d9";
}

.fa-signal::before {
  content: "\f012";
}

.fa-signal-5::before {
  content: "\f012";
}

.fa-signal-perfect::before {
  content: "\f012";
}

.fa-signal-bars::before {
  content: "\f690";
}

.fa-signal-alt::before {
  content: "\f690";
}

.fa-signal-alt-4::before {
  content: "\f690";
}

.fa-signal-bars-strong::before {
  content: "\f690";
}

.fa-signal-bars-fair::before {
  content: "\f692";
}

.fa-signal-alt-2::before {
  content: "\f692";
}

.fa-signal-bars-good::before {
  content: "\f693";
}

.fa-signal-alt-3::before {
  content: "\f693";
}

.fa-signal-bars-slash::before {
  content: "\f694";
}

.fa-signal-alt-slash::before {
  content: "\f694";
}

.fa-signal-bars-weak::before {
  content: "\f691";
}

.fa-signal-alt-1::before {
  content: "\f691";
}

.fa-signal-fair::before {
  content: "\f68d";
}

.fa-signal-2::before {
  content: "\f68d";
}

.fa-signal-good::before {
  content: "\f68e";
}

.fa-signal-3::before {
  content: "\f68e";
}

.fa-signal-slash::before {
  content: "\f695";
}

.fa-signal-stream::before {
  content: "\f8dd";
}

.fa-signal-stream-slash::before {
  content: "\e250";
}

.fa-signal-strong::before {
  content: "\f68f";
}

.fa-signal-4::before {
  content: "\f68f";
}

.fa-signal-weak::before {
  content: "\f68c";
}

.fa-signal-1::before {
  content: "\f68c";
}

.fa-signature::before {
  content: "\f5b7";
}

.fa-signature-lock::before {
  content: "\e3ca";
}

.fa-signature-slash::before {
  content: "\e3cb";
}

.fa-signs-post::before {
  content: "\f277";
}

.fa-map-signs::before {
  content: "\f277";
}

.fa-sim-card::before {
  content: "\f7c4";
}

.fa-sim-cards::before {
  content: "\e251";
}

.fa-sink::before {
  content: "\e06d";
}

.fa-siren::before {
  content: "\e02d";
}

.fa-siren-on::before {
  content: "\e02e";
}

.fa-sitemap::before {
  content: "\f0e8";
}

.fa-skeleton::before {
  content: "\f620";
}

.fa-ski-boot::before {
  content: "\e3cc";
}

.fa-ski-boot-ski::before {
  content: "\e3cd";
}

.fa-skull::before {
  content: "\f54c";
}

.fa-skull-cow::before {
  content: "\f8de";
}

.fa-skull-crossbones::before {
  content: "\f714";
}

.fa-slash::before {
  content: "\f715";
}

.fa-slash-back::before {
  content: "\\";
}

.fa-slash-forward::before {
  content: "\/";
}

.fa-sleigh::before {
  content: "\f7cc";
}

.fa-slider::before {
  content: "\e252";
}

.fa-sliders::before {
  content: "\f1de";
}

.fa-sliders-h::before {
  content: "\f1de";
}

.fa-sliders-simple::before {
  content: "\e253";
}

.fa-sliders-up::before {
  content: "\f3f1";
}

.fa-sliders-v::before {
  content: "\f3f1";
}

.fa-slot-machine::before {
  content: "\e3ce";
}

.fa-smog::before {
  content: "\f75f";
}

.fa-smoke::before {
  content: "\f760";
}

.fa-smoking::before {
  content: "\f48d";
}

.fa-snake::before {
  content: "\f716";
}

.fa-snooze::before {
  content: "\f880";
}

.fa-zzz::before {
  content: "\f880";
}

.fa-snow-blowing::before {
  content: "\f761";
}

.fa-snowflake::before {
  content: "\f2dc";
}

.fa-snowflakes::before {
  content: "\f7cf";
}

.fa-snowman::before {
  content: "\f7d0";
}

.fa-snowman-head::before {
  content: "\f79b";
}

.fa-frosty-head::before {
  content: "\f79b";
}

.fa-snowplow::before {
  content: "\f7d2";
}

.fa-soap::before {
  content: "\e06e";
}

.fa-socks::before {
  content: "\f696";
}

.fa-soft-serve::before {
  content: "\e400";
}

.fa-creemee::before {
  content: "\e400";
}

.fa-solar-panel::before {
  content: "\f5ba";
}

.fa-solar-system::before {
  content: "\e02f";
}

.fa-sort::before {
  content: "\f0dc";
}

.fa-unsorted::before {
  content: "\f0dc";
}

.fa-sort-down::before {
  content: "\f0dd";
}

.fa-sort-desc::before {
  content: "\f0dd";
}

.fa-sort-up::before {
  content: "\f0de";
}

.fa-sort-asc::before {
  content: "\f0de";
}

.fa-spa::before {
  content: "\f5bb";
}

.fa-space-station-moon::before {
  content: "\e033";
}

.fa-space-station-moon-construction::before {
  content: "\e034";
}

.fa-space-station-moon-alt::before {
  content: "\e034";
}

.fa-spade::before {
  content: "\f2f4";
}

.fa-spaghetti-monster-flying::before {
  content: "\f67b";
}

.fa-pastafarianism::before {
  content: "\f67b";
}

.fa-sparkles::before {
  content: "\f890";
}

.fa-speaker::before {
  content: "\f8df";
}

.fa-speakers::before {
  content: "\f8e0";
}

.fa-spell-check::before {
  content: "\f891";
}

.fa-spider::before {
  content: "\f717";
}

.fa-spider-black-widow::before {
  content: "\f718";
}

.fa-spider-web::before {
  content: "\f719";
}

.fa-spinner::before {
  content: "\f110";
}

.fa-spinner-third::before {
  content: "\f3f4";
}

.fa-split::before {
  content: "\e254";
}

.fa-splotch::before {
  content: "\f5bc";
}

.fa-spoon::before {
  content: "\f2e5";
}

.fa-utensil-spoon::before {
  content: "\f2e5";
}

.fa-sportsball::before {
  content: "\e44b";
}

.fa-spray-can::before {
  content: "\f5bd";
}

.fa-spray-can-sparkles::before {
  content: "\f5d0";
}

.fa-air-freshener::before {
  content: "\f5d0";
}

.fa-sprinkler::before {
  content: "\e035";
}

.fa-sprinkler-ceiling::before {
  content: "\e44c";
}

.fa-square::before {
  content: "\f0c8";
}

.fa-square-0::before {
  content: "\e255";
}

.fa-square-1::before {
  content: "\e256";
}

.fa-square-2::before {
  content: "\e257";
}

.fa-square-3::before {
  content: "\e258";
}

.fa-square-4::before {
  content: "\e259";
}

.fa-square-5::before {
  content: "\e25a";
}

.fa-square-6::before {
  content: "\e25b";
}

.fa-square-7::before {
  content: "\e25c";
}

.fa-square-8::before {
  content: "\e25d";
}

.fa-square-9::before {
  content: "\e25e";
}

.fa-square-a::before {
  content: "\e25f";
}

.fa-square-a-lock::before {
  content: "\e44d";
}

.fa-square-ampersand::before {
  content: "\e260";
}

.fa-square-arrow-down::before {
  content: "\f339";
}

.fa-arrow-square-down::before {
  content: "\f339";
}

.fa-square-arrow-down-left::before {
  content: "\e261";
}

.fa-square-arrow-down-right::before {
  content: "\e262";
}

.fa-square-arrow-left::before {
  content: "\f33a";
}

.fa-arrow-square-left::before {
  content: "\f33a";
}

.fa-square-arrow-right::before {
  content: "\f33b";
}

.fa-arrow-square-right::before {
  content: "\f33b";
}

.fa-square-arrow-up::before {
  content: "\f33c";
}

.fa-arrow-square-up::before {
  content: "\f33c";
}

.fa-square-arrow-up-left::before {
  content: "\e263";
}

.fa-square-arrow-up-right::before {
  content: "\f14c";
}

.fa-external-link-square::before {
  content: "\f14c";
}

.fa-square-b::before {
  content: "\e264";
}

.fa-square-bolt::before {
  content: "\e265";
}

.fa-square-c::before {
  content: "\e266";
}

.fa-square-caret-down::before {
  content: "\f150";
}

.fa-caret-square-down::before {
  content: "\f150";
}

.fa-square-caret-left::before {
  content: "\f191";
}

.fa-caret-square-left::before {
  content: "\f191";
}

.fa-square-caret-right::before {
  content: "\f152";
}

.fa-caret-square-right::before {
  content: "\f152";
}

.fa-square-caret-up::before {
  content: "\f151";
}

.fa-caret-square-up::before {
  content: "\f151";
}

.fa-square-check::before {
  content: "\f14a";
}

.fa-check-square::before {
  content: "\f14a";
}

.fa-square-chevron-down::before {
  content: "\f329";
}

.fa-chevron-square-down::before {
  content: "\f329";
}

.fa-square-chevron-left::before {
  content: "\f32a";
}

.fa-chevron-square-left::before {
  content: "\f32a";
}

.fa-square-chevron-right::before {
  content: "\f32b";
}

.fa-chevron-square-right::before {
  content: "\f32b";
}

.fa-square-chevron-up::before {
  content: "\f32c";
}

.fa-chevron-square-up::before {
  content: "\f32c";
}

.fa-square-code::before {
  content: "\e267";
}

.fa-square-d::before {
  content: "\e268";
}

.fa-square-dashed::before {
  content: "\e269";
}

.fa-square-divide::before {
  content: "\e26a";
}

.fa-square-dollar::before {
  content: "\f2e9";
}

.fa-dollar-square::before {
  content: "\f2e9";
}

.fa-usd-square::before {
  content: "\f2e9";
}

.fa-square-down::before {
  content: "\f350";
}

.fa-arrow-alt-square-down::before {
  content: "\f350";
}

.fa-square-down-left::before {
  content: "\e26b";
}

.fa-square-down-right::before {
  content: "\e26c";
}

.fa-square-e::before {
  content: "\e26d";
}

.fa-square-ellipsis::before {
  content: "\e26e";
}

.fa-square-ellipsis-vertical::before {
  content: "\e26f";
}

.fa-square-envelope::before {
  content: "\f199";
}

.fa-envelope-square::before {
  content: "\f199";
}

.fa-square-exclamation::before {
  content: "\f321";
}

.fa-exclamation-square::before {
  content: "\f321";
}

.fa-square-f::before {
  content: "\e270";
}

.fa-square-fragile::before {
  content: "\f49b";
}

.fa-box-fragile::before {
  content: "\f49b";
}

.fa-square-wine-glass-crack::before {
  content: "\f49b";
}

.fa-square-full::before {
  content: "\f45c";
}

.fa-square-g::before {
  content: "\e271";
}

.fa-square-h::before {
  content: "\f0fd";
}

.fa-h-square::before {
  content: "\f0fd";
}

.fa-square-heart::before {
  content: "\f4c8";
}

.fa-heart-square::before {
  content: "\f4c8";
}

.fa-square-i::before {
  content: "\e272";
}

.fa-square-info::before {
  content: "\f30f";
}

.fa-info-square::before {
  content: "\f30f";
}

.fa-square-j::before {
  content: "\e273";
}

.fa-square-k::before {
  content: "\e274";
}

.fa-square-kanban::before {
  content: "\e488";
}

.fa-square-l::before {
  content: "\e275";
}

.fa-square-left::before {
  content: "\f351";
}

.fa-arrow-alt-square-left::before {
  content: "\f351";
}

.fa-square-list::before {
  content: "\e489";
}

.fa-square-m::before {
  content: "\e276";
}

.fa-square-minus::before {
  content: "\f146";
}

.fa-minus-square::before {
  content: "\f146";
}

.fa-square-n::before {
  content: "\e277";
}

.fa-square-nfi::before {
  content: "\e576";
}

.fa-square-o::before {
  content: "\e278";
}

.fa-square-p::before {
  content: "\e279";
}

.fa-square-parking::before {
  content: "\f540";
}

.fa-parking::before {
  content: "\f540";
}

.fa-square-parking-slash::before {
  content: "\f617";
}

.fa-parking-slash::before {
  content: "\f617";
}

.fa-square-pen::before {
  content: "\f14b";
}

.fa-pen-square::before {
  content: "\f14b";
}

.fa-pencil-square::before {
  content: "\f14b";
}

.fa-square-person-confined::before {
  content: "\e577";
}

.fa-square-phone::before {
  content: "\f098";
}

.fa-phone-square::before {
  content: "\f098";
}

.fa-square-phone-flip::before {
  content: "\f87b";
}

.fa-phone-square-alt::before {
  content: "\f87b";
}

.fa-square-phone-hangup::before {
  content: "\e27a";
}

.fa-phone-square-down::before {
  content: "\e27a";
}

.fa-square-plus::before {
  content: "\f0fe";
}

.fa-plus-square::before {
  content: "\f0fe";
}

.fa-square-poll-horizontal::before {
  content: "\f682";
}

.fa-poll-h::before {
  content: "\f682";
}

.fa-square-poll-vertical::before {
  content: "\f681";
}

.fa-poll::before {
  content: "\f681";
}

.fa-square-q::before {
  content: "\e27b";
}

.fa-square-quarters::before {
  content: "\e44e";
}

.fa-square-question::before {
  content: "\f2fd";
}

.fa-question-square::before {
  content: "\f2fd";
}

.fa-square-quote::before {
  content: "\e329";
}

.fa-square-r::before {
  content: "\e27c";
}

.fa-square-right::before {
  content: "\f352";
}

.fa-arrow-alt-square-right::before {
  content: "\f352";
}

.fa-square-ring::before {
  content: "\e44f";
}

.fa-square-root::before {
  content: "\f697";
}

.fa-square-root-variable::before {
  content: "\f698";
}

.fa-square-root-alt::before {
  content: "\f698";
}

.fa-square-rss::before {
  content: "\f143";
}

.fa-rss-square::before {
  content: "\f143";
}

.fa-square-s::before {
  content: "\e27d";
}

.fa-square-share-nodes::before {
  content: "\f1e1";
}

.fa-share-alt-square::before {
  content: "\f1e1";
}

.fa-square-sliders::before {
  content: "\f3f0";
}

.fa-sliders-h-square::before {
  content: "\f3f0";
}

.fa-square-sliders-vertical::before {
  content: "\f3f2";
}

.fa-sliders-v-square::before {
  content: "\f3f2";
}

.fa-square-small::before {
  content: "\e27e";
}

.fa-square-star::before {
  content: "\e27f";
}

.fa-square-t::before {
  content: "\e280";
}

.fa-square-terminal::before {
  content: "\e32a";
}

.fa-square-this-way-up::before {
  content: "\f49f";
}

.fa-box-up::before {
  content: "\f49f";
}

.fa-square-u::before {
  content: "\e281";
}

.fa-square-up::before {
  content: "\f353";
}

.fa-arrow-alt-square-up::before {
  content: "\f353";
}

.fa-square-up-left::before {
  content: "\e282";
}

.fa-square-up-right::before {
  content: "\f360";
}

.fa-external-link-square-alt::before {
  content: "\f360";
}

.fa-square-user::before {
  content: "\e283";
}

.fa-square-v::before {
  content: "\e284";
}

.fa-square-virus::before {
  content: "\e578";
}

.fa-square-w::before {
  content: "\e285";
}

.fa-square-x::before {
  content: "\e286";
}

.fa-square-xmark::before {
  content: "\f2d3";
}

.fa-times-square::before {
  content: "\f2d3";
}

.fa-xmark-square::before {
  content: "\f2d3";
}

.fa-square-y::before {
  content: "\e287";
}

.fa-square-z::before {
  content: "\e288";
}

.fa-squid::before {
  content: "\e450";
}

.fa-squirrel::before {
  content: "\f71a";
}

.fa-staff::before {
  content: "\f71b";
}

.fa-staff-aesculapius::before {
  content: "\e579";
}

.fa-rod-asclepius::before {
  content: "\e579";
}

.fa-rod-snake::before {
  content: "\e579";
}

.fa-staff-snake::before {
  content: "\e579";
}

.fa-stairs::before {
  content: "\e289";
}

.fa-stamp::before {
  content: "\f5bf";
}

.fa-standard-definition::before {
  content: "\e28a";
}

.fa-rectangle-sd::before {
  content: "\e28a";
}

.fa-star::before {
  content: "\f005";
}

.fa-star-and-crescent::before {
  content: "\f699";
}

.fa-star-christmas::before {
  content: "\f7d4";
}

.fa-star-exclamation::before {
  content: "\f2f3";
}

.fa-star-half::before {
  content: "\f089";
}

.fa-star-half-stroke::before {
  content: "\f5c0";
}

.fa-star-half-alt::before {
  content: "\f5c0";
}

.fa-star-of-david::before {
  content: "\f69a";
}

.fa-star-of-life::before {
  content: "\f621";
}

.fa-star-sharp::before {
  content: "\e28b";
}

.fa-star-sharp-half::before {
  content: "\e28c";
}

.fa-star-sharp-half-stroke::before {
  content: "\e28d";
}

.fa-star-sharp-half-alt::before {
  content: "\e28d";
}

.fa-star-shooting::before {
  content: "\e036";
}

.fa-starfighter::before {
  content: "\e037";
}

.fa-starfighter-twin-ion-engine::before {
  content: "\e038";
}

.fa-starfighter-alt::before {
  content: "\e038";
}

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e";
}

.fa-starfighter-alt-advanced::before {
  content: "\e28e";
}

.fa-stars::before {
  content: "\f762";
}

.fa-starship::before {
  content: "\e039";
}

.fa-starship-freighter::before {
  content: "\e03a";
}

.fa-steak::before {
  content: "\f824";
}

.fa-steering-wheel::before {
  content: "\f622";
}

.fa-sterling-sign::before {
  content: "\f154";
}

.fa-gbp::before {
  content: "\f154";
}

.fa-pound-sign::before {
  content: "\f154";
}

.fa-stethoscope::before {
  content: "\f0f1";
}

.fa-stocking::before {
  content: "\f7d5";
}

.fa-stomach::before {
  content: "\f623";
}

.fa-stop::before {
  content: "\f04d";
}

.fa-stopwatch::before {
  content: "\f2f2";
}

.fa-stopwatch-20::before {
  content: "\e06f";
}

.fa-store::before {
  content: "\f54e";
}

.fa-store-lock::before {
  content: "\e4a6";
}

.fa-store-slash::before {
  content: "\e071";
}

.fa-strawberry::before {
  content: "\e32b";
}

.fa-street-view::before {
  content: "\f21d";
}

.fa-stretcher::before {
  content: "\f825";
}

.fa-strikethrough::before {
  content: "\f0cc";
}

.fa-stroopwafel::before {
  content: "\f551";
}

.fa-subscript::before {
  content: "\f12c";
}

.fa-suitcase::before {
  content: "\f0f2";
}

.fa-suitcase-medical::before {
  content: "\f0fa";
}

.fa-medkit::before {
  content: "\f0fa";
}

.fa-suitcase-rolling::before {
  content: "\f5c1";
}

.fa-sun::before {
  content: "\f185";
}

.fa-sun-bright::before {
  content: "\e28f";
}

.fa-sun-alt::before {
  content: "\e28f";
}

.fa-sun-cloud::before {
  content: "\f763";
}

.fa-sun-dust::before {
  content: "\f764";
}

.fa-sun-haze::before {
  content: "\f765";
}

.fa-sun-plant-wilt::before {
  content: "\e57a";
}

.fa-sunglasses::before {
  content: "\f892";
}

.fa-sunrise::before {
  content: "\f766";
}

.fa-sunset::before {
  content: "\f767";
}

.fa-superscript::before {
  content: "\f12b";
}

.fa-sushi::before {
  content: "\e48a";
}

.fa-nigiri::before {
  content: "\e48a";
}

.fa-sushi-roll::before {
  content: "\e48b";
}

.fa-maki-roll::before {
  content: "\e48b";
}

.fa-makizushi::before {
  content: "\e48b";
}

.fa-swatchbook::before {
  content: "\f5c3";
}

.fa-sword::before {
  content: "\f71c";
}

.fa-sword-laser::before {
  content: "\e03b";
}

.fa-sword-laser-alt::before {
  content: "\e03c";
}

.fa-swords::before {
  content: "\f71d";
}

.fa-swords-laser::before {
  content: "\e03d";
}

.fa-symbols::before {
  content: "\f86e";
}

.fa-icons-alt::before {
  content: "\f86e";
}

.fa-synagogue::before {
  content: "\f69b";
}

.fa-syringe::before {
  content: "\f48e";
}

.fa-t::before {
  content: "T";
}

.fa-table::before {
  content: "\f0ce";
}

.fa-table-cells::before {
  content: "\f00a";
}

.fa-th::before {
  content: "\f00a";
}

.fa-table-cells-large::before {
  content: "\f009";
}

.fa-th-large::before {
  content: "\f009";
}

.fa-table-columns::before {
  content: "\f0db";
}

.fa-columns::before {
  content: "\f0db";
}

.fa-table-layout::before {
  content: "\e290";
}

.fa-table-list::before {
  content: "\f00b";
}

.fa-th-list::before {
  content: "\f00b";
}

.fa-table-picnic::before {
  content: "\e32d";
}

.fa-table-pivot::before {
  content: "\e291";
}

.fa-table-rows::before {
  content: "\e292";
}

.fa-rows::before {
  content: "\e292";
}

.fa-table-tennis-paddle-ball::before {
  content: "\f45d";
}

.fa-ping-pong-paddle-ball::before {
  content: "\f45d";
}

.fa-table-tennis::before {
  content: "\f45d";
}

.fa-table-tree::before {
  content: "\e293";
}

.fa-tablet::before {
  content: "\f3fb";
}

.fa-tablet-android::before {
  content: "\f3fb";
}

.fa-tablet-button::before {
  content: "\f10a";
}

.fa-tablet-rugged::before {
  content: "\f48f";
}

.fa-tablet-screen::before {
  content: "\f3fc";
}

.fa-tablet-android-alt::before {
  content: "\f3fc";
}

.fa-tablet-screen-button::before {
  content: "\f3fa";
}

.fa-tablet-alt::before {
  content: "\f3fa";
}

.fa-tablets::before {
  content: "\f490";
}

.fa-tachograph-digital::before {
  content: "\f566";
}

.fa-digital-tachograph::before {
  content: "\f566";
}

.fa-taco::before {
  content: "\f826";
}

.fa-tag::before {
  content: "\f02b";
}

.fa-tags::before {
  content: "\f02c";
}

.fa-tally::before {
  content: "\f69c";
}

.fa-tally-5::before {
  content: "\f69c";
}

.fa-tally-1::before {
  content: "\e294";
}

.fa-tally-2::before {
  content: "\e295";
}

.fa-tally-3::before {
  content: "\e296";
}

.fa-tally-4::before {
  content: "\e297";
}

.fa-tamale::before {
  content: "\e451";
}

.fa-tank-water::before {
  content: "\e452";
}

.fa-tape::before {
  content: "\f4db";
}

.fa-tarp::before {
  content: "\e57b";
}

.fa-tarp-droplet::before {
  content: "\e57c";
}

.fa-taxi::before {
  content: "\f1ba";
}

.fa-cab::before {
  content: "\f1ba";
}

.fa-taxi-bus::before {
  content: "\e298";
}

.fa-teddy-bear::before {
  content: "\e3cf";
}

.fa-teeth::before {
  content: "\f62e";
}

.fa-teeth-open::before {
  content: "\f62f";
}

.fa-telescope::before {
  content: "\e03e";
}

.fa-temperature-arrow-down::before {
  content: "\e03f";
}

.fa-temperature-down::before {
  content: "\e03f";
}

.fa-temperature-arrow-up::before {
  content: "\e040";
}

.fa-temperature-up::before {
  content: "\e040";
}

.fa-temperature-empty::before {
  content: "\f2cb";
}

.fa-temperature-0::before {
  content: "\f2cb";
}

.fa-thermometer-0::before {
  content: "\f2cb";
}

.fa-thermometer-empty::before {
  content: "\f2cb";
}

.fa-temperature-full::before {
  content: "\f2c7";
}

.fa-temperature-4::before {
  content: "\f2c7";
}

.fa-thermometer-4::before {
  content: "\f2c7";
}

.fa-thermometer-full::before {
  content: "\f2c7";
}

.fa-temperature-half::before {
  content: "\f2c9";
}

.fa-temperature-2::before {
  content: "\f2c9";
}

.fa-thermometer-2::before {
  content: "\f2c9";
}

.fa-thermometer-half::before {
  content: "\f2c9";
}

.fa-temperature-high::before {
  content: "\f769";
}

.fa-temperature-list::before {
  content: "\e299";
}

.fa-temperature-low::before {
  content: "\f76b";
}

.fa-temperature-quarter::before {
  content: "\f2ca";
}

.fa-temperature-1::before {
  content: "\f2ca";
}

.fa-thermometer-1::before {
  content: "\f2ca";
}

.fa-thermometer-quarter::before {
  content: "\f2ca";
}

.fa-temperature-snow::before {
  content: "\f768";
}

.fa-temperature-frigid::before {
  content: "\f768";
}

.fa-temperature-sun::before {
  content: "\f76a";
}

.fa-temperature-hot::before {
  content: "\f76a";
}

.fa-temperature-three-quarters::before {
  content: "\f2c8";
}

.fa-temperature-3::before {
  content: "\f2c8";
}

.fa-thermometer-3::before {
  content: "\f2c8";
}

.fa-thermometer-three-quarters::before {
  content: "\f2c8";
}

.fa-tenge-sign::before {
  content: "\f7d7";
}

.fa-tenge::before {
  content: "\f7d7";
}

.fa-tennis-ball::before {
  content: "\f45e";
}

.fa-tent::before {
  content: "\e57d";
}

.fa-tent-arrow-down-to-line::before {
  content: "\e57e";
}

.fa-tent-arrow-left-right::before {
  content: "\e57f";
}

.fa-tent-arrow-turn-left::before {
  content: "\e580";
}

.fa-tent-arrows-down::before {
  content: "\e581";
}

.fa-tents::before {
  content: "\e582";
}

.fa-terminal::before {
  content: "\f120";
}

.fa-text::before {
  content: "\f893";
}

.fa-text-height::before {
  content: "\f034";
}

.fa-text-size::before {
  content: "\f894";
}

.fa-text-slash::before {
  content: "\f87d";
}

.fa-remove-format::before {
  content: "\f87d";
}

.fa-text-width::before {
  content: "\f035";
}

.fa-thermometer::before {
  content: "\f491";
}

.fa-theta::before {
  content: "\f69e";
}

.fa-thought-bubble::before {
  content: "\e32e";
}

.fa-thumbs-down::before {
  content: "\f165";
}

.fa-thumbs-up::before {
  content: "\f164";
}

.fa-thumbtack::before {
  content: "\f08d";
}

.fa-thumb-tack::before {
  content: "\f08d";
}

.fa-tick::before {
  content: "\e32f";
}

.fa-ticket::before {
  content: "\f145";
}

.fa-ticket-airline::before {
  content: "\e29a";
}

.fa-ticket-simple::before {
  content: "\f3ff";
}

.fa-ticket-alt::before {
  content: "\f3ff";
}

.fa-tickets-airline::before {
  content: "\e29b";
}

.fa-tilde::before {
  content: "\~";
}

.fa-timeline::before {
  content: "\e29c";
}

.fa-timeline-arrow::before {
  content: "\e29d";
}

.fa-timer::before {
  content: "\e29e";
}

.fa-tire::before {
  content: "\f631";
}

.fa-tire-flat::before {
  content: "\f632";
}

.fa-tire-pressure-warning::before {
  content: "\f633";
}

.fa-tire-rugged::before {
  content: "\f634";
}

.fa-toggle-off::before {
  content: "\f204";
}

.fa-toggle-on::before {
  content: "\f205";
}

.fa-toilet::before {
  content: "\f7d8";
}

.fa-toilet-paper::before {
  content: "\f71e";
}

.fa-toilet-paper-blank::before {
  content: "\f71f";
}

.fa-toilet-paper-alt::before {
  content: "\f71f";
}

.fa-toilet-paper-blank-under::before {
  content: "\e29f";
}

.fa-toilet-paper-reverse-alt::before {
  content: "\e29f";
}

.fa-toilet-paper-slash::before {
  content: "\e072";
}

.fa-toilet-paper-under::before {
  content: "\e2a0";
}

.fa-toilet-paper-reverse::before {
  content: "\e2a0";
}

.fa-toilet-paper-under-slash::before {
  content: "\e2a1";
}

.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1";
}

.fa-toilet-portable::before {
  content: "\e583";
}

.fa-toilets-portable::before {
  content: "\e584";
}

.fa-tomato::before {
  content: "\e330";
}

.fa-tombstone::before {
  content: "\f720";
}

.fa-tombstone-blank::before {
  content: "\f721";
}

.fa-tombstone-alt::before {
  content: "\f721";
}

.fa-toolbox::before {
  content: "\f552";
}

.fa-tooth::before {
  content: "\f5c9";
}

.fa-toothbrush::before {
  content: "\f635";
}

.fa-torii-gate::before {
  content: "\f6a1";
}

.fa-tornado::before {
  content: "\f76f";
}

.fa-tower-broadcast::before {
  content: "\f519";
}

.fa-broadcast-tower::before {
  content: "\f519";
}

.fa-tower-cell::before {
  content: "\e585";
}

.fa-tower-control::before {
  content: "\e2a2";
}

.fa-tower-observation::before {
  content: "\e586";
}

.fa-tractor::before {
  content: "\f722";
}

.fa-trademark::before {
  content: "\f25c";
}

.fa-traffic-cone::before {
  content: "\f636";
}

.fa-traffic-light::before {
  content: "\f637";
}

.fa-traffic-light-go::before {
  content: "\f638";
}

.fa-traffic-light-slow::before {
  content: "\f639";
}

.fa-traffic-light-stop::before {
  content: "\f63a";
}

.fa-trailer::before {
  content: "\e041";
}

.fa-train::before {
  content: "\f238";
}

.fa-train-subway::before {
  content: "\f239";
}

.fa-subway::before {
  content: "\f239";
}

.fa-train-subway-tunnel::before {
  content: "\e2a3";
}

.fa-subway-tunnel::before {
  content: "\e2a3";
}

.fa-train-track::before {
  content: "\e453";
}

.fa-train-tram::before {
  content: "\f7da";
}

.fa-tram::before {
  content: "\f7da";
}

.fa-train-tunnel::before {
  content: "\e454";
}

.fa-transformer-bolt::before {
  content: "\e2a4";
}

.fa-transgender::before {
  content: "\f225";
}

.fa-transgender-alt::before {
  content: "\f225";
}

.fa-transporter::before {
  content: "\e042";
}

.fa-transporter-1::before {
  content: "\e043";
}

.fa-transporter-2::before {
  content: "\e044";
}

.fa-transporter-3::before {
  content: "\e045";
}

.fa-transporter-4::before {
  content: "\e2a5";
}

.fa-transporter-5::before {
  content: "\e2a6";
}

.fa-transporter-6::before {
  content: "\e2a7";
}

.fa-transporter-7::before {
  content: "\e2a8";
}

.fa-transporter-empty::before {
  content: "\e046";
}

.fa-trash::before {
  content: "\f1f8";
}

.fa-trash-arrow-up::before {
  content: "\f829";
}

.fa-trash-restore::before {
  content: "\f829";
}

.fa-trash-can::before {
  content: "\f2ed";
}

.fa-trash-alt::before {
  content: "\f2ed";
}

.fa-trash-can-arrow-up::before {
  content: "\f82a";
}

.fa-trash-restore-alt::before {
  content: "\f82a";
}

.fa-trash-can-check::before {
  content: "\e2a9";
}

.fa-trash-can-clock::before {
  content: "\e2aa";
}

.fa-trash-can-list::before {
  content: "\e2ab";
}

.fa-trash-can-plus::before {
  content: "\e2ac";
}

.fa-trash-can-slash::before {
  content: "\e2ad";
}

.fa-trash-alt-slash::before {
  content: "\e2ad";
}

.fa-trash-can-undo::before {
  content: "\f896";
}

.fa-trash-can-arrow-turn-left::before {
  content: "\f896";
}

.fa-trash-undo-alt::before {
  content: "\f896";
}

.fa-trash-can-xmark::before {
  content: "\e2ae";
}

.fa-trash-check::before {
  content: "\e2af";
}

.fa-trash-clock::before {
  content: "\e2b0";
}

.fa-trash-list::before {
  content: "\e2b1";
}

.fa-trash-plus::before {
  content: "\e2b2";
}

.fa-trash-slash::before {
  content: "\e2b3";
}

.fa-trash-undo::before {
  content: "\f895";
}

.fa-trash-arrow-turn-left::before {
  content: "\f895";
}

.fa-trash-xmark::before {
  content: "\e2b4";
}

.fa-treasure-chest::before {
  content: "\f723";
}

.fa-tree::before {
  content: "\f1bb";
}

.fa-tree-christmas::before {
  content: "\f7db";
}

.fa-tree-city::before {
  content: "\e587";
}

.fa-tree-deciduous::before {
  content: "\f400";
}

.fa-tree-alt::before {
  content: "\f400";
}

.fa-tree-decorated::before {
  content: "\f7dc";
}

.fa-tree-large::before {
  content: "\f7dd";
}

.fa-tree-palm::before {
  content: "\f82b";
}

.fa-trees::before {
  content: "\f724";
}

.fa-triangle::before {
  content: "\f2ec";
}

.fa-triangle-exclamation::before {
  content: "\f071";
}

.fa-exclamation-triangle::before {
  content: "\f071";
}

.fa-warning::before {
  content: "\f071";
}

.fa-triangle-instrument::before {
  content: "\f8e2";
}

.fa-triangle-music::before {
  content: "\f8e2";
}

.fa-triangle-person-digging::before {
  content: "\f85d";
}

.fa-construction::before {
  content: "\f85d";
}

.fa-trillium::before {
  content: "\e588";
}

.fa-trophy::before {
  content: "\f091";
}

.fa-trophy-star::before {
  content: "\f2eb";
}

.fa-trophy-alt::before {
  content: "\f2eb";
}

.fa-trowel::before {
  content: "\e589";
}

.fa-trowel-bricks::before {
  content: "\e58a";
}

.fa-truck::before {
  content: "\f0d1";
}

.fa-truck-arrow-right::before {
  content: "\e58b";
}

.fa-truck-bolt::before {
  content: "\e3d0";
}

.fa-truck-clock::before {
  content: "\f48c";
}

.fa-shipping-timed::before {
  content: "\f48c";
}

.fa-truck-container::before {
  content: "\f4dc";
}

.fa-truck-container-empty::before {
  content: "\e2b5";
}

.fa-truck-droplet::before {
  content: "\e58c";
}

.fa-truck-fast::before {
  content: "\f48b";
}

.fa-shipping-fast::before {
  content: "\f48b";
}

.fa-truck-field::before {
  content: "\e58d";
}

.fa-truck-field-un::before {
  content: "\e58e";
}

.fa-truck-flatbed::before {
  content: "\e2b6";
}

.fa-truck-front::before {
  content: "\e2b7";
}

.fa-truck-medical::before {
  content: "\f0f9";
}

.fa-ambulance::before {
  content: "\f0f9";
}

.fa-truck-monster::before {
  content: "\f63b";
}

.fa-truck-moving::before {
  content: "\f4df";
}

.fa-truck-pickup::before {
  content: "\f63c";
}

.fa-truck-plane::before {
  content: "\e58f";
}

.fa-truck-plow::before {
  content: "\f7de";
}

.fa-truck-ramp::before {
  content: "\f4e0";
}

.fa-truck-ramp-box::before {
  content: "\f4de";
}

.fa-truck-loading::before {
  content: "\f4de";
}

.fa-truck-ramp-couch::before {
  content: "\f4dd";
}

.fa-truck-couch::before {
  content: "\f4dd";
}

.fa-truck-tow::before {
  content: "\e2b8";
}

.fa-trumpet::before {
  content: "\f8e3";
}

.fa-tty::before {
  content: "\f1e4";
}

.fa-teletype::before {
  content: "\f1e4";
}

.fa-tty-answer::before {
  content: "\e2b9";
}

.fa-teletype-answer::before {
  content: "\e2b9";
}

.fa-tugrik-sign::before {
  content: "\e2ba";
}

.fa-turkey::before {
  content: "\f725";
}

.fa-turkish-lira-sign::before {
  content: "\e2bb";
}

.fa-try::before {
  content: "\e2bb";
}

.fa-turkish-lira::before {
  content: "\e2bb";
}

.fa-turn-down::before {
  content: "\f3be";
}

.fa-level-down-alt::before {
  content: "\f3be";
}

.fa-turn-down-left::before {
  content: "\e331";
}

.fa-turn-down-right::before {
  content: "\e455";
}

.fa-turn-up::before {
  content: "\f3bf";
}

.fa-level-up-alt::before {
  content: "\f3bf";
}

.fa-turntable::before {
  content: "\f8e4";
}

.fa-turtle::before {
  content: "\f726";
}

.fa-tv::before {
  content: "\f26c";
}

.fa-television::before {
  content: "\f26c";
}

.fa-tv-alt::before {
  content: "\f26c";
}

.fa-tv-music::before {
  content: "\f8e6";
}

.fa-tv-retro::before {
  content: "\f401";
}

.fa-typewriter::before {
  content: "\f8e7";
}

.fa-u::before {
  content: "U";
}

.fa-ufo::before {
  content: "\e047";
}

.fa-ufo-beam::before {
  content: "\e048";
}

.fa-umbrella::before {
  content: "\f0e9";
}

.fa-umbrella-beach::before {
  content: "\f5ca";
}

.fa-umbrella-simple::before {
  content: "\e2bc";
}

.fa-umbrella-alt::before {
  content: "\e2bc";
}

.fa-underline::before {
  content: "\f0cd";
}

.fa-unicorn::before {
  content: "\f727";
}

.fa-uniform-martial-arts::before {
  content: "\e3d1";
}

.fa-union::before {
  content: "\f6a2";
}

.fa-universal-access::before {
  content: "\f29a";
}

.fa-unlock::before {
  content: "\f09c";
}

.fa-unlock-keyhole::before {
  content: "\f13e";
}

.fa-unlock-alt::before {
  content: "\f13e";
}

.fa-up::before {
  content: "\f357";
}

.fa-arrow-alt-up::before {
  content: "\f357";
}

.fa-up-down::before {
  content: "\f338";
}

.fa-arrows-alt-v::before {
  content: "\f338";
}

.fa-up-down-left-right::before {
  content: "\f0b2";
}

.fa-arrows-alt::before {
  content: "\f0b2";
}

.fa-up-from-bracket::before {
  content: "\e590";
}

.fa-up-from-dotted-line::before {
  content: "\e456";
}

.fa-up-from-line::before {
  content: "\f346";
}

.fa-arrow-alt-from-bottom::before {
  content: "\f346";
}

.fa-up-left::before {
  content: "\e2bd";
}

.fa-up-long::before {
  content: "\f30c";
}

.fa-long-arrow-alt-up::before {
  content: "\f30c";
}

.fa-up-right::before {
  content: "\e2be";
}

.fa-up-right-and-down-left-from-center::before {
  content: "\f424";
}

.fa-expand-alt::before {
  content: "\f424";
}

.fa-up-right-from-square::before {
  content: "\f35d";
}

.fa-external-link-alt::before {
  content: "\f35d";
}

.fa-up-to-dotted-line::before {
  content: "\e457";
}

.fa-up-to-line::before {
  content: "\f34d";
}

.fa-arrow-alt-to-top::before {
  content: "\f34d";
}

.fa-upload::before {
  content: "\f093";
}

.fa-usb-drive::before {
  content: "\f8e9";
}

.fa-user::before {
  content: "\f007";
}

.fa-user-alien::before {
  content: "\e04a";
}

.fa-user-astronaut::before {
  content: "\f4fb";
}

.fa-user-bounty-hunter::before {
  content: "\e2bf";
}

.fa-user-check::before {
  content: "\f4fc";
}

.fa-user-chef::before {
  content: "\e3d2";
}

.fa-user-clock::before {
  content: "\f4fd";
}

.fa-user-cowboy::before {
  content: "\f8ea";
}

.fa-user-crown::before {
  content: "\f6a4";
}

.fa-user-doctor::before {
  content: "\f0f0";
}

.fa-user-md::before {
  content: "\f0f0";
}

.fa-user-doctor-hair::before {
  content: "\e458";
}

.fa-user-doctor-hair-long::before {
  content: "\e459";
}

.fa-user-doctor-message::before {
  content: "\f82e";
}

.fa-user-md-chat::before {
  content: "\f82e";
}

.fa-user-gear::before {
  content: "\f4fe";
}

.fa-user-cog::before {
  content: "\f4fe";
}

.fa-user-graduate::before {
  content: "\f501";
}

.fa-user-group::before {
  content: "\f500";
}

.fa-user-friends::before {
  content: "\f500";
}

.fa-user-group-crown::before {
  content: "\f6a5";
}

.fa-users-crown::before {
  content: "\f6a5";
}

.fa-user-hair::before {
  content: "\e45a";
}

.fa-user-hair-buns::before {
  content: "\e3d3";
}

.fa-user-hair-long::before {
  content: "\e45b";
}

.fa-user-hair-mullet::before {
  content: "\e45c";
}

.fa-business-front::before {
  content: "\e45c";
}

.fa-party-back::before {
  content: "\e45c";
}

.fa-trian-balbot::before {
  content: "\e45c";
}

.fa-user-headset::before {
  content: "\f82d";
}

.fa-user-helmet-safety::before {
  content: "\f82c";
}

.fa-user-construction::before {
  content: "\f82c";
}

.fa-user-hard-hat::before {
  content: "\f82c";
}

.fa-user-injured::before {
  content: "\f728";
}

.fa-user-large::before {
  content: "\f406";
}

.fa-user-alt::before {
  content: "\f406";
}

.fa-user-large-slash::before {
  content: "\f4fa";
}

.fa-user-alt-slash::before {
  content: "\f4fa";
}

.fa-user-lock::before {
  content: "\f502";
}

.fa-user-minus::before {
  content: "\f503";
}

.fa-user-music::before {
  content: "\f8eb";
}

.fa-user-ninja::before {
  content: "\f504";
}

.fa-user-nurse::before {
  content: "\f82f";
}

.fa-user-nurse-hair::before {
  content: "\e45d";
}

.fa-user-nurse-hair-long::before {
  content: "\e45e";
}

.fa-user-pen::before {
  content: "\f4ff";
}

.fa-user-edit::before {
  content: "\f4ff";
}

.fa-user-pilot::before {
  content: "\e2c0";
}

.fa-user-pilot-tie::before {
  content: "\e2c1";
}

.fa-user-plus::before {
  content: "\f234";
}

.fa-user-police::before {
  content: "\e333";
}

.fa-user-police-tie::before {
  content: "\e334";
}

.fa-user-robot::before {
  content: "\e04b";
}

.fa-user-robot-xmarks::before {
  content: "\e4a7";
}

.fa-user-secret::before {
  content: "\f21b";
}

.fa-user-shakespeare::before {
  content: "\e2c2";
}

.fa-user-shield::before {
  content: "\f505";
}

.fa-user-slash::before {
  content: "\f506";
}

.fa-user-tag::before {
  content: "\f507";
}

.fa-user-tie::before {
  content: "\f508";
}

.fa-user-tie-hair::before {
  content: "\e45f";
}

.fa-user-tie-hair-long::before {
  content: "\e460";
}

.fa-user-unlock::before {
  content: "\e058";
}

.fa-user-visor::before {
  content: "\e04c";
}

.fa-user-vneck::before {
  content: "\e461";
}

.fa-user-vneck-hair::before {
  content: "\e462";
}

.fa-user-vneck-hair-long::before {
  content: "\e463";
}

.fa-user-xmark::before {
  content: "\f235";
}

.fa-user-times::before {
  content: "\f235";
}

.fa-users::before {
  content: "\f0c0";
}

.fa-users-between-lines::before {
  content: "\e591";
}

.fa-users-gear::before {
  content: "\f509";
}

.fa-users-cog::before {
  content: "\f509";
}

.fa-users-line::before {
  content: "\e592";
}

.fa-users-medical::before {
  content: "\f830";
}

.fa-users-rays::before {
  content: "\e593";
}

.fa-users-rectangle::before {
  content: "\e594";
}

.fa-users-slash::before {
  content: "\e073";
}

.fa-users-viewfinder::before {
  content: "\e595";
}

.fa-utensils::before {
  content: "\f2e7";
}

.fa-cutlery::before {
  content: "\f2e7";
}

.fa-utensils-slash::before {
  content: "\e464";
}

.fa-utility-pole::before {
  content: "\e2c3";
}

.fa-utility-pole-double::before {
  content: "\e2c4";
}

.fa-v::before {
  content: "V";
}

.fa-vacuum::before {
  content: "\e04d";
}

.fa-vacuum-robot::before {
  content: "\e04e";
}

.fa-value-absolute::before {
  content: "\f6a6";
}

.fa-van-shuttle::before {
  content: "\f5b6";
}

.fa-shuttle-van::before {
  content: "\f5b6";
}

.fa-vault::before {
  content: "\e2c5";
}

.fa-vector-circle::before {
  content: "\e2c6";
}

.fa-vector-polygon::before {
  content: "\e2c7";
}

.fa-vector-square::before {
  content: "\f5cb";
}

.fa-vent-damper::before {
  content: "\e465";
}

.fa-venus::before {
  content: "\f221";
}

.fa-venus-double::before {
  content: "\f226";
}

.fa-venus-mars::before {
  content: "\f228";
}

.fa-vest::before {
  content: "\e085";
}

.fa-vest-patches::before {
  content: "\e086";
}

.fa-vial::before {
  content: "\f492";
}

.fa-vial-circle-check::before {
  content: "\e596";
}

.fa-vial-virus::before {
  content: "\e597";
}

.fa-vials::before {
  content: "\f493";
}

.fa-video::before {
  content: "\f03d";
}

.fa-video-camera::before {
  content: "\f03d";
}

.fa-video-arrow-down-left::before {
  content: "\e2c8";
}

.fa-video-arrow-up-right::before {
  content: "\e2c9";
}

.fa-video-plus::before {
  content: "\f4e1";
}

.fa-video-slash::before {
  content: "\f4e2";
}

.fa-vihara::before {
  content: "\f6a7";
}

.fa-violin::before {
  content: "\f8ed";
}

.fa-virus::before {
  content: "\e074";
}

.fa-virus-covid::before {
  content: "\e4a8";
}

.fa-virus-covid-slash::before {
  content: "\e4a9";
}

.fa-virus-slash::before {
  content: "\e075";
}

.fa-viruses::before {
  content: "\e076";
}

.fa-voicemail::before {
  content: "\f897";
}

.fa-volcano::before {
  content: "\f770";
}

.fa-volleyball::before {
  content: "\f45f";
}

.fa-volleyball-ball::before {
  content: "\f45f";
}

.fa-volume::before {
  content: "\f6a8";
}

.fa-volume-medium::before {
  content: "\f6a8";
}

.fa-volume-high::before {
  content: "\f028";
}

.fa-volume-up::before {
  content: "\f028";
}

.fa-volume-low::before {
  content: "\f027";
}

.fa-volume-down::before {
  content: "\f027";
}

.fa-volume-off::before {
  content: "\f026";
}

.fa-volume-slash::before {
  content: "\f2e2";
}

.fa-volume-xmark::before {
  content: "\f6a9";
}

.fa-volume-mute::before {
  content: "\f6a9";
}

.fa-volume-times::before {
  content: "\f6a9";
}

.fa-vr-cardboard::before {
  content: "\f729";
}

.fa-w::before {
  content: "W";
}

.fa-waffle::before {
  content: "\e466";
}

.fa-wagon-covered::before {
  content: "\f8ee";
}

.fa-walker::before {
  content: "\f831";
}

.fa-walkie-talkie::before {
  content: "\f8ef";
}

.fa-wallet::before {
  content: "\f555";
}

.fa-wand::before {
  content: "\f72a";
}

.fa-wand-magic::before {
  content: "\f0d0";
}

.fa-magic::before {
  content: "\f0d0";
}

.fa-wand-magic-sparkles::before {
  content: "\e2ca";
}

.fa-magic-wand-sparkles::before {
  content: "\e2ca";
}

.fa-wand-sparkles::before {
  content: "\f72b";
}

.fa-warehouse::before {
  content: "\f494";
}

.fa-warehouse-full::before {
  content: "\f495";
}

.fa-warehouse-alt::before {
  content: "\f495";
}

.fa-washing-machine::before {
  content: "\f898";
}

.fa-washer::before {
  content: "\f898";
}

.fa-watch::before {
  content: "\f2e1";
}

.fa-watch-apple::before {
  content: "\e2cb";
}

.fa-watch-calculator::before {
  content: "\f8f0";
}

.fa-watch-fitness::before {
  content: "\f63e";
}

.fa-watch-smart::before {
  content: "\e2cc";
}

.fa-water::before {
  content: "\f773";
}

.fa-water-arrow-down::before {
  content: "\f774";
}

.fa-water-lower::before {
  content: "\f774";
}

.fa-water-arrow-up::before {
  content: "\f775";
}

.fa-water-rise::before {
  content: "\f775";
}

.fa-water-ladder::before {
  content: "\f5c5";
}

.fa-ladder-water::before {
  content: "\f5c5";
}

.fa-swimming-pool::before {
  content: "\f5c5";
}

.fa-watermelon-slice::before {
  content: "\e337";
}

.fa-wave-pulse::before {
  content: "\f5f8";
}

.fa-heart-rate::before {
  content: "\f5f8";
}

.fa-wave-sine::before {
  content: "\f899";
}

.fa-wave-square::before {
  content: "\f83e";
}

.fa-wave-triangle::before {
  content: "\f89a";
}

.fa-waveform::before {
  content: "\f8f1";
}

.fa-waveform-lines::before {
  content: "\f8f2";
}

.fa-waveform-path::before {
  content: "\f8f2";
}

.fa-weight-hanging::before {
  content: "\f5cd";
}

.fa-weight-scale::before {
  content: "\f496";
}

.fa-weight::before {
  content: "\f496";
}

.fa-whale::before {
  content: "\f72c";
}

.fa-wheat::before {
  content: "\f72d";
}

.fa-wheat-awn::before {
  content: "\e2cd";
}

.fa-wheat-alt::before {
  content: "\e2cd";
}

.fa-wheat-awn-circle-exclamation::before {
  content: "\e598";
}

.fa-wheat-awn-slash::before {
  content: "\e338";
}

.fa-wheat-slash::before {
  content: "\e339";
}

.fa-wheelchair::before {
  content: "\f193";
}

.fa-wheelchair-move::before {
  content: "\e2ce";
}

.fa-wheelchair-alt::before {
  content: "\e2ce";
}

.fa-whiskey-glass::before {
  content: "\f7a0";
}

.fa-glass-whiskey::before {
  content: "\f7a0";
}

.fa-whiskey-glass-ice::before {
  content: "\f7a1";
}

.fa-glass-whiskey-rocks::before {
  content: "\f7a1";
}

.fa-whistle::before {
  content: "\f460";
}

.fa-wifi::before {
  content: "\f1eb";
}

.fa-wifi-3::before {
  content: "\f1eb";
}

.fa-wifi-strong::before {
  content: "\f1eb";
}

.fa-wifi-exclamation::before {
  content: "\e2cf";
}

.fa-wifi-fair::before {
  content: "\f6ab";
}

.fa-wifi-2::before {
  content: "\f6ab";
}

.fa-wifi-slash::before {
  content: "\f6ac";
}

.fa-wifi-weak::before {
  content: "\f6aa";
}

.fa-wifi-1::before {
  content: "\f6aa";
}

.fa-wind::before {
  content: "\f72e";
}

.fa-wind-turbine::before {
  content: "\f89b";
}

.fa-wind-warning::before {
  content: "\f776";
}

.fa-wind-circle-exclamation::before {
  content: "\f776";
}

.fa-window::before {
  content: "\f40e";
}

.fa-window-flip::before {
  content: "\f40f";
}

.fa-window-alt::before {
  content: "\f40f";
}

.fa-window-frame::before {
  content: "\e04f";
}

.fa-window-frame-open::before {
  content: "\e050";
}

.fa-window-maximize::before {
  content: "\f2d0";
}

.fa-window-minimize::before {
  content: "\f2d1";
}

.fa-window-restore::before {
  content: "\f2d2";
}

.fa-windsock::before {
  content: "\f777";
}

.fa-wine-bottle::before {
  content: "\f72f";
}

.fa-wine-glass::before {
  content: "\f4e3";
}

.fa-wine-glass-crack::before {
  content: "\f4bb";
}

.fa-fragile::before {
  content: "\f4bb";
}

.fa-wine-glass-empty::before {
  content: "\f5ce";
}

.fa-wine-glass-alt::before {
  content: "\f5ce";
}

.fa-won-sign::before {
  content: "\f159";
}

.fa-krw::before {
  content: "\f159";
}

.fa-won::before {
  content: "\f159";
}

.fa-worm::before {
  content: "\e599";
}

.fa-wreath::before {
  content: "\f7e2";
}

.fa-wrench::before {
  content: "\f0ad";
}

.fa-wrench-simple::before {
  content: "\e2d1";
}

.fa-x::before {
  content: "X";
}

.fa-x-ray::before {
  content: "\f497";
}

.fa-xmark::before {
  content: "\f00d";
}

.fa-close::before {
  content: "\f00d";
}

.fa-multiply::before {
  content: "\f00d";
}

.fa-remove::before {
  content: "\f00d";
}

.fa-times::before {
  content: "\f00d";
}

.fa-xmark-large::before {
  content: "\e59b";
}

.fa-xmark-to-slot::before {
  content: "\f771";
}

.fa-times-to-slot::before {
  content: "\f771";
}

.fa-vote-nay::before {
  content: "\f771";
}

.fa-xmarks-lines::before {
  content: "\e59a";
}

.fa-y::before {
  content: "Y";
}

.fa-yen-sign::before {
  content: "\f157";
}

.fa-cny::before {
  content: "\f157";
}

.fa-jpy::before {
  content: "\f157";
}

.fa-rmb::before {
  content: "\f157";
}

.fa-yen::before {
  content: "\f157";
}

.fa-yin-yang::before {
  content: "\f6ad";
}

.fa-z::before {
  content: "Z";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/*!
 * Bootstrap Grid v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 640px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 960px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 1200px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1600px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 1320px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 640px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 960px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1600px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 640px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 960px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1600px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
html {
  /* 1 */
  font-family: myriad-pro, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: #ffffff;
  color: #666;
}

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/* Links
 ========================================================================== */
/*
 * Style
 */
a,
.uk-link {
  color: #61A6C7;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link {
  color: #61A6C7;
  text-decoration: underline;
}

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted;
}

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.875rem;
  /* 3 */
  color: #D60727;
  white-space: nowrap;
}

/*
 * Emphasize
 */
em {
  color: #D60727;
}

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #666;
  text-decoration: none;
}

/*
 * Mark
 */
mark {
  background: #ffd;
  color: #666;
}

/*
 * Quote
 */
q {
  font-style: italic;
}

/*
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between the element and the bottom of its parent container.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * 1. Constrain the element to its parent width.
 * 2. Preserve the intrinsic aspect ratio and auto-scale the height of an image if the `height` attribute is present.
 * 3. Take border and padding into account.
 */
canvas,
img,
svg,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box;
}

/*
 * Deprecated: only needed for `img` elements with `uk-img`
 * 1. Hide `alt` text for lazy load images.
 * 2. Fix lazy loading images if parent element is set to `display: inline` and has `overflow: hidden`.
 */
img:not([src]) {
  /* 1 */
  visibility: hidden;
  /* 2 */
  min-width: 1px;
}

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0;
}

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0;
}

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px;
}

/* Headings
 ========================================================================== */
h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 20px 0;
  font-family: myriad-pro, sans-serif;
  font-weight: normal;
  color: #333;
  text-transform: none;
}

/* Add margin if adjacent element */
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  margin-top: 60px;
}

/*
 * Sizes
 */
h1, .uk-h1 {
  font-size: 2.23125rem;
  line-height: 1.2;
}

h2, .uk-h2 {
  font-size: 1.7rem;
  line-height: 1.3;
}

h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.4;
}

h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.4;
}

h5, .uk-h5 {
  font-size: 16px;
  line-height: 1.4;
}

h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.4;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  h1, .uk-h1 {
    font-size: 2.625rem;
  }

  h2, .uk-h2 {
    font-size: 2rem;
  }
}
/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 30px;
}

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e8e8e8;
}

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 20px;
}

/* Address
 ========================================================================== */
address {
  font-style: normal;
}

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: italic;
}

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px;
}

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0;
}

blockquote footer {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.875rem / 1.5 Consolas, monaco, monospace;
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto;
}

pre code {
  font-family: Consolas, monaco, monospace;
}

/* Focus
 ========================================================================== */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px dotted #333;
}

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none;
}

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main {
  /* 2 */
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 960px;
  --uk-breakpoint-l: 1200px;
  --uk-breakpoint-xl: 1600px;
}

/* ========================================================================
   Component: Link
 ========================================================================== */
/* Muted
 ========================================================================== */
a.uk-link-muted,
.uk-link-muted a,
.uk-link-toggle .uk-link-muted {
  color: #999;
}

a.uk-link-muted:hover,
.uk-link-muted a:hover,
.uk-link-toggle:hover .uk-link-muted {
  color: #666;
}

/* Text
 ========================================================================== */
a.uk-link-text,
.uk-link-text a,
.uk-link-toggle .uk-link-text {
  color: inherit;
}

a.uk-link-text:hover,
.uk-link-text a:hover,
.uk-link-toggle:hover .uk-link-text {
  color: #999;
}

/* Heading
 ========================================================================== */
a.uk-link-heading,
.uk-link-heading a,
.uk-link-toggle .uk-link-heading {
  color: inherit;
}

a.uk-link-heading:hover,
.uk-link-heading a:hover,
.uk-link-toggle:hover .uk-link-heading {
  color: #61A6C7;
  text-decoration: none;
}

/* Reset
 ========================================================================== */
/*
 * `!important` needed to override inverse component
 */
a.uk-link-reset,
.uk-link-reset a {
  color: inherit !important;
  text-decoration: none !important;
}

/* Toggle
 ========================================================================== */
.uk-link-toggle {
  color: inherit !important;
  text-decoration: none !important;
}

/* ========================================================================
   Component: Heading
 ========================================================================== */
.uk-heading-small {
  font-size: 2.6rem;
  line-height: 1.2;
}

.uk-heading-medium {
  font-size: 2.8875rem;
  line-height: 1.1;
}

.uk-heading-large {
  font-size: 3.4rem;
  line-height: 1.1;
}

.uk-heading-xlarge {
  font-size: 4rem;
  line-height: 1;
}

.uk-heading-2xlarge {
  font-size: 6rem;
  line-height: 1;
}

/* Tablet Landscape and bigger */
@media (min-width: 960px) {
  .uk-heading-small {
    font-size: 3.25rem;
  }

  .uk-heading-medium {
    font-size: 3.5rem;
  }

  .uk-heading-large {
    font-size: 4rem;
  }

  .uk-heading-xlarge {
    font-size: 6rem;
  }

  .uk-heading-2xlarge {
    font-size: 8rem;
  }
}
/* Laptop and bigger */
@media (min-width: 1200px) {
  .uk-heading-medium {
    font-size: 4rem;
  }

  .uk-heading-large {
    font-size: 6rem;
  }

  .uk-heading-xlarge {
    font-size: 8rem;
  }

  .uk-heading-2xlarge {
    font-size: 11rem;
  }
}
/* Primary
   Deprecated: Use `uk-heading-medium` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Hero
   Deprecated: Use `uk-heading-xlarge` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Divider
 ========================================================================== */
.uk-heading-divider {
  padding-bottom: calc(5px + 0.1em);
  border-bottom: calc(0.2px + 0.05em) solid #e8e8e8;
}

/* Bullet
 ========================================================================== */
.uk-heading-bullet {
  position: relative;
}

/*
 * 1. Using `inline-block` to make it work with text alignment
 * 2. Center vertically
 * 3. Style
 */
.uk-heading-bullet::before {
  content: "";
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  top: calc(-0.1 * 1em);
  vertical-align: middle;
  /* 3 */
  height: calc(4px + 0.7em);
  margin-right: calc(5px + 0.2em);
  border-left: calc(5px + 0.1em) solid #e8e8e8;
}

/* Line
 ========================================================================== */
/*
 * Clip the child element
 */
.uk-heading-line {
  overflow: hidden;
}

/*
 * Extra markup is needed to make it work with text align
 */
.uk-heading-line > * {
  display: inline-block;
  position: relative;
}

/*
 * 1. Center vertically
 * 2. Make the element as large as possible. It's clipped by the container.
 * 3. Style
 */
.uk-heading-line > ::before,
.uk-heading-line > ::after {
  content: "";
  /* 1 */
  position: absolute;
  top: calc(50% - (calc(0.2px + 0.05em) / 2));
  /* 2 */
  width: 2000px;
  /* 3 */
  border-bottom: calc(0.2px + 0.05em) solid #e8e8e8;
}

.uk-heading-line > ::before {
  right: 100%;
  margin-right: calc(5px + 0.3em);
}

.uk-heading-line > ::after {
  left: 100%;
  margin-left: calc(5px + 0.3em);
}

/* ========================================================================
   Component: Divider
 ========================================================================== */
/*
 * 1. Reset default `hr`
 * 2. Set margin if a `div` is used for semantical reason
 */
[class*=uk-divider] {
  /* 1 */
  border: none;
  /* 2 */
  margin-bottom: 20px;
}

/* Add margin if adjacent element */
* + [class*=uk-divider] {
  margin-top: 20px;
}

/* Icon
 ========================================================================== */
.uk-divider-icon {
  position: relative;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22%23e8e8e8%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-divider-icon::before,
.uk-divider-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  max-width: calc(50% - (50px / 2));
  border-bottom: 1px solid #e8e8e8;
}

.uk-divider-icon::before {
  right: calc(50% + (50px / 2));
  width: 100%;
}

.uk-divider-icon::after {
  left: calc(50% + (50px / 2));
  width: 100%;
}

/* Small
 ========================================================================== */
/*
 * 1. Fix height because of `inline-block`
 * 2. Using ::after and inline-block to make `text-align` work
 */
/* 1 */
.uk-divider-small {
  line-height: 0;
}

/* 2 */
.uk-divider-small::after {
  content: "";
  display: inline-block;
  width: 100px;
  max-width: 100%;
  border-top: 1px solid #e8e8e8;
  vertical-align: top;
}

/* Vertical
 ========================================================================== */
.uk-divider-vertical {
  width: max-content;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #e8e8e8;
}

/* ========================================================================
   Component: List
 ========================================================================== */
.uk-list {
  padding: 0;
  list-style: none;
}

/*
 * Remove margin from the last-child
 */
.uk-list > * > :last-child {
  margin-bottom: 0;
}

/*
 * Style
 */
.uk-list > :nth-child(n+2),
.uk-list > * > ul {
  margin-top: 10px;
}

/* Marker modifiers
 * Moving `::marker` inside `::before` to style it differently
 * To style the `::marker` is currently only supported in Firefox and Safari
 ========================================================================== */
.uk-list-disc > *,
.uk-list-circle > *,
.uk-list-square > *,
.uk-list-decimal > *,
.uk-list-hyphen > * {
  padding-left: 30px;
}

/*
 * Type modifiers
 */
.uk-list-decimal {
  counter-reset: decimal;
}

.uk-list-decimal > * {
  counter-increment: decimal;
}

.uk-list-disc > ::before,
.uk-list-circle > ::before,
.uk-list-square > ::before,
.uk-list-decimal > ::before,
.uk-list-hyphen > ::before {
  content: "";
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  display: list-item;
  list-style-position: inside;
  text-align: right;
}

.uk-list-disc > ::before {
  list-style-type: disc;
}

.uk-list-circle > ::before {
  list-style-type: circle;
}

.uk-list-square > ::before {
  list-style-type: square;
}

.uk-list-decimal > ::before {
  content: counter(decimal, decimal) " . ";
}

.uk-list-hyphen > ::before {
  content: "–  ";
}

/*
 * Color modifiers
 */
.uk-list-muted > ::before {
  color: #999 !important;
}

.uk-list-emphasis > ::before {
  color: #333 !important;
}

.uk-list-primary > ::before {
  color: #61A6C7 !important;
}

.uk-list-secondary > ::before {
  color: #222 !important;
}

/* Image bullet modifier
 ========================================================================== */
.uk-list-bullet > * {
  padding-left: 30px;
}

.uk-list-bullet > ::before {
  content: "";
  display: list-item;
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-list-divider > :nth-child(n+2) {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e8e8e8;
}

/*
 * Striped
 */
.uk-list-striped > * {
  padding: 10px 10px;
}

.uk-list-striped > :nth-of-type(odd) {
  background: #f8f8f8;
}

.uk-list-striped > :nth-child(n+2) {
  margin-top: 0;
}

/* Size modifier
 ========================================================================== */
.uk-list-large > :nth-child(n+2),
.uk-list-large > * > ul {
  margin-top: 20px;
}

.uk-list-collapse > :nth-child(n+2),
.uk-list-collapse > * > ul {
  margin-top: 0;
}

/*
 * Divider
 */
.uk-list-large.uk-list-divider > :nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px;
}

.uk-list-collapse.uk-list-divider > :nth-child(n+2) {
  margin-top: 0;
  padding-top: 0;
}

/*
 * Striped
 */
.uk-list-large.uk-list-striped > * {
  padding: 20px 10px;
}

.uk-list-collapse.uk-list-striped > * {
  padding-top: 0;
  padding-bottom: 0;
}

.uk-list-large.uk-list-striped > :nth-child(n+2),
.uk-list-collapse.uk-list-striped > :nth-child(n+2) {
  margin-top: 0;
}

/* ========================================================================
   Component: Description list
 ========================================================================== */
/*
 * Term
 */
.uk-description-list > dt {
  color: #333;
}

.uk-description-list > dt:nth-child(n+2) {
  margin-top: 20px;
}

/*
 * Description
 */
/* Style modifier
 ========================================================================== */
/*
 * Line
 */
.uk-description-list-divider > dt:nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e8e8e8;
}

/* ========================================================================
   Component: Table
 ========================================================================== */
/*
 * 1. Remove most spacing between table cells.
 * 2. Behave like a block element
 * 3. Style
 */
.uk-table {
  /* 1 */
  border-collapse: collapse;
  border-spacing: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  margin-bottom: 20px;
}

/* Add margin if adjacent element */
* + .uk-table {
  margin-top: 20px;
}

/* Header cell
 ========================================================================== */
/*
 * 1. Style
 */
.uk-table th {
  padding: 16px 12px;
  text-align: left;
  vertical-align: bottom;
  /* 1 */
  font-size: 16px;
  font-weight: bold;
  color: #666;
}

/* Cell
 ========================================================================== */
.uk-table td {
  padding: 16px 12px;
  vertical-align: top;
}

/*
 * Remove margin from the last-child
 */
.uk-table td > :last-child {
  margin-bottom: 0;
}

/* Footer
 ========================================================================== */
.uk-table tfoot {
  font-size: 0.875rem;
}

/* Caption
 ========================================================================== */
.uk-table caption {
  font-size: 0.875rem;
  text-align: left;
  color: #999;
}

/* Alignment modifier
 ========================================================================== */
.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important;
}

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-table-divider > tr:not(:first-child),
.uk-table-divider > :not(:first-child) > tr,
.uk-table-divider > :first-child > tr:not(:first-child) {
  border-top: 1px solid #e8e8e8;
}

/*
 * Striped
 */
.uk-table-striped > tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #f8f8f8;
}

/*
 * Hover
 */
.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
  background: #ffd;
}

/* Active state
 ========================================================================== */
.uk-table > tr.uk-active,
.uk-table tbody tr.uk-active {
  background: #ffd;
}

/* Size modifier
 ========================================================================== */
.uk-table-small th,
.uk-table-small td {
  padding: 10px 12px;
}

.uk-table-large th,
.uk-table-large td {
  padding: 22px 12px;
}

/* Justify modifier
 ========================================================================== */
.uk-table-justify th:first-child,
.uk-table-justify td:first-child {
  padding-left: 0;
}

.uk-table-justify th:last-child,
.uk-table-justify td:last-child {
  padding-right: 0;
}

/* Cell size modifier
 ========================================================================== */
.uk-table-shrink {
  width: 1px;
}

.uk-table-expand {
  min-width: 150px;
}

/* Cell link modifier
 ========================================================================== */
/*
 * Does not work with `uk-table-justify` at the moment
 */
.uk-table-link {
  padding: 0 !important;
}

.uk-table-link > a {
  display: block;
  padding: 16px 12px;
}

.uk-table-small .uk-table-link > a {
  padding: 10px 12px;
}

/* Responsive table
 ========================================================================== */
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-table-responsive,
.uk-table-responsive tbody,
.uk-table-responsive th,
.uk-table-responsive td,
.uk-table-responsive tr {
    display: block;
  }

  .uk-table-responsive thead {
    display: none;
  }

  .uk-table-responsive th,
.uk-table-responsive td {
    width: auto !important;
    max-width: none !important;
    min-width: 0 !important;
    overflow: visible !important;
    white-space: normal !important;
  }

  .uk-table-responsive th:not(:first-child):not(.uk-table-link),
.uk-table-responsive td:not(:first-child):not(.uk-table-link),
.uk-table-responsive .uk-table-link:not(:first-child) > a {
    padding-top: 5px !important;
  }

  .uk-table-responsive th:not(:last-child):not(.uk-table-link),
.uk-table-responsive td:not(:last-child):not(.uk-table-link),
.uk-table-responsive .uk-table-link:not(:last-child) > a {
    padding-bottom: 5px !important;
  }

  .uk-table-justify.uk-table-responsive th,
.uk-table-justify.uk-table-responsive td {
    padding-left: 0;
    padding-right: 0;
  }
}
/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0;
}

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentcolor;
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0);
}

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 * 1. Required for `span` with background image
 * 2. Required for `image`
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  /* 1 */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  /* 2 */
  object-fit: scale-down;
  max-width: none;
}

/* Style modifiers
 ========================================================================== */
/*
 * Link
 */
.uk-icon-link {
  color: #999;
}

.uk-icon-link:hover {
  color: #666;
}

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959;
}

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Hover */
.uk-icon-button:hover {
  background-color: #ebebeb;
  color: #666;
}

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666;
}

/* ========================================================================
   Component: Form Range
 ========================================================================== */
/*
 * 1. Remove default style.
 * 2. Define consistent box sizing.
 * 3. Remove `margin` in all browsers.
 * 4. Align to the center of the line box.
 * 5. Prevent content overflow if a fixed width is used.
 * 6. Take the full width.
 * 7. Remove white background in Chrome.
 */
.uk-range {
  /* 1 */
  -webkit-appearance: none;
  /* 2 */
  box-sizing: border-box;
  /* 3 */
  margin: 0;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  max-width: 100%;
  /* 6 */
  width: 100%;
  /* 7 */
  background: transparent;
}

/* Focus */
.uk-range:focus {
  outline: none;
}

.uk-range::-moz-focus-outer {
  border: none;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-range:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer;
}

.uk-range:not(:disabled)::-moz-range-thumb {
  cursor: pointer;
}

/*
 * Track
 * 1. Safari doesn't have a focus state. Using active instead.
 */
/* Webkit */
.uk-range::-webkit-slider-runnable-track {
  height: 3px;
  background: #ebebeb;
}

.uk-range:focus::-webkit-slider-runnable-track,
.uk-range:active::-webkit-slider-runnable-track {
  background: #dfdfdf;
}

/* Firefox */
.uk-range::-moz-range-track {
  height: 3px;
  background: #ebebeb;
}

.uk-range:focus::-moz-range-track {
  background: #dfdfdf;
}

/*
 * Thumb
 * 1. Reset
 * 2. Style
 */
/* Webkit */
.uk-range::-webkit-slider-thumb {
  /* 1 */
  -webkit-appearance: none;
  margin-top: -7px;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #666;
}

/* Firefox */
.uk-range::-moz-range-thumb {
  /* 1 */
  border: none;
  /* 2 */
  height: 15px;
  width: 15px;
  margin-top: -7px;
  border-radius: 500px;
  background: #666;
}

/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers.
 */
.uk-input,
.uk-select,
.uk-textarea,
.uk-radio,
.uk-checkbox {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
}

/*
 * Show the overflow in Edge.
 */
.uk-input {
  overflow: visible;
}

/*
 * Remove the inheritance of text transform in Firefox.
 */
.uk-select {
  text-transform: none;
}

/*
 * 1. Change font properties to `inherit` in all browsers
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.uk-select optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold;
}

/*
 * Remove the default vertical scrollbar in IE 10+.
 */
.uk-textarea {
  overflow: auto;
}

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-input[type=search]::-webkit-search-cancel-button,
.uk-input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
.uk-input[type=number]::-webkit-inner-spin-button,
.uk-input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-input::-moz-placeholder,
.uk-textarea::-moz-placeholder {
  opacity: 1;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-radio:not(:disabled),
.uk-checkbox:not(:disabled) {
  cursor: pointer;
}

/*
 * Define consistent border, margin, and padding.
 */
.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/* Input, select and textarea
 * Allowed: `text`, `password`, `datetime`, `datetime-local`, `date`,  `month`,
            `time`, `week`, `number`, `email`, `url`, `search`, `tel`, `color`
 * Disallowed: `range`, `radio`, `checkbox`, `file`, `submit`, `reset` and `image`
 ========================================================================== */
/*
 * Remove default style in iOS.
 */
.uk-input,
.uk-textarea {
  -webkit-appearance: none;
}

/*
 * 1. Prevent content overflow if a fixed width is used
 * 2. Take the full width
 * 3. Reset default
 * 4. Style
 */
.uk-input,
.uk-select,
.uk-textarea {
  /* 1 */
  max-width: 100%;
  /* 2 */
  width: 100%;
  /* 3 */
  border: 0 none;
  /* 4 */
  padding: 0 10px;
  background: #f8f8f8;
  color: #666;
}

/*
 * Single-line
 * 1. Allow any element to look like an `input` or `select` element
 * 2. Make sure line-height is not larger than height
 *    Also needed to center the text vertically
 */
.uk-input,
.uk-select:not([multiple]):not([size]) {
  height: 40px;
  vertical-align: middle;
  /* 1 */
  display: inline-block;
}

/* 2 */
.uk-input:not(input),
.uk-select:not(select) {
  line-height: 40px;
}

/*
 * Multi-line
 */
.uk-select[multiple],
.uk-select[size],
.uk-textarea {
  padding-top: 6px;
  padding-bottom: 6px;
  vertical-align: top;
}

.uk-select[multiple],
.uk-select[size] {
  resize: vertical;
}

/* Focus */
.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: none;
  background-color: #ebebeb;
  color: #666;
}

/* Disabled */
.uk-input:disabled,
.uk-select:disabled,
.uk-textarea:disabled {
  background-color: #f8f8f8;
  color: #999;
}

/*
 * Placeholder
 */
.uk-input::placeholder {
  color: #999;
}

.uk-textarea::placeholder {
  color: #999;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Small
 */
.uk-form-small {
  font-size: 0.875rem;
}

/* Single-line */
.uk-form-small:not(textarea):not([multiple]):not([size]) {
  height: 30px;
  padding-left: 8px;
  padding-right: 8px;
}

/* Multi-line */
textarea.uk-form-small,
[multiple].uk-form-small,
[size].uk-form-small {
  padding: 5px 8px;
}

.uk-form-small:not(select):not(input):not(textarea) {
  line-height: 30px;
}

/*
 * Large
 */
.uk-form-large {
  font-size: 1.25rem;
}

/* Single-line */
.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 55px;
  padding-left: 12px;
  padding-right: 12px;
}

/* Multi-line */
textarea.uk-form-large,
[multiple].uk-form-large,
[size].uk-form-large {
  padding: 7px 12px;
}

.uk-form-large:not(select):not(input):not(textarea) {
  line-height: 55px;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Error
 */
.uk-form-danger,
.uk-form-danger:focus {
  color: #D60727;
}

/*
 * Success
 */
.uk-form-success,
.uk-form-success:focus {
  color: #1eaf3a;
}

/*
 * Blank
 */
.uk-form-blank {
  background: none;
}

/* Width modifiers (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-xsmall {
  width: 50px;
}

select.uk-form-width-xsmall {
  width: 75px;
}

.uk-form-width-small {
  width: 130px;
}

.uk-form-width-medium {
  width: 200px;
}

.uk-form-width-large {
  width: 500px;
}

/* Select
 ========================================================================== */
/*
 * 1. Remove default style. Also works in Firefox
 * 2. Style
 * 3. Set `color` for options in the select dropdown, because the inherited `color` might be too light.
 */
.uk-select:not([multiple]):not([size]) {
  /* 1 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 2 */
  padding-right: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

/* 3 */
.uk-select:not([multiple]):not([size]) option {
  color: #666;
}

/*
 * Disabled
 */
.uk-select:not([multiple]):not([size]):disabled {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* Datalist
 ========================================================================== */
/*
 * 1. Remove default style in Chrome
 */
.uk-input[list] {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

.uk-input[list]:hover,
.uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* 1 */
.uk-input[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}

/* Radio and checkbox
 ========================================================================== */
/*
 * 1. Style
 * 2. Make box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 * 6. Center icons
 */
.uk-radio,
.uk-checkbox {
  /* 1 */
  display: inline-block;
  height: 16px;
  width: 16px;
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: -4px;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 5 */
  background-color: #ebebeb;
  /* 6 */
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-radio {
  border-radius: 50%;
}

/* Focus */
.uk-radio:focus,
.uk-checkbox:focus {
  background-color: #dfdfdf;
  outline: none;
}

/*
 * Checked
 */
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: #61A6C7;
}

/* Focus */
.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: #408fb5;
}

/*
 * Icons
 */
.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/*
 * Disabled
 */
.uk-radio:disabled,
.uk-checkbox:disabled {
  background-color: #f8f8f8;
}

.uk-radio:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:disabled:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23999%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Legend
 ========================================================================== */
/*
 * Legend
 * 1. Behave like block element
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove padding so people aren't caught out if they zero out fieldsets.
 * 4. Style
 */
.uk-legend {
  /* 1 */
  width: 100%;
  /* 2 */
  color: inherit;
  /* 3 */
  padding: 0;
  /* 4 */
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Custom controls
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 */
.uk-form-custom {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
}

/*
 * 1. Position and resize the form control to always cover its container
 * 2. Required for Firefox for positioning to the left
 * 3. Required for Webkit to make `height` work
 * 4. Hide controle and show cursor
 * 5. Needed for the cursor
 * 6. Clip height caused by 5. Needed for Webkit only
 */
.uk-form-custom select,
.uk-form-custom input[type=file] {
  /* 1 */
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* 2 */
  left: 0;
  /* 3 */
  -webkit-appearance: none;
  /* 4 */
  opacity: 0;
  cursor: pointer;
}

.uk-form-custom input[type=file] {
  /* 5 */
  font-size: 500px;
  /* 6 */
  overflow: hidden;
}

/* Label
 ========================================================================== */
/* Layout
 ========================================================================== */
/*
 * Stacked
 */
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 10px;
}

/*
 * Horizontal
 */
/* Tablet portrait and smaller */
@media (max-width: 959px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 10px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 7px;
    float: left;
  }

  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px;
  }

  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 7px;
  }
}
/* Icons
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set width
 * 3. Center icon vertically and horizontally
 * 4. Style
 */
.uk-form-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 40px;
  /* 3 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 4 */
  color: #999;
}

/*
 * Required for `a`.
 */
.uk-form-icon:hover {
  color: #666;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Input padding
 */
.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
  padding-left: 40px !important;
}

/*
 * Position modifier
 */
.uk-form-icon-flip {
  right: 0;
  left: auto;
}

.uk-form-icon-flip ~ .uk-input {
  padding-right: 40px !important;
}

/* ========================================================================
   Component: Button
 ========================================================================== */
/*
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Address `overflow` set to `hidden` in IE.
 * 4. Correct `font` properties and `color` not being inherited for `button`.
 * 5. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 6. Remove default style for `input type="submit"`in iOS.
 * 7. Style
 * 8. `line-height` is used to create a height because it also centers the text vertically for `a` elements.
 *    Better would be to use height and flexbox to center the text vertically but flexbox doesn't work in Firefox on `button` elements.
 * 9. Align text if button has a width
 * 10. Required for `a`.
 */
.uk-button {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  overflow: visible;
  /* 4 */
  font: inherit;
  color: inherit;
  /* 5 */
  text-transform: none;
  /* 6 */
  -webkit-appearance: none;
  border-radius: 0;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;
  padding: 0 60px;
  vertical-align: middle;
  font-size: 16px;
  /* 8 */
  line-height: 40px;
  /* 9 */
  text-align: center;
  /* 10 */
  text-decoration: none;
}

.uk-button:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Hover */
.uk-button:hover {
  /* 9 */
  text-decoration: none;
}

/* OnClick + Active */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-button-default {
  background-color: #f8f8f8;
  color: #333;
}

/* Hover */
.uk-button-default:hover {
  background-color: #ebebeb;
  color: #333;
}

/* OnClick + Active */
.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: #dfdfdf;
  color: #333;
}

/*
 * Primary
 */
.uk-button-primary {
  background-color: #61A6C7;
  color: #fff;
}

/* Hover */
.uk-button-primary:hover {
  background-color: #4e9bc0;
  color: #fff;
}

/* OnClick + Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #408fb5;
  color: #fff;
}

/*
 * Secondary
 */
.uk-button-secondary {
  background-color: #222;
  color: #fff;
}

/* Hover */
.uk-button-secondary:hover {
  background-color: #151515;
  color: #fff;
}

/* OnClick + Active */
.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: #090909;
  color: #fff;
}

/*
 * Danger
 */
.uk-button-danger {
  background-color: #D60727;
  color: #fff;
}

/* Hover */
.uk-button-danger:hover {
  background-color: #bd0623;
  color: #fff;
}

/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #a5051e;
  color: #fff;
}

/*
 * Disabled
 * The same for all style modifiers
 */
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: #f8f8f8;
  color: #999;
}

/* Size modifiers
 ========================================================================== */
.uk-button-small {
  padding: 0 60px;
  line-height: 30px;
  font-size: 0.875rem;
}

.uk-button-large {
  padding: 0 60;
  line-height: 55px;
  font-size: 1.25rem;
}

/* Text modifiers
 ========================================================================== */
/*
 * Text
 * 1. Reset
 * 2. Style
 */
.uk-button-text {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #333;
}

/* Hover */
.uk-button-text:hover {
  color: #999;
}

/* Disabled */
.uk-button-text:disabled {
  color: #999;
}

/*
 * Link
 * 1. Reset
 * 2. Style
 */
.uk-button-link {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #333;
}

/* Hover */
.uk-button-link:hover {
  color: #999;
  text-decoration: none;
}

/* Disabled */
.uk-button-link:disabled {
  color: #999;
  text-decoration: none;
}

/* Group
 ========================================================================== */
/*
 * 1. Using `flex` instead of `inline-block` to prevent whitespace betweent child elements
 * 2. Behave like button
 * 3. Create position context
 */
.uk-button-group {
  /* 1 */
  display: inline-flex;
  /* 2 */
  vertical-align: middle;
  /* 3 */
  position: relative;
}

/* ========================================================================
   Component: Progress
 ========================================================================== */
/*
 * 1. Add the correct vertical alignment in all browsers.
 * 2. Behave like a block element.
 * 3. Remove borders in Firefox.
 * 4. Remove default style in Chrome, Safari and Edge.
 * 5. Style
 */
.uk-progress {
  /* 1 */
  vertical-align: baseline;
  /* 2 */
  display: block;
  width: 100%;
  /* 3 */
  border: 0;
  /* 4 */
  background-color: #f8f8f8;
  /* 5 */
  margin-bottom: 20px;
  height: 15px;
}

/* Add margin if adjacent element */
* + .uk-progress {
  margin-top: 20px;
}

/*
 * Show background color set on `uk-progress` in Chrome, Safari and Edge.
 */
.uk-progress::-webkit-progress-bar {
  background-color: transparent;
}

/*
 * Progress Bar
 * 1. Transitions don't work on `::-moz-progress-bar` pseudo element in Firefox yet.
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=662351
 */
.uk-progress::-webkit-progress-value {
  background-color: #61A6C7;
  transition: width 0.6s ease;
}

.uk-progress::-moz-progress-bar {
  background-color: #61A6C7;
  /* 1 */
  transition: width 0.6s ease;
}

/* ========================================================================
   Component: Section
 ========================================================================== */
/*
 * 1. Make it work with `100vh` and height in general
 */
.uk-section {
  display: flow-root;
  box-sizing: border-box;
  /* 1 */
  padding-top: 60px;
  padding-bottom: 60px;
}

/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-section > :last-child {
  margin-bottom: 0;
}

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-section-xsmall {
  padding-top: 20px;
  padding-bottom: 20px;
}

/*
 * Small
 */
.uk-section-small {
  padding-top: 60px;
  padding-bottom: 60px;
}

/*
 * Large
 */
.uk-section-large {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-large {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}
/*
 * XLarge
 */
.uk-section-xlarge {
  padding-top: 140px;
  padding-bottom: 140px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-xlarge {
    padding-top: 240px;
    padding-bottom: 240px;
  }
}
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-section-default {
  background: #ffffff;
}

/*
 * Muted
 */
.uk-section-muted {
  background: #f8f8f8;
}

/*
 * Primary
 */
.uk-section-primary {
  background: #61A6C7;
}

/*
 * Secondary
 */
.uk-section-secondary {
  background: #222;
}

/* Overlap modifier
 ========================================================================== */
/*
 * Reserved modifier to make a section overlap another section with an border image
 * Implemented by the theme
 */
/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container {
  display: flow-root;
  /* 1 */
  box-sizing: content-box;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container {
    padding-left: 60;
    padding-right: 60;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-container > :last-child {
  margin-bottom: 0;
}

/*
 * Remove padding from nested containers
 */
.uk-container .uk-container {
  padding-left: 0;
  padding-right: 0;
}

/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 750px;
}

.uk-container-small {
  max-width: 900px;
}

.uk-container-large {
  max-width: 1600px;
}

.uk-container-xlarge {
  max-width: 1600px;
}

.uk-container-expand {
  max-width: none;
}

/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0;
}

.uk-container-expand-right {
  margin-right: 0;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 60px);
  }

  .uk-container-expand-left.uk-container-small,
.uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 60px);
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-expand-left,
.uk-container-expand-right {
    max-width: calc(50% + (1600px / 2) - 60);
  }

  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 60);
  }

  .uk-container-expand-left.uk-container-small,
.uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 60);
  }

  .uk-container-expand-left.uk-container-large,
.uk-container-expand-right.uk-container-large {
    max-width: calc(50% + (1600px / 2) - 60);
  }

  .uk-container-expand-left.uk-container-xlarge,
.uk-container-expand-right.uk-container-xlarge {
    max-width: calc(50% + (1600px / 2) - 60);
  }
}
/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 15px);
}

.uk-container-item-padding-remove-left {
  margin-left: -15px;
}

.uk-container-item-padding-remove-right {
  margin-right: -15px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 60px);
  }

  .uk-container-item-padding-remove-left {
    margin-left: -60px;
  }

  .uk-container-item-padding-remove-right {
    margin-right: -60px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 60);
  }

  .uk-container-item-padding-remove-left {
    margin-left: -60;
  }

  .uk-container-item-padding-remove-right {
    margin-right: -60;
  }
}
/* ========================================================================
   Component: Tile
 ========================================================================== */
.uk-tile {
  display: flow-root;
  position: relative;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 60px;
  padding-bottom: 60px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-tile {
    padding-left: 60px;
    padding-right: 60px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile {
    padding-left: 60;
    padding-right: 60;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-tile > :last-child {
  margin-bottom: 0;
}

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-tile-xsmall {
  padding-top: 20px;
  padding-bottom: 20px;
}

/*
 * Small
 */
.uk-tile-small {
  padding-top: 60px;
  padding-bottom: 60px;
}

/*
 * Large
 */
.uk-tile-large {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile-large {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}
/*
 * XLarge
 */
.uk-tile-xlarge {
  padding-top: 140px;
  padding-bottom: 140px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile-xlarge {
    padding-top: 240px;
    padding-bottom: 240px;
  }
}
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-tile-default {
  background-color: #ffffff;
}

/*
 * Muted
 */
.uk-tile-muted {
  background-color: #f8f8f8;
}

/*
 * Primary
 */
.uk-tile-primary {
  background-color: #61A6C7;
}

/*
 * Secondary
 */
.uk-tile-secondary {
  background-color: #222;
}

/* ========================================================================
   Component: Card
 ========================================================================== */
.uk-card {
  position: relative;
  box-sizing: border-box;
}

/* Sections
 ========================================================================== */
.uk-card-body {
  display: flow-root;
  padding: 60px 60px;
}

.uk-card-header {
  display: flow-root;
  padding: 30px 60px;
}

.uk-card-footer {
  display: flow-root;
  padding: 30px 60px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-body {
    padding: 60 60;
  }

  .uk-card-header {
    padding: 30 60;
  }

  .uk-card-footer {
    padding: 30 60;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-card-body > :last-child,
.uk-card-header > :last-child,
.uk-card-footer > :last-child {
  margin-bottom: 0;
}

/* Media
 ========================================================================== */
/*
 * Reserved alignment modifier to style the media element, e.g. with `border-radius`
 * Implemented by the theme
 */
/* Title
 ========================================================================== */
.uk-card-title {
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Badge
 ========================================================================== */
/*
 * 1. Position
 * 2. Size
 * 3. Style
 * 4. Center child vertically
 */
.uk-card-badge {
  /* 1 */
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  /* 2 */
  height: 22px;
  padding: 0 10px;
  /* 3 */
  background: #61A6C7;
  color: #fff;
  font-size: 0.875rem;
  /* 4 */
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

/*
 * Remove margin from adjacent element
 */
.uk-card-badge:first-child + * {
  margin-top: 0;
}

/* Hover modifier
 ========================================================================== */
.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
  background-color: #f8f8f8;
}

/* Style modifiers
 ========================================================================== */
/*
 * Default
 * Note: Header and Footer are only implemented for the default style
 */
.uk-card-default {
  background-color: #f8f8f8;
  color: #666;
}

.uk-card-default .uk-card-title {
  color: #333;
}

.uk-card-default.uk-card-hover:hover {
  background-color: #ebebeb;
}

/*
 * Primary
 */
.uk-card-primary {
  background-color: #61A6C7;
  color: #fff;
}

.uk-card-primary .uk-card-title {
  color: #fff;
}

.uk-card-primary.uk-card-hover:hover {
  background-color: #4e9bc0;
}

/*
 * Secondary
 */
.uk-card-secondary {
  background-color: #222;
  color: #fff;
}

.uk-card-secondary .uk-card-title {
  color: #fff;
}

.uk-card-secondary.uk-card-hover:hover {
  background-color: #151515;
}

/* Size modifier
 ========================================================================== */
/*
 * Small
 */
.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
  padding: 20px 20px;
}

.uk-card-small .uk-card-header {
  padding: 13px 20px;
}

.uk-card-small .uk-card-footer {
  padding: 13px 20px;
}

/*
 * Large
 */
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-large.uk-card-body,
.uk-card-large .uk-card-body {
    padding: 60 60;
  }

  .uk-card-large .uk-card-header {
    padding: 30 60;
  }

  .uk-card-large .uk-card-footer {
    padding: 30 60;
  }
}
/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999;
}

/* Hover */
.uk-close:hover {
  color: #666;
}

/* ========================================================================
   Component: Spinner
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
/* SVG
 ========================================================================== */
.uk-spinner > * {
  animation: uk-spinner-rotate 1.4s linear infinite;
}

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
/*
 * Circle
 */
.uk-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round;
}

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px;
  }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}
/* ========================================================================
   Component: Totop
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-totop {
  padding: 5px;
  color: #999;
}

/* Hover */
.uk-totop:hover {
  color: #666;
}

/* OnClick */
.uk-totop:active {
  color: #333;
}

/* ========================================================================
   Component: Marker
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-marker {
  padding: 5px;
  background: #222;
  color: #fff;
}

/* Hover */
.uk-marker:hover {
  color: #fff;
}

/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #666;
}

/* Add margin if adjacent element */
* + .uk-alert {
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px;
}

/*
 * Remove margin from adjacent element
 */
.uk-alert-close:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-alert-primary {
  background: #ebf4f8;
  color: #61A6C7;
}

/*
 * Success
 */
.uk-alert-success {
  background: #d6f0db;
  color: #1eaf3a;
}

/*
 * Warning
 */
.uk-alert-warning {
  background: #ffd9c0;
  color: #ff6601;
}

/*
 * Danger
 */
.uk-alert-danger {
  background: #f5c1c9;
  color: #D60727;
}

/* ========================================================================
   Component: Placeholder
 ========================================================================== */
.uk-placeholder {
  margin-bottom: 20px;
  padding: 60px 60px;
  background: #f8f8f8;
}

/* Add margin if adjacent element */
* + .uk-placeholder {
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-placeholder > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Badge
 ========================================================================== */
/*
 * 1. Style
 * 2. Center child vertically and horizontally
 */
.uk-badge {
  box-sizing: border-box;
  min-width: 18px;
  height: 18px;
  padding: 0 5px;
  border-radius: 500px;
  vertical-align: middle;
  /* 1 */
  background: #61A6C7;
  color: #fff !important;
  font-size: 11px;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

/*
 * Required for `a`
 */
.uk-badge:hover {
  text-decoration: none;
}

/* ========================================================================
   Component: Label
 ========================================================================== */
.uk-label {
  display: inline-block;
  padding: 0 10px;
  background: #61A6C7;
  line-height: 1.5;
  font-size: 0.875rem;
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
}

/* Color modifiers
 ========================================================================== */
/*
 * Success
 */
.uk-label-success {
  background-color: #1eaf3a;
  color: #fff;
}

/*
 * Warning
 */
.uk-label-warning {
  background-color: #ff6601;
  color: #fff;
}

/*
 * Danger
 */
.uk-label-danger {
  background-color: #D60727;
  color: #fff;
}

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.uk-overlay {
  padding: 60px 60px;
}

/*
 * Remove margin from the last-child
 */
.uk-overlay > :last-child {
  margin-bottom: 0;
}

/* Icon
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-overlay-default {
  background: rgba(255, 255, 255, 0.8);
}

/*
 * Primary
 */
.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.8);
}

/* ========================================================================
   Component: Article
 ========================================================================== */
.uk-article {
  display: flow-root;
}

/*
 * Remove margin from the last-child
 */
.uk-article > :last-child {
  margin-bottom: 0;
}

/* Adjacent sibling
 ========================================================================== */
.uk-article + .uk-article {
  margin-top: 100px;
}

/* Title
 ========================================================================== */
.uk-article-title {
  font-size: 2.23125rem;
  line-height: 1.2;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-article-title {
    font-size: 2.625rem;
  }
}
/* Meta
 ========================================================================== */
.uk-article-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}

/* ========================================================================
   Component: Comment
 ========================================================================== */
/* Sections
 ========================================================================== */
.uk-comment-body {
  display: flow-root;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.uk-comment-header {
  display: flow-root;
  margin-bottom: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-comment-body > :last-child,
.uk-comment-header > :last-child {
  margin-bottom: 0;
}

/* Title
 ========================================================================== */
.uk-comment-title {
  font-size: 1.25rem;
  line-height: 1.4;
}

/* Meta
 ========================================================================== */
.uk-comment-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}

/* Avatar
 ========================================================================== */
/* List
 ========================================================================== */
.uk-comment-list {
  padding: 0;
  list-style: none;
}

/* Adjacent siblings */
.uk-comment-list > :nth-child(n+2) {
  margin-top: 100px;
}

/*
 * Sublists
 * Note: General sibling selector allows reply block between comment and sublist
 */
.uk-comment-list .uk-comment ~ ul {
  margin: 100px 0 0 0;
  padding-left: 30px;
  list-style: none;
}

/* Tablet and bigger */
@media (min-width: 960px) {
  .uk-comment-list .uk-comment ~ ul {
    padding-left: 100px;
  }
}
/* Adjacent siblings */
.uk-comment-list .uk-comment ~ ul > :nth-child(n+2) {
  margin-top: 100px;
}

/* Style modifier
 ========================================================================== */
/* ========================================================================
   Component: Search
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Reset `form`
 */
.uk-search {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  margin: 0;
}

/* Input
 ========================================================================== */
/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-search-input::-webkit-search-cancel-button,
.uk-search-input::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-search-input::-moz-placeholder {
  opacity: 1;
}

/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers
 * 5. Show the overflow in Edge.
 * 6. Remove default style in iOS.
 * 7. Vertical alignment
 * 8. Take the full container width
 * 9. Style
 */
.uk-search-input {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  /* 5 */
  overflow: visible;
  /* 6 */
  -webkit-appearance: none;
  /* 7 */
  vertical-align: middle;
  /* 8 */
  width: 100%;
  /* 9 */
  border: none;
  color: #666;
}

.uk-search-input:focus {
  outline: none;
}

/* Placeholder */
.uk-search-input::placeholder {
  color: #999;
}

/* Icon (Adopts `uk-icon`)
 ========================================================================== */
/*
 * Position above input
 * 1. Set position
 * 2. Center icon vertically and horizontally
 * 3. Style
 */
.uk-search .uk-search-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: #999;
}

/*
 * Required for `a`.
 */
.uk-search .uk-search-icon:hover {
  color: #999;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-search .uk-search-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Position modifier
 */
.uk-search .uk-search-icon-flip {
  right: 0;
  left: auto;
}

/* Default modifier
 ========================================================================== */
.uk-search-default {
  width: 240px;
}

/*
 * Input
 */
.uk-search-default .uk-search-input {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  background: #f8f8f8;
}

/* Focus */
.uk-search-default .uk-search-input:focus {
  background-color: #ebebeb;
}

/*
 * Icon
 */
.uk-search-default .uk-search-icon {
  width: 40px;
}

.uk-search-default .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px;
}

.uk-search-default .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px;
}

/* Navbar modifier
 ========================================================================== */
.uk-search-navbar {
  width: 400px;
}

/*
 * Input
 */
.uk-search-navbar .uk-search-input {
  height: 40px;
  background: transparent;
  font-size: 1.5rem;
}

/* Focus */
/*
 * Icon
 */
.uk-search-navbar .uk-search-icon {
  width: 40px;
}

.uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px;
}

.uk-search-navbar .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px;
}

/* Large modifier
 ========================================================================== */
.uk-search-large {
  width: 500px;
}

/*
 * Input
 */
.uk-search-large .uk-search-input {
  height: 80px;
  background: transparent;
  font-size: 2.625rem;
}

/* Focus */
/*
 * Icon
 */
.uk-search-large .uk-search-icon {
  width: 80px;
}

.uk-search-large .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 80px;
}

.uk-search-large .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 80px;
}

/* Toggle
 ========================================================================== */
.uk-search-toggle {
  color: #999;
}

/* Hover */
.uk-search-toggle:hover {
  color: #666;
}

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none;
}

/* Item
 ========================================================================== */
.uk-accordion > :nth-child(n+2) {
  margin-top: 20px;
}

/* Title
 ========================================================================== */
.uk-accordion-title {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333;
}

/* Hover */
.uk-accordion-title:hover {
  color: #666;
  text-decoration: none;
}

/* Content
 ========================================================================== */
.uk-accordion-content {
  display: flow-root;
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Drop
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 */
.uk-drop {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 20px;
  /* 3 */
  box-sizing: border-box;
  width: 300px;
  max-width: 100vw;
}

/* Show */
.uk-drop.uk-open {
  display: block;
}

/* Grid modifiers
 ========================================================================== */
.uk-drop-stack .uk-drop-grid > * {
  width: 100% !important;
}

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 10px;
  /* 3 */
  box-sizing: border-box;
  min-width: 200px;
  max-width: 100vw;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #666;
}

/* Show */
.uk-dropdown.uk-open {
  display: block;
}

/* Nav
 * Adopts `uk-nav`
 ========================================================================== */
.uk-dropdown-nav {
  white-space: nowrap;
}

/*
 * Items
 */
.uk-dropdown-nav > li > a {
  color: #999;
}

/* Hover + Active */
.uk-dropdown-nav > li > a:hover,
.uk-dropdown-nav > li.uk-active > a {
  color: #666;
}

/*
 * Header
 */
.uk-dropdown-nav .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e8e8e8;
}

/*
 * Sublists
 */
.uk-dropdown-nav .uk-nav-sub a {
  color: #999;
}

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #666;
}

/* Grid modifiers
 ========================================================================== */
.uk-dropdown-stack .uk-dropdown-grid > * {
  width: 100% !important;
}

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 60px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 60;
    padding-right: 60;
  }
}
/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1;
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden;
}

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. `!important` is needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: 100% !important;
  /* 4 */
  background: #ffffff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform;
}

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0);
}

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px;
}

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none;
}

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0);
}

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 60px 60px;
}

.uk-modal-header {
  display: flow-root;
  padding: 30px 60px;
  background: #f8f8f8;
}

.uk-modal-footer {
  display: flow-root;
  padding: 30px 60px;
  background: #f8f8f8;
}

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0;
}

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.3;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*=uk-modal-close-] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px;
}

/*
 * Remove margin from adjacent element
 */
[class*=uk-modal-close-]:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white;
}

.uk-modal-close-outside:hover {
  color: #fff;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%);
  }
}
/*
 * Full
 */
/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slideshow {
  /* 1 */
  -webkit-tap-highlight-color: transparent;
}

/* Items
 ========================================================================== */
/*
 * 1. Create position and stacking context
 * 2. Reset list
 * 3. Clip child elements
 * 4. Prevent displaying the callout information on iOS.
 * 5. Disable horizontal panning gestures
 */
.uk-slideshow-items {
  /* 1 */
  position: relative;
  z-index: 0;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  overflow: hidden;
  /* 4 */
  -webkit-touch-callout: none;
  /* 5 */
  touch-action: pan-y;
}

/* Item
 ========================================================================== */
/*
 * 1. Position items above each other
 * 2. Take the full width
 * 3. Clip child elements, e.g. for `uk-cover`
 * 4. Optimize animation
 */
.uk-slideshow-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  right: 0;
  bottom: 0;
  /* 3 */
  overflow: hidden;
  /* 4 */
  will-change: transform, opacity;
}

/*
 * Hide not active items
 */
.uk-slideshow-items > :not(.uk-active) {
  display: none;
}

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slider {
  /* 1 */
  -webkit-tap-highlight-color: transparent;
}

/* Container
 ========================================================================== */
/*
 * Clip child elements
 */
.uk-slider-container {
  overflow: hidden;
}

/*
 * Widen container to prevent box-shadows from clipping, `large-box-shadow`
 */
.uk-slider-container-offset {
  margin: -11px -25px -39px -25px;
  padding: 11px 25px 39px 25px;
}

/* Items
 ========================================================================== */
/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 * 3. Disable horizontal panning gestures
 */
.uk-slider-items {
  /* 1 */
  will-change: transform;
  /* 2 */
  position: relative;
  /* 3 */
  touch-action: pan-y;
}

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */
.uk-slider-items:not(.uk-grid) {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 2 */
  -webkit-touch-callout: none;
}

.uk-slider-items.uk-grid {
  flex-wrap: nowrap;
}

/* Item
 ========================================================================== */
/*
 * 1. Let items take content dimensions (0 0 auto)
 *    `max-width` needed to keep image responsiveness and prevent content overflow
 * 3. Create position context
 */
.uk-slider-items > * {
  /* 1 */
  flex: none;
  max-width: 100%;
  /* 3 */
  position: relative;
}

/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * Create position context so it's t the same like when fixed.
 */
.uk-sticky {
  position: relative;
}

/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */
.uk-sticky-fixed {
  z-index: 980;
  box-sizing: border-box;
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/*
 * Faster animations
 */
.uk-sticky[class*=uk-animation-] {
  animation-duration: 0.2s;
}

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s;
}

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto;
}

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  /* 2 */
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #fff;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 60 60;
  }
}
/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px;
  }
}
/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0;
}

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0;
}

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out;
}

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right;
}

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out;
}

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0;
}

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0;
}

.uk-open > .uk-offcanvas-reveal {
  width: 270px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px;
  }
}
/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 5px;
  right: 5px;
  padding: 5px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-close {
    top: 20px;
    right: 20px;
  }
}
/*
 * Remove margin from adjacent element
 */
.uk-offcanvas-close:first-child + * {
  margin-top: 0;
}

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none;
}

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1;
}

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden;
}

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%;
}

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px;
}

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px;
  }

  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px;
  }
}
/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * Reset list
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Items
 ========================================================================== */
/*
 * Hide not active items
 */
.uk-switcher > :not(.uk-active) {
  display: none;
}

/*
 * Remove margin from the last-child
 */
.uk-switcher > * > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Leader
 ========================================================================== */
.uk-leader {
  overflow: hidden;
}

/*
 * 1. Place element in text flow
 * 2. Never break into a new line
 * 3. Get a string back with as many repeating characters to fill the container
 * 4. Prevent wrapping. Overflowing characters will be clipped by the container
 */
.uk-leader-fill::after {
  /* 1 */
  display: inline-block;
  margin-left: 60px;
  /* 2 */
  width: 0;
  /* 3 */
  content: attr(data-fill);
  /* 4 */
  white-space: nowrap;
}

/*
 * Hide if media does not match
 */
.uk-leader-fill.uk-leader-hide::after {
  display: none;
}

/*
 * Pass fill character to JS
 */
:root {
  --uk-leader-fill-content: ".";
}

/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px;
}

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px;
}

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px;
}

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px;
}

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0;
  }
}
/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 60px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer;
}

* + .uk-notification-message {
  margin-top: 10px;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 65px;
  right: 60px;
}

.uk-notification-message:hover .uk-notification-close {
  display: block;
}

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #61A6C7;
}

/*
 * Success
 */
.uk-notification-message-success {
  color: #1eaf3a;
}

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #ff6601;
}

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #D60727;
}

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  --uk-position-offset: 10px;
  /* 3 */
  top: 0;
  /* 4 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 3px 6px;
  /* 5 */
  background: #666;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
}

/* Show */
.uk-tooltip.uk-active {
  display: block;
}

/* ========================================================================
   Component: Sortable
 ========================================================================== */
.uk-sortable {
  position: relative;
}

/*
 * Remove margin from the last-child
 */
.uk-sortable > :last-child {
  margin-bottom: 0;
}

/* Drag
 ========================================================================== */
.uk-sortable-drag {
  position: fixed !important;
  z-index: 1050 !important;
  pointer-events: none;
}

/* Placeholder
 ========================================================================== */
.uk-sortable-placeholder {
  opacity: 0;
  pointer-events: none;
}

/* Empty modifier
 ========================================================================== */
.uk-sortable-empty {
  min-height: 50px;
}

/* Handle
 ========================================================================== */
/* Hover */
.uk-sortable-handle:hover {
  cursor: move;
}

/* ========================================================================
   Component: Countdown
 ========================================================================== */
/* Item
 ========================================================================== */
/* Number
 ========================================================================== */
/*
 * 1. Make numbers all of the same size to prevent jumping. Must be supported by the font.
 * 2. Style
 */
.uk-countdown-number {
  /* 1 */
  font-variant-numeric: tabular-nums;
  /* 2 */
  font-size: 2rem;
  line-height: 0.8;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-countdown-number {
    font-size: 4rem;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-countdown-number {
    font-size: 6rem;
  }
}
/* Separator
 ========================================================================== */
.uk-countdown-separator {
  font-size: 1rem;
  line-height: 1.6;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-countdown-separator {
    font-size: 2rem;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-countdown-separator {
    font-size: 3rem;
  }
}
/* Label
 ========================================================================== */
/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0;
}

/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0;
}

/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -60px;
}

.uk-grid > * {
  padding-left: 60px;
}

/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 60px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -60;
  }

  .uk-grid > * {
    padding-left: 60;
  }

  /* Vertical */
  .uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
    margin-top: 60;
  }
}
/*
 * Small
 */
/* Horizontal */
.uk-grid-small,
.uk-grid-column-small {
  margin-left: -24px;
}

.uk-grid-small > *,
.uk-grid-column-small > * {
  padding-left: 24px;
}

/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid + .uk-grid-row-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 24px;
}

/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium,
.uk-grid-column-medium {
  margin-left: -20px;
}

.uk-grid-medium > *,
.uk-grid-column-medium > * {
  padding-left: 20px;
}

/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid + .uk-grid-row-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-row-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 40px;
}

/*
 * Large
 */
/* Horizontal */
.uk-grid-large,
.uk-grid-column-large {
  margin-left: -60px;
}

.uk-grid-large > *,
.uk-grid-column-large > * {
  padding-left: 60px;
}

/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 60px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large,
.uk-grid-column-large {
    margin-left: -60;
  }

  .uk-grid-large > *,
.uk-grid-column-large > * {
    padding-left: 60;
  }

  /* Vertical */
  .uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
    margin-top: 60;
  }
}
/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse,
.uk-grid-column-collapse {
  margin-left: 0;
}

.uk-grid-collapse > *,
.uk-grid-column-collapse > * {
  padding-left: 0;
}

/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid + .uk-grid-row-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 0;
}

/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative;
}

.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e8e8e8;
}

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e8e8e8;
}

/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -120px;
}

.uk-grid-divider > * {
  padding-left: 120px;
}

.uk-grid-divider > :not(.uk-first-column)::before {
  left: 60px;
}

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 120px;
}

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -60px;
  left: 120px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -120;
  }

  .uk-grid-divider > * {
    padding-left: 120;
  }

  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 60;
  }

  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 120;
  }

  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -60;
    left: 120;
  }
}
/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small,
.uk-grid-divider.uk-grid-column-small {
  margin-left: -48px;
}

.uk-grid-divider.uk-grid-small > *,
.uk-grid-divider.uk-grid-column-small > * {
  padding-left: 48px;
}

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before {
  left: 24px;
}

/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 48px;
}

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -24px;
  left: 48px;
}

.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
  top: -24px;
}

.uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
  left: 48px;
}

/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium,
.uk-grid-divider.uk-grid-column-medium {
  margin-left: -40px;
}

.uk-grid-divider.uk-grid-medium > *,
.uk-grid-divider.uk-grid-column-medium > * {
  padding-left: 40px;
}

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before {
  left: 20px;
}

/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px;
}

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 40px;
}

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
}

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
  left: 40px;
}

/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
  margin-left: -120px;
}

.uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
  padding-left: 120px;
}

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
  left: 60px;
}

/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 120px;
}

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -60px;
  left: 120px;
}

.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
  top: -60px;
}

.uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
  left: 120px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
    margin-left: -120;
  }

  .uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
    padding-left: 120;
  }

  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
    left: 60;
  }

  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 120;
  }

  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -60;
    left: 120;
  }

  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
    top: -60;
  }

  .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
    left: 120;
  }
}
/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
}

.uk-grid-match > * > :not([class*=uk-width]),
.uk-grid-item-match > :not([class*=uk-width]) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto;
}

/* ========================================================================
   Component: Nav
 ========================================================================== */
/*
 * Reset
 */
.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
* 1. Center content vertically, e.g. an icon
* 2. Imitate white space gap when using flexbox
* 3. Reset link
 */
.uk-nav li > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3*/
  text-decoration: none;
}

/*
 * Items
 * Must target `a` elements to exclude other elements (e.g. lists)
 */
.uk-nav > li > a {
  padding: 5px 0;
}

/* Sublists
 ========================================================================== */
/*
 * Level 2
 * `ul` needed for higher specificity to override padding
 */
ul.uk-nav-sub {
  padding: 5px 0 5px 15px;
}

/*
 * Level 3 and deeper
 */
.uk-nav-sub ul {
  padding-left: 15px;
}

/*
 * Items
 */
.uk-nav-sub a {
  padding: 2px 0;
}

/* Parent icon modifier
 ========================================================================== */
.uk-nav-parent-icon > .uk-parent > a::after {
  content: "";
  width: 1.5em;
  height: 1.5em;
  margin-left: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-nav-parent-icon > .uk-parent.uk-open > a::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Header
 ========================================================================== */
.uk-nav-header {
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.uk-nav-header:not(:first-child) {
  margin-top: 20px;
}

/* Divider
 ========================================================================== */
.uk-nav .uk-nav-divider {
  margin: 5px 0;
}

/* Default modifier
 ========================================================================== */
/*
 * Items
 */
.uk-nav-default > li > a {
  color: #999;
}

/* Hover */
.uk-nav-default > li > a:hover {
  color: #666;
}

/* Active */
.uk-nav-default > li.uk-active > a {
  color: #333;
}

/*
 * Header
 */
.uk-nav-default .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-nav-default .uk-nav-divider {
  border-top: 1px solid #e8e8e8;
}

/*
 * Sublists
 */
.uk-nav-default .uk-nav-sub a {
  color: #999;
}

.uk-nav-default .uk-nav-sub a:hover {
  color: #666;
}

.uk-nav-default .uk-nav-sub li.uk-active > a {
  color: #333;
}

/* Primary modifier
 ========================================================================== */
/*
 * Items
 */
.uk-nav-primary > li > a {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #999;
}

/* Hover */
.uk-nav-primary > li > a:hover {
  color: #666;
}

/* Active */
.uk-nav-primary > li.uk-active > a {
  color: #333;
}

/*
 * Header
 */
.uk-nav-primary .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-nav-primary .uk-nav-divider {
  border-top: 1px solid #e8e8e8;
}

/*
 * Sublists
 */
.uk-nav-primary .uk-nav-sub a {
  color: #999;
}

.uk-nav-primary .uk-nav-sub a:hover {
  color: #666;
}

.uk-nav-primary .uk-nav-sub li.uk-active > a {
  color: #333;
}

/* Alignment modifier
 ========================================================================== */
/*
 * 1. Center header
 * 2. Center items
 */
/* 1 */
.uk-nav-center {
  text-align: center;
}

/* 2 */
.uk-nav-center li > a {
  justify-content: center;
}

/* Sublists */
.uk-nav-center .uk-nav-sub,
.uk-nav-center .uk-nav-sub ul {
  padding-left: 0;
}

/* Parent icon modifier  */
.uk-nav-center.uk-nav-parent-icon > .uk-parent > a::after {
  margin-left: 0;
}

/* Style modifier
 ========================================================================== */
.uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
  margin-top: 0;
  padding-top: 0;
  border-top: 1px solid #e8e8e8;
}

/* ========================================================================
   Component: Navbar
 ========================================================================== */
/*
 * 1. Create position context to center navbar group
 */
.uk-navbar {
  display: flex;
  /* 1 */
  position: relative;
}

/* Container
 ========================================================================== */
.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #f8f8f8;
}

/* Groups
 ========================================================================== */
/*
 * 1. Align navs and items vertically if they have a different height
 * 2. Note: IE 11 requires an extra `div` which affects the center selector
 */
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center,
.uk-navbar-center-left > *,
.uk-navbar-center-right > * {
  display: flex;
  /* 1 */
  align-items: center;
}

/*
 * Horizontal alignment
 * 1. Create position context for centered navbar with sub groups (left/right)
 * 2. Fix text wrapping if content is larger than 50% of the container.
 * 3. Needed for dropdowns because a new position context is created
 *    `z-index` must be smaller than off-canvas
 * 4. Align sub groups for centered navbar
 */
.uk-navbar-right {
  margin-left: auto;
}

.uk-navbar-center:only-child {
  margin-left: auto;
  margin-right: auto;
  /* 1 */
  position: relative;
}

.uk-navbar-center:not(:only-child) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 2 */
  width: max-content;
  box-sizing: border-box;
  /* 3 */
  z-index: 990;
}

/* 4 */
.uk-navbar-center-left,
.uk-navbar-center-right {
  position: absolute;
  top: 0;
}

.uk-navbar-center-left {
  right: 100%;
}

.uk-navbar-center-right {
  left: 100%;
}

[class*=uk-navbar-center-] {
  width: max-content;
  box-sizing: border-box;
}

/* Nav
 ========================================================================== */
/*
 * 1. Reset list
 */
.uk-navbar-nav {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
 * Allow items to wrap into the next line
 * Only not `absolute` positioned groups
 */
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center:only-child {
  flex-wrap: wrap;
}

/*
 * Items
 * 1. Center content vertically and horizontally
 * 2. Imitate white space gap when using flexbox
 * 3. Dimensions
 * 4. Style
 * 5. Required for `a`
 */
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  /* 1 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  box-sizing: border-box;
  min-height: 80px;
  padding: 0 15px;
  /* 4 */
  font-size: 16px;
  font-family: myriad-pro, sans-serif;
  /* 5 */
  text-decoration: none;
}

/*
 * Nav items
 */
.uk-navbar-nav > li > a {
  color: #999;
}

/*
 * Hover
 * Apply hover style also if dropdown is opened
 */
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a[aria-expanded=true] {
  color: #666;
}

/* OnClick */
.uk-navbar-nav > li > a:active {
  color: #333;
}

/* Active */
.uk-navbar-nav > li.uk-active > a {
  color: #333;
}

/* Item
 ========================================================================== */
.uk-navbar-item {
  color: #666;
}

/*
 * Remove margin from the last-child
 */
.uk-navbar-item > :last-child {
  margin-bottom: 0;
}

/* Toggle
 ========================================================================== */
.uk-navbar-toggle {
  color: #999;
}

.uk-navbar-toggle:hover,
.uk-navbar-toggle[aria-expanded=true] {
  color: #666;
  text-decoration: none;
}

/*
 * Icon
 * Adopts `uk-icon`
 */
/* Hover */
/* Subtitle
 ========================================================================== */
.uk-navbar-subtitle {
  font-size: 0.875rem;
}

/* Style modifiers
 ========================================================================== */
/* Dropdown
 ========================================================================== */
/*
 * Adopts `uk-dropdown`
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-navbar-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 0px;
  /* 3 */
  box-sizing: border-box;
  width: 200px;
  max-width: 100vw;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #666;
}

/* Show */
.uk-navbar-dropdown.uk-open {
  display: block;
}

/*
 * Grid
 * Adopts `uk-grid`
 */
/* Gutter Horizontal */
.uk-navbar-dropdown-grid {
  margin-left: -60px;
}

.uk-navbar-dropdown-grid > * {
  padding-left: 60px;
}

/* Gutter Vertical */
.uk-navbar-dropdown-grid > .uk-grid-margin {
  margin-top: 60px;
}

/* Stack */
.uk-navbar-dropdown-stack .uk-navbar-dropdown-grid > * {
  width: 100% !important;
}

/*
 * Width modifier
 */
.uk-navbar-dropdown-width-2:not(.uk-navbar-dropdown-stack) {
  width: 400px;
}

.uk-navbar-dropdown-width-3:not(.uk-navbar-dropdown-stack) {
  width: 600px;
}

.uk-navbar-dropdown-width-4:not(.uk-navbar-dropdown-stack) {
  width: 800px;
}

.uk-navbar-dropdown-width-5:not(.uk-navbar-dropdown-stack) {
  width: 1000px;
}

/*
 * Dropbar modifier
 * 1. Set position
 * 2. Bottom padding for dropbar
 * 3. Horizontal padding
 */
.uk-navbar-dropdown-dropbar {
  /* 1 */
  --uk-position-offset: 0px;
  /* 2 */
  margin-bottom: 0px;
  padding: 15px;
}

/* Dropdown Nav
 * Adopts `uk-nav`
 ========================================================================== */
/*
 * Items
 */
.uk-navbar-dropdown-nav > li > a {
  color: #999;
}

/* Hover */
.uk-navbar-dropdown-nav > li > a:hover {
  color: #666;
}

/* Active */
.uk-navbar-dropdown-nav > li.uk-active > a {
  color: #333;
}

/*
 * Header
 */
.uk-navbar-dropdown-nav .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-navbar-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e8e8e8;
}

/*
 * Sublists
 */
.uk-navbar-dropdown-nav .uk-nav-sub a {
  color: #999;
}

.uk-navbar-dropdown-nav .uk-nav-sub a:hover {
  color: #666;
}

.uk-navbar-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #333;
}

/* Dropbar
 ========================================================================== */
.uk-navbar-dropbar {
  position: absolute;
  z-index: 980;
  left: 0;
  right: 0;
  background: #f8f8f8;
}

/* ========================================================================
   Component: Subnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Center items vertically if they have a different height
 * 3. Gutter
 * 4. Reset list
 */
.uk-subnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  align-items: center;
  /* 3 */
  margin-left: -20px;
  /* 4 */
  padding: 0;
  list-style: none;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-subnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * Using `:first-child` instead of `a` to support `span` elements for text
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Style
 */
.uk-subnav > * > :first-child {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  color: #999;
}

/* Hover */
.uk-subnav > * > a:hover {
  color: #666;
  text-decoration: none;
}

/* Active */
.uk-subnav > .uk-active > a {
  color: #333;
}

/* Divider modifier
 ========================================================================== */
/*
 * Set gutter
 */
.uk-subnav-divider {
  margin-left: -41px;
}

/*
 * Align items and divider vertically
 */
.uk-subnav-divider > * {
  display: flex;
  align-items: center;
}

/*
 * Divider
 * 1. `nth-child` makes it also work without JS if it's only one row
 */
.uk-subnav-divider > ::before {
  content: "";
  height: 1.5em;
  margin-left: 0px;
  margin-right: 20px;
  border-left: 1px solid transparent;
}

/* 1 */
.uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: #e8e8e8;
}

/* Pill modifier
 ========================================================================== */
.uk-subnav-pill > * > :first-child {
  padding: 5px 10px;
  background: transparent;
  color: #999;
}

/* Hover */
.uk-subnav-pill > * > a:hover {
  background-color: #f8f8f8;
  color: #666;
}

/* OnClick */
.uk-subnav-pill > * > a:active {
  background-color: #f8f8f8;
  color: #666;
}

/* Active */
.uk-subnav-pill > .uk-active > a {
  background-color: #61A6C7;
  color: #fff;
}

/* Disabled
 * The same for all style modifiers
 ========================================================================== */
.uk-subnav > .uk-disabled > a {
  color: #999;
}

/* ========================================================================
   Component: Breadcrumb
 ========================================================================== */
/*
 * Reset list
 */
.uk-breadcrumb {
  padding: 0;
  list-style: none;
}

/*
 * 1. Doesn't generate any box and replaced by child boxes
 */
.uk-breadcrumb > * {
  display: contents;
}

/* Items
 ========================================================================== */
.uk-breadcrumb > * > * {
  font-size: 0.875rem;
  color: #999;
}

/* Hover */
.uk-breadcrumb > * > :hover {
  color: #666;
  text-decoration: none;
}

/* Disabled */
/* Active */
.uk-breadcrumb > :last-child > span,
.uk-breadcrumb > :last-child > a:not([href]) {
  color: #666;
}

/*
 * Divider
 * `nth-child` makes it also work without JS if it's only one row
 * 1. Remove space between inline block elements.
 * 2. Style
 */
.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  content: "/";
  display: inline-block;
  /* 1 */
  margin: 0 20px 0 calc(20px - 4px);
  /* 2 */
  font-size: 0.875rem;
  color: #999;
}

/* ========================================================================
   Component: Pagination
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-pagination {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: 0;
  /* 3 */
  padding: 0;
  list-style: none;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-pagination > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 0;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */
.uk-pagination > * > * {
  /* 1 */
  display: block;
  /* 2 */
  padding: 5px 10px;
  color: #999;
}

/* Hover */
.uk-pagination > * > :hover {
  color: #666;
  text-decoration: none;
}

/* Active */
.uk-pagination > .uk-active > * {
  color: #666;
}

/* Disabled */
.uk-pagination > .uk-disabled > * {
  color: #999;
}

/* ========================================================================
   Component: Tab
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-tab {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-tab > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Center content if a width is set
 * 4. Style
 */
.uk-tab > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  justify-content: center;
  /* 4 */
  padding: 5px 10px;
  color: #999;
}

/* Hover */
.uk-tab > * > a:hover {
  color: #666;
  text-decoration: none;
}

/* Active */
.uk-tab > .uk-active > a {
  color: #333;
}

/* Disabled */
.uk-tab > .uk-disabled > a {
  color: #999;
}

/* Position modifier
 ========================================================================== */
/*
 * Bottom
 */
/*
 * Left + Right
 * 1. Reset Gutter
 */
.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  /* 1 */
  margin-left: 0;
}

/* 1 */
.uk-tab-left > *,
.uk-tab-right > * {
  padding-left: 0;
}

.uk-tab-left > * > a {
  justify-content: left;
}

.uk-tab-right > * > a {
  justify-content: left;
}

/* ========================================================================
   Component: Slidenav
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-slidenav {
  padding: 5px 10px;
  color: rgba(102, 102, 102, 0.5);
}

/* Hover */
.uk-slidenav:hover {
  color: rgba(102, 102, 102, 0.9);
}

/* OnClick */
.uk-slidenav:active {
  color: rgba(102, 102, 102, 0.5);
}

/* Icon modifier
 ========================================================================== */
/*
 * Previous
 */
/*
 * Next
 */
/* Size modifier
 ========================================================================== */
.uk-slidenav-large {
  padding: 10px 10px;
}

/* Container
 ========================================================================== */
.uk-slidenav-container {
  display: flex;
}

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-dotnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -12px;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 */
.uk-dotnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 12px;
}

/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.uk-dotnav > * > * {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(102, 102, 102, 0.2);
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
}

/* Hover */
.uk-dotnav > * > :hover {
  background-color: rgba(102, 102, 102, 0.6);
}

/* OnClick */
.uk-dotnav > * > :active {
  background-color: rgba(102, 102, 102, 0.2);
}

/* Active */
.uk-dotnav > .uk-active > * {
  background-color: rgba(102, 102, 102, 0.6);
}

/* Modifier: 'uk-dotnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-dotnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -12px;
}

/* 2 */
.uk-dotnav-vertical > * {
  padding-left: 0;
  padding-top: 12px;
}

/* ========================================================================
   Component: Thumbnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-thumbnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -15px;
}

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-thumbnav > * {
  /* 1 */
  padding-left: 15px;
}

/* Items
 ========================================================================== */
/*
 * Items
 */
.uk-thumbnav > * > * {
  display: inline-block;
}

/* Hover */
/* Active */
/* Modifier: 'uk-thumbnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-thumbnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -15px;
}

/* 2 */
.uk-thumbnav-vertical > * {
  padding-left: 0;
  padding-top: 15px;
}

/* ========================================================================
   Component: Iconnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-iconnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -10px;
}

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-iconnav > * {
  /* 1 */
  padding-left: 10px;
}

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically if there is still some text
 * 2. Imitate white space gap when using flexbox
 * 3. Force text not to affect item height
 * 4. Style
 * 5. Required for `a` if there is still some text
 */
.uk-iconnav > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  line-height: 0;
  /* 4 */
  color: #999;
  /* 5 */
  text-decoration: none;
}

/* Hover */
.uk-iconnav > * > a:hover {
  color: #666;
}

/* Active */
.uk-iconnav > .uk-active > a {
  color: #666;
}

/* Modifier: 'uk-iconnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-iconnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -10px;
}

/* 2 */
.uk-iconnav-vertical > * {
  padding-left: 0;
  padding-top: 10px;
}

/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom;
}

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1;
}

/*
 * Focus
 */
.uk-lightbox :focus {
  outline-color: rgba(255, 255, 255, 0.7);
}

.uk-lightbox :focus-visible {
  outline-color: rgba(255, 255, 255, 0.7);
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden;
}

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity;
}

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh;
}

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto;
}

.uk-lightbox-items > .uk-active {
  display: flex;
}

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
}

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7);
}

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7);
}

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff;
}

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Hover */
.uk-lightbox-button:hover {
  color: #fff;
}

/* OnClick */
/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none;
}

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%;
}

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*=uk-animation-] {
  animation: 0.5s ease-out both;
}

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear;
}

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade, uk-scale-up;
}

.uk-animation-scale-down {
  animation-name: uk-fade, uk-scale-down;
}

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade, uk-slide-top;
}

.uk-animation-slide-bottom {
  animation-name: uk-fade, uk-slide-bottom;
}

.uk-animation-slide-left {
  animation-name: uk-fade, uk-slide-left;
}

.uk-animation-slide-right {
  animation-name: uk-fade, uk-slide-right;
}

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade, uk-slide-top-small;
}

.uk-animation-slide-bottom-small {
  animation-name: uk-fade, uk-slide-bottom-small;
}

.uk-animation-slide-left-small {
  animation-name: uk-fade, uk-slide-left-small;
}

.uk-animation-slide-right-small {
  animation-name: uk-fade, uk-slide-right-small;
}

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade, uk-slide-top-medium;
}

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade, uk-slide-bottom-medium;
}

.uk-animation-slide-left-medium {
  animation-name: uk-fade, uk-slide-left-medium;
}

.uk-animation-slide-right-medium {
  animation-name: uk-fade, uk-slide-right-medium;
}

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-kenburns;
  animation-duration: 15s;
}

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake;
}

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  animation-duration: 2s;
  stroke-dasharray: var(--uk-animation-stroke);
}

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s;
}

/* Toggle animation based on the State of the Parent Element
 ========================================================================== */
.uk-animation-toggle:not(:hover):not(:focus) [class*=uk-animation-] {
  animation-name: none;
}

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
 * Scale
 */
@keyframes uk-scale-up {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes uk-scale-down {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/*
 * Slide
 */
@keyframes uk-slide-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Slide Small
 */
@keyframes uk-slide-top-small {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom-small {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left-small {
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right-small {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Slide Medium
 */
@keyframes uk-slide-top-medium {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom-medium {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left-medium {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right-medium {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Kenburns
 */
@keyframes uk-kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*=uk-child-width] > * {
  box-sizing: border-box;
  width: 100%;
}

.uk-child-width-1-2 > * {
  width: 50%;
}

.uk-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001);
}

.uk-child-width-1-4 > * {
  width: 25%;
}

.uk-child-width-1-5 > * {
  width: 20%;
}

.uk-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001);
}

.uk-child-width-auto > * {
  width: auto;
}

/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*=uk-width]) {
  flex: 1;
  /* 1 */
  min-width: 1px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%;
  }

  .uk-child-width-1-2\@s > * {
    width: 50%;
  }

  .uk-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001);
  }

  .uk-child-width-1-4\@s > * {
    width: 25%;
  }

  .uk-child-width-1-5\@s > * {
    width: 20%;
  }

  .uk-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001);
  }

  .uk-child-width-auto\@s > * {
    width: auto;
  }

  .uk-child-width-expand\@s > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%;
  }

  .uk-child-width-1-2\@m > * {
    width: 50%;
  }

  .uk-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001);
  }

  .uk-child-width-1-4\@m > * {
    width: 25%;
  }

  .uk-child-width-1-5\@m > * {
    width: 20%;
  }

  .uk-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001);
  }

  .uk-child-width-auto\@m > * {
    width: auto;
  }

  .uk-child-width-expand\@m > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%;
  }

  .uk-child-width-1-2\@l > * {
    width: 50%;
  }

  .uk-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001);
  }

  .uk-child-width-1-4\@l > * {
    width: 25%;
  }

  .uk-child-width-1-5\@l > * {
    width: 20%;
  }

  .uk-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001);
  }

  .uk-child-width-auto\@l > * {
    width: auto;
  }

  .uk-child-width-expand\@l > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%;
  }

  .uk-child-width-1-2\@xl > * {
    width: 50%;
  }

  .uk-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001);
  }

  .uk-child-width-1-4\@xl > * {
    width: 25%;
  }

  .uk-child-width-1-5\@xl > * {
    width: 20%;
  }

  .uk-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001);
  }

  .uk-child-width-auto\@xl > * {
    width: auto;
  }

  .uk-child-width-expand\@xl > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*=uk-width] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%;
}

/* Halves */
.uk-width-1-2 {
  width: 50%;
}

/* Thirds */
.uk-width-1-3 {
  width: calc(100% * 1 / 3.001);
}

.uk-width-2-3 {
  width: calc(100% * 2 / 3.001);
}

/* Quarters */
.uk-width-1-4 {
  width: 25%;
}

.uk-width-3-4 {
  width: 75%;
}

/* Fifths */
.uk-width-1-5 {
  width: 20%;
}

.uk-width-2-5 {
  width: 40%;
}

.uk-width-3-5 {
  width: 60%;
}

.uk-width-4-5 {
  width: 80%;
}

/* Sixths */
.uk-width-1-6 {
  width: calc(100% * 1 / 6.001);
}

.uk-width-5-6 {
  width: calc(100% * 5 / 6.001);
}

/* Pixel */
.uk-width-small {
  width: 150px;
}

.uk-width-medium {
  width: 300px;
}

.uk-width-large {
  width: 450px;
}

.uk-width-xlarge {
  width: 600px;
}

.uk-width-2xlarge {
  width: 750px;
}

/* Auto */
.uk-width-auto {
  width: auto;
}

/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%;
  }

  /* Halves */
  .uk-width-1-2\@s {
    width: 50%;
  }

  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001);
  }

  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001);
  }

  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%;
  }

  .uk-width-3-4\@s {
    width: 75%;
  }

  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%;
  }

  .uk-width-2-5\@s {
    width: 40%;
  }

  .uk-width-3-5\@s {
    width: 60%;
  }

  .uk-width-4-5\@s {
    width: 80%;
  }

  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001);
  }

  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001);
  }

  /* Pixel */
  .uk-width-small\@s {
    width: 150px;
  }

  .uk-width-medium\@s {
    width: 300px;
  }

  .uk-width-large\@s {
    width: 450px;
  }

  .uk-width-xlarge\@s {
    width: 600px;
  }

  .uk-width-2xlarge\@s {
    width: 750px;
  }

  /* Auto */
  .uk-width-auto\@s {
    width: auto;
  }

  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%;
  }

  /* Halves */
  .uk-width-1-2\@m {
    width: 50%;
  }

  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001);
  }

  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001);
  }

  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%;
  }

  .uk-width-3-4\@m {
    width: 75%;
  }

  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%;
  }

  .uk-width-2-5\@m {
    width: 40%;
  }

  .uk-width-3-5\@m {
    width: 60%;
  }

  .uk-width-4-5\@m {
    width: 80%;
  }

  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001);
  }

  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001);
  }

  /* Pixel */
  .uk-width-small\@m {
    width: 150px;
  }

  .uk-width-medium\@m {
    width: 300px;
  }

  .uk-width-large\@m {
    width: 450px;
  }

  .uk-width-xlarge\@m {
    width: 600px;
  }

  .uk-width-2xlarge\@m {
    width: 750px;
  }

  /* Auto */
  .uk-width-auto\@m {
    width: auto;
  }

  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%;
  }

  /* Halves */
  .uk-width-1-2\@l {
    width: 50%;
  }

  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001);
  }

  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001);
  }

  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%;
  }

  .uk-width-3-4\@l {
    width: 75%;
  }

  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%;
  }

  .uk-width-2-5\@l {
    width: 40%;
  }

  .uk-width-3-5\@l {
    width: 60%;
  }

  .uk-width-4-5\@l {
    width: 80%;
  }

  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001);
  }

  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001);
  }

  /* Pixel */
  .uk-width-small\@l {
    width: 150px;
  }

  .uk-width-medium\@l {
    width: 300px;
  }

  .uk-width-large\@l {
    width: 450px;
  }

  .uk-width-xlarge\@l {
    width: 600px;
  }

  .uk-width-2xlarge\@l {
    width: 750px;
  }

  /* Auto */
  .uk-width-auto\@l {
    width: auto;
  }

  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%;
  }

  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%;
  }

  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001);
  }

  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001);
  }

  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%;
  }

  .uk-width-3-4\@xl {
    width: 75%;
  }

  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%;
  }

  .uk-width-2-5\@xl {
    width: 40%;
  }

  .uk-width-3-5\@xl {
    width: 60%;
  }

  .uk-width-4-5\@xl {
    width: 80%;
  }

  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001);
  }

  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001);
  }

  /* Pixel */
  .uk-width-small\@xl {
    width: 150px;
  }

  .uk-width-medium\@xl {
    width: 300px;
  }

  .uk-width-large\@xl {
    width: 450px;
  }

  .uk-width-xlarge\@xl {
    width: 600px;
  }

  .uk-width-2xlarge\@xl {
    width: 750px;
  }

  /* Auto */
  .uk-width-auto\@xl {
    width: auto;
  }

  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px;
  }
}
/* Intrinsic Widths
 ========================================================================== */
.uk-width-max-content {
  width: max-content;
}

.uk-width-min-content {
  width: min-content;
}

/* ========================================================================
   Component: Height
 ========================================================================== */
[class*=uk-height] {
  box-sizing: border-box;
}

/*
 * Only works if parent element has a height set
 */
.uk-height-1-1 {
  height: 100%;
}

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  min-height: 100vh;
}

.uk-height-viewport-2 {
  min-height: 200vh;
}

.uk-height-viewport-3 {
  min-height: 300vh;
}

.uk-height-viewport-4 {
  min-height: 400vh;
}

/*
 * Pixel
 * Useful for `overflow: auto`
 */
.uk-height-small {
  height: 150px;
}

.uk-height-medium {
  height: 300px;
}

.uk-height-large {
  height: 450px;
}

.uk-height-max-small {
  max-height: 150px;
}

.uk-height-max-medium {
  max-height: 300px;
}

.uk-height-max-large {
  max-height: 450px;
}

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
.uk-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333;
}

.uk-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}

/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 0.875rem;
  line-height: 1.5;
}

.uk-text-large {
  font-size: 1.5rem;
  line-height: 1.5;
}

.uk-text-default {
  font-size: 16px;
  line-height: 1.5;
}

/* Weight modifier
 ========================================================================== */
.uk-text-light {
  font-weight: 300;
}

.uk-text-normal {
  font-weight: 400;
}

.uk-text-bold {
  font-weight: 700;
}

.uk-text-lighter {
  font-weight: lighter;
}

.uk-text-bolder {
  font-weight: bolder;
}

/* Style modifier
 ========================================================================== */
.uk-text-italic {
  font-style: italic;
}

/* Transform modifier
 ========================================================================== */
.uk-text-capitalize {
  text-transform: capitalize !important;
}

.uk-text-uppercase {
  text-transform: uppercase !important;
}

.uk-text-lowercase {
  text-transform: lowercase !important;
}

/* Decoration modifier
 ========================================================================== */
.uk-text-decoration-none {
  text-decoration: none !important;
}

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #999 !important;
}

.uk-text-emphasis {
  color: #333 !important;
}

.uk-text-primary {
  color: #61A6C7 !important;
}

.uk-text-secondary {
  color: #222 !important;
}

.uk-text-success {
  color: #1eaf3a !important;
}

.uk-text-warning {
  color: #ff6601 !important;
}

.uk-text-danger {
  color: #D60727 !important;
}

/* Background modifier
 ========================================================================== */
/*
 * 1. The background clips to the foreground text. Works in all browsers.
 * 2. Default color is set to transparent.
 * 3. Container fits the text
 * 4. Style
 */
.uk-text-background {
  /* 1 */
  -webkit-background-clip: text;
  /* 2 */
  color: transparent !important;
  /* 3 */
  display: inline-block;
  /* 4 */
  background-color: #61A6C7;
}

/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important;
}

.uk-text-right {
  text-align: right !important;
}

.uk-text-center {
  text-align: center !important;
}

.uk-text-justify {
  text-align: justify !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important;
  }

  .uk-text-right\@s {
    text-align: right !important;
  }

  .uk-text-center\@s {
    text-align: center !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-text-left\@m {
    text-align: left !important;
  }

  .uk-text-right\@m {
    text-align: right !important;
  }

  .uk-text-center\@m {
    text-align: center !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-text-left\@l {
    text-align: left !important;
  }

  .uk-text-right\@l {
    text-align: right !important;
  }

  .uk-text-center\@l {
    text-align: center !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-text-left\@xl {
    text-align: left !important;
  }

  .uk-text-right\@xl {
    text-align: right !important;
  }

  .uk-text-center\@xl {
    text-align: center !important;
  }
}
/*
 * Vertical
 */
.uk-text-top {
  vertical-align: top !important;
}

.uk-text-middle {
  vertical-align: middle !important;
}

.uk-text-bottom {
  vertical-align: bottom !important;
}

.uk-text-baseline {
  vertical-align: baseline !important;
}

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap;
}

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
.uk-text-truncate {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0;
}

/*
 * Wrap long words onto the next line and break them if they are too long to fit.
 * 1. Make it work with table cells in all browsers.
 * Note: Not using `hyphens: auto` because it hyphenates text even if not needed.
 */
.uk-text-break {
  overflow-wrap: break-word;
}

/* 1 */
th.uk-text-break,
td.uk-text-break {
  word-break: break-word;
}

/* ========================================================================
   Component: Column
 ========================================================================== */
[class*=uk-column-] {
  column-gap: 60px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  [class*=uk-column-] {
    column-gap: 60;
  }
}
/*
 * Fix image 1px line wrapping into the next column in Chrome
 */
[class*=uk-column-] img {
  transform: translate3d(0, 0, 0);
}

/* Divider
 ========================================================================== */
/*
 * 1. Double the column gap
 */
.uk-column-divider {
  column-rule: 1px solid #e8e8e8;
  /* 1 */
  column-gap: 120px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-divider {
    column-gap: 120;
  }
}
/* Width modifiers
 ========================================================================== */
.uk-column-1-2 {
  column-count: 2;
}

.uk-column-1-3 {
  column-count: 3;
}

.uk-column-1-4 {
  column-count: 4;
}

.uk-column-1-5 {
  column-count: 5;
}

.uk-column-1-6 {
  column-count: 6;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-column-1-2\@s {
    column-count: 2;
  }

  .uk-column-1-3\@s {
    column-count: 3;
  }

  .uk-column-1-4\@s {
    column-count: 4;
  }

  .uk-column-1-5\@s {
    column-count: 5;
  }

  .uk-column-1-6\@s {
    column-count: 6;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-column-1-2\@m {
    column-count: 2;
  }

  .uk-column-1-3\@m {
    column-count: 3;
  }

  .uk-column-1-4\@m {
    column-count: 4;
  }

  .uk-column-1-5\@m {
    column-count: 5;
  }

  .uk-column-1-6\@m {
    column-count: 6;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-1-2\@l {
    column-count: 2;
  }

  .uk-column-1-3\@l {
    column-count: 3;
  }

  .uk-column-1-4\@l {
    column-count: 4;
  }

  .uk-column-1-5\@l {
    column-count: 5;
  }

  .uk-column-1-6\@l {
    column-count: 6;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-column-1-2\@xl {
    column-count: 2;
  }

  .uk-column-1-3\@xl {
    column-count: 3;
  }

  .uk-column-1-4\@xl {
    column-count: 4;
  }

  .uk-column-1-5\@xl {
    column-count: 5;
  }

  .uk-column-1-6\@xl {
    column-count: 6;
  }
}
/* Make element span across all columns
 * Does not work in Firefox yet
 ========================================================================== */
.uk-column-span {
  column-span: all;
}

/* ========================================================================
   Component: Cover
 ========================================================================== */
/*
 * Works with iframes and embedded content
 * 1. Use attribute to apply transform instantly. Needed if transform is transitioned.
 * 2. Reset responsiveness for embedded content
 * 3. Center object
 * Note: Percent values on the `top` property only works if this element
 *       is absolute positioned or if the container has a height
 */
/* 1 */
[uk-cover],
[data-uk-cover] {
  /* 2 */
  max-width: none;
  /* 3 */
  position: absolute;
  left: 50%;
  top: 50%;
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
}

iframe[uk-cover],
iframe[data-uk-cover] {
  pointer-events: none;
}

/* Container
 ========================================================================== */
/*
 * 1. Parent container which clips resized object
 * 2. Needed if the child is positioned absolute. See note above
 */
.uk-cover-container {
  /* 1 */
  overflow: hidden;
  /* 2 */
  position: relative;
}

/* ========================================================================
   Component: Background
 ========================================================================== */
/* Color
 ========================================================================== */
.uk-background-default {
  background-color: #ffffff;
}

.uk-background-muted {
  background-color: #f8f8f8;
}

.uk-background-primary {
  background-color: #61A6C7;
}

.uk-background-secondary {
  background-color: #222;
}

/* Size
 ========================================================================== */
.uk-background-cover,
.uk-background-contain,
.uk-background-width-1-1,
.uk-background-height-1-1 {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.uk-background-cover {
  background-size: cover;
}

.uk-background-contain {
  background-size: contain;
}

.uk-background-width-1-1 {
  background-size: 100%;
}

.uk-background-height-1-1 {
  background-size: auto 100%;
}

/* Position
 ========================================================================== */
.uk-background-top-left {
  background-position: 0 0;
}

.uk-background-top-center {
  background-position: 50% 0;
}

.uk-background-top-right {
  background-position: 100% 0;
}

.uk-background-center-left {
  background-position: 0 50%;
}

.uk-background-center-center {
  background-position: 50% 50%;
}

.uk-background-center-right {
  background-position: 100% 50%;
}

.uk-background-bottom-left {
  background-position: 0 100%;
}

.uk-background-bottom-center {
  background-position: 50% 100%;
}

.uk-background-bottom-right {
  background-position: 100% 100%;
}

/* Repeat
 ========================================================================== */
.uk-background-norepeat {
  background-repeat: no-repeat;
}

/* Attachment
 ========================================================================== */
/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */
.uk-background-fixed {
  background-attachment: fixed;
  /* 1 */
  backface-visibility: hidden;
}

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */
@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll;
  }
}
/* Image
 ========================================================================== */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important;
  }
}
/* Blend modes
 ========================================================================== */
.uk-background-blend-multiply {
  background-blend-mode: multiply;
}

.uk-background-blend-screen {
  background-blend-mode: screen;
}

.uk-background-blend-overlay {
  background-blend-mode: overlay;
}

.uk-background-blend-darken {
  background-blend-mode: darken;
}

.uk-background-blend-lighten {
  background-blend-mode: lighten;
}

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge;
}

.uk-background-blend-color-burn {
  background-blend-mode: color-burn;
}

.uk-background-blend-hard-light {
  background-blend-mode: hard-light;
}

.uk-background-blend-soft-light {
  background-blend-mode: soft-light;
}

.uk-background-blend-difference {
  background-blend-mode: difference;
}

.uk-background-blend-exclusion {
  background-blend-mode: exclusion;
}

.uk-background-blend-hue {
  background-blend-mode: hue;
}

.uk-background-blend-saturation {
  background-blend-mode: saturation;
}

.uk-background-blend-color {
  background-blend-mode: color;
}

.uk-background-blend-luminosity {
  background-blend-mode: luminosity;
}

/* ========================================================================
   Component: Align
 ========================================================================== */
/*
 * Default
 */
[class*=uk-align] {
  display: block;
  margin-bottom: 60px;
}

* + [class*=uk-align] {
  margin-top: 60px;
}

/*
 * Center
 */
.uk-align-center {
  margin-left: auto;
  margin-right: auto;
}

/*
 * Left/Right
 */
.uk-align-left {
  margin-top: 0;
  margin-right: 60px;
  float: left;
}

.uk-align-right {
  margin-top: 0;
  margin-left: 60px;
  float: right;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-align-left\@s {
    margin-top: 0;
    margin-right: 60px;
    float: left;
  }

  .uk-align-right\@s {
    margin-top: 0;
    margin-left: 60px;
    float: right;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-align-left\@m {
    margin-top: 0;
    margin-right: 60px;
    float: left;
  }

  .uk-align-right\@m {
    margin-top: 0;
    margin-left: 60px;
    float: right;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-align-left\@l {
    margin-top: 0;
    float: left;
  }

  .uk-align-right\@l {
    margin-top: 0;
    float: right;
  }

  .uk-align-left,
.uk-align-left\@s,
.uk-align-left\@m,
.uk-align-left\@l {
    margin-right: 60;
  }

  .uk-align-right,
.uk-align-right\@s,
.uk-align-right\@m,
.uk-align-right\@l {
    margin-left: 60;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-align-left\@xl {
    margin-top: 0;
    margin-right: 60;
    float: left;
  }

  .uk-align-right\@xl {
    margin-top: 0;
    margin-left: 60;
    float: right;
  }
}
/* ========================================================================
   Component: SVG
 ========================================================================== */
/*
 * 1. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 2. Set the fill and stroke color of all SVG elements to the current text color
 */
/* 1 */
.uk-svg,
.uk-svg:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentcolor;
}

.uk-svg:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-svg {
  transform: translate(0, 0);
}

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Panel
 ========================================================================== */
.uk-panel {
  display: flow-root;
  position: relative;
  box-sizing: border-box;
}

/*
 * Remove margin from the last-child
 */
.uk-panel > :last-child {
  margin-bottom: 0;
}

/*
 * Scrollable
 */
.uk-panel-scrollable {
  height: 170px;
  padding: 10px;
  border: 1px solid #e8e8e8;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both;
}

/* Clearfix
 ========================================================================== */
/*
 * 1. `table-cell` is used with `::before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * 2. `table` is used again with `::after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
/* 1 */
.uk-clearfix::before {
  content: "";
  display: table-cell;
}

/* 2 */
.uk-clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/* Float
 ========================================================================== */
/*
 * 1. Prevent content overflow
 */
.uk-float-left {
  float: left;
}

.uk-float-right {
  float: right;
}

/* 1 */
[class*=uk-float-] {
  max-width: 100%;
}

/* Overfow
 ========================================================================== */
.uk-overflow-hidden {
  overflow: hidden;
}

/*
 * Enable scrollbars if content is clipped
 * Note: Firefox ignores `padding-bottom` for the scrollable overflow https://bugzilla.mozilla.org/show_bug.cgi?id=748518
 */
.uk-overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.uk-overflow-auto > :last-child {
  margin-bottom: 0;
}

/* Resize
 ========================================================================== */
.uk-resize {
  resize: both;
}

.uk-resize-vertical {
  resize: vertical;
}

/* Display
 ========================================================================== */
.uk-display-block {
  display: block !important;
}

.uk-display-inline {
  display: inline !important;
}

.uk-display-inline-block {
  display: inline-block !important;
}

/* Inline
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 * 5. Force new layer without creating a new stacking context
 *    to fix 1px glitch when combined with overlays and transitions in Webkit
 * 6. Clip child elements
 */
[class*=uk-inline] {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  -webkit-backface-visibility: hidden;
}

.uk-inline-clip {
  /* 6 */
  overflow: hidden;
}

/* Responsive objects
 ========================================================================== */
/*
 * Preserve original dimensions
 * Because `img, `video`, `canvas` and  `audio` are already responsive by default, see Base component
 */
.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
  max-width: none;
}

/*
 * Responsiveness
 * Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box;
}

/*
 * 1. Set a maximum width. `important` needed to override `uk-preserve-width img`
 * 2. Auto scale the height. Only needed if `height` attribute is present
 */
.uk-responsive-width {
  /* 1 */
  max-width: 100% !important;
  /* 2 */
  height: auto;
}

/*
 * 1. Set a maximum height. Only works if the parent element has a fixed height
 * 2. Auto scale the width. Only needed if `width` attribute is present
 * 3. Reset max-width, which `img, `video`, `canvas` and  `audio` already have by default
 */
.uk-responsive-height {
  /* 1 */
  max-height: 100%;
  /* 2 */
  width: auto;
  /* 3 */
  max-width: none;
}

/*
 * Fix initial iframe width. Without the viewport is expanded on iOS devices
 */
[uk-responsive],
[data-uk-responsive] {
  max-width: 100%;
}

/* Object
 ========================================================================== */
.uk-object-cover {
  object-fit: cover;
}

.uk-object-contain {
  object-fit: contain;
}

.uk-object-fill {
  object-fit: fill;
}

.uk-object-none {
  object-fit: none;
}

.uk-object-scale-down {
  object-fit: scale-down;
}

/* 
 * Position
 */
.uk-object-top-left {
  object-position: 0 0;
}

.uk-object-top-center {
  object-position: 50% 0;
}

.uk-object-top-right {
  object-position: 100% 0;
}

.uk-object-center-left {
  object-position: 0 50%;
}

.uk-object-center-center {
  object-position: 50% 50%;
}

.uk-object-center-right {
  object-position: 100% 50%;
}

.uk-object-bottom-left {
  object-position: 0 100%;
}

.uk-object-bottom-center {
  object-position: 50% 100%;
}

.uk-object-bottom-right {
  object-position: 100% 100%;
}

/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%;
}

.uk-border-pill {
  border-radius: 500px;
}

.uk-border-rounded {
  border-radius: 5px;
}

/*
 * Fix `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 */
.uk-inline-clip[class*=uk-border-] {
  -webkit-transform: translateZ(0);
}

/* Box-shadow
 ========================================================================== */
.uk-box-shadow-small {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-medium {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-large {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-box-shadow-xlarge {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
}

/*
 * Hover
 */
[class*=uk-box-shadow-hover] {
  transition: box-shadow 0.1s ease-in-out;
}

.uk-box-shadow-hover-small:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-hover-medium:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-hover-large:hover {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-box-shadow-hover-xlarge:hover {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
}

/* Box-shadow bottom
 ========================================================================== */
/*
 * 1. Set position.
 * 2. Set style
 * 3. Fix shadow being clipped in Safari if container is animated
 */
@supports (filter: blur(0)) {
  .uk-box-shadow-bottom {
    display: inline-block;
    position: relative;
    z-index: 0;
    max-width: 100%;
    vertical-align: middle;
  }

  .uk-box-shadow-bottom::after {
    content: "";
    /* 1 */
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    z-index: -1;
    /* 2 */
    height: 30px;
    border-radius: 100%;
    background: #444;
    filter: blur(20px);
    /* 3 */
    will-change: filter;
  }
}
/* Drop cap
 ========================================================================== */
/*
 * 1. Firefox doesn't apply `::first-letter` if the first letter is inside child elements
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=214004
 * 2. In Firefox, a floating `::first-letter` doesn't have a line box and there for no `line-height`
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=317933
 */
.uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
  display: block;
  margin-right: 10px;
  float: left;
  font-size: 4.5em;
  line-height: 1;
}

/* 2 */
@-moz-document url-prefix() {
  .uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
    margin-top: 1.1%;
  }
}
/* Logo
 ========================================================================== */
/*
 * 1. Required for `a`
 */
.uk-logo {
  font-size: 1.5rem;
  font-family: myriad-pro, sans-serif;
  color: #333;
  /* 1 */
  text-decoration: none;
}

/* Hover */
.uk-logo:hover {
  color: #333;
  /* 1 */
  text-decoration: none;
}

.uk-logo > :where(img, svg, video) {
  display: block;
}

.uk-logo-inverse {
  display: none;
}

/* Disabled State
 ========================================================================== */
.uk-disabled {
  pointer-events: none;
}

/* Drag State
 ========================================================================== */
/*
 * 1. Needed if moving over elements with have their own cursor on hover, e.g. links or buttons
 * 2. Fix dragging over iframes
 */
.uk-drag,
.uk-drag * {
  cursor: move;
}

/* 2 */
.uk-drag iframe {
  pointer-events: none;
}

/* Dragover State
 ========================================================================== */
/*
 * Create a box-shadow when dragging a file over the upload area
 */
.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);
}

/* Blend modes
 ========================================================================== */
.uk-blend-multiply {
  mix-blend-mode: multiply;
}

.uk-blend-screen {
  mix-blend-mode: screen;
}

.uk-blend-overlay {
  mix-blend-mode: overlay;
}

.uk-blend-darken {
  mix-blend-mode: darken;
}

.uk-blend-lighten {
  mix-blend-mode: lighten;
}

.uk-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.uk-blend-color-burn {
  mix-blend-mode: color-burn;
}

.uk-blend-hard-light {
  mix-blend-mode: hard-light;
}

.uk-blend-soft-light {
  mix-blend-mode: soft-light;
}

.uk-blend-difference {
  mix-blend-mode: difference;
}

.uk-blend-exclusion {
  mix-blend-mode: exclusion;
}

.uk-blend-hue {
  mix-blend-mode: hue;
}

.uk-blend-saturation {
  mix-blend-mode: saturation;
}

.uk-blend-color {
  mix-blend-mode: color;
}

.uk-blend-luminosity {
  mix-blend-mode: luminosity;
}

/* Transform
========================================================================== */
.uk-transform-center {
  transform: translate(-50%, -50%);
}

/* Transform Origin
========================================================================== */
.uk-transform-origin-top-left {
  transform-origin: 0 0;
}

.uk-transform-origin-top-center {
  transform-origin: 50% 0;
}

.uk-transform-origin-top-right {
  transform-origin: 100% 0;
}

.uk-transform-origin-center-left {
  transform-origin: 0 50%;
}

.uk-transform-origin-center-right {
  transform-origin: 100% 50%;
}

.uk-transform-origin-bottom-left {
  transform-origin: 0 100%;
}

.uk-transform-origin-bottom-center {
  transform-origin: 50% 100%;
}

.uk-transform-origin-bottom-right {
  transform-origin: 100% 100%;
}

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex;
}

.uk-flex-inline {
  display: inline-flex;
}

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start;
}

.uk-flex-center {
  justify-content: center;
}

.uk-flex-right {
  justify-content: flex-end;
}

.uk-flex-between {
  justify-content: space-between;
}

.uk-flex-around {
  justify-content: space-around;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start;
  }

  .uk-flex-center\@s {
    justify-content: center;
  }

  .uk-flex-right\@s {
    justify-content: flex-end;
  }

  .uk-flex-between\@s {
    justify-content: space-between;
  }

  .uk-flex-around\@s {
    justify-content: space-around;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start;
  }

  .uk-flex-center\@m {
    justify-content: center;
  }

  .uk-flex-right\@m {
    justify-content: flex-end;
  }

  .uk-flex-between\@m {
    justify-content: space-between;
  }

  .uk-flex-around\@m {
    justify-content: space-around;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start;
  }

  .uk-flex-center\@l {
    justify-content: center;
  }

  .uk-flex-right\@l {
    justify-content: flex-end;
  }

  .uk-flex-between\@l {
    justify-content: space-between;
  }

  .uk-flex-around\@l {
    justify-content: space-around;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start;
  }

  .uk-flex-center\@xl {
    justify-content: center;
  }

  .uk-flex-right\@xl {
    justify-content: flex-end;
  }

  .uk-flex-between\@xl {
    justify-content: space-between;
  }

  .uk-flex-around\@xl {
    justify-content: space-around;
  }
}
/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch;
}

.uk-flex-top {
  align-items: flex-start;
}

.uk-flex-middle {
  align-items: center;
}

.uk-flex-bottom {
  align-items: flex-end;
}

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row;
}

.uk-flex-row-reverse {
  flex-direction: row-reverse;
}

.uk-flex-column {
  flex-direction: column;
}

.uk-flex-column-reverse {
  flex-direction: column-reverse;
}

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap;
}

.uk-flex-wrap {
  flex-wrap: wrap;
}

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch;
}

.uk-flex-wrap-top {
  align-content: flex-start;
}

.uk-flex-wrap-middle {
  align-content: center;
}

.uk-flex-wrap-bottom {
  align-content: flex-end;
}

.uk-flex-wrap-between {
  align-content: space-between;
}

.uk-flex-wrap-around {
  align-content: space-around;
}

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1;
}

.uk-flex-last {
  order: 99;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1;
  }

  .uk-flex-last\@s {
    order: 99;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1;
  }

  .uk-flex-last\@m {
    order: 99;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1;
  }

  .uk-flex-last\@l {
    order: 99;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1;
  }

  .uk-flex-last\@xl {
    order: 99;
  }
}
/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none;
}

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto;
}

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1;
}

/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
.uk-margin {
  margin-bottom: 20px;
}

* + .uk-margin {
  margin-top: 20px !important;
}

.uk-margin-top {
  margin-top: 20px !important;
}

.uk-margin-bottom {
  margin-bottom: 20px !important;
}

.uk-margin-left {
  margin-left: 20px !important;
}

.uk-margin-right {
  margin-right: 20px !important;
}

/* Small
 ========================================================================== */
.uk-margin-small {
  margin-bottom: 10px;
}

* + .uk-margin-small {
  margin-top: 10px !important;
}

.uk-margin-small-top {
  margin-top: 10px !important;
}

.uk-margin-small-bottom {
  margin-bottom: 10px !important;
}

.uk-margin-small-left {
  margin-left: 10px !important;
}

.uk-margin-small-right {
  margin-right: 10px !important;
}

/* Medium
 ========================================================================== */
.uk-margin-medium {
  margin-bottom: 60px;
}

* + .uk-margin-medium {
  margin-top: 60px !important;
}

.uk-margin-medium-top {
  margin-top: 60px !important;
}

.uk-margin-medium-bottom {
  margin-bottom: 60px !important;
}

.uk-margin-medium-left {
  margin-left: 60px !important;
}

.uk-margin-medium-right {
  margin-right: 60px !important;
}

/* Large
 ========================================================================== */
.uk-margin-large {
  margin-bottom: 60px;
}

* + .uk-margin-large {
  margin-top: 60px !important;
}

.uk-margin-large-top {
  margin-top: 60px !important;
}

.uk-margin-large-bottom {
  margin-bottom: 60px !important;
}

.uk-margin-large-left {
  margin-left: 60px !important;
}

.uk-margin-large-right {
  margin-right: 60px !important;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-large {
    margin-bottom: 100px;
  }

  * + .uk-margin-large {
    margin-top: 100px !important;
  }

  .uk-margin-large-top {
    margin-top: 100px !important;
  }

  .uk-margin-large-bottom {
    margin-bottom: 100px !important;
  }

  .uk-margin-large-left {
    margin-left: 100px !important;
  }

  .uk-margin-large-right {
    margin-right: 100px !important;
  }
}
/* XLarge
 ========================================================================== */
.uk-margin-xlarge {
  margin-bottom: 100px;
}

* + .uk-margin-xlarge {
  margin-top: 100px !important;
}

.uk-margin-xlarge-top {
  margin-top: 100px !important;
}

.uk-margin-xlarge-bottom {
  margin-bottom: 100px !important;
}

.uk-margin-xlarge-left {
  margin-left: 100px !important;
}

.uk-margin-xlarge-right {
  margin-right: 100px !important;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-xlarge {
    margin-bottom: 140px;
  }

  * + .uk-margin-xlarge {
    margin-top: 140px !important;
  }

  .uk-margin-xlarge-top {
    margin-top: 140px !important;
  }

  .uk-margin-xlarge-bottom {
    margin-bottom: 140px !important;
  }

  .uk-margin-xlarge-left {
    margin-left: 140px !important;
  }

  .uk-margin-xlarge-right {
    margin-right: 140px !important;
  }
}
/* Auto
 ========================================================================== */
.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.uk-margin-auto-top {
  margin-top: auto !important;
}

.uk-margin-auto-bottom {
  margin-bottom: auto !important;
}

.uk-margin-auto-left {
  margin-left: auto !important;
}

.uk-margin-auto-right {
  margin-right: auto !important;
}

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@s {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@s {
    margin-right: auto !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@m {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@m {
    margin-right: auto !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@l {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@l {
    margin-right: auto !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@xl {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@xl {
    margin-right: auto !important;
  }
}
/* Remove
 ========================================================================== */
.uk-margin-remove {
  margin: 0 !important;
}

.uk-margin-remove-top {
  margin-top: 0 !important;
}

.uk-margin-remove-bottom {
  margin-bottom: 0 !important;
}

.uk-margin-remove-left {
  margin-left: 0 !important;
}

.uk-margin-remove-right {
  margin-right: 0 !important;
}

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child {
  margin-top: 0 !important;
}

.uk-margin-remove-last-child > :last-child {
  margin-bottom: 0 !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@s {
    margin-right: 0 !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@m {
    margin-right: 0 !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@l {
    margin-right: 0 !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@xl {
    margin-right: 0 !important;
  }
}
/* ========================================================================
   Component: Padding
 ========================================================================== */
.uk-padding {
  padding: 60px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding {
    padding: 60;
  }
}
/* Small
 ========================================================================== */
.uk-padding-small {
  padding: 60px;
}

/* Large
 ========================================================================== */
.uk-padding-large {
  padding: 60;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding-large {
    padding: 60;
  }
}
/* Remove
 ========================================================================== */
.uk-padding-remove {
  padding: 0 !important;
}

.uk-padding-remove-top {
  padding-top: 0 !important;
}

.uk-padding-remove-bottom {
  padding-bottom: 0 !important;
}

.uk-padding-remove-left {
  padding-left: 0 !important;
}

.uk-padding-remove-right {
  padding-right: 0 !important;
}

.uk-padding-remove-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.uk-padding-remove-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* ========================================================================
   Component: Position
 ========================================================================== */
:root {
  --uk-position-margin-offset: 0px;
}

/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow.
 */
[class*=uk-position-top],
[class*=uk-position-bottom],
[class*=uk-position-left],
[class*=uk-position-right],
[class*=uk-position-center] {
  position: absolute !important;
  /* 1 */
  max-width: calc(100% - (var(--uk-position-margin-offset) * 2));
  box-sizing: border-box;
}

/*
 * Edges
 * Don't use `width: 100%` because it's wrong if the parent has padding.
 */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0;
}

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0;
}

/*
 * Corners
 */
.uk-position-top-left {
  top: 0;
  left: 0;
}

.uk-position-top-right {
  top: 0;
  right: 0;
}

.uk-position-bottom-left {
  bottom: 0;
  left: 0;
}

.uk-position-bottom-right {
  bottom: 0;
  right: 0;
}

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 *    Using `max-content` requires `max-width` of 100% which is set generally.
 */
.uk-position-center {
  top: calc(50% - var(--uk-position-margin-offset));
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
  /* 1 */
  width: max-content;
}

/* Vertical */
[class*=uk-position-center-left],
[class*=uk-position-center-right] {
  top: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-y: -50%;
  transform: translate(0, var(--uk-position-translate-y));
}

.uk-position-center-left {
  left: 0;
}

.uk-position-center-right {
  right: 0;
}

.uk-position-center-left-out {
  right: 100%;
  width: max-content;
}

.uk-position-center-right-out {
  left: 100%;
  width: max-content;
}

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  transform: translate(var(--uk-position-translate-x), 0);
  /* 1 */
  width: max-content;
}

.uk-position-top-center {
  top: 0;
}

.uk-position-bottom-center {
  bottom: 0;
}

/*
 * Cover
 */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Margin
 ========================================================================== */
.uk-position-small {
  margin: 60px;
  --uk-position-margin-offset: 60px;
}

.uk-position-medium {
  margin: 60px;
  --uk-position-margin-offset: 60px;
}

.uk-position-large {
  margin: 60px;
  --uk-position-margin-offset: 60px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    margin: 50px;
    --uk-position-margin-offset: 50px;
  }
}
/* Schemes
 ========================================================================== */
.uk-position-relative {
  position: relative !important;
}

.uk-position-absolute {
  position: absolute !important;
}

.uk-position-fixed {
  position: fixed !important;
}

.uk-position-sticky {
  position: sticky !important;
}

/* Layer
 ========================================================================== */
.uk-position-z-index {
  z-index: 1;
}

.uk-position-z-index-negative {
  z-index: -1;
}

/* ========================================================================
   Component: Transition
 ========================================================================== */
/* Transitions
 ========================================================================== */
/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 */
:where(.uk-transition-fade),
:where([class*=uk-transition-scale]),
:where([class*=uk-transition-slide]) {
  --uk-position-translate-x: 0;
  --uk-position-translate-y: 0;
}

.uk-transition-fade,
[class*=uk-transition-scale],
[class*=uk-transition-slide] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0;
}

/*
 * Fade
 */
.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-toggle .uk-transition-fade:focus-within,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1;
}

/*
 * Scale
 * 1. Make image rendering the same during the transition as before and after. Prefixed because of Safari.
 */
/* 1 */
[class*=uk-transition-scale] {
  -webkit-backface-visibility: hidden;
}

.uk-transition-scale-up {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
}

.uk-transition-scale-down {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
}

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-toggle .uk-transition-scale-up:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-up {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
  opacity: 1;
}

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-toggle .uk-transition-scale-down:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-down {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  opacity: 1;
}

/*
 * Slide
 */
.uk-transition-slide-top {
  --uk-translate-y: -100%;
}

.uk-transition-slide-bottom {
  --uk-translate-y: 100%;
}

.uk-transition-slide-left {
  --uk-translate-x: -100%;
}

.uk-transition-slide-right {
  --uk-translate-x: 100%;
}

.uk-transition-slide-top-small {
  --uk-translate-y: calc(-1 * 10px);
}

.uk-transition-slide-bottom-small {
  --uk-translate-y: 10px;
}

.uk-transition-slide-left-small {
  --uk-translate-x: calc(-1 * 10px);
}

.uk-transition-slide-right-small {
  --uk-translate-x: 10px;
}

.uk-transition-slide-top-medium {
  --uk-translate-y: calc(-1 * 50px);
}

.uk-transition-slide-bottom-medium {
  --uk-translate-y: 50px;
}

.uk-transition-slide-left-medium {
  --uk-translate-x: calc(-1 * 50px);
}

.uk-transition-slide-right-medium {
  --uk-translate-x: 50px;
}

/* Show */
.uk-transition-toggle:hover [class*=uk-transition-slide],
.uk-transition-toggle:focus [class*=uk-transition-slide],
.uk-transition-toggle [class*=uk-transition-slide]:focus-within,
.uk-transition-active.uk-active [class*=uk-transition-slide] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  opacity: 1;
}

/* Opacity modifier
 ========================================================================== */
.uk-transition-opaque {
  opacity: 1;
}

/* Duration modifiers
 ========================================================================== */
.uk-transition-slow {
  transition-duration: 0.7s;
}

/* ========================================================================
   Component: Visibility
 ========================================================================== */
/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */
[hidden],
.uk-hidden {
  display: none !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-hidden\@m {
    display: none !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-hidden\@l {
    display: none !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-hidden\@xl {
    display: none !important;
  }
}
/*
 * Visible
 */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-visible\@m {
    display: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-visible\@l {
    display: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-visible\@xl {
    display: none !important;
  }
}
/* Visibility
 ========================================================================== */
.uk-invisible {
  visibility: hidden !important;
}

/* Based on the State of the Parent Element
 ========================================================================== */
/*
 * Can't use `display: none` nor `visibility: hidden` because both are not focusable.
 * The target stays visible if any element within receives focus through keyboard.
 */
/*
 * Discard space when hidden.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

/*
 * Keep space when hidden.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  opacity: 0 !important;
}

/* Based on Hover Capability of the Pointing Device
 ========================================================================== */
/*
 * Hover
 */
/* Hide if primary pointing device doesn't support hover, e.g. touch screens. */
@media (hover: none) {
  .uk-hidden-touch {
    display: none !important;
  }
}
/* Hide if primary pointing device supports hover, e.g. mice. */
@media (hover) {
  .uk-hidden-notouch {
    display: none !important;
  }
}
/* ========================================================================
   Component: Inverse
 ========================================================================== */
/*
 * Implemented class depends on the general theme color
 * `uk-light` is for light colors on dark backgrounds
 * `uk-dark` is or dark colors on light backgrounds
 */
.uk-light, .uk-section-primary:not(.uk-preserve-color), .uk-section-secondary:not(.uk-preserve-color), .uk-tile-primary:not(.uk-preserve-color), .uk-tile-secondary:not(.uk-preserve-color), .uk-card-primary.uk-card-body, .uk-card-primary > :not([class*=uk-card-media]), .uk-card-secondary.uk-card-body, .uk-card-secondary > :not([class*=uk-card-media]), .uk-overlay-primary {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light a, .uk-section-primary:not(.uk-preserve-color) a, .uk-section-secondary:not(.uk-preserve-color) a, .uk-tile-primary:not(.uk-preserve-color) a, .uk-tile-secondary:not(.uk-preserve-color) a, .uk-card-primary.uk-card-body a, .uk-card-primary > :not([class*=uk-card-media]) a, .uk-card-secondary.uk-card-body a, .uk-card-secondary > :not([class*=uk-card-media]) a, .uk-overlay-primary a,
.uk-light .uk-link,
.uk-section-primary:not(.uk-preserve-color) .uk-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link,
.uk-card-primary.uk-card-body .uk-link,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link,
.uk-card-secondary.uk-card-body .uk-link,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link,
.uk-overlay-primary .uk-link {
  color: #fff;
}
.uk-light a:hover, .uk-section-primary:not(.uk-preserve-color) a:hover, .uk-section-secondary:not(.uk-preserve-color) a:hover, .uk-tile-primary:not(.uk-preserve-color) a:hover, .uk-tile-secondary:not(.uk-preserve-color) a:hover, .uk-card-primary.uk-card-body a:hover, .uk-card-primary > :not([class*=uk-card-media]) a:hover, .uk-card-secondary.uk-card-body a:hover, .uk-card-secondary > :not([class*=uk-card-media]) a:hover, .uk-overlay-primary a:hover,
.uk-light .uk-link:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link:hover,
.uk-card-primary.uk-card-body .uk-link:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link:hover,
.uk-card-secondary.uk-card-body .uk-link:hover,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link:hover,
.uk-overlay-primary .uk-link:hover,
.uk-light .uk-link-toggle:hover .uk-link,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link,
.uk-overlay-primary .uk-link-toggle:hover .uk-link {
  color: #fff;
}
.uk-light :not(pre) > code, .uk-section-primary:not(.uk-preserve-color) :not(pre) > code, .uk-section-secondary:not(.uk-preserve-color) :not(pre) > code, .uk-tile-primary:not(.uk-preserve-color) :not(pre) > code, .uk-tile-secondary:not(.uk-preserve-color) :not(pre) > code, .uk-card-primary.uk-card-body :not(pre) > code, .uk-card-primary > :not([class*=uk-card-media]) :not(pre) > code, .uk-card-secondary.uk-card-body :not(pre) > code, .uk-card-secondary > :not([class*=uk-card-media]) :not(pre) > code, .uk-overlay-primary :not(pre) > code,
.uk-light :not(pre) > kbd,
.uk-section-primary:not(.uk-preserve-color) :not(pre) > kbd,
.uk-section-secondary:not(.uk-preserve-color) :not(pre) > kbd,
.uk-tile-primary:not(.uk-preserve-color) :not(pre) > kbd,
.uk-tile-secondary:not(.uk-preserve-color) :not(pre) > kbd,
.uk-card-primary.uk-card-body :not(pre) > kbd,
.uk-card-primary > :not([class*=uk-card-media]) :not(pre) > kbd,
.uk-card-secondary.uk-card-body :not(pre) > kbd,
.uk-card-secondary > :not([class*=uk-card-media]) :not(pre) > kbd,
.uk-overlay-primary :not(pre) > kbd,
.uk-light :not(pre) > samp,
.uk-section-primary:not(.uk-preserve-color) :not(pre) > samp,
.uk-section-secondary:not(.uk-preserve-color) :not(pre) > samp,
.uk-tile-primary:not(.uk-preserve-color) :not(pre) > samp,
.uk-tile-secondary:not(.uk-preserve-color) :not(pre) > samp,
.uk-card-primary.uk-card-body :not(pre) > samp,
.uk-card-primary > :not([class*=uk-card-media]) :not(pre) > samp,
.uk-card-secondary.uk-card-body :not(pre) > samp,
.uk-card-secondary > :not([class*=uk-card-media]) :not(pre) > samp,
.uk-overlay-primary :not(pre) > samp {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light em, .uk-section-primary:not(.uk-preserve-color) em, .uk-section-secondary:not(.uk-preserve-color) em, .uk-tile-primary:not(.uk-preserve-color) em, .uk-tile-secondary:not(.uk-preserve-color) em, .uk-card-primary.uk-card-body em, .uk-card-primary > :not([class*=uk-card-media]) em, .uk-card-secondary.uk-card-body em, .uk-card-secondary > :not([class*=uk-card-media]) em, .uk-overlay-primary em {
  color: #fff;
}
.uk-light h1, .uk-section-primary:not(.uk-preserve-color) h1, .uk-section-secondary:not(.uk-preserve-color) h1, .uk-tile-primary:not(.uk-preserve-color) h1, .uk-tile-secondary:not(.uk-preserve-color) h1, .uk-card-primary.uk-card-body h1, .uk-card-primary > :not([class*=uk-card-media]) h1, .uk-card-secondary.uk-card-body h1, .uk-card-secondary > :not([class*=uk-card-media]) h1, .uk-overlay-primary h1, .uk-light .uk-h1, .uk-section-primary:not(.uk-preserve-color) .uk-h1, .uk-section-secondary:not(.uk-preserve-color) .uk-h1, .uk-tile-primary:not(.uk-preserve-color) .uk-h1, .uk-tile-secondary:not(.uk-preserve-color) .uk-h1, .uk-card-primary.uk-card-body .uk-h1, .uk-card-primary > :not([class*=uk-card-media]) .uk-h1, .uk-card-secondary.uk-card-body .uk-h1, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h1, .uk-overlay-primary .uk-h1,
.uk-light h2,
.uk-section-primary:not(.uk-preserve-color) h2,
.uk-section-secondary:not(.uk-preserve-color) h2,
.uk-tile-primary:not(.uk-preserve-color) h2,
.uk-tile-secondary:not(.uk-preserve-color) h2,
.uk-card-primary.uk-card-body h2,
.uk-card-primary > :not([class*=uk-card-media]) h2,
.uk-card-secondary.uk-card-body h2,
.uk-card-secondary > :not([class*=uk-card-media]) h2,
.uk-overlay-primary h2, .uk-light .uk-h2, .uk-section-primary:not(.uk-preserve-color) .uk-h2, .uk-section-secondary:not(.uk-preserve-color) .uk-h2, .uk-tile-primary:not(.uk-preserve-color) .uk-h2, .uk-tile-secondary:not(.uk-preserve-color) .uk-h2, .uk-card-primary.uk-card-body .uk-h2, .uk-card-primary > :not([class*=uk-card-media]) .uk-h2, .uk-card-secondary.uk-card-body .uk-h2, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h2, .uk-overlay-primary .uk-h2,
.uk-light h3,
.uk-section-primary:not(.uk-preserve-color) h3,
.uk-section-secondary:not(.uk-preserve-color) h3,
.uk-tile-primary:not(.uk-preserve-color) h3,
.uk-tile-secondary:not(.uk-preserve-color) h3,
.uk-card-primary.uk-card-body h3,
.uk-card-primary > :not([class*=uk-card-media]) h3,
.uk-card-secondary.uk-card-body h3,
.uk-card-secondary > :not([class*=uk-card-media]) h3,
.uk-overlay-primary h3, .uk-light .uk-h3, .uk-section-primary:not(.uk-preserve-color) .uk-h3, .uk-section-secondary:not(.uk-preserve-color) .uk-h3, .uk-tile-primary:not(.uk-preserve-color) .uk-h3, .uk-tile-secondary:not(.uk-preserve-color) .uk-h3, .uk-card-primary.uk-card-body .uk-h3, .uk-card-primary > :not([class*=uk-card-media]) .uk-h3, .uk-card-secondary.uk-card-body .uk-h3, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h3, .uk-overlay-primary .uk-h3,
.uk-light h4,
.uk-section-primary:not(.uk-preserve-color) h4,
.uk-section-secondary:not(.uk-preserve-color) h4,
.uk-tile-primary:not(.uk-preserve-color) h4,
.uk-tile-secondary:not(.uk-preserve-color) h4,
.uk-card-primary.uk-card-body h4,
.uk-card-primary > :not([class*=uk-card-media]) h4,
.uk-card-secondary.uk-card-body h4,
.uk-card-secondary > :not([class*=uk-card-media]) h4,
.uk-overlay-primary h4, .uk-light .uk-h4, .uk-section-primary:not(.uk-preserve-color) .uk-h4, .uk-section-secondary:not(.uk-preserve-color) .uk-h4, .uk-tile-primary:not(.uk-preserve-color) .uk-h4, .uk-tile-secondary:not(.uk-preserve-color) .uk-h4, .uk-card-primary.uk-card-body .uk-h4, .uk-card-primary > :not([class*=uk-card-media]) .uk-h4, .uk-card-secondary.uk-card-body .uk-h4, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h4, .uk-overlay-primary .uk-h4,
.uk-light h5,
.uk-section-primary:not(.uk-preserve-color) h5,
.uk-section-secondary:not(.uk-preserve-color) h5,
.uk-tile-primary:not(.uk-preserve-color) h5,
.uk-tile-secondary:not(.uk-preserve-color) h5,
.uk-card-primary.uk-card-body h5,
.uk-card-primary > :not([class*=uk-card-media]) h5,
.uk-card-secondary.uk-card-body h5,
.uk-card-secondary > :not([class*=uk-card-media]) h5,
.uk-overlay-primary h5, .uk-light .uk-h5, .uk-section-primary:not(.uk-preserve-color) .uk-h5, .uk-section-secondary:not(.uk-preserve-color) .uk-h5, .uk-tile-primary:not(.uk-preserve-color) .uk-h5, .uk-tile-secondary:not(.uk-preserve-color) .uk-h5, .uk-card-primary.uk-card-body .uk-h5, .uk-card-primary > :not([class*=uk-card-media]) .uk-h5, .uk-card-secondary.uk-card-body .uk-h5, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h5, .uk-overlay-primary .uk-h5,
.uk-light h6,
.uk-section-primary:not(.uk-preserve-color) h6,
.uk-section-secondary:not(.uk-preserve-color) h6,
.uk-tile-primary:not(.uk-preserve-color) h6,
.uk-tile-secondary:not(.uk-preserve-color) h6,
.uk-card-primary.uk-card-body h6,
.uk-card-primary > :not([class*=uk-card-media]) h6,
.uk-card-secondary.uk-card-body h6,
.uk-card-secondary > :not([class*=uk-card-media]) h6,
.uk-overlay-primary h6, .uk-light .uk-h6, .uk-section-primary:not(.uk-preserve-color) .uk-h6, .uk-section-secondary:not(.uk-preserve-color) .uk-h6, .uk-tile-primary:not(.uk-preserve-color) .uk-h6, .uk-tile-secondary:not(.uk-preserve-color) .uk-h6, .uk-card-primary.uk-card-body .uk-h6, .uk-card-primary > :not([class*=uk-card-media]) .uk-h6, .uk-card-secondary.uk-card-body .uk-h6, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h6, .uk-overlay-primary .uk-h6,
.uk-light .uk-heading-small,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-small,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-small,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-small,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-small,
.uk-card-primary.uk-card-body .uk-heading-small,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-small,
.uk-card-secondary.uk-card-body .uk-heading-small,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-small,
.uk-overlay-primary .uk-heading-small,
.uk-light .uk-heading-medium,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-medium,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-medium,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-medium,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-medium,
.uk-card-primary.uk-card-body .uk-heading-medium,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-medium,
.uk-card-secondary.uk-card-body .uk-heading-medium,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-medium,
.uk-overlay-primary .uk-heading-medium,
.uk-light .uk-heading-large,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-large,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-large,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-large,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-large,
.uk-card-primary.uk-card-body .uk-heading-large,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-large,
.uk-card-secondary.uk-card-body .uk-heading-large,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-large,
.uk-overlay-primary .uk-heading-large,
.uk-light .uk-heading-xlarge,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-card-primary.uk-card-body .uk-heading-xlarge,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-xlarge,
.uk-card-secondary.uk-card-body .uk-heading-xlarge,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-xlarge,
.uk-overlay-primary .uk-heading-xlarge,
.uk-light .uk-heading-2xlarge,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-card-primary.uk-card-body .uk-heading-2xlarge,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-2xlarge,
.uk-card-secondary.uk-card-body .uk-heading-2xlarge,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-2xlarge,
.uk-overlay-primary .uk-heading-2xlarge {
  color: #fff;
}
.uk-light hr, .uk-section-primary:not(.uk-preserve-color) hr, .uk-section-secondary:not(.uk-preserve-color) hr, .uk-tile-primary:not(.uk-preserve-color) hr, .uk-tile-secondary:not(.uk-preserve-color) hr, .uk-card-primary.uk-card-body hr, .uk-card-primary > :not([class*=uk-card-media]) hr, .uk-card-secondary.uk-card-body hr, .uk-card-secondary > :not([class*=uk-card-media]) hr, .uk-overlay-primary hr, .uk-light .uk-hr, .uk-section-primary:not(.uk-preserve-color) .uk-hr, .uk-section-secondary:not(.uk-preserve-color) .uk-hr, .uk-tile-primary:not(.uk-preserve-color) .uk-hr, .uk-tile-secondary:not(.uk-preserve-color) .uk-hr, .uk-card-primary.uk-card-body .uk-hr, .uk-card-primary > :not([class*=uk-card-media]) .uk-hr, .uk-card-secondary.uk-card-body .uk-hr, .uk-card-secondary > :not([class*=uk-card-media]) .uk-hr, .uk-overlay-primary .uk-hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light :focus, .uk-section-primary:not(.uk-preserve-color) :focus, .uk-section-secondary:not(.uk-preserve-color) :focus, .uk-tile-primary:not(.uk-preserve-color) :focus, .uk-tile-secondary:not(.uk-preserve-color) :focus, .uk-card-primary.uk-card-body :focus, .uk-card-primary > :not([class*=uk-card-media]) :focus, .uk-card-secondary.uk-card-body :focus, .uk-card-secondary > :not([class*=uk-card-media]) :focus, .uk-overlay-primary :focus {
  outline-color: #fff;
}
.uk-light :focus-visible, .uk-section-primary:not(.uk-preserve-color) :focus-visible, .uk-section-secondary:not(.uk-preserve-color) :focus-visible, .uk-tile-primary:not(.uk-preserve-color) :focus-visible, .uk-tile-secondary:not(.uk-preserve-color) :focus-visible, .uk-card-primary.uk-card-body :focus-visible, .uk-card-primary > :not([class*=uk-card-media]) :focus-visible, .uk-card-secondary.uk-card-body :focus-visible, .uk-card-secondary > :not([class*=uk-card-media]) :focus-visible, .uk-overlay-primary :focus-visible {
  outline-color: #fff;
}
.uk-light a.uk-link-muted, .uk-section-primary:not(.uk-preserve-color) a.uk-link-muted, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-muted, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-muted, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-muted, .uk-card-primary.uk-card-body a.uk-link-muted, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-muted, .uk-card-secondary.uk-card-body a.uk-link-muted, .uk-card-secondary > :not([class*=uk-card-media]) a.uk-link-muted, .uk-overlay-primary a.uk-link-muted,
.uk-light .uk-link-muted a,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted a,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a,
.uk-card-primary.uk-card-body .uk-link-muted a,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-muted a,
.uk-card-secondary.uk-card-body .uk-link-muted a,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-muted a,
.uk-overlay-primary .uk-link-muted a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light a.uk-link-muted:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-card-primary.uk-card-body a.uk-link-muted:hover, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-muted:hover, .uk-card-secondary.uk-card-body a.uk-link-muted:hover, .uk-card-secondary > :not([class*=uk-card-media]) a.uk-link-muted:hover, .uk-overlay-primary a.uk-link-muted:hover,
.uk-light .uk-link-muted a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-card-primary.uk-card-body .uk-link-muted a:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-muted a:hover,
.uk-card-secondary.uk-card-body .uk-link-muted a:hover,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-muted a:hover,
.uk-overlay-primary .uk-link-muted a:hover,
.uk-light .uk-link-toggle:hover .uk-link-muted,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-muted,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-muted,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-muted,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-muted,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-muted {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light a.uk-link-text:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-card-primary.uk-card-body a.uk-link-text:hover, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-text:hover, .uk-card-secondary.uk-card-body a.uk-link-text:hover, .uk-card-secondary > :not([class*=uk-card-media]) a.uk-link-text:hover, .uk-overlay-primary a.uk-link-text:hover,
.uk-light .uk-link-text a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-card-primary.uk-card-body .uk-link-text a:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-text a:hover,
.uk-card-secondary.uk-card-body .uk-link-text a:hover,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-text a:hover,
.uk-overlay-primary .uk-link-text a:hover,
.uk-light .uk-link-toggle:hover .uk-link-text,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-text,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-text,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-text,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-text,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-text {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light a.uk-link-heading:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-card-primary.uk-card-body a.uk-link-heading:hover, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-heading:hover, .uk-card-secondary.uk-card-body a.uk-link-heading:hover, .uk-card-secondary > :not([class*=uk-card-media]) a.uk-link-heading:hover, .uk-overlay-primary a.uk-link-heading:hover,
.uk-light .uk-link-heading a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-card-primary.uk-card-body .uk-link-heading a:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-heading a:hover,
.uk-card-secondary.uk-card-body .uk-link-heading a:hover,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-heading a:hover,
.uk-overlay-primary .uk-link-heading a:hover,
.uk-light .uk-link-toggle:hover .uk-link-heading,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-heading,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-heading,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-heading,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-heading,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-heading {
  color: #fff;
}
.uk-light .uk-heading-divider, .uk-section-primary:not(.uk-preserve-color) .uk-heading-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-divider, .uk-card-primary.uk-card-body .uk-heading-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-heading-divider, .uk-card-secondary.uk-card-body .uk-heading-divider, .uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-divider, .uk-overlay-primary .uk-heading-divider {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-heading-bullet::before, .uk-section-primary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-card-primary.uk-card-body .uk-heading-bullet::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-heading-bullet::before, .uk-card-secondary.uk-card-body .uk-heading-bullet::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-bullet::before, .uk-overlay-primary .uk-heading-bullet::before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-heading-line > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-card-primary.uk-card-body .uk-heading-line > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-heading-line > ::before, .uk-card-secondary.uk-card-body .uk-heading-line > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-line > ::before, .uk-overlay-primary .uk-heading-line > ::before,
.uk-light .uk-heading-line > ::after,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-card-primary.uk-card-body .uk-heading-line > ::after,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-line > ::after,
.uk-card-secondary.uk-card-body .uk-heading-line > ::after,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-line > ::after,
.uk-overlay-primary .uk-heading-line > ::after {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-divider-icon, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon, .uk-card-primary.uk-card-body .uk-divider-icon, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-icon, .uk-card-secondary.uk-card-body .uk-divider-icon, .uk-card-secondary > :not([class*=uk-card-media]) .uk-divider-icon, .uk-overlay-primary .uk-divider-icon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22rgba(255, 255, 255, 0.2)%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-divider-icon::before, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-card-primary.uk-card-body .uk-divider-icon::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-icon::before, .uk-card-secondary.uk-card-body .uk-divider-icon::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-divider-icon::before, .uk-overlay-primary .uk-divider-icon::before,
.uk-light .uk-divider-icon::after,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-card-primary.uk-card-body .uk-divider-icon::after,
.uk-card-primary > :not([class*=uk-card-media]) .uk-divider-icon::after,
.uk-card-secondary.uk-card-body .uk-divider-icon::after,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-divider-icon::after,
.uk-overlay-primary .uk-divider-icon::after {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-divider-small::after, .uk-section-primary:not(.uk-preserve-color) .uk-divider-small::after, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-small::after, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-small::after, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-small::after, .uk-card-primary.uk-card-body .uk-divider-small::after, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-small::after, .uk-card-secondary.uk-card-body .uk-divider-small::after, .uk-card-secondary > :not([class*=uk-card-media]) .uk-divider-small::after, .uk-overlay-primary .uk-divider-small::after {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-divider-vertical, .uk-section-primary:not(.uk-preserve-color) .uk-divider-vertical, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-vertical, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-vertical, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-vertical, .uk-card-primary.uk-card-body .uk-divider-vertical, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-vertical, .uk-card-secondary.uk-card-body .uk-divider-vertical, .uk-card-secondary > :not([class*=uk-card-media]) .uk-divider-vertical, .uk-overlay-primary .uk-divider-vertical {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-list-muted > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-card-primary.uk-card-body .uk-list-muted > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-muted > ::before, .uk-card-secondary.uk-card-body .uk-list-muted > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-muted > ::before, .uk-overlay-primary .uk-list-muted > ::before {
  color: rgba(255, 255, 255, 0.5) !important;
}
.uk-light .uk-list-emphasis > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-card-primary.uk-card-body .uk-list-emphasis > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-emphasis > ::before, .uk-card-secondary.uk-card-body .uk-list-emphasis > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-emphasis > ::before, .uk-overlay-primary .uk-list-emphasis > ::before {
  color: #fff !important;
}
.uk-light .uk-list-primary > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-card-primary.uk-card-body .uk-list-primary > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-primary > ::before, .uk-card-secondary.uk-card-body .uk-list-primary > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-primary > ::before, .uk-overlay-primary .uk-list-primary > ::before {
  color: #fff !important;
}
.uk-light .uk-list-secondary > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-card-primary.uk-card-body .uk-list-secondary > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-secondary > ::before, .uk-card-secondary.uk-card-body .uk-list-secondary > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-secondary > ::before, .uk-overlay-primary .uk-list-secondary > ::before {
  color: #fff !important;
}
.uk-light .uk-list-bullet > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-card-primary.uk-card-body .uk-list-bullet > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-bullet > ::before, .uk-card-secondary.uk-card-body .uk-list-bullet > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-bullet > ::before, .uk-overlay-primary .uk-list-bullet > ::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-list-divider > :nth-child(n+2), .uk-section-primary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-section-secondary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-tile-primary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-tile-secondary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-card-primary.uk-card-body .uk-list-divider > :nth-child(n+2), .uk-card-primary > :not([class*=uk-card-media]) .uk-list-divider > :nth-child(n+2), .uk-card-secondary.uk-card-body .uk-list-divider > :nth-child(n+2), .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-divider > :nth-child(n+2), .uk-overlay-primary .uk-list-divider > :nth-child(n+2) {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-list-striped > :nth-of-type(odd), .uk-section-primary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-section-secondary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-tile-primary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-tile-secondary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-card-primary.uk-card-body .uk-list-striped > :nth-of-type(odd), .uk-card-primary > :not([class*=uk-card-media]) .uk-list-striped > :nth-of-type(odd), .uk-card-secondary.uk-card-body .uk-list-striped > :nth-of-type(odd), .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-striped > :nth-of-type(odd), .uk-overlay-primary .uk-list-striped > :nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light .uk-icon-link, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link, .uk-card-primary.uk-card-body .uk-icon-link, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-link, .uk-card-secondary.uk-card-body .uk-icon-link, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-link, .uk-overlay-primary .uk-icon-link {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-icon-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-card-primary.uk-card-body .uk-icon-link:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-link:hover, .uk-card-secondary.uk-card-body .uk-icon-link:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-link:hover, .uk-overlay-primary .uk-icon-link:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-icon-link:active, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:active, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:active, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:active, .uk-card-primary.uk-card-body .uk-icon-link:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-link:active, .uk-card-secondary.uk-card-body .uk-icon-link:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-link:active, .uk-overlay-primary .uk-icon-link:active,
.uk-light .uk-active > .uk-icon-link,
.uk-section-primary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
.uk-card-primary.uk-card-body .uk-active > .uk-icon-link,
.uk-card-primary > :not([class*=uk-card-media]) .uk-active > .uk-icon-link,
.uk-card-secondary.uk-card-body .uk-active > .uk-icon-link,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-active > .uk-icon-link,
.uk-overlay-primary .uk-active > .uk-icon-link {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-icon-button, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button, .uk-card-primary.uk-card-body .uk-icon-button, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button, .uk-card-secondary.uk-card-body .uk-icon-button, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-button, .uk-overlay-primary .uk-icon-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-icon-button:hover, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-card-primary.uk-card-body .uk-icon-button:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button:hover, .uk-card-secondary.uk-card-body .uk-icon-button:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-button:hover, .uk-overlay-primary .uk-icon-button:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-icon-button:active, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:active, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:active, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:active, .uk-card-primary.uk-card-body .uk-icon-button:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button:active, .uk-card-secondary.uk-card-body .uk-icon-button:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-button:active, .uk-overlay-primary .uk-icon-button:active {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-input, .uk-section-primary:not(.uk-preserve-color) .uk-input, .uk-section-secondary:not(.uk-preserve-color) .uk-input, .uk-tile-primary:not(.uk-preserve-color) .uk-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-input, .uk-card-primary.uk-card-body .uk-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-input, .uk-card-secondary.uk-card-body .uk-input, .uk-card-secondary > :not([class*=uk-card-media]) .uk-input, .uk-overlay-primary .uk-input,
.uk-light .uk-select,
.uk-section-primary:not(.uk-preserve-color) .uk-select,
.uk-section-secondary:not(.uk-preserve-color) .uk-select,
.uk-tile-primary:not(.uk-preserve-color) .uk-select,
.uk-tile-secondary:not(.uk-preserve-color) .uk-select,
.uk-card-primary.uk-card-body .uk-select,
.uk-card-primary > :not([class*=uk-card-media]) .uk-select,
.uk-card-secondary.uk-card-body .uk-select,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-select,
.uk-overlay-primary .uk-select,
.uk-light .uk-textarea,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea,
.uk-card-primary.uk-card-body .uk-textarea,
.uk-card-primary > :not([class*=uk-card-media]) .uk-textarea,
.uk-card-secondary.uk-card-body .uk-textarea,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-textarea,
.uk-overlay-primary .uk-textarea {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
}
.uk-light .uk-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-input:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-input:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-input:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-input:focus, .uk-card-primary.uk-card-body .uk-input:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-input:focus, .uk-card-secondary.uk-card-body .uk-input:focus, .uk-card-secondary > :not([class*=uk-card-media]) .uk-input:focus, .uk-overlay-primary .uk-input:focus,
.uk-light .uk-select:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-select:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-select:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-select:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-select:focus,
.uk-card-primary.uk-card-body .uk-select:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-select:focus,
.uk-card-secondary.uk-card-body .uk-select:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-select:focus,
.uk-overlay-primary .uk-select:focus,
.uk-light .uk-textarea:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-card-primary.uk-card-body .uk-textarea:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-textarea:focus,
.uk-card-secondary.uk-card-body .uk-textarea:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-textarea:focus,
.uk-overlay-primary .uk-textarea:focus {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-input::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-input::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-input::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-input::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-input::placeholder, .uk-card-primary.uk-card-body .uk-input::placeholder, .uk-card-primary > :not([class*=uk-card-media]) .uk-input::placeholder, .uk-card-secondary.uk-card-body .uk-input::placeholder, .uk-card-secondary > :not([class*=uk-card-media]) .uk-input::placeholder, .uk-overlay-primary .uk-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-textarea::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-card-primary.uk-card-body .uk-textarea::placeholder, .uk-card-primary > :not([class*=uk-card-media]) .uk-textarea::placeholder, .uk-card-secondary.uk-card-body .uk-textarea::placeholder, .uk-card-secondary > :not([class*=uk-card-media]) .uk-textarea::placeholder, .uk-overlay-primary .uk-textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-select:not([multiple]):not([size]), .uk-section-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-section-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-tile-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-tile-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-card-primary.uk-card-body .uk-select:not([multiple]):not([size]), .uk-card-primary > :not([class*=uk-card-media]) .uk-select:not([multiple]):not([size]), .uk-card-secondary.uk-card-body .uk-select:not([multiple]):not([size]), .uk-card-secondary > :not([class*=uk-card-media]) .uk-select:not([multiple]):not([size]), .uk-overlay-primary .uk-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-input[list]:hover, .uk-section-primary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-card-primary.uk-card-body .uk-input[list]:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-input[list]:hover, .uk-card-secondary.uk-card-body .uk-input[list]:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-input[list]:hover, .uk-overlay-primary .uk-input[list]:hover,
.uk-light .uk-input[list]:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-card-primary.uk-card-body .uk-input[list]:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-input[list]:focus,
.uk-card-secondary.uk-card-body .uk-input[list]:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-input[list]:focus,
.uk-overlay-primary .uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-radio, .uk-section-primary:not(.uk-preserve-color) .uk-radio, .uk-section-secondary:not(.uk-preserve-color) .uk-radio, .uk-tile-primary:not(.uk-preserve-color) .uk-radio, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio, .uk-card-primary.uk-card-body .uk-radio, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio, .uk-card-secondary.uk-card-body .uk-radio, .uk-card-secondary > :not([class*=uk-card-media]) .uk-radio, .uk-overlay-primary .uk-radio,
.uk-light .uk-checkbox,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox,
.uk-card-primary.uk-card-body .uk-checkbox,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox,
.uk-card-secondary.uk-card-body .uk-checkbox,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox,
.uk-overlay-primary .uk-checkbox {
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light .uk-radio:focus, .uk-section-primary:not(.uk-preserve-color) .uk-radio:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:focus, .uk-card-primary.uk-card-body .uk-radio:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:focus, .uk-card-secondary.uk-card-body .uk-radio:focus, .uk-card-secondary > :not([class*=uk-card-media]) .uk-radio:focus, .uk-overlay-primary .uk-radio:focus,
.uk-light .uk-checkbox:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-card-primary.uk-card-body .uk-checkbox:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:focus,
.uk-overlay-primary .uk-checkbox:focus {
  background-color: rgba(255, 255, 255, 0.15);
}
.uk-light .uk-radio:checked, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-card-primary.uk-card-body .uk-radio:checked, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:checked, .uk-card-secondary.uk-card-body .uk-radio:checked, .uk-card-secondary > :not([class*=uk-card-media]) .uk-radio:checked, .uk-overlay-primary .uk-radio:checked,
.uk-light .uk-checkbox:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-card-primary.uk-card-body .uk-checkbox:checked,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:checked,
.uk-card-secondary.uk-card-body .uk-checkbox:checked,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:checked,
.uk-overlay-primary .uk-checkbox:checked,
.uk-light .uk-checkbox:indeterminate,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate,
.uk-card-secondary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate,
.uk-overlay-primary .uk-checkbox:indeterminate {
  background-color: #fff;
}
.uk-light .uk-radio:checked:focus, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-card-primary.uk-card-body .uk-radio:checked:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:checked:focus, .uk-card-secondary.uk-card-body .uk-radio:checked:focus, .uk-card-secondary > :not([class*=uk-card-media]) .uk-radio:checked:focus, .uk-overlay-primary .uk-radio:checked:focus,
.uk-light .uk-checkbox:checked:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-card-primary.uk-card-body .uk-checkbox:checked:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:checked:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:checked:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:checked:focus,
.uk-overlay-primary .uk-checkbox:checked:focus,
.uk-light .uk-checkbox:indeterminate:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:indeterminate:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate:focus,
.uk-overlay-primary .uk-checkbox:indeterminate:focus {
  background-color: white;
}
.uk-light .uk-radio:checked, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-card-primary.uk-card-body .uk-radio:checked, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:checked, .uk-card-secondary.uk-card-body .uk-radio:checked, .uk-card-secondary > :not([class*=uk-card-media]) .uk-radio:checked, .uk-overlay-primary .uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-checkbox:checked, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-card-primary.uk-card-body .uk-checkbox:checked, .uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:checked, .uk-card-secondary.uk-card-body .uk-checkbox:checked, .uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:checked, .uk-overlay-primary .uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-checkbox:indeterminate, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-card-primary.uk-card-body .uk-checkbox:indeterminate, .uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate, .uk-card-secondary.uk-card-body .uk-checkbox:indeterminate, .uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate, .uk-overlay-primary .uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-form-icon, .uk-section-primary:not(.uk-preserve-color) .uk-form-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-form-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-form-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon, .uk-card-primary.uk-card-body .uk-form-icon, .uk-card-primary > :not([class*=uk-card-media]) .uk-form-icon, .uk-card-secondary.uk-card-body .uk-form-icon, .uk-card-secondary > :not([class*=uk-card-media]) .uk-form-icon, .uk-overlay-primary .uk-form-icon {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-form-icon:hover, .uk-section-primary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-card-primary.uk-card-body .uk-form-icon:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-form-icon:hover, .uk-card-secondary.uk-card-body .uk-form-icon:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-form-icon:hover, .uk-overlay-primary .uk-form-icon:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-button-default, .uk-section-primary:not(.uk-preserve-color) .uk-button-default, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default, .uk-card-primary.uk-card-body .uk-button-default, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-default, .uk-card-secondary.uk-card-body .uk-button-default, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-default, .uk-overlay-primary .uk-button-default {
  background-color: #fff;
  color: #666;
}
.uk-light .uk-button-default:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:hover, .uk-card-primary.uk-card-body .uk-button-default:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-default:hover, .uk-card-secondary.uk-card-body .uk-button-default:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-default:hover, .uk-overlay-primary .uk-button-default:hover {
  background-color: #f2f2f2;
  color: #666;
}
.uk-light .uk-button-default:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:active, .uk-card-primary.uk-card-body .uk-button-default:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-default:active, .uk-card-secondary.uk-card-body .uk-button-default:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-default:active, .uk-overlay-primary .uk-button-default:active,
.uk-light .uk-button-default.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-card-primary.uk-card-body .uk-button-default.uk-active,
.uk-card-primary > :not([class*=uk-card-media]) .uk-button-default.uk-active,
.uk-card-secondary.uk-card-body .uk-button-default.uk-active,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-button-default.uk-active,
.uk-overlay-primary .uk-button-default.uk-active {
  background-color: #e6e6e6;
  color: #666;
}
.uk-light .uk-button-primary, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary, .uk-card-primary.uk-card-body .uk-button-primary, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary, .uk-card-secondary.uk-card-body .uk-button-primary, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-primary, .uk-overlay-primary .uk-button-primary {
  background-color: #fff;
  color: #666;
}
.uk-light .uk-button-primary:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-card-primary.uk-card-body .uk-button-primary:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary:hover, .uk-card-secondary.uk-card-body .uk-button-primary:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-primary:hover, .uk-overlay-primary .uk-button-primary:hover {
  background-color: #f2f2f2;
  color: #666;
}
.uk-light .uk-button-primary:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:active, .uk-card-primary.uk-card-body .uk-button-primary:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary:active, .uk-card-secondary.uk-card-body .uk-button-primary:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-primary:active, .uk-overlay-primary .uk-button-primary:active,
.uk-light .uk-button-primary.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-card-primary.uk-card-body .uk-button-primary.uk-active,
.uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary.uk-active,
.uk-card-secondary.uk-card-body .uk-button-primary.uk-active,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-button-primary.uk-active,
.uk-overlay-primary .uk-button-primary.uk-active {
  background-color: #e6e6e6;
  color: #666;
}
.uk-light .uk-button-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary, .uk-card-primary.uk-card-body .uk-button-secondary, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary, .uk-card-secondary.uk-card-body .uk-button-secondary, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-secondary, .uk-overlay-primary .uk-button-secondary {
  background-color: #fff;
  color: #666;
}
.uk-light .uk-button-secondary:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-card-primary.uk-card-body .uk-button-secondary:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary:hover, .uk-card-secondary.uk-card-body .uk-button-secondary:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-secondary:hover, .uk-overlay-primary .uk-button-secondary:hover {
  background-color: #f2f2f2;
  color: #666;
}
.uk-light .uk-button-secondary:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-card-primary.uk-card-body .uk-button-secondary:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary:active, .uk-card-secondary.uk-card-body .uk-button-secondary:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-secondary:active, .uk-overlay-primary .uk-button-secondary:active,
.uk-light .uk-button-secondary.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-card-primary.uk-card-body .uk-button-secondary.uk-active,
.uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary.uk-active,
.uk-card-secondary.uk-card-body .uk-button-secondary.uk-active,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-button-secondary.uk-active,
.uk-overlay-primary .uk-button-secondary.uk-active {
  background-color: #e6e6e6;
  color: #666;
}
.uk-light .uk-button-text, .uk-section-primary:not(.uk-preserve-color) .uk-button-text, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text, .uk-card-primary.uk-card-body .uk-button-text, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text, .uk-card-secondary.uk-card-body .uk-button-text, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-text, .uk-overlay-primary .uk-button-text {
  color: #fff;
}
.uk-light .uk-button-text:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:hover, .uk-card-primary.uk-card-body .uk-button-text:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text:hover, .uk-card-secondary.uk-card-body .uk-button-text:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-text:hover, .uk-overlay-primary .uk-button-text:hover {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-button-text:disabled, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-card-primary.uk-card-body .uk-button-text:disabled, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text:disabled, .uk-card-secondary.uk-card-body .uk-button-text:disabled, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-text:disabled, .uk-overlay-primary .uk-button-text:disabled {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-button-link, .uk-section-primary:not(.uk-preserve-color) .uk-button-link, .uk-section-secondary:not(.uk-preserve-color) .uk-button-link, .uk-tile-primary:not(.uk-preserve-color) .uk-button-link, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-link, .uk-card-primary.uk-card-body .uk-button-link, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-link, .uk-card-secondary.uk-card-body .uk-button-link, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-link, .uk-overlay-primary .uk-button-link {
  color: #fff;
}
.uk-light .uk-button-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-link:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-link:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-link:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-link:hover, .uk-card-primary.uk-card-body .uk-button-link:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-link:hover, .uk-card-secondary.uk-card-body .uk-button-link:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-link:hover, .uk-overlay-primary .uk-button-link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-grid-divider > :not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-grid-divider > :not(.uk-first-column)::before, .uk-overlay-primary .uk-grid-divider > :not(.uk-first-column)::before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-section-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-primary.uk-card-body .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-secondary.uk-card-body .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-overlay-primary .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-close, .uk-section-primary:not(.uk-preserve-color) .uk-close, .uk-section-secondary:not(.uk-preserve-color) .uk-close, .uk-tile-primary:not(.uk-preserve-color) .uk-close, .uk-tile-secondary:not(.uk-preserve-color) .uk-close, .uk-card-primary.uk-card-body .uk-close, .uk-card-primary > :not([class*=uk-card-media]) .uk-close, .uk-card-secondary.uk-card-body .uk-close, .uk-card-secondary > :not([class*=uk-card-media]) .uk-close, .uk-overlay-primary .uk-close {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-close:hover, .uk-section-primary:not(.uk-preserve-color) .uk-close:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-close:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-close:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-close:hover, .uk-card-primary.uk-card-body .uk-close:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-close:hover, .uk-card-secondary.uk-card-body .uk-close:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-close:hover, .uk-overlay-primary .uk-close:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-totop, .uk-section-primary:not(.uk-preserve-color) .uk-totop, .uk-section-secondary:not(.uk-preserve-color) .uk-totop, .uk-tile-primary:not(.uk-preserve-color) .uk-totop, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop, .uk-card-primary.uk-card-body .uk-totop, .uk-card-primary > :not([class*=uk-card-media]) .uk-totop, .uk-card-secondary.uk-card-body .uk-totop, .uk-card-secondary > :not([class*=uk-card-media]) .uk-totop, .uk-overlay-primary .uk-totop {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-totop:hover, .uk-section-primary:not(.uk-preserve-color) .uk-totop:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-totop:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-totop:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop:hover, .uk-card-primary.uk-card-body .uk-totop:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-totop:hover, .uk-card-secondary.uk-card-body .uk-totop:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-totop:hover, .uk-overlay-primary .uk-totop:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-totop:active, .uk-section-primary:not(.uk-preserve-color) .uk-totop:active, .uk-section-secondary:not(.uk-preserve-color) .uk-totop:active, .uk-tile-primary:not(.uk-preserve-color) .uk-totop:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop:active, .uk-card-primary.uk-card-body .uk-totop:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-totop:active, .uk-card-secondary.uk-card-body .uk-totop:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-totop:active, .uk-overlay-primary .uk-totop:active {
  color: #fff;
}
.uk-light .uk-badge, .uk-section-primary:not(.uk-preserve-color) .uk-badge, .uk-section-secondary:not(.uk-preserve-color) .uk-badge, .uk-tile-primary:not(.uk-preserve-color) .uk-badge, .uk-tile-secondary:not(.uk-preserve-color) .uk-badge, .uk-card-primary.uk-card-body .uk-badge, .uk-card-primary > :not([class*=uk-card-media]) .uk-badge, .uk-card-secondary.uk-card-body .uk-badge, .uk-card-secondary > :not([class*=uk-card-media]) .uk-badge, .uk-overlay-primary .uk-badge {
  background-color: #fff;
  color: #666 !important;
}
.uk-light .uk-label, .uk-section-primary:not(.uk-preserve-color) .uk-label, .uk-section-secondary:not(.uk-preserve-color) .uk-label, .uk-tile-primary:not(.uk-preserve-color) .uk-label, .uk-tile-secondary:not(.uk-preserve-color) .uk-label, .uk-card-primary.uk-card-body .uk-label, .uk-card-primary > :not([class*=uk-card-media]) .uk-label, .uk-card-secondary.uk-card-body .uk-label, .uk-card-secondary > :not([class*=uk-card-media]) .uk-label, .uk-overlay-primary .uk-label {
  background-color: #fff;
  color: #666;
}
.uk-light .uk-article-meta, .uk-section-primary:not(.uk-preserve-color) .uk-article-meta, .uk-section-secondary:not(.uk-preserve-color) .uk-article-meta, .uk-tile-primary:not(.uk-preserve-color) .uk-article-meta, .uk-tile-secondary:not(.uk-preserve-color) .uk-article-meta, .uk-card-primary.uk-card-body .uk-article-meta, .uk-card-primary > :not([class*=uk-card-media]) .uk-article-meta, .uk-card-secondary.uk-card-body .uk-article-meta, .uk-card-secondary > :not([class*=uk-card-media]) .uk-article-meta, .uk-overlay-primary .uk-article-meta {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-input, .uk-card-primary.uk-card-body .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-input, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-input, .uk-overlay-primary .uk-search-input {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-search-input::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-card-primary.uk-card-body .uk-search-input::placeholder, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-input::placeholder, .uk-card-secondary.uk-card-body .uk-search-input::placeholder, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-input::placeholder, .uk-overlay-primary .uk-search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search .uk-search-icon, .uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-card-primary.uk-card-body .uk-search .uk-search-icon, .uk-card-primary > :not([class*=uk-card-media]) .uk-search .uk-search-icon, .uk-card-secondary.uk-card-body .uk-search .uk-search-icon, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search .uk-search-icon, .uk-overlay-primary .uk-search .uk-search-icon {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search .uk-search-icon:hover, .uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-card-primary.uk-card-body .uk-search .uk-search-icon:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-search .uk-search-icon:hover, .uk-card-secondary.uk-card-body .uk-search .uk-search-icon:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search .uk-search-icon:hover, .uk-overlay-primary .uk-search .uk-search-icon:hover {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search-default .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-card-primary.uk-card-body .uk-search-default .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-default .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-default .uk-search-input, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-default .uk-search-input, .uk-overlay-primary .uk-search-default .uk-search-input {
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light .uk-search-default .uk-search-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-card-primary.uk-card-body .uk-search-default .uk-search-input:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-default .uk-search-input:focus, .uk-card-secondary.uk-card-body .uk-search-default .uk-search-input:focus, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-default .uk-search-input:focus, .uk-overlay-primary .uk-search-default .uk-search-input:focus {
  background-color: rgba(255, 255, 255, 0.15);
}
.uk-light .uk-search-navbar .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-card-primary.uk-card-body .uk-search-navbar .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-navbar .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-navbar .uk-search-input, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-navbar .uk-search-input, .uk-overlay-primary .uk-search-navbar .uk-search-input {
  background-color: transparent;
}
.uk-light .uk-search-large .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-card-primary.uk-card-body .uk-search-large .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-large .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-large .uk-search-input, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-large .uk-search-input, .uk-overlay-primary .uk-search-large .uk-search-input {
  background-color: transparent;
}
.uk-light .uk-search-toggle, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle, .uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle, .uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle, .uk-card-primary.uk-card-body .uk-search-toggle, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-toggle, .uk-card-secondary.uk-card-body .uk-search-toggle, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-toggle, .uk-overlay-primary .uk-search-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search-toggle:hover, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-card-primary.uk-card-body .uk-search-toggle:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-toggle:hover, .uk-card-secondary.uk-card-body .uk-search-toggle:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-toggle:hover, .uk-overlay-primary .uk-search-toggle:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-parent-icon > .uk-parent > a::after, .uk-section-primary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent > a::after, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent > a::after, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent > a::after, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent > a::after, .uk-card-primary.uk-card-body .uk-nav-parent-icon > .uk-parent > a::after, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-parent-icon > .uk-parent > a::after, .uk-card-secondary.uk-card-body .uk-nav-parent-icon > .uk-parent > a::after, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-parent-icon > .uk-parent > a::after, .uk-overlay-primary .uk-nav-parent-icon > .uk-parent > a::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba(255, 255, 255, 0.7)%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-section-primary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-card-primary.uk-card-body .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-card-secondary.uk-card-body .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-overlay-primary .uk-nav-parent-icon > .uk-parent.uk-open > a::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba(255, 255, 255, 0.7)%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-nav-default > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-card-primary.uk-card-body .uk-nav-default > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default > li > a, .uk-card-secondary.uk-card-body .uk-nav-default > li > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default > li > a, .uk-overlay-primary .uk-nav-default > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-default > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-default > li > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default > li > a:hover, .uk-card-secondary.uk-card-body .uk-nav-default > li > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default > li > a:hover, .uk-overlay-primary .uk-nav-default > li > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-default > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-default > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-default > li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default > li.uk-active > a, .uk-overlay-primary .uk-nav-default > li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-default .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-header, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-header, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-header, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-header, .uk-overlay-primary .uk-nav-default .uk-nav-header {
  color: #fff;
}
.uk-light .uk-nav-default .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-divider, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-divider, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-divider, .uk-overlay-primary .uk-nav-default .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-nav-default .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a, .uk-overlay-primary .uk-nav-default .uk-nav-sub a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-default .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a:hover, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-default .uk-nav-sub a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-default .uk-nav-sub li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-primary > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-card-primary.uk-card-body .uk-nav-primary > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary > li > a, .uk-card-secondary.uk-card-body .uk-nav-primary > li > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary > li > a, .uk-overlay-primary .uk-nav-primary > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-primary > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-primary > li > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary > li > a:hover, .uk-card-secondary.uk-card-body .uk-nav-primary > li > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary > li > a:hover, .uk-overlay-primary .uk-nav-primary > li > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-primary > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-primary > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-primary > li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary > li.uk-active > a, .uk-overlay-primary .uk-nav-primary > li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-primary .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-header, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-header, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-header, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-header, .uk-overlay-primary .uk-nav-primary .uk-nav-header {
  color: #fff;
}
.uk-light .uk-nav-primary .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-divider, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-divider, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-divider, .uk-overlay-primary .uk-nav-primary .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-nav-primary .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-primary .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a:hover, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-primary .uk-nav-sub li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-section-primary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-section-secondary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-tile-primary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-tile-secondary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-card-primary.uk-card-body .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-card-primary > :not([class*=uk-card-media]) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-card-secondary.uk-card-body .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-overlay-primary .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-navbar-nav > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a, .uk-overlay-primary .uk-navbar-nav > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-navbar-nav > li:hover > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li:hover > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li:hover > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li:hover > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-nav > li:hover > a, .uk-overlay-primary .uk-navbar-nav > li:hover > a,
.uk-light .uk-navbar-nav > li > a[aria-expanded=true],
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-card-primary.uk-card-body .uk-navbar-nav > li > a[aria-expanded=true],
.uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-card-secondary.uk-card-body .uk-navbar-nav > li > a[aria-expanded=true],
.uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-overlay-primary .uk-navbar-nav > li > a[aria-expanded=true] {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-navbar-nav > li > a:active, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a:active, .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a:active, .uk-overlay-primary .uk-navbar-nav > li > a:active {
  color: #fff;
}
.uk-light .uk-navbar-nav > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-nav > li.uk-active > a, .uk-overlay-primary .uk-navbar-nav > li.uk-active > a {
  color: #fff;
}
.uk-light .uk-navbar-item, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-item, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-item, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-item, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-item, .uk-card-primary.uk-card-body .uk-navbar-item, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-item, .uk-card-secondary.uk-card-body .uk-navbar-item, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-item, .uk-overlay-primary .uk-navbar-item {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-navbar-toggle, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-card-primary.uk-card-body .uk-navbar-toggle, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-toggle, .uk-card-secondary.uk-card-body .uk-navbar-toggle, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-toggle, .uk-overlay-primary .uk-navbar-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-navbar-toggle:hover, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-card-primary.uk-card-body .uk-navbar-toggle:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-toggle:hover, .uk-card-secondary.uk-card-body .uk-navbar-toggle:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-toggle:hover, .uk-overlay-primary .uk-navbar-toggle:hover,
.uk-light .uk-navbar-toggle[aria-expanded=true],
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-card-primary.uk-card-body .uk-navbar-toggle[aria-expanded=true],
.uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-toggle[aria-expanded=true],
.uk-card-secondary.uk-card-body .uk-navbar-toggle[aria-expanded=true],
.uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-toggle[aria-expanded=true],
.uk-overlay-primary .uk-navbar-toggle[aria-expanded=true] {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-subnav > * > :first-child, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-card-primary.uk-card-body .uk-subnav > * > :first-child, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > * > :first-child, .uk-card-secondary.uk-card-body .uk-subnav > * > :first-child, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav > * > :first-child, .uk-overlay-primary .uk-subnav > * > :first-child {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-subnav > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-card-primary.uk-card-body .uk-subnav > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > * > a:hover, .uk-card-secondary.uk-card-body .uk-subnav > * > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav > * > a:hover, .uk-overlay-primary .uk-subnav > * > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-subnav > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-card-primary.uk-card-body .uk-subnav > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > .uk-active > a, .uk-card-secondary.uk-card-body .uk-subnav > .uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav > .uk-active > a, .uk-overlay-primary .uk-subnav > .uk-active > a {
  color: #fff;
}
.uk-light .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-overlay-primary .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-subnav-pill > * > :first-child, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-card-primary.uk-card-body .uk-subnav-pill > * > :first-child, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > * > :first-child, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > :first-child, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav-pill > * > :first-child, .uk-overlay-primary .uk-subnav-pill > * > :first-child {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-subnav-pill > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > * > a:hover, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav-pill > * > a:hover, .uk-overlay-primary .uk-subnav-pill > * > a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-subnav-pill > * > a:active, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > * > a:active, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > a:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav-pill > * > a:active, .uk-overlay-primary .uk-subnav-pill > * > a:active {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-subnav-pill > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-card-primary.uk-card-body .uk-subnav-pill > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > .uk-active > a, .uk-card-secondary.uk-card-body .uk-subnav-pill > .uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav-pill > .uk-active > a, .uk-overlay-primary .uk-subnav-pill > .uk-active > a {
  background-color: #fff;
  color: #666;
}
.uk-light .uk-subnav > .uk-disabled > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-card-primary.uk-card-body .uk-subnav > .uk-disabled > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > .uk-disabled > a, .uk-card-secondary.uk-card-body .uk-subnav > .uk-disabled > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav > .uk-disabled > a, .uk-overlay-primary .uk-subnav > .uk-disabled > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-breadcrumb > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-card-primary.uk-card-body .uk-breadcrumb > * > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > * > *, .uk-card-secondary.uk-card-body .uk-breadcrumb > * > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-breadcrumb > * > *, .uk-overlay-primary .uk-breadcrumb > * > * {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-breadcrumb > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-card-primary.uk-card-body .uk-breadcrumb > * > :hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > * > :hover, .uk-card-secondary.uk-card-body .uk-breadcrumb > * > :hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-breadcrumb > * > :hover, .uk-overlay-primary .uk-breadcrumb > * > :hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-breadcrumb > :last-child > *, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-card-primary.uk-card-body .uk-breadcrumb > :last-child > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > :last-child > *, .uk-card-secondary.uk-card-body .uk-breadcrumb > :last-child > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-breadcrumb > :last-child > *, .uk-overlay-primary .uk-breadcrumb > :last-child > * {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-overlay-primary .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-pagination > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-card-primary.uk-card-body .uk-pagination > * > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > * > *, .uk-card-secondary.uk-card-body .uk-pagination > * > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-pagination > * > *, .uk-overlay-primary .uk-pagination > * > * {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-pagination > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-card-primary.uk-card-body .uk-pagination > * > :hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > * > :hover, .uk-card-secondary.uk-card-body .uk-pagination > * > :hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-pagination > * > :hover, .uk-overlay-primary .uk-pagination > * > :hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-pagination > .uk-active > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-card-primary.uk-card-body .uk-pagination > .uk-active > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > .uk-active > *, .uk-card-secondary.uk-card-body .uk-pagination > .uk-active > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-pagination > .uk-active > *, .uk-overlay-primary .uk-pagination > .uk-active > * {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-pagination > .uk-disabled > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-card-primary.uk-card-body .uk-pagination > .uk-disabled > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > .uk-disabled > *, .uk-card-secondary.uk-card-body .uk-pagination > .uk-disabled > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-pagination > .uk-disabled > *, .uk-overlay-primary .uk-pagination > .uk-disabled > * {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-tab > * > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > * > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > * > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > * > a, .uk-card-primary.uk-card-body .uk-tab > * > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > * > a, .uk-card-secondary.uk-card-body .uk-tab > * > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-tab > * > a, .uk-overlay-primary .uk-tab > * > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-tab > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-card-primary.uk-card-body .uk-tab > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > * > a:hover, .uk-card-secondary.uk-card-body .uk-tab > * > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-tab > * > a:hover, .uk-overlay-primary .uk-tab > * > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-tab > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-card-primary.uk-card-body .uk-tab > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > .uk-active > a, .uk-card-secondary.uk-card-body .uk-tab > .uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-tab > .uk-active > a, .uk-overlay-primary .uk-tab > .uk-active > a {
  color: #fff;
}
.uk-light .uk-tab > .uk-disabled > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-card-primary.uk-card-body .uk-tab > .uk-disabled > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > .uk-disabled > a, .uk-card-secondary.uk-card-body .uk-tab > .uk-disabled > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-tab > .uk-disabled > a, .uk-overlay-primary .uk-tab > .uk-disabled > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-slidenav, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-card-primary.uk-card-body .uk-slidenav, .uk-card-primary > :not([class*=uk-card-media]) .uk-slidenav, .uk-card-secondary.uk-card-body .uk-slidenav, .uk-card-secondary > :not([class*=uk-card-media]) .uk-slidenav, .uk-overlay-primary .uk-slidenav {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-slidenav:hover, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-card-primary.uk-card-body .uk-slidenav:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-slidenav:hover, .uk-card-secondary.uk-card-body .uk-slidenav:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-slidenav:hover, .uk-overlay-primary .uk-slidenav:hover {
  color: rgba(255, 255, 255, 0.95);
}
.uk-light .uk-slidenav:active, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:active, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:active, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:active, .uk-card-primary.uk-card-body .uk-slidenav:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-slidenav:active, .uk-card-secondary.uk-card-body .uk-slidenav:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-slidenav:active, .uk-overlay-primary .uk-slidenav:active {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-dotnav > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-card-primary.uk-card-body .uk-dotnav > * > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > * > *, .uk-card-secondary.uk-card-body .uk-dotnav > * > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-dotnav > * > *, .uk-overlay-primary .uk-dotnav > * > * {
  background-color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-dotnav > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-card-primary.uk-card-body .uk-dotnav > * > :hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > * > :hover, .uk-card-secondary.uk-card-body .uk-dotnav > * > :hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-dotnav > * > :hover, .uk-overlay-primary .uk-dotnav > * > :hover {
  background-color: rgba(255, 255, 255, 0.9);
}
.uk-light .uk-dotnav > * > :active, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-card-primary.uk-card-body .uk-dotnav > * > :active, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > * > :active, .uk-card-secondary.uk-card-body .uk-dotnav > * > :active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-dotnav > * > :active, .uk-overlay-primary .uk-dotnav > * > :active {
  background-color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-dotnav > .uk-active > *, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-card-primary.uk-card-body .uk-dotnav > .uk-active > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > .uk-active > *, .uk-card-secondary.uk-card-body .uk-dotnav > .uk-active > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-dotnav > .uk-active > *, .uk-overlay-primary .uk-dotnav > .uk-active > * {
  background-color: rgba(255, 255, 255, 0.9);
}
.uk-light .uk-accordion-title, .uk-section-primary:not(.uk-preserve-color) .uk-accordion-title, .uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title, .uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title, .uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title, .uk-card-primary.uk-card-body .uk-accordion-title, .uk-card-primary > :not([class*=uk-card-media]) .uk-accordion-title, .uk-card-secondary.uk-card-body .uk-accordion-title, .uk-card-secondary > :not([class*=uk-card-media]) .uk-accordion-title, .uk-overlay-primary .uk-accordion-title {
  color: #fff;
}
.uk-light .uk-accordion-title:hover, .uk-section-primary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-card-primary.uk-card-body .uk-accordion-title:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-accordion-title:hover, .uk-card-secondary.uk-card-body .uk-accordion-title:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-accordion-title:hover, .uk-overlay-primary .uk-accordion-title:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-iconnav > * > a, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-card-primary.uk-card-body .uk-iconnav > * > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-iconnav > * > a, .uk-card-secondary.uk-card-body .uk-iconnav > * > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-iconnav > * > a, .uk-overlay-primary .uk-iconnav > * > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-iconnav > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-card-primary.uk-card-body .uk-iconnav > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-iconnav > * > a:hover, .uk-card-secondary.uk-card-body .uk-iconnav > * > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-iconnav > * > a:hover, .uk-overlay-primary .uk-iconnav > * > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-iconnav > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-card-primary.uk-card-body .uk-iconnav > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-iconnav > .uk-active > a, .uk-card-secondary.uk-card-body .uk-iconnav > .uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-iconnav > .uk-active > a, .uk-overlay-primary .uk-iconnav > .uk-active > a {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-text-lead, .uk-section-primary:not(.uk-preserve-color) .uk-text-lead, .uk-section-secondary:not(.uk-preserve-color) .uk-text-lead, .uk-tile-primary:not(.uk-preserve-color) .uk-text-lead, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-lead, .uk-card-primary.uk-card-body .uk-text-lead, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-lead, .uk-card-secondary.uk-card-body .uk-text-lead, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-lead, .uk-overlay-primary .uk-text-lead {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-text-meta, .uk-section-primary:not(.uk-preserve-color) .uk-text-meta, .uk-section-secondary:not(.uk-preserve-color) .uk-text-meta, .uk-tile-primary:not(.uk-preserve-color) .uk-text-meta, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-meta, .uk-card-primary.uk-card-body .uk-text-meta, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-meta, .uk-card-secondary.uk-card-body .uk-text-meta, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-meta, .uk-overlay-primary .uk-text-meta {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-text-muted, .uk-section-primary:not(.uk-preserve-color) .uk-text-muted, .uk-section-secondary:not(.uk-preserve-color) .uk-text-muted, .uk-tile-primary:not(.uk-preserve-color) .uk-text-muted, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-muted, .uk-card-primary.uk-card-body .uk-text-muted, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-muted, .uk-card-secondary.uk-card-body .uk-text-muted, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-muted, .uk-overlay-primary .uk-text-muted {
  color: rgba(255, 255, 255, 0.5) !important;
}
.uk-light .uk-text-emphasis, .uk-section-primary:not(.uk-preserve-color) .uk-text-emphasis, .uk-section-secondary:not(.uk-preserve-color) .uk-text-emphasis, .uk-tile-primary:not(.uk-preserve-color) .uk-text-emphasis, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-emphasis, .uk-card-primary.uk-card-body .uk-text-emphasis, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-emphasis, .uk-card-secondary.uk-card-body .uk-text-emphasis, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-emphasis, .uk-overlay-primary .uk-text-emphasis {
  color: #fff !important;
}
.uk-light .uk-text-primary, .uk-section-primary:not(.uk-preserve-color) .uk-text-primary, .uk-section-secondary:not(.uk-preserve-color) .uk-text-primary, .uk-tile-primary:not(.uk-preserve-color) .uk-text-primary, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-primary, .uk-card-primary.uk-card-body .uk-text-primary, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-primary, .uk-card-secondary.uk-card-body .uk-text-primary, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-primary, .uk-overlay-primary .uk-text-primary {
  color: #fff !important;
}
.uk-light .uk-text-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-text-secondary, .uk-section-secondary:not(.uk-preserve-color) .uk-text-secondary, .uk-tile-primary:not(.uk-preserve-color) .uk-text-secondary, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-secondary, .uk-card-primary.uk-card-body .uk-text-secondary, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-secondary, .uk-card-secondary.uk-card-body .uk-text-secondary, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-secondary, .uk-overlay-primary .uk-text-secondary {
  color: #fff !important;
}
.uk-light .uk-column-divider, .uk-section-primary:not(.uk-preserve-color) .uk-column-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-column-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-column-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-column-divider, .uk-card-primary.uk-card-body .uk-column-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-column-divider, .uk-card-secondary.uk-card-body .uk-column-divider, .uk-card-secondary > :not([class*=uk-card-media]) .uk-column-divider, .uk-overlay-primary .uk-column-divider {
  column-rule-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-logo, .uk-section-primary:not(.uk-preserve-color) .uk-logo, .uk-section-secondary:not(.uk-preserve-color) .uk-logo, .uk-tile-primary:not(.uk-preserve-color) .uk-logo, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo, .uk-card-primary.uk-card-body .uk-logo, .uk-card-primary > :not([class*=uk-card-media]) .uk-logo, .uk-card-secondary.uk-card-body .uk-logo, .uk-card-secondary > :not([class*=uk-card-media]) .uk-logo, .uk-overlay-primary .uk-logo {
  color: #fff;
}
.uk-light .uk-logo:hover, .uk-section-primary:not(.uk-preserve-color) .uk-logo:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-logo:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-logo:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo:hover, .uk-card-primary.uk-card-body .uk-logo:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-logo:hover, .uk-card-secondary.uk-card-body .uk-logo:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-logo:hover, .uk-overlay-primary .uk-logo:hover {
  color: #fff;
}
.uk-light .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-section-primary:not(.uk-preserve-color) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-section-secondary:not(.uk-preserve-color) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-tile-primary:not(.uk-preserve-color) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-tile-secondary:not(.uk-preserve-color) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-card-primary.uk-card-body .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-card-primary > :not([class*=uk-card-media]) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-card-secondary.uk-card-body .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-card-secondary > :not([class*=uk-card-media]) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-overlay-primary .uk-logo > :not(.uk-logo-inverse):not(:only-of-type) {
  display: none;
}
.uk-light .uk-logo-inverse, .uk-section-primary:not(.uk-preserve-color) .uk-logo-inverse, .uk-section-secondary:not(.uk-preserve-color) .uk-logo-inverse, .uk-tile-primary:not(.uk-preserve-color) .uk-logo-inverse, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo-inverse, .uk-card-primary.uk-card-body .uk-logo-inverse, .uk-card-primary > :not([class*=uk-card-media]) .uk-logo-inverse, .uk-card-secondary.uk-card-body .uk-logo-inverse, .uk-card-secondary > :not([class*=uk-card-media]) .uk-logo-inverse, .uk-overlay-primary .uk-logo-inverse {
  display: block;
}

/* ========================================================================
   Component: Print
 ========================================================================== */
@media print {
  *,
*::before,
*::after {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }
}